import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  CarnivalLocationsTypeArray,
} from '@noitada/shared/arrays/carnival.array';

import {
  FormatCarnivalMarkerType,
} from '@noitada/shared/config/carnival.config';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECarnivalLocation,
} from '@noitada/shared/enums/Carnival/carnival-location.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Overlay,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconCheckBoxOff,
  IconCheckBoxOn,
} from '../../../../components/Icons';

import {
  renderCarnivalTypeIcon,
} from '../../../../config/icon-carnival-markers.config';

import {
  CarnivalMapsContainer,
  CarnivalMapTypesContainer,
  CarnivalMapTypesButton,
  CarnivalMapTypesText,
  CarnivalMarkAllView,
  CarnivalMarkAllButton,
  CarnivalMarkAllIcon,
  CarnivalMarkAllText,
} from './styled';



export interface IProps {
  selectTypes?: Array<any>;
  visible?: boolean;
  canAddGhost?: boolean;
  onItemPress?: any;
  onCheckAllPress?: any;
  onUncheckAllPress?: any;
  onCancelPress?: any;
}



const CarnivalMapChooseModal: React.FC<IProps> = (props: IProps) => {
  if (!props.visible) {
    return null;
  }

  const { t: translate } = useTranslation();



  function renderItemMarkerChoose(typeMarker: ECarnivalLocation, index: number) {
    const includesItemMarker = props?.selectTypes?.includes(typeMarker);

    return (

      <CarnivalMapTypesButton
        key={index}
        backgroundColor={includesItemMarker ? Colors.primary : Colors.white}
        onClick={() => {
          props?.onItemPress(typeMarker);
        }}>

        {renderCarnivalTypeIcon(
          typeMarker,
          includesItemMarker ? Colors.white : Colors.black,
          35,
        )}

        <CarnivalMapTypesText
          color={includesItemMarker ? Colors.white : Colors.black}>
          {translate(FormatCarnivalMarkerType(typeMarker))}
        </CarnivalMapTypesText>

      </CarnivalMapTypesButton>

    );
  }


  function onCancel() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  }



  return (

    <Overlay
      visible={props.visible}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <CarnivalMapsContainer>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.CARNIVAL_INTEREST_POINTS)}
            </Title>
          }
          rightIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={() => {
                onCancel();
              }}
            />
          }
        />

        <CarnivalMapTypesContainer>

          {CarnivalLocationsTypeArray.map((typeMarker, index: number) => (
            renderItemMarkerChoose(typeMarker.value, index)
          ))}

        </CarnivalMapTypesContainer>



        <CarnivalMarkAllView>

          <CarnivalMarkAllButton
            onClick={() => {
              if (props.onCheckAllPress) {
                props.onCheckAllPress();
              }
            }}>

            <CarnivalMarkAllIcon>
              <IconCheckBoxOn
                color={Colors.white}
                size={18}
              />
            </CarnivalMarkAllIcon>


            <CarnivalMarkAllText>
              {translate(TranslateConfig.ACTION_CHECK_ALL)}
            </CarnivalMarkAllText>

          </CarnivalMarkAllButton>



          <CarnivalMarkAllButton
            onClick={() => {
              if (props.onUncheckAllPress) {
                props.onUncheckAllPress();
              }
            }}>

            <CarnivalMarkAllIcon>
              <IconCheckBoxOff
                color={Colors.white}
                size={18}
              />
            </CarnivalMarkAllIcon>


            <CarnivalMarkAllText>
              {translate(TranslateConfig.ACTION_UNCHECK_ALL)}
            </CarnivalMarkAllText>

          </CarnivalMarkAllButton>

        </CarnivalMarkAllView>

      </CarnivalMapsContainer>

    </Overlay>

  );
};



export default CarnivalMapChooseModal;
