import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconCheck,
} from '../../../Icons';

import {
  CardLocationCountryContainer,
  CardLocationCountryImage,
  CardLocationCountryInfoView,
  CardLocationInfoNameView,
  CardLocationCountryNameText,
  CardLocationInfoIcon,
  CardLocationCountryCurrencyText,
  CardLocationCountryCodeText,
} from './styled';



export interface IProps {
  selected: boolean;
  title: string;
  code?: string;
  currency?: string;
  image?: any;
  onPress?: any;
}



const CardLocationCountry: React.FC<IProps> = (props: IProps) => {
  return (

    <CardLocationCountryContainer
      selected={props.selected}
      onClick={props.onPress}>

      <CardLocationCountryImage
        alt={'CountryImage'}
        src={props.image}
      />


      <CardLocationCountryInfoView>

        <CardLocationInfoNameView>

          <CardLocationCountryNameText
            selected={props.selected}>
            {props.title}
          </CardLocationCountryNameText>


          {props.selected && (
            <CardLocationInfoIcon>
              <IconCheck
                color={Colors.accent}
                size={22}
              />
            </CardLocationInfoIcon>
          )}

        </CardLocationInfoNameView>


        <CardLocationCountryCurrencyText
          selected={props.selected}>
          {props.currency}
        </CardLocationCountryCurrencyText>

      </CardLocationCountryInfoView>


      <CardLocationCountryCodeText
        selected={props.selected}>
        {props.code}
      </CardLocationCountryCodeText>

    </CardLocationCountryContainer>

  );
};



export default CardLocationCountry;
