import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const BottomCartStyle = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${Sizes.BOTTOM_TOOLBAR_ANDROID}px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${Colors.primary};
`;


export const ContainNumberItems = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding-top: 2px;
  color: ${Colors.black};
  background-color: ${Colors.white};
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
`;

export const TextCart = styled.p`
  display: flex;
  flex: 1;
  margin-left: 15px;
  padding-top: 3px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 700;
`;

export const PriceCart = styled.p`
  padding-top: 3px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 700;
`;
