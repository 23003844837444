import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  color?: string;
}



export const DetailsPaymentsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;


export const DetailsPaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;


export const DetailsPaymentsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 1px;
`;

export const DetailsPaymentsLabel = styled.p`
  display: flex;
  flex: 1;
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 17px;
`;

export const DetailsPaymentsPrice = styled.p`
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 17px;
`;
