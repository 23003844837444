import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  items: [] as any,
  total: 0,
};



// Action Types

export const USER_ADD_SACOLA = '@user/ADD_SACOLA';
export const USER_CLEAR_SACOLA = '@user/CLEAR_SACOLA';
export const USER_UPDATE_SACOLA_ITEM = '@user/UPDATE_SACOLA_ITEM';
export const USER_REMOVE_SACOLA_ITEM = '@user/REMOVE_SACOLA_ITEM';



// Action Creators

export const addItemAction = createAction<object>(USER_ADD_SACOLA);
export const clearItemsAction = createAction<object>(USER_CLEAR_SACOLA);
export const updateItemAction = createAction<object>(USER_UPDATE_SACOLA_ITEM);
export const removeItemAction = createAction<object>(USER_REMOVE_SACOLA_ITEM);



// Handlers

const calcTotal = (items) => {
  let total = 0;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    total += (item.itemGroup.price * item.quantity);
  }
  return total;
};


const addItem = (state = INITIAL_STATE, action) => {
  const newItems = [...state.items, action.payload];

  const total = calcTotal(newItems);

  return {
    ...state,
    items: newItems,
    total: total,
  };
};


const updateItem = (state = INITIAL_STATE, action) => {
  const updatedItems = [...state.items];
  updatedItems[action.payload.idx] = action.payload.item;

  const total = calcTotal(updatedItems);
  return {
    ...state,
    items: updatedItems,
    total: total,
  };
};


const removeItem = (state = INITIAL_STATE, action) => {
  const updatedItems = [...state.items];
  updatedItems.splice(action.payload.idx, 1);

  const total = calcTotal(updatedItems);
  return {
    ...state,
    items: updatedItems,
    total: total,
  };
};


const clearItems = () => ({
  ...INITIAL_STATE,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [addItemAction.type]: addItem,
  [clearItemsAction.type]: clearItems,
  [updateItemAction.type]: updateItem,
  [removeItemAction.type]: removeItem,
});
