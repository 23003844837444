import React from 'react';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  IconArrowExpand,
  IconArrowExpanded,
} from '../../../Icons';

import {
  GuideListHeaderContent,
  InfoGuideListTitle,
  InfoGuideListActionsView,
} from './styled';



export interface IProps {
  expanded?: boolean;
  title?: string;
  onPress?: any;
  onEditPress?: any;
}



const CardCarnivalGuideHeader: React.FC<IProps> = (props: IProps) => {
  function generateBackgroundColor() {
    const colors = [
      Colors.carnivalBlue,
      Colors.carnivalGreen,
      Colors.carnivalPurple,
      Colors.carnivalRed,
      Colors.carnivalYellow,
    ];

    return colors[Math.floor(Math.random() * colors.length) + 0];
  }


  function renderExpandableIcon() {
    if (props.expanded) {
      return (

        <IconArrowExpanded
          color={Colors.white}
          size={Sizes.iconNavigationBottomSize}
        />

      );
    }


    return (

      <IconArrowExpand
        color={Colors.white}
        size={Sizes.iconNavigationBottomSize}
      />

    );
  }



  return (

    <GuideListHeaderContent
      backgroundColor={props.expanded
        ? Colors.primary
        : generateBackgroundColor()
      }
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <InfoGuideListTitle>
        {props.title || '-'}
      </InfoGuideListTitle>


      <InfoGuideListActionsView>



        {renderExpandableIcon()}

      </InfoGuideListActionsView>

    </GuideListHeaderContent>

  );
};



export default CardCarnivalGuideHeader;
