import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  SubtitleDetails,
} from '../../../styles/styled.layout';

import {
  TicketCreatedByButton,
  TicketCreatedByContainer,
  TicketCompanyImage,
  TicketCreatedByInfo,
  TicketCreatedByTitle,
  TicketCreatedByAction,
  TicketsCompanySeeText,
} from './styled';



export interface IProps {
  name?: string;
  image?: string;
  onPress?: any;
}



const DetailsCompanyRealization: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const companyImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.placeholderAvatarCompany
    : props.image;



  return (

    <TicketCreatedByButton
      onClick={props.onPress}>

      <SubtitleDetails>
        {translate(TranslateConfig.PARTNER)}
      </SubtitleDetails>


      <TicketCreatedByContainer>

        <TicketCompanyImage
          src={companyImage}
        />


        <TicketCreatedByInfo>
          <TicketCreatedByTitle>
            {props.name || '-'}
          </TicketCreatedByTitle>

        </TicketCreatedByInfo>


        <TicketCreatedByAction>
          <TicketsCompanySeeText>
            {translate(TranslateConfig.ACTION_PROFILE_SEE)}
          </TicketsCompanySeeText>
        </TicketCreatedByAction>

      </TicketCreatedByContainer>

    </TicketCreatedByButton>

  );
};



export default DetailsCompanyRealization;
