import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const ReserveContentPadder = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
`;

export const MyReservePlaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 8px;
`;

export const MyReserveDate = styled.h1`
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
`;



export const CardReservePaymentView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;

export const CardReserveReservePayView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const CardReserveReservePayText = styled.p`
  color: ${Colors.textApp};
  font-size: 14px;
`;

export const CardReservePaymentChoose = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 5px;
`;
