export enum ELanguage {
  PT_BR = 'pt_br',

  ES_AR = 'es_ar',
  EN_US = 'en_us',
  IT_IT = 'it_it',
  FR_FR = 'fr_fr',
}



export default ELanguage;
