import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
  Sizes,
  Keys,
} from '@noitada/shared/constants';

import Vars from '@noitada/shared/environments/variables';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Content,
  Items,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconEmail,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialLinkedIn,
  IconSocialMessenger,
  IconSocialThreads,
  IconSocialTikTok,
  IconSocialTwitter,
  IconSocialWhatsApp,
  IconSocialYoutube,
} from '../../../components/Icons';

import {
  openEmail,
  openSite,
  openWebApp,
  openWhatsApp,
} from '../../../config/linking.config';

import {
  AboutInfoContent,
  AboutInfoDetailsView,
  AboutMessageContent,
  AboutMessageItem,
  AboutMessageItemText,
  HeaderContent,
  AboutLogo,
  AboutVersion,
  AboutAppSlogan,
  DividerAbout,
  AboutSocialContent,
  AboutSocialGridStyled,
  AboutFooterContent,
  AboutImageFooter,
  AboutCenterFooter,
  AboutFooterImage,
  AboutFooterSlogan,
} from './styled';



const AboutScreen: React.FC = () => {
  const { t: translate } = useTranslation();



  function renderItemsAboutGrid(
    label: string,
    icon: any,
    onPress: any,
  ) {
    return (

      <AboutSocialGridStyled
        activeOpacity={Sizes.ButtonActiveOpacity}
        icon={icon}
        iconGridLabel={label}
        iconGridColor={Colors.white}
        onPress={onPress}
      />

    );
  }


  function renderItemsContactList(
    icon: any,
    label: string,
    onPress: any,
  ) {
    return (

      <AboutMessageItem
        onClick={onPress}>

        {icon}

        <AboutMessageItemText>
          {label}
        </AboutMessageItemText>

      </AboutMessageItem>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {`${translate(TranslateConfig.ABOUT)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>
      </Helmet>



      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            {translate(TranslateConfig.ABOUT)}
          </Title>
        }
      />



      <Content>

        <AboutInfoDetailsView>

          <AboutInfoContent>

            <HeaderContent>
              <AboutLogo
                alt={'logo'}
                src={Images.logoIconAppWhite}
              />

              <AboutVersion>
                {translate(TranslateConfig.NAME_APP).toUpperCase()}
              </AboutVersion>

              <AboutAppSlogan>
                {translate(TranslateConfig.SLOGAN_APP)}
              </AboutAppSlogan>

            </HeaderContent>


            <DividerAbout
              height={0.5}
              width={'60%'}
              color={Colors.menuSeparator}
            />


            <AboutSocialContent>

              {renderItemsAboutGrid(
                translate(TranslateConfig.FACEBOOK),
                <IconSocialFacebook
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openWebApp(Keys.facebookLink);
                },
              )}


              {renderItemsAboutGrid(
                translate(TranslateConfig.INSTAGRAM),
                <IconSocialInstagram
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openWebApp(Keys.instagramLink);
                },
              )}


              {renderItemsAboutGrid(
                translate(TranslateConfig.TWITTER),
                <IconSocialTwitter
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openWebApp(Keys.twitterLink);
                },
              )}


              {renderItemsAboutGrid(
                translate(TranslateConfig.LINKEDIN),
                <IconSocialLinkedIn
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openSite(Keys.linkedInLink);
                },
              )}


              {renderItemsAboutGrid(
                translate(TranslateConfig.YOUTUBE),
                <IconSocialYoutube
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openSite(Keys.youtubeLink);
                },
              )}


              {renderItemsAboutGrid(
                translate(TranslateConfig.THREADS),
                <IconSocialThreads
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openSite(Keys.tiktokLink);
                },
              )}


              {renderItemsAboutGrid(
                translate(TranslateConfig.TIKTOK),
                <IconSocialTikTok
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openSite(Keys.tiktokLink);
                },
              )}


              {renderItemsAboutGrid(
                translate(TranslateConfig.EMAIL),
                <IconEmail
                  color={Colors.white}
                  size={35}
                />,
                () => {
                  openEmail(translate(TranslateConfig.HELP), '');
                },
              )}

            </AboutSocialContent>

          </AboutInfoContent>



          <AboutMessageContent>
            {renderItemsContactList(
              <IconSocialWhatsApp
                color={Colors.white}
                size={26}
              />,
              translate(TranslateConfig.CONTACT_US),
              () => {
                openWhatsApp();
              },
            )}


            {renderItemsContactList(
              <IconSocialMessenger
                color={Colors.white}
                size={26}
              />,
              translate(TranslateConfig.SEND_MESSAGE),
              () => {
                openWebApp(Keys.messengerLink);
              },
            )}
          </AboutMessageContent>

        </AboutInfoDetailsView>



        <AboutFooterContent
          onClick={() => {
            openSite(Vars().noitadaSite);
          }}>

          <AboutImageFooter
            alt={'background'}
            src={Images.backgroundFooterAbout}
          />


          <AboutCenterFooter>

            <AboutFooterImage
              alt={'Logo'}
              src={Images.logoAppNameWhite}
            />


            <AboutFooterSlogan>
              {translate(TranslateConfig.COMPANY_APP)}
            </AboutFooterSlogan>

          </AboutCenterFooter>
        </AboutFooterContent>

      </Content>

    </Screen>

  );
};



export default AboutScreen;
