
const AddPersonModel = {
  usernameCpf: '',
  email: '',
  emptyPerson: false,
};



export default AddPersonModel;
