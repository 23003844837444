import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetCarnivalsFestivals,
} from '@noitada/axios-config/endpoints/highlights.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  CARNIVAL_SET_PERIOD_CURRECT,
  CARNIVAL_ACTIONSHEET_CIRCUITS,
  CARNIVAL_SELECTED_CIRCUITS,
} from '@noitada/redux-config/reducers/carnival-festivals.store';

import {
  MODAL_LOCATION_APP_ACTION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECarnivalPeriod,
} from '@noitada/shared/enums/Carnival/carnival-period.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Container,
  Screen,
} from '../../../components/Composh/web';

import {
  IconCarnivalAttractions,
  IconCarnivalDistricts,
  IconCarnivalCabin,
  IconCarnivalMaps,
  IconCarnivalStreet,
} from '../../../components/Icons';

import LocationsScreen from '../../../modals/LocationsApp';

import {
  ActionBottomStyle,
  BottomNavigationStyle,
} from '../../../styles/bottom-navigation.layout';

import CarnivalActionSheet from './ActionSheet';

import TabAttractionsScreen from './Tabs/TabAttractions';
import TabCabinsScreen from './Tabs/TabCabins';
import TabDistrictsScreen from './Tabs/TabDistricts';
import TabMapCarnivalScreen from './Tabs/TabMapCarnival';
import TabNoCarnivalScreen from './Tabs/TabNoCarnival';
import TabStreetScreen from './Tabs/TabStreet';



const CarnivalGridScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);

  const openLocation = useSelector((state: RootState) => state.localities.modalLocationApp);

  const periodCarnival = useSelector((state: RootState) => state.carnivalFestivals.itemCarnival);
  const carnivalCircuits = useSelector((state: RootState) => state.carnivalFestivals.carnivalSheetCircuits);


  const [value, setValue] = useState('Attractions');
  const [hasCarnival, setHasCarnival] = useState<boolean>(true);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getCarnivalStatus() {
    try {
      setLoading(true);

      const itemsFestivalCarnival = await GetCarnivalsFestivals(localities);

      if (itemsFestivalCarnival?.carnival) {
        dispatch({ type: CARNIVAL_SET_PERIOD_CURRECT, payload: itemsFestivalCarnival?.carnival });
        setHasCarnival(!!itemsFestivalCarnival?.carnival);
      }
      else {
        dispatch({ type: CARNIVAL_SET_PERIOD_CURRECT, payload: null });
        setHasCarnival(false);
      }
    }
    catch (error: any) {
      console.error(error);
      dispatch({ type: CARNIVAL_SET_PERIOD_CURRECT, payload: null });
      setHasCarnival(false);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }



  const getCurrentTab = () => {
    switch (value.toUpperCase()) {
      case 'Attractions'.toUpperCase():
        return (
          <TabAttractionsScreen />
        );

      case 'Street'.toUpperCase():
        return (
          <TabStreetScreen />
        );

      case 'Districts'.toUpperCase():
        return (
          <TabDistrictsScreen />
        );

      case 'Cabins'.toUpperCase():
        return (
          <TabCabinsScreen />
        );

      case 'MapCarnival'.toUpperCase():
        return (
          <TabMapCarnivalScreen />
        );

      default:
        return (
          <TabNoCarnivalScreen />
        );
    }
  };



  useEffect(() => {
    getCarnivalStatus();
  }, [localities]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        {(loading || !hasCarnival || !value) && (
          <TabNoCarnivalScreen
            loading={loading}
            hasCarnival={Boolean(periodCarnival?.id)}
          />
        )}



        {!loading && hasCarnival && (
          <Container>

            {getCurrentTab()}


            <BottomNavigationStyle
              value={value}
              onChange={(_, newValue) => {
                setValue(String(newValue));
              }}>

              {periodCarnival?.type === ECarnivalPeriod.OFFICIAL && (
                <ActionBottomStyle
                  value={'Street'}
                  label={translate(TranslateConfig.STREETS)}
                  icon={
                    <IconCarnivalStreet />
                  }
                />
              )}


              {periodCarnival?.type === ECarnivalPeriod.OFFICIAL && (
                <ActionBottomStyle
                  value={'Districts'}
                  label={translate(TranslateConfig.DISTRICTS)}
                  icon={
                    <IconCarnivalDistricts />
                  }
                />
              )}


              <ActionBottomStyle
                value={'Attractions'}
                label={translate(TranslateConfig.ATTRACTIONS)}
                icon={
                  <IconCarnivalAttractions />
                }
              />


              <ActionBottomStyle
                value={'Cabins'}
                label={translate(TranslateConfig.CABINS)}
                icon={
                  <IconCarnivalCabin />
                }
              />


              <ActionBottomStyle
                value={'MapCarnival'}
                label={translate(TranslateConfig.MAPS)}
                icon={
                  <IconCarnivalMaps />
                }
              />

            </BottomNavigationStyle>

          </Container>
        )}

      </Container>



      <CarnivalActionSheet
        visible={carnivalCircuits?.show}
        title={carnivalCircuits?.title}
        data={carnivalCircuits?.data}
        onClose={() => {
          dispatch({
            type: CARNIVAL_ACTIONSHEET_CIRCUITS, payload: {
              show: false,
              title: '',
              data: [],
            },
          });
        }}
        onPress={(item: any) => {
          dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: item });
          dispatch({
            type: CARNIVAL_ACTIONSHEET_CIRCUITS, payload: {
              show: false,
              title: '',
              data: [],
            },
          });
        }}
      />



      <LocationsScreen
        visible={openLocation}
        type={'CARNIVAL'}
        action={'DISPATCH'}
        onCancelPress={() => {
          dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: false });
        }}
      />

    </Screen>

  );
};



export default CarnivalGridScreen;
