import Address from '../Address';
import Company from '../Company';
import Picture from '../Picture';
import EventEntrance from './event-entrance';
import EventList from './event-list';
import EventTicket from './event-ticket';



const Event = {
  id: '',
  old: false,
  lgbt: false,
  disableOrderSheet: false,
  // frequency: Frequency,
  // state: EventState,
  idShort: '',
  type: '',
  name: '',
  musicGenre: '',
  // type: EventType,
  startTime: Date,
  about: '',
  parentalRating: '',
  comments: '',
  drinks: '',
  attractions: '',
  address: Address,
  company: Company,
  // availables: Available[],
  eventEntrance: EventEntrance,
  event_tickets: Array<EventTicket>(),
  event_lists: Array<EventTicket>(),
  // eventPrice: EventPrice[],
  // eventUsers: EventUser[],
  picture: '', // Picture,
  availables: '', // Picture,

  status: '',
  startAt: '',
  endAt: '',
};



export default Event;
