export const DRAWER_ROUTES = {
  GridEventsScreen:                 '/',
  CarnivalGridScreen:               '/carnival',
  FestivalGridScreen:               '/festival',

  PromotionsScreen:                 '/promotions',
  FavoritesScreen:                  '/favorites',
  PrivacyScreen:                    '/privacy',
  UserTicketsScreen:                '/user/tickets',
  UserListsScreen:                  '/user/lists',
  UserOrdersScreen:                 '/user/orders',
  UserReservesScreen:               '/user/reserves',

  ProfileScreen:                    '/user/profile',

  HelpFaqScreen:                    '/help',
};


export const APP_ROUTES = {
  TutorialScreen:                   '/tutorial',
  PermissionsScreen:                '/permissions',

  AccessScreen:                     '/login',

  DetailsCompanyScreen:             '/:company_username',
  CompanyGalleryScreen:             '/:company_username/gallery',
  AllCompanyEventsScreen:           '/:company_username/events',
  MenuSheetScreen:                  '/:company_username/menu',
  CompanyReservesScreen:            '/:company_username/reserves',
  
  ReserveConfirmedScreen:           '/reserves/confirmed',

  DetailsEventScreen:               '/event/:event_id',
  BuyEntrancesScreen:               '/event/:event_id/tickets',
  JoinListsScreen:                  '/event/:event_id/lists',

  ListInviteScreen:                 '/lists/:list_id/invite',

  ChangeTicketScreen:               '/ticket/change/:ticket_id',

  UserDetailsOrderScreen:           '/order/:order_id',
  
  OrderSheetScreen:                 '/order-sheet',
  OrderBagScreen:                   '/order-bag',
  OrderSheetPayAppScreen:           '/order-pay',

  CarnivalGuidesScreen:             '/carnival/guides',
  CarnivalVotesListScreen:          '/carnival/votes',

  CouponsManagementScreen:          'CouponsManagement',

  QrCodeScreen:                     '/qr-code',

  NotificationsScreen:              '/notifications',

  TicketBuyDetailsScreen:           '/user/ticket/:ticket_id',
  DetailsReserveScreen:             '/user/reserve/:reserve_id',

  SearchScreen:                     '/search',
  SearchResultsScreen:              '/search/results',

  FaqCategoryScreen:                '/faq/category',
  FaqBulletScreen:                  '/faq/bullet',

  PreferencesScreen:                '/settings',
  AboutScreen:                      '/settings/about',
  TermsPolicyScreen:                '/settings/terms-policy',

  SuggestLocationScreen:            '/suggest-location',
  ViewSuccessScreen:                '/view-success',

  WebViewScreen:                    '/webview',
};



const NameRoutes = {
  ...DRAWER_ROUTES,
  ...APP_ROUTES,
};



export default NameRoutes;
