import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  TouchableTickets,
  TicketsPriceInfoView,
  TicketsMoreInfoTitle,
  SeeTicketsView,
  TicketsMoreInfoText,
  SeeTicketsText,
} from './styled';



export interface IProps {
  disabled?: any;

  onPress?: any;
}



const DetailsSeeLists: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <TouchableTickets
      // disabled={props.disabled}
      onClick={props.onPress}>

      <TicketsPriceInfoView>
        <TicketsMoreInfoTitle>
          {translate(TranslateConfig.LISTS)}
        </TicketsMoreInfoTitle>


        <TicketsMoreInfoText>
          {translate(TranslateConfig.EVENT_LIST_AVAILABLES)}
        </TicketsMoreInfoText>
      </TicketsPriceInfoView>


      <SeeTicketsView
        style={{
          opacity: props.disabled
            ? 0.5
            : 1,
        }}>
        <SeeTicketsText>
          {translate(TranslateConfig.ACTION_LISTS_SEE)}
        </SeeTicketsText>
      </SeeTicketsView>

    </TouchableTickets>

  );
};



export default DetailsSeeLists;
