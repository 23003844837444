import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  SubtitleText,
} from '../../../styles/styled.layout';



export const TicketCreatedByButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const TicketCreatedByContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const TicketCompanyImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: ${Sizes.cardRadius}px;
`;

export const TicketCreatedByInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`;

export const TicketCreatedByTitle = styled.h3`
  color: ${Colors.white};
  font-size: 17px;
`;

export const TicketCreatedByAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
  width: 90px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const TicketsCompanySeeText = styled(SubtitleText)`
  font-size: 13px;
  font-style: italic;
`;
