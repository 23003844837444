export enum EAppTranslations {
  NAME_APP = 'NAME_APP',
  SHORTNAME_APP = 'SHORTNAME_APP',
  SLOGAN_APP = 'SLOGAN_APP',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_APP = 'COMPANY_APP',
};


export enum EAppTutorialTranslations {
  TUTORIAL_TITLE_1 = 'TUTORIAL_TITLE_1',
  TUTORIAL_TEXT_1 = 'TUTORIAL_TEXT_1',
  TUTORIAL_TITLE_2 = 'TUTORIAL_TITLE_2',
  TUTORIAL_TEXT_2 = 'TUTORIAL_TEXT_2',
  TUTORIAL_TITLE_3 = 'TUTORIAL_TITLE_3',
  TUTORIAL_TEXT_3 = 'TUTORIAL_TEXT_3',
  TUTORIAL_TITLE_4 = 'TUTORIAL_TITLE_4',
};


export enum EButtonsTranslations {
  ACTION_ACCESS = 'ACTION_ACCESS',
  ACTION_ACCESS_TO_CONTINUE = 'ACTION_ACCESS_TO_CONTINUE',
  ACTION_REGISTER = 'ACTION_REGISTER',
  ACTION_REGISTER_USER = 'ACTION_REGISTER_USER',
  ACTION_REGISTER_COMPANY = 'ACTION_REGISTER_COMPANY',
  ACTION_REGISTER_TO_CONTINUE = 'ACTION_REGISTER_TO_CONTINUE',
  ACTION_CANCEL = 'ACTION_CANCEL',
  ACTION_CONFIRM = 'ACTION_CONFIRM',
  ACTION_FORWARD = 'ACTION_FORWARD',
  ACTION_BACK = 'ACTION_BACK',
  ACTION_CLOSE = 'ACTION_CLOSE',
  ACTION_ASK_CHECKIN = 'ACTION_ASK_CHECKIN',
  ACTION_CANCEL_CHECKIN = 'ACTION_CANCEL_CHECKIN',
  ACTION_CHECKIN_MANUAL = 'ACTION_CHECKIN_MANUAL',
  ACTION_CLICK_OPEN = 'ACTION_CLICK_OPEN',
  ACTION_CLICK_CLOSE = 'ACTION_CLICK_CLOSE',
  ACTION_CLICK_CANCEL = 'ACTION_CLICK_CANCEL',
  ACTION_MORE_DETAILS = 'ACTION_MORE_DETAILS',
  ACTION_LISTS_SEE = 'ACTION_LISTS_SEE',
  ACTION_SEE_MENUSHEET = 'ACTION_SEE_MENUSHEET',
  ACTION_PAY = 'ACTION_PAY',
  ACTION_PAY_APP = 'ACTION_PAY_APP',
  ACTION_PAY_PRESENTIAL = 'ACTION_PAY_PRESENTIAL',
  ACTION_PAY_ORDERSHEET = 'ACTION_PAY_ORDERSHEET',
  ACTION_ADD_MORE_ITEMS = 'ACTION_ADD_MORE_ITEMS',
  ACTION_BAG_SEE = 'ACTION_BAG_SEE',
  ACTION_BAG_CLEAN = 'ACTION_BAG_CLEAN',
  ACTION_FINISH_ORDER = 'ACTION_FINISH_ORDER',
  ACTION_CLOSE_ORDERSHEET = 'ACTION_CLOSE_ORDERSHEET',
  ACTION_SEE_WIFI = 'ACTION_SEE_WIFI',
  ACTION_NO = 'ACTION_NO',
  ACTION_YES = 'ACTION_YES',
  ACTION_SEE_HISTORY = 'ACTION_SEE_HISTORY',
  ACTION_HISTORY = 'ACTION_HISTORY',
  ACTION_SAVE = 'ACTION_SAVE',
  ACTION_OPEN_LIST = 'ACTION_OPEN_LIST',
  ACTION_OPEN_GALLERY = 'ACTION_OPEN_GALLERY',
  ACTION_TRANSFER_ADD = 'ACTION_TRANSFER_ADD',
  ACTION_COMPANY_ADD = 'ACTION_COMPANY_ADD',
  ACTION_USER_DISABLE = 'ACTION_USER_DISABLE',
  ACTION_RECOVER_ACCESS = 'ACTION_RECOVER_ACCESS',
  ACTION_CARD_ADD = 'ACTION_CARD_ADD',
  ACTION_ADDRESS_REGISTER = 'ACTION_ADDRESS_REGISTER',
  ACTION_CLICK_EDIT = 'ACTION_CLICK_EDIT',
  ACTION_LOCATION_ENABLED = 'ACTION_LOCATION_ENABLED',
  ACTION_INVITE_LINK = 'ACTION_INVITE_LINK',
  ACTION_ADD_PERSON = 'ACTION_ADD_PERSON',
  ACTION_ADD_PEOPLE = 'ACTION_ADD_PEOPLE',
  ACTION_ADD_MANUAL = 'ACTION_ADD_MANUAL',
  ACTION_LIST_PARTICIPATE = 'ACTION_LIST_PARTICIPATE',
  ACTION_LIST_LEAVE = 'ACTION_LIST_LEAVE',
  ACTION_TICKET_SEE = 'ACTION_TICKET_SEE',
  ACTION_COPY_PASSWORD = 'ACTION_COPY_PASSWORD',
  ACTION_COPY_LINK = 'ACTION_COPY_LINK',
  ACTION_SEE_DATES = 'ACTION_SEE_DATES',
  ACTION_DO_LOGIN = 'ACTION_DO_LOGIN',
  ACTION_TO_RESERVE = 'ACTION_TO_RESERVE',
  ACTION_CANCEL_RESERVE = 'ACTION_CANCEL_RESERVE',
  ACTION_SEARCH = 'ACTION_SEARCH',
  ACTION_RESEARCH = 'ACTION_RESEARCH',
  ACTION_ADDRESS_SEE_YOURS = 'ACTION_ADDRESS_SEE_YOURS',
  ACTION_ADDRESS_SEE = 'ACTION_ADDRESS_SEE',
  ACTION_PAY_FORMS_SEE = 'ACTION_PAY_FORMS_SEE',
  ACTION_OPEN_WAZE = 'ACTION_OPEN_WAZE',
  ACTION_OPEN_UBER = 'ACTION_OPEN_UBER',
  ACTION_HOUR_SEE = 'ACTION_HOUR_SEE',
  ACTION_CLICK_TO_MANAGE = 'ACTION_CLICK_TO_MANAGE',
  ACTION_LOCATION_MY = 'ACTION_LOCATION_MY',
  ACTION_PROFILE_SEE = 'ACTION_PROFILE_SEE',
  ACTION_CLICK_TO_ADD = 'ACTION_CLICK_TO_ADD',
  ACTION_CLICK_TO_EDIT = 'ACTION_CLICK_TO_EDIT',
  ACTION_TO_SEE_PROFILE = 'ACTION_TO_SEE_PROFILE',
  ACTION_SKIP = 'ACTION_SKIP',
  ACTION_COME_ON = 'ACTION_COME_ON',
  ACTION_PROFILE_MANAGE = 'ACTION_PROFILE_MANAGE',
  ACTION_CHECK_ALL = 'ACTION_CHECK_ALL',
  ACTION_UNCHECK_ALL = 'ACTION_UNCHECK_ALL',
  ACTION_JOIN_VIA_LIST = 'ACTION_JOIN_VIA_LIST',
  ACTION_SEE_AVAILABLES_TICKETS = 'ACTION_SEE_AVAILABLES_TICKETS',
  ACTION_ENTRANCE_IN_EVENT = 'ACTION_ENTRANCE_IN_EVENT',
};


export enum ECompanyTypesTranslations {
  EVENT_PRODUCER = 'EVENT_PRODUCER',
  EVENT_PRODUCERS = 'EVENT_PRODUCERS',
  BAR = 'BAR',
  BARS = 'BARS',
  PUB = 'PUB',
  PUBS = 'PUBS',
  NIGHT_CLUB = 'NIGHT_CLUB',
  NIGHT_CLUBS = 'NIGHT_CLUBS',
  RESTAURANT = 'RESTAURANT',
  RESTAURANTS = 'RESTAURANTS',
  PIZZERIA = 'PIZZERIA',
  PIZZERIAS = 'PIZZERIAS',
  STEAKHOUSE = 'STEAKHOUSE',
  STEAKHOUSES = 'STEAKHOUSES',
  HAMBURGUER = 'HAMBURGUER',
  HAMBURGUERS = 'HAMBURGUERS',
  COFFEE_SHOP = 'COFFEE_SHOP',
  COFFEE_SHOPS = 'COFFEE_SHOPS',
  CARNIVAL_PRODUCER = 'CARNIVAL_PRODUCER',
  CARNIVAL_PRODUCERS = 'CARNIVAL_PRODUCERS',
  PARTY_PRODUCER = 'PARTY_PRODUCER',
  PARTY_PRODUCERS = 'PARTY_PRODUCERS',
  GOVERN = 'GOVERN',
  GOVERNS = 'GOVERNS',
};


export enum ECarnivalTranslations {
  CARNIVAL_VOTES = 'CARNIVAL_VOTES',
  CARNIVAL_INTEREST_POINTS = 'CARNIVAL_INTEREST_POINTS',
  CARNIVAL_OFFICIAL = 'CARNIVAL_OFFICIAL',
  CARNIVAL_MICARETA = 'CARNIVAL_MICARETA',
  CIRCUIT_OFFICIAL = 'CIRCUIT_OFFICIAL',
  BLOC = 'BLOC',
  CIRCUIT = 'CIRCUIT',
  CIRCUITS = 'CIRCUITS',
  CABIN = 'CABIN',
  CABINS = 'CABINS',
  POLICE = 'POLICE',
  BUS = 'BUS',
  TAXI = 'TAXI',
  BATHROOM = 'BATHROOM',
  FIREMAN = 'FIREMAN',
  SAVE_LIFE = 'SAVE_LIFE',
  MEDICAL_POINT = 'MEDICAL_POINT',
  JUVENILE = 'JUVENILE',
  OTHER_ATTRACTION = 'OTHER_ATTRACTION',
  AGENCY_FLOW = 'AGENCY_FLOW',
  AGENCY_PUBLIC = 'AGENCY_PUBLIC',
  CLEAN_HEALTH = 'CLEAN_HEALTH',
  AREA_RESERVED = 'AREA_RESERVED',
};


export enum ECountriesTranslations {
  BRAZIL = 'BRAZIL',
  PORTUGAL = 'PORTUGAL',
  UNITED_STATES = 'UNITED_STATES',
  SPAIN = 'SPAIN',
  ITALY = 'ITALY',
  FANCE = 'FANCE',
};


export enum EDrinksTranslations {
  WATER = 'WATER',
  SODA = 'SODA',
  VODKA = 'VODKA',
  BEER = 'BEER',
  WINE = 'WINE',
  TEA = 'TEA',
  COFFEE = 'COFFEE',
  JUICE = 'JUICE',
  WHISKY = 'WHISKY',
  LIQUOR = 'LIQUOR',
  COCKTAIL = 'COCKTAIL',
  CHAMPAGNE = 'CHAMPAGNE',
  GIN = 'GIN',
  RUM = 'RUM',
  ENERGY_DRINK = 'ENERGY_DRINK',
  SMOOTHIE = 'SMOOTHIE',
  YERBA_MATE = 'YERBA_MATE',
  SAKE = 'SAKE',
  TEQUILA = 'TEQUILA',
};


export enum EEventsTranslations {
  EVENT_TYPE = 'EVENT_TYPE',
  EVENT_IDEAL_LGBT = 'EVENT_IDEAL_LGBT',
  EVENT_ABOUT = 'EVENT_ABOUT',
  EVENT_ATTRACTIONS = 'EVENT_ATTRACTIONS',
  EVENT_START = 'EVENT_START',
  EVENT_END = 'EVENT_END',
  EVENT_OPENBAR = 'EVENT_OPENBAR',
  EVENT_PARENTAL_RATING = 'EVENT_PARENTAL_RATING',
  EVENT_PARENTAL_NONE = 'EVENT_PARENTAL_NONE',
  EVENT_PARENTAL_14 = 'EVENT_PARENTAL_14',
  EVENT_PARENTAL_16 = 'EVENT_PARENTAL_16',
  EVENT_PARENTAL_18 = 'EVENT_PARENTAL_18',
  EVENT_LIST_AVAILABLES = 'EVENT_LIST_AVAILABLES',
  ID_TICKET = 'ID_TICKET',
  TICKET = 'TICKET',
  TICKETS = 'TICKETS',
  TICKETS_MY = 'TICKETS_MY',
  TICKETS_AVAILABLE = 'TICKETS_AVAILABLE',
  EVENT_FREE = 'EVENT_FREE',
  EVENT_DATE = 'EVENT_DATE',
  EVENT_DATE_INITIAL = 'EVENT_DATE_INITIAL',
  EVENT_DATE_FINAL = 'EVENT_DATE_FINAL',
  TICKET_ENTRANCE_PRICE = 'TICKET_ENTRANCE_PRICE',
  PARTY = 'PARTY',
  NIGHT_PARTY = 'NIGHT_PARTY',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
};


export enum EInterpolatesTranslations {
  HELLO = 'HELLO',
  PERSON_TO = 'PERSON_TO',
  CODE_ABREV = 'CODE_ABREV',
  FROM = 'FROM',
  TO = 'TO',
  PAY_PRESENTIAL = 'PAY_PRESENTIAL',
  PAY_BY_APP = 'PAY_BY_APP',
  PAY_TO = 'PAY_TO',
  TO_DO = 'TO_DO',
  HAVE_WIFI = 'HAVE_WIFI',
  INTERPOLATE_ORDERSHEET_CLOSING = 'INTERPOLATE_ORDERSHEET_CLOSING',
  INTERPOLATE_ORDERSHEET_CLOSE = 'INTERPOLATE_ORDERSHEET_CLOSE',
  INTERPOLATE_ORDERSHEET_FINISH = 'INTERPOLATE_ORDERSHEET_FINISH',
  AT = 'AT',
  OR = 'OR',
  AND = 'AND',
  THERE_IS = 'THERE_IS',
  LAST_ORDER = 'LAST_ORDER',
  LAST_ORDER_THERE_IS = 'LAST_ORDER_THERE_IS',
  ACCESS_USER_REGISTER = 'ACCESS_USER_REGISTER',
  ACCESS_USER_TERMS_USE = 'ACCESS_USER_TERMS_USE',
  ACCESS_USER_TERMS_PRIVACY = 'ACCESS_USER_TERMS_PRIVACY',
  INTERPOLATE_LIST_LIMIT = 'INTERPOLATE_LIST_LIMIT',
  INTERPOLATE_LIST_UNTIL_LIMIT = 'INTERPOLATE_LIST_UNTIL_LIMIT',
  PRICE_BETWEEN_MIN_MAX = 'PRICE_BETWEEN_MIN_MAX',
  PRICE_INSTALLMENT_VALUE = 'PRICE_INSTALLMENT_VALUE',
  RESERVE_PEOPLE_LIMIT = 'RESERVE_PEOPLE_LIMIT',
  RESERVE_TABLE_AVAILABLE = 'RESERVE_TABLE_AVAILABLE',
  RESERVE_PRICE_PAY = 'RESERVE_PRICE_PAY',
  PAGE_VIEWS = 'PAGE_VIEWS',
  TERMS_ACCESS_APP = 'TERMS_ACCESS_APP',
};


export enum EKitchensTranslations {
  AFRICAN = 'AFRICAN',
  GERMAN = 'GERMAN',
  ARABIC = 'ARABIC',
  ARGENTINA = 'ARGENTINA',
  DRINKS = 'DRINKS',
  BRAZILIAN = 'BRAZILIAN',
  COFFEE = 'COFFEE',
  MEATS = 'MEATS',
  CHINESE = 'CHINESE',
  COLOMBIAN = 'COLOMBIAN',
  KOREAN = 'KOREAN',
  SPANISH = 'SPANISH',
  FRENCH = 'FRENCH',
  SEAFOOD = 'SEAFOOD',
  INDIAN = 'INDIAN',
  ITALIAN = 'ITALIAN',
  JAPANESE = 'JAPANESE',
  MEDITERRANEAN = 'MEDITERRANEAN',
  MEXICAN = 'MEXICAN',
  FRIED_PASTRY = 'FRIED_PASTRY',
  FISH = 'FISH',
  PERUVIAN = 'PERUVIAN',
  PIZZA = 'PIZZA',
  PORTUGUESE = 'PORTUGUESE',
  HEALTHY = 'HEALTHY',
  JUICES = 'JUICES',
  THAI = 'THAI',
  VEGETARIAN = 'VEGETARIAN',
};


export enum ELabelsTranslations {
  ALL = 'ALL',
  COMPANY = 'COMPANY',
  COMPANIES = 'COMPANIES',
  COMPANIES_MY = 'COMPANIES_MY',
  PROFILE = 'PROFILE',
  PROFILES = 'PROFILES',
  PROFILES_MY = 'PROFILES_MY',
  PERSONAL_DATA = 'PERSONAL_DATA',
  PERSONAL_INFORMATIONS = 'PERSONAL_INFORMATIONS',
  COUNTRY = 'COUNTRY',
  FANTASY_NAME = 'FANTASY_NAME',
  SOCIAL_REASON = 'SOCIAL_REASON',
  LOADING = 'LOADING',
  USER = 'USER',
  NAME = 'NAME',
  NAME_FULL = 'NAME_FULL',
  DATE_BORN = 'DATE_BORN',
  GENRE = 'GENRE',
  DDI_NUMBER_FIRST = 'DDI_NUMBER_FIRST',
  DDI_NUMBER_SECOND = 'DDI_NUMBER_SECOND',
  DDI = 'DDI',
  PERSONS = 'PERSONS',
  USERNAME = 'USERNAME',
  USERNAME_COMPANY = 'USERNAME_COMPANY',
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  PASSWORD_CURRENT = 'PASSWORD_CURRENT',
  PASSWORD_NEW = 'PASSWORD_NEW',
  PASSWORD_REPEAT = 'PASSWORD_REPEAT',
  PASSWORD_CONFIRM = 'PASSWORD_CONFIRM',
  CNPJ = 'CNPJ',
  CPF = 'CPF',
  FORMS = 'FORMS',
  COMMENTS = 'COMMENTS',
  OPTIONAL = 'OPTIONAL',
  PUBLIC_ID = 'PUBLIC_ID',
  PUBLIC_PARTNER_ID = 'PUBLIC_PARTNER_ID',
  NUMBER_PRIMARY = 'NUMBER_PRIMARY',
  NUMBER_SECONDARY = 'NUMBER_SECONDARY',
  PARTNER_ABOUT = 'PARTNER_ABOUT',
  PARTNER_SERVICES = 'PARTNER_SERVICES',
  PARTNER_PHONES = 'PARTNER_PHONES',
  PHONE_ONLY_ONE = 'PHONE_ONLY_ONE',
  PHONE_FIRST = 'PHONE_FIRST',
  PHONE_SECOND = 'PHONE_SECOND',
  PARTNER_RULES = 'PARTNER_RULES',
  PARTNER_TYPES_KITCHEN = 'PARTNER_TYPES_KITCHEN',
  PARTNER_OPEN_HOURS = 'PARTNER_OPEN_HOURS',
  WIFI = 'WIFI',
  KIDS_AREA = 'KIDS_AREA',
  PARKING = 'PARKING',
  SMOKING = 'SMOKING',
  AIR_CONDITIONER = 'AIR_CONDITIONER',
  ACCESSIBILITY = 'ACCESSIBILITY',
  MORE_POPULAR = 'MORE_POPULAR',
  NUMBER_OR_NAME = 'NUMBER_OR_NAME',
  INPUT_MODAL_ADD_USER_PLACEHOLDER = 'INPUT_MODAL_ADD_USER_PLACEHOLDER',
  CONFIRM_CPF = 'CONFIRM_CPF',
  CONFIRM_CNPJ = 'CONFIRM_CNPJ',
  CONFIRM_INFO = 'CONFIRM_INFO',
  NICKNAME = 'NICKNAME',
  DESCRIPTION = 'DESCRIPTION',
  FAVORED = 'FAVORED',
  ACCOUNT_USE_MAIN = 'ACCOUNT_USE_MAIN',
  ADDRESS_USE_MAIN = 'ADDRESS_USE_MAIN',
  INPUT_MODAL_ADD_USER_CARGO = 'INPUT_MODAL_ADD_USER_CARGO',
  CODE = 'CODE',
  DIGIT = 'DIGIT',
  ACCOUNT = 'ACCOUNT',
  ID = 'ID',
  ID_PRIVATE = 'ID_PRIVATE',
  ID_PUBLIC = 'ID_PUBLIC',
  COMPANY_STATUS_APP = 'COMPANY_STATUS_APP',
  COMPANY_STATUS_ACTIVE = 'COMPANY_STATUS_ACTIVE',
  COMPANY_STATUS_INACTIVE = 'COMPANY_STATUS_INACTIVE',
  PLACE_NAME = 'PLACE_NAME',
  ZIP_CODE = 'ZIP_CODE',
  STREET = 'STREET',
  STREETS = 'STREETS',
  DISTRICT = 'DISTRICT',
  DISTRICTS = 'DISTRICTS',
  ATTRACTIONS = 'ATTRACTIONS',
  COMPLEMENT = 'COMPLEMENT',
  CITY = 'CITY',
  CITIES = 'CITIES',
  STATE = 'STATE',
  SOCIAL = 'SOCIAL',
  SLOGAN = 'SLOGAN',
  CELLPHONE = 'CELLPHONE',
  ABOUT = 'ABOUT',
  ABOUT_APP = 'ABOUT_APP',
  PROFILE_TYPE = 'PROFILE_TYPE',
  PARTNER_TYPE = 'PARTNER_TYPE',
  INFORMATIONS = 'INFORMATIONS',
  CARD_HOLDER_NAME = 'CARD_HOLDER_NAME',
  CARD_NICKNAME = 'CARD_NICKNAME',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  SOCIAL_NAME = 'SOCIAL_NAME',
  ABOUT_ME = 'ABOUT_ME',
  LEVEL = 'LEVEL',
  BIRTHDAY = 'BIRTHDAY',
  PICTURE = 'PICTURE',
  USER_PRE_REGISTER = 'USER_PRE_REGISTER',
  DATAS = 'DATAS',
  SHOPS = 'SHOPS',
  OPTIONS = 'OPTIONS',
  ME_AND_GUESTS = 'ME_AND_GUESTS',
  ONLY_YOU = 'ONLY_YOU',
  ONLY_FOR_ME = 'ONLY_FOR_ME',
  PEOPLE = 'PEOPLE',
  RESERVE_DATE = 'RESERVE_DATE',
  DATE_SELECTED = 'DATE_SELECTED',
  ENVIRONMENT = 'ENVIRONMENT',
  PEOPLE_NUMBER = 'PEOPLE_NUMBER',
  MORE_INFORMATIONS = 'MORE_INFORMATIONS',
  OBSERVATIONS = 'OBSERVATIONS',
  INITIAL_DATE = 'INITIAL_DATE',
  INITIAL_HOUR = 'INITIAL_HOUR',
  FINAL_DATE = 'FINAL_DATE',
  FINAL_HOUR = 'FINAL_HOUR',
  INITIAL_VALUE = 'INITIAL_VALUE',
  FINAL_VALUE = 'FINAL_VALUE',
  PARAMETERS = 'PARAMETERS',
  NAME_OR_USERNAME = 'NAME_OR_USERNAME',
  MODALITIES = 'MODALITIES',
  VERSION = 'VERSION',
  MY_ACCOUNT = 'MY_ACCOUNT',
  ACCOUNT_SEE = 'ACCOUNT_SEE',
  ACCOUNT_ACTIONS = 'ACCOUNT_ACTIONS',
  PAYMENTS_FORMS = 'PAYMENTS_FORMS',
  LOCATION = 'LOCATION',
  LOCATION_NO_ADDRESS = 'LOCATION_NO_ADDRESS',
  TICKETS_SALES_UNTIL = 'TICKETS_SALES_UNTIL',
  PARTICIPATION = 'PARTICIPATION',
  WAIT_YOU = 'WAIT_YOU',
  ITEMS = 'ITEMS',
  HELP = 'HELP',
  TYPE = 'TYPE',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  OTHER = 'OTHER',
  ADD = 'ADD',
  ACTUAL = 'ACTUAL',
  COUNTRIES = 'COUNTRIES',
  NO = 'NO',
  YES = 'YES',
  LIST_NAME = 'LIST_NAME',
  GUIDES_INFORMATIONS = 'GUIDES_INFORMATIONS',
  PHONES_USEFUL = 'PHONES_USEFUL',
  CASADINHA = 'CASADINHA',
  OPEN_BAR = 'OPEN_BAR',
  LIST = 'LIST',
  VIP = 'VIP',
};


export enum ELanguagesTranslations {
  PT_BR = 'PT_BR',
  EN_US = 'EN_US',
  ES_AR = 'ES_AR',
  IT_IT = 'IT_IT',
  FR_FR = 'FR_FR',
};


export enum EMenuOrderTranslations {
  COMBO = 'COMBO',
  ITEM_DOUBLE = 'ITEM_DOUBLE',
  AVAILABLES = 'AVAILABLES',
  TABLE = 'TABLE',
  NUMBER = 'NUMBER',
  NUMBER_TABLE_ENTRANCE = 'NUMBER_TABLE_ENTRANCE',
  TABLE_ENTRANCE = 'TABLE_ENTRANCE',
  MIN_ORDERSHEET = 'MIN_ORDERSHEET',
  WAITER_CALL = 'WAITER_CALL',
  WAITER_CALLING = 'WAITER_CALLING',
  WAITER_GOING = 'WAITER_GOING',
  ORDERSHEET_CLOSING_ORDERSHEET = 'ORDERSHEET_CLOSING_ORDERSHEET',
  ORDERSHEET_CLOSED = 'ORDERSHEET_CLOSED',
  ORDERSHEET_OPENED = 'ORDERSHEET_OPENED',
  TOTAL_CONSUMPTION = 'TOTAL_CONSUMPTION',
  ALCOHOLIC = 'ALCOHOLIC',
  SPICY = 'SPICY',
  VEGETARIAN = 'VEGETARIAN',
  VEGAN = 'VEGAN',
  ORGANIC = 'ORGANIC',
  NO_LACTOSE = 'NO_LACTOSE',
  NO_GLUTEN = 'NO_GLUTEN',
  REQUESTS = 'REQUESTS',
  REQUESTS_MINIMAL = 'REQUESTS_MINIMAL',
  REQUESTS_UNTIL = 'REQUESTS_UNTIL',
  DELIVERY = 'DELIVERY',
  REMOVAL_ITEM = 'REMOVAL_ITEM',
  PRESENTIAL = 'PRESENTIAL',
  ORDER_PLACED = 'ORDER_PLACED',
  ORDER_PREPARING = 'ORDER_PREPARING',
  ORDER_TO_DELIVER = 'ORDER_TO_DELIVER',
  ORDER_REFUSED = 'ORDER_REFUSED',
};


export enum EMessagesEmptysTranslations {
  EMPTY_COMPANY_GALLERY = 'EMPTY_COMPANY_GALLERY',
  EMPTY_COMPANY_NOT_FOUND = 'EMPTY_COMPANY_NOT_FOUND',
  EMPTY_COMPANY_EVENTS = 'EMPTY_COMPANY_EVENTS',
  EMPTY_EVENTS_NOT_FOUND = 'EMPTY_EVENTS_NOT_FOUND',
  EMPTY_HIGHLIGHTS_ITEMS = 'EMPTY_HIGHLIGHTS_ITEMS',
  EMPTY_HIGHLIGHTS_EVENTS = 'EMPTY_HIGHLIGHTS_EVENTS',
  EMPTY_HIGHLIGHTS_PARTNERS = 'EMPTY_HIGHLIGHTS_PARTNERS',
  EMPTY_CATEGORIES = 'EMPTY_CATEGORIES',
  EMPTY_MENUSHEET_ITEMS = 'EMPTY_MENUSHEET_ITEMS',
  EMPTY_MENUSHEET_CATEGORIES = 'EMPTY_MENUSHEET_CATEGORIES',
  EMPTY_NOTIFICATIONS = 'EMPTY_NOTIFICATIONS',
  EMPTY_ORDERSHEET_HISTORY = 'EMPTY_ORDERSHEET_HISTORY',
  EMPTY_BANKS = 'EMPTY_BANKS',
  EMPTY_COMPANIES_REGISTERED = 'EMPTY_COMPANIES_REGISTERED',
  EMPTY_HOME = 'EMPTY_HOME',
  EMPTY_FAVORITES = 'EMPTY_FAVORITES',
  EMPTY_PAYMENT = 'EMPTY_PAYMENT',
  EMPTY_ADDRESS = 'EMPTY_ADDRESS',
  EMPTY_USER_DATA = 'EMPTY_USER_DATA',
  EMPTY_USER_NO_RECOG = 'EMPTY_USER_NO_RECOG',
  EMPTY_LISTS = 'EMPTY_LISTS',
  EMPTY_LISTS_PARTICIPATE = 'EMPTY_LISTS_PARTICIPATE',
  EMPTY_LISTS_FINISHED = 'EMPTY_LISTS_FINISHED',
  EMPTY_TICKET = 'EMPTY_TICKET',
  EMPTY_TICKET_IN_EVENT = 'EMPTY_TICKET_IN_EVENT',
  EMPTY_EVENT_EXPLORE = 'EMPTY_EVENT_EXPLORE',
  EMPTY_CARD_REGISTERED = 'EMPTY_CARD_REGISTERED',
  EMPTY_CARD_NO_ADDED = 'EMPTY_CARD_NO_ADDED',
  EMPTY_RESERVE_REGISTERED = 'EMPTY_RESERVE_REGISTERED',
  EMPTY_RESERVE_USER = 'EMPTY_RESERVE_USER',
  EMPTY_LINK_SEE = 'EMPTY_LINK_SEE',
  EMPTY_SEARCH = 'EMPTY_SEARCH',
  EMPTY_ORDERS = 'EMPTY_ORDERS',
  EMPTY_EVENT_FINISHED = 'EMPTY_EVENT_FINISHED',
  EMPTY_PLACES = 'EMPTY_PLACES',
  EMPTY_CARNIVAL_ATTRACTIONS = 'EMPTY_CARNIVAL_ATTRACTIONS',
  EMPTY_CARNIVAL_NONE = 'EMPTY_CARNIVAL_NONE',
  EMPTY_CARNIVAL_DISTRICTS = 'EMPTY_CARNIVAL_DISTRICTS',
  EMPTY_CARNIVAL_CABINS = 'EMPTY_CARNIVAL_CABINS',
  EMPTY_CARNIVAL_LOCATIONS = 'EMPTY_CARNIVAL_LOCATIONS',
  EMPTY_CARNIVAL_STREETS = 'EMPTY_CARNIVAL_STREETS',
  EMPTY_CARNIVAL_INFOS = 'EMPTY_CARNIVAL_INFOS',
  EMPTY_CARNIVAL_PHONES = 'EMPTY_CARNIVAL_PHONES',
  EMPTY_CARNIVAL_VOTES = 'EMPTY_CARNIVAL_VOTES',
  EMPTY_LANGUAGES = 'EMPTY_LANGUAGES',
  EMPTY_LIST_AVAILABLE = 'EMPTY_LIST_AVAILABLE',
  EMPTY_TICKET_AVAILABLE = 'EMPTY_TICKET_AVAILABLE',
};


export enum EMessagesErrosTranslations {
  ERROR = 'ERROR',
  ERROR_PAY = 'ERROR_PAY',
  ERROR_PAY_APP = 'ERROR_PAY_APP',
  ERROR_CHECKIN_ASK = 'ERROR_CHECKIN_ASK',
  ERROR_CHECKIN_CANCEL = 'ERROR_CHECKIN_CANCEL',
  ERROR_HAD = 'ERROR_HAD',
  ERROR_SEARCH = 'ERROR_SEARCH',
  TRY_AGAIN = 'TRY_AGAIN',
  ACCESS_DENIED = 'ACCESS_DENIED',
  PASSWORD_WRONG = 'PASSWORD_WRONG',
  ERROR_PARAMS_INVALID = 'ERROR_PARAMS_INVALID',
  ERROR_COMPANY_CREATE = 'ERROR_COMPANY_CREATE',
  ERROR_USER_CREATE = 'ERROR_USER_CREATE',
  ERROR_DATE = 'ERROR_DATE',
  ERROR_NO_DATA_FOUND = 'ERROR_NO_DATA_FOUND',
  ERROR_EMPTY_FIELDS = 'ERROR_EMPTY_FIELDS',
  ERROR_ACCOUNT_TRANSFER = 'ERROR_ACCOUNT_TRANSFER',
  ERROR_ACCOUNT_SEARCH = 'ERROR_ACCOUNT_SEARCH',
  ERROR_ACCOUNT_MAIN_CHANGE = 'ERROR_ACCOUNT_MAIN_CHANGE',
  ERROR_ACCOUNT_DELETE = 'ERROR_ACCOUNT_DELETE',
  ERROR_SAVE = 'ERROR_SAVE',
  ERROR_CREATE = 'ERROR_CREATE',
  ERROR_UPDATE = 'ERROR_UPDATE',
  ERROR_USER_DISABLE = 'ERROR_USER_DISABLE',
  ERROR_COMPANY_DISABLE = 'ERROR_COMPANY_DISABLE',
  ERROR_MAKE_REGISTER = 'ERROR_MAKE_REGISTER',
  ERROR_PAYMENT_MAIN_CHOOSED = 'ERROR_PAYMENT_MAIN_CHOOSED',
  ERROR_PAYMENT_DELETE = 'ERROR_PAYMENT_DELETE',
  ERROR_ADDRESS_DELETE = 'ERROR_ADDRESS_DELETE',
  ERROR_LIST_USER_ADDED = 'ERROR_LIST_USER_ADDED',
  ERROR_LIST_ENTER = 'ERROR_LIST_ENTER',
  ERROR_LIST_GET_DATA = 'ERROR_LIST_GET_DATA',
  ERROR_GET_DATA = 'ERROR_GET_DATA',
  ERROR_TICKET_UNAVAILABLES = 'ERROR_TICKET_UNAVAILABLES',
  ERROR_LISTS_UNAVAILABLES = 'ERROR_LISTS_UNAVAILABLES',
  ERROR_USER_DOESNOT_EXIST = 'ERROR_USER_DOESNOT_EXIST',
  ERROR_USER_IN_LIST = 'ERROR_USER_IN_LIST',
  ERROR_FAVORITE_ADD = 'ERROR_FAVORITE_ADD',
  ERROR_FAVORITE_REMOVE = 'ERROR_FAVORITE_REMOVE',
};


export enum EMessagesHelpsTranslations {
  HELP_INPUT_COMPANY = 'HELP_INPUT_COMPANY',
  HELP_INPUT_USER = 'HELP_INPUT_USER',
  HELP_INPUT_REQUIRED = 'HELP_INPUT_REQUIRED',
  HELP_QR = 'HELP_QR',
  HELP_CHECKIN = 'HELP_CHECKIN',
  HELP_CHECKIN_WAIT = 'HELP_CHECKIN_WAIT',
  HELP_EVENT_RATING = 'HELP_EVENT_RATING',
  HELP_EVENT_ALCOHOL = 'HELP_EVENT_ALCOHOL',
  HELP_PAY_UNAVAILABLE = 'HELP_PAY_UNAVAILABLE',
  HELP_SUCCESS_ORDER_DONE = 'HELP_SUCCESS_ORDER_DONE',
  HELP_CHEKIN_HOW_TO = 'HELP_CHEKIN_HOW_TO',
  HELP_ORDERSHEET_CLOSING = 'HELP_ORDERSHEET_CLOSING',
  HELP_ORDERSHEET_THANKS = 'HELP_ORDERSHEET_THANKS',
  HELP_ORDERSHEET_PARTNER_CLOSE = 'HELP_ORDERSHEET_PARTNER_CLOSE',
  HELP_ORDERSHEET_RECUSED = 'HELP_ORDERSHEET_RECUSED',
  HELP_ORDERSHEET_NOT_FOUND = 'HELP_ORDERSHEET_NOT_FOUND',
  HELP_SOME = 'HELP_SOME',
  HELP_TALK_TO_US = 'HELP_TALK_TO_US',
  HELP_ACCOUNT_TRANSFER = 'HELP_ACCOUNT_TRANSFER',
  HELP_ACCOUNT_BANK_RECEIVE = 'HELP_ACCOUNT_BANK_RECEIVE',
  HELP_ACCOUNT_LIST_TRANSFER = 'HELP_ACCOUNT_LIST_TRANSFER',
  HELP_ACCOUNT_LIST_TRANSFER_TEXT = 'HELP_ACCOUNT_LIST_TRANSFER_TEXT',
  HELP_PROFILE_PICTURE = 'HELP_PROFILE_PICTURE',
  HELP_LOGOTIPO_COMPANY_TEXT = 'HELP_LOGOTIPO_COMPANY_TEXT',
  HELP_LOGOTIPO_COMPANY_DIMENSIONS = 'HELP_LOGOTIPO_COMPANY_DIMENSIONS',
  HELP_COMPANY_COMPLETE_PROFILE = 'HELP_COMPANY_COMPLETE_PROFILE',
  HELP_USER_DISABLE = 'HELP_USER_DISABLE',
  HELP_USER_DISABLE_BACK = 'HELP_USER_DISABLE_BACK',
  HELP_COMPANY_DISABLE_BACK = 'HELP_COMPANY_DISABLE_BACK',
  HELP_COMPANY_DISABLE_PLAN = 'HELP_COMPANY_DISABLE_PLAN',
  HELP_WELCOME_TITLE = 'HELP_WELCOME_TITLE',
  HELP_WELCOME_DESCRIPTION = 'HELP_WELCOME_DESCRIPTION',
  HELP_PASSWORD_NEW = 'HELP_PASSWORD_NEW',
  HELP_PASSWORD_OLD = 'HELP_PASSWORD_OLD',
  HELP_PASSWORD_REMENBER = 'HELP_PASSWORD_REMENBER',
  HELP_PASSWORD_REDEFINE = 'HELP_PASSWORD_REDEFINE',
  HELP_PASSWORD_FORGOT = 'HELP_PASSWORD_FORGOT',
  HELP_INVITE_PEOPLE = 'HELP_INVITE_PEOPLE',
  HELP_INVITE_LINK = 'HELP_INVITE_LINK',
  HELP_LIST_HOW_INVITE = 'HELP_LIST_HOW_INVITE',
  HELP_LIST_HOW_FINISH = 'HELP_LIST_HOW_FINISH',
  HELP_WIFI_NO_REGISTER = 'HELP_WIFI_NO_REGISTER',
  HELP_RESERVE_MINUTES = 'HELP_RESERVE_MINUTES',
  HELP_RESERVE_AGE = 'HELP_RESERVE_AGE',
  HELP_RESERVE_TABLE = 'HELP_RESERVE_TABLE',
  HELP_RESERVE_TAXES = 'HELP_RESERVE_TAXES',
  HELP_RESERVE_CANCEL = 'HELP_RESERVE_CANCEL',
  HELP_MINIMAL_AGE = 'HELP_MINIMAL_AGE',
  HELP_RESERVE_OBS_EXAMPLE = 'HELP_RESERVE_OBS_EXAMPLE',
  HELP_RESERVE_QUANTITY_PARTNER = 'HELP_RESERVE_QUANTITY_PARTNER',
  HELP_SEARCH_BY_NAME = 'HELP_SEARCH_BY_NAME',
  HELP_SEARCH_PARAMETERS = 'HELP_SEARCH_PARAMETERS',
  HELP_LOGIN_TO_SEE = 'HELP_LOGIN_TO_SEE',
  HELP_INSERT_USER = 'HELP_INSERT_USER',
  HELP_USER_HAVENT_DATA = 'HELP_USER_HAVENT_DATA',
  HELP_COMPANY_QUANTITY_ACCEPT = 'HELP_COMPANY_QUANTITY_ACCEPT',
  HELP_PROFILE_VERIFY_UNMANAGE = 'HELP_PROFILE_VERIFY_UNMANAGE',
  HELP_PROFILE_UNMANAGE = 'HELP_PROFILE_UNMANAGE',
  HELP_PROFILE_MANAGE = 'HELP_PROFILE_MANAGE',
  HELP_ORDERSHEET_DETAILS_ENABLED = 'HELP_ORDERSHEET_DETAILS_ENABLED',
  HELP_ORDERSHEET_DETAILS_DISABLED = 'HELP_ORDERSHEET_DETAILS_DISABLED',
};


export enum EMessagesQuestionsTranslations {
  ANY_QUESTION = 'ANY_QUESTION',
  ANY_QUESTION_CONTACT = 'ANY_QUESTION_CONTACT',
  PAY_ATTENTION = 'PAY_ATTENTION',
  QUESTION_DELETE_CARD = 'QUESTION_DELETE_CARD',
  QUESTION_DELETE_CARD_TEXT = 'QUESTION_DELETE_CARD_TEXT',
  QUESTION_ORDERSHEET_CLOSE = 'QUESTION_ORDERSHEET_CLOSE',
  QUESTION_ADD_CREDIT_CARD = 'QUESTION_ADD_CREDIT_CARD',
  QUESTION_ACCOUNT_MAIN_CHANGE = 'QUESTION_ACCOUNT_MAIN_CHANGE',
  QUESTION_ACCOUNT_MAIN_CHANGE_TEXT = 'QUESTION_ACCOUNT_MAIN_CHANGE_TEXT',
  QUESTION_ACCOUNT_DELETE = 'QUESTION_ACCOUNT_DELETE',
  QUESTION_ACCOUNT_DELETE_TEXT = 'QUESTION_ACCOUNT_DELETE_TEXT',
  QUESTION_USER_DISABLE = 'QUESTION_USER_DISABLE',
  QUESTION_COMPANY_DISABLE = 'QUESTION_COMPANY_DISABLE',
  QUESTION_ADDRESS_REGISTER_TEXT = 'QUESTION_ADDRESS_REGISTER_TEXT',
  QUESTION_ADDRESS_DELETE = 'QUESTION_ADDRESS_DELETE',
  QUESTION_ADDRESS_DELETE_TEXT = 'QUESTION_ADDRESS_DELETE_TEXT',
  QUESTION_BAG_ITEM_REMOVE = 'QUESTION_BAG_ITEM_REMOVE',
  QUESTION_BAG_CLEAR = 'QUESTION_BAG_CLEAR',
  QUESTION_EXIT = 'QUESTION_EXIT',
  QUESTION_FAVORITE_REMOVE = 'QUESTION_FAVORITE_REMOVE',
};


export enum EMessagesSuccessTranslations {
  SUCCESS = 'SUCCESS',
  SUCCESS_CHECKIN_ASK = 'SUCCESS_CHECKIN_ASK',
  SUCCESS_CHECKIN_CANCEL = 'SUCCESS_CHECKIN_CANCEL',
  SUCCESS_ADD_ITEM_BAG = 'SUCCESS_ADD_ITEM_BAG',
  SUCCESS_ORDER_DONE = 'SUCCESS_ORDER_DONE',
  SUCCESS_PAYED = 'SUCCESS_PAYED',
  SUCEESS_COMPANY_CREATE = 'SUCEESS_COMPANY_CREATE',
  SUCEESS_COMPANY_CREATE_TEXT = 'SUCEESS_COMPANY_CREATE_TEXT',
  SUCEESS_USER_CREATE = 'SUCEESS_USER_CREATE',
  SUCEESS_USER_CREATE_TEXT = 'SUCEESS_USER_CREATE_TEXT',
  SUCCESS_ACCOUNT_TRANSFER = 'SUCCESS_ACCOUNT_TRANSFER',
  SUCCESS_ACCOUNT_MAIN_CHANGE = 'SUCCESS_ACCOUNT_MAIN_CHANGE',
  SUCCESS_ACCOUNT_DELETE = 'SUCCESS_ACCOUNT_DELETE',
  SUCCESS_CREATE = 'SUCCESS_CREATE',
  SUCCESS_UPDATE = 'SUCCESS_UPDATE',
  SUCCESS_USER_DISABLE = 'SUCCESS_USER_DISABLE',
  SUCCESS_COMPANY_DISABLE = 'SUCCESS_COMPANY_DISABLE',
  SUCCESS_PASSWORD_RECOVER = 'SUCCESS_PASSWORD_RECOVER',
  SUCCESS_PASSWORD_RECOVER_TEXT = 'SUCCESS_PASSWORD_RECOVER_TEXT',
  SUCCESS_CARD_REGISTER = 'SUCCESS_CARD_REGISTER',
  SUCCESS_PAYMENT_MAIN_CHOOSED = 'SUCCESS_PAYMENT_MAIN_CHOOSED',
  SUCCESS_PAYMENT_DELETE = 'SUCCESS_PAYMENT_DELETE',
  SUCCESS_ADDRESS_DELETE = 'SUCCESS_ADDRESS_DELETE',
  SUCCESS_EMAIL_CHANGE = 'SUCCESS_EMAIL_CHANGE',
  SUCCESS_LIST_USER_ADDED = 'SUCCESS_LIST_USER_ADDED',
  SUCCESS_PASSWORD_COPY = 'SUCCESS_PASSWORD_COPY',
  SUCCESS_USER_ADDED = 'SUCCESS_USER_ADDED',
  SUCCESS_FAVORITE_ADD = 'SUCCESS_FAVORITE_ADD',
  SUCCESS_FAVORITE_REMOVE = 'SUCCESS_FAVORITE_REMOVE',
  SUCCESS_COPIED_ADDRESS = 'SUCCESS_COPIED_ADDRESS',
  SUCCESS_COPIED_LINK = 'SUCCESS_COPIED_LINK',
};


export enum EMusicsTranslations {
  EVENT_MUSICAL_GENRES = 'EVENT_MUSICAL_GENRES',
  MUSIC = 'MUSIC',
  ELECTRONIC = 'ELECTRONIC',
  FUNK = 'FUNK',
  COUNTRY_MUSIC = 'COUNTRY_MUSIC',
  POP = 'POP',
  RAP = 'RAP',
  ROCK = 'ROCK',
  MPB = 'MPB',
  AXE = 'AXE',
  SAMBA = 'SAMBA',
  REGGAE = 'REGGAE',
  FORRO = 'FORRO',
  GOSPEL = 'GOSPEL',
  HIP_HOP = 'HIP_HOP',
  JAZZ = 'JAZZ',
  CLASSIC_MUSIC = 'CLASSIC_MUSIC',
  PAGODE = 'PAGODE',
  BLUES = 'BLUES',
};


export enum EPaymentsTranslations {
  BANK = 'BANK',
  AGENCY = 'AGENCY',
  CARD_SELECTED = 'CARD_SELECTED',
  DEBIT_CARD = 'DEBIT_CARD',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  TICKETS_CARD = 'TICKETS_CARD',
  CASH = 'CASH',
  BANK_CHECK = 'BANK_CHECK',
  PIX = 'PIX',
  ELO = 'ELO',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  AMERICAN = 'AMERICAN',
  DINERS = 'DINERS',
  HIPERCARD = 'HIPERCARD',
  ALELO = 'ALELO',
  BEN = 'BEN',
  SODEXO = 'SODEXO',
  CARD_TICKET = 'CARD_TICKET',
  VR = 'VR',
  CHANGE = 'CHANGE',
  DISCOUNT = 'DISCOUNT',
  TIP = 'TIP',
  TOTAL_PAY = 'TOTAL_PAY',
  TOTAL = 'TOTAL',
  SUBTOTAL = 'SUBTOTAL',
  TAXES = 'TAXES',
  TAX_DELIVERY = 'TAX_DELIVERY',
  PARTNER_PAYMENTS_ON = 'PARTNER_PAYMENTS_ON',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  ALL_BANKS = 'ALL_BANKS',
  ACCOUNT_CURRENT = 'ACCOUNT_CURRENT',
  ACCOUNT_SAVING = 'ACCOUNT_SAVING',
  ACCOUNT_NICKNAME = 'ACCOUNT_NICKNAME',
  ACCOUNT_BELONGING = 'ACCOUNT_BELONGING',
  ACCOUNT_CURRENT_SAVING_SAME_NUMBER = 'ACCOUNT_CURRENT_SAVING_SAME_NUMBER',
  CARD_ACCEPTED = 'CARD_ACCEPTED',
  PAYMENT_FORM = 'PAYMENT_FORM',
  CARD_NUMBER = 'CARD_NUMBER',
  DATE_VALID_UNTIL = 'DATE_VALID_UNTIL',
  CARD = 'CARD',
  CARDS = 'CARDS',
  CARD_CVV = 'CARD_CVV',
  CARD_VALIDITY = 'CARD_VALIDITY',
  CARD_CURRENCY = 'CARD_CURRENCY',
  CARD_DEFINE_CURRENCY = 'CARD_DEFINE_CURRENCY',
  CARD_DELETE = 'CARD_DELETE',
  CARD_CHOOSE_MAIN = 'CARD_CHOOSE_MAIN',
  CARD_CHOOSE_DEBIT_TOO = 'CARD_CHOOSE_DEBIT_TOO',
  CARD_CHOOSE_CREDIT_TOO = 'CARD_CHOOSE_CREDIT_TOO',
};


export enum EReservesTranslations {
  RESERVE_TO = 'RESERVE_TO',
  RESERVE_PRICE = 'RESERVE_PRICE',
  RESERVE_FREE = 'RESERVE_FREE',
  RESERVED_TO = 'RESERVED_TO',
  RESERVED_AT = 'RESERVED_AT',
  RESERVE_MADE_TO = 'RESERVE_MADE_TO',
  RESERVE_HOUR = 'RESERVE_HOUR',
  RESERVE_PEOPLE = 'RESERVE_PEOPLE',
};


export enum ESocialsTranslations {
  WHATSAPP = 'WHATSAPP',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  LINKEDIN = 'LINKEDIN',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  CONTACT_US = 'CONTACT_US',
  SEND_MESSAGE = 'SEND_MESSAGE',
};


export enum ETimeTranslations {
  DATE = 'DATE',
  HOUR = 'HOUR',
  DATE_HOUR = 'DATE_HOUR',
  DATES_AVAILABLE = 'DATES_AVAILABLE',
  OPENED_UNTIL = 'OPENED_UNTIL',
  OPENEDS = 'OPENEDS',
  CLOSE_IN = 'CLOSE_IN',
  CLOSED = 'CLOSED',
  CLOSEDS = 'CLOSEDS',
  TODAY = 'TODAY',
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  NEXT = 'NEXT',
  PAST = 'PAST',
  UPDATED_AT = 'UPDATED_AT',
};


export enum ETitlesTranslations {
  HOME = 'HOME',
  FAVORITES = 'FAVORITES',
  EXIT = 'EXIT',
  FORGOT_PASSWORD_QUESTION = 'FORGOT_PASSWORD_QUESTION',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  REGISTER = 'REGISTER',
  ACCESS = 'ACCESS',
  CHECKIN = 'CHECKIN',
  CHECKOUT = 'CHECKOUT',
  CHECKIN_WAIT = 'CHECKIN_WAIT',
  QR_READER = 'QR_READER',
  QR_MY_CODE = 'QR_MY_CODE',
  GALLERY = 'GALLERY',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  ALL_EVENTS = 'ALL_EVENTS',
  LISTS = 'LISTS',
  LISTS_EVENT = 'LISTS_EVENT',
  LISTS_ALL = 'LISTS_ALL',
  LISTS_AVAILABLES = 'LISTS_AVAILABLES',
  LISTS_SPECIALS = 'LISTS_SPECIALS',
  MENUSHEET = 'MENUSHEET',
  HIGHLIGHTS = 'HIGHLIGHTS',
  PARTNER = 'PARTNER',
  PARTNERS = 'PARTNERS',
  PARTNERS_HIGHLIGHTS = 'PARTNERS_HIGHLIGHTS',
  EVENTS = 'EVENTS',
  EVENTS_HIGHTLIGHTS = 'EVENTS_HIGHTLIGHTS',
  EVENTS_MORE = 'EVENTS_MORE',
  EVENTS_STREET = 'EVENTS_STREET',
  MAP = 'MAP',
  MAPS = 'MAPS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATIONS_CONFIG = 'NOTIFICATIONS_CONFIG',
  ORDERSHEET = 'ORDERSHEET',
  ORDERSHEET_PAY = 'ORDERSHEET_PAY',
  PAYMENT_OPTIONS = 'PAYMENT_OPTIONS',
  ORDERBAG = 'ORDERBAG',
  HISTORIC = 'HISTORIC',
  COMPANY_NEW = 'COMPANY_NEW',
  COMPANY_REGISTER = 'COMPANY_REGISTER',
  BANKS = 'BANKS',
  MODAL_ADD_USER = 'MODAL_ADD_USER',
  ACCOUNT_TRANSFER = 'ACCOUNT_TRANSFER',
  ACCOUNT_TRANSFER_ADD = 'ACCOUNT_TRANSFER_ADD',
  COMPANY_ADDRESS_EDIT = 'COMPANY_ADDRESS_EDIT',
  CONTACT = 'CONTACT',
  COMPANY_EDIT = 'COMPANY_EDIT',
  COMPANY_DATAS = 'COMPANY_DATAS',
  PROFILE_PICTURE = 'PROFILE_PICTURE',
  LOGOTIPO = 'LOGOTIPO',
  LOGOTIPO_COMPANY = 'LOGOTIPO_COMPANY',
  ADDRESS = 'ADDRESS',
  ADDRESS_CREATE = 'ADDRESS_CREATE',
  ADDRESS_EDIT = 'ADDRESS_EDIT',
  ADDRESSES = 'ADDRESSES',
  USER_DISABLE = 'USER_DISABLE',
  COMPANY_DISABLE = 'COMPANY_DISABLE',
  REDEFINE_PASSWORD = 'REDEFINE_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  PREFERENCES = 'PREFERENCES',
  SETTINGS = 'SETTINGS',
  LANGUAGE = 'LANGUAGE',
  CARD_ADD = 'CARD_ADD',
  ADDRESS_REGISTER = 'ADDRESS_REGISTER',
  PAYMENTS_SUBSCRIPTIONS = 'PAYMENTS_SUBSCRIPTIONS',
  PROFILE_MY_ABOUT = 'PROFILE_MY_ABOUT',
  PROFILE_DETAILS = 'PROFILE_DETAILS',
  PROFILE_INFORMATIOS = 'PROFILE_INFORMATIOS',
  PROFILE_PUBLIC = 'PROFILE_PUBLIC',
  PROFILE_MAIN = 'PROFILE_MAIN',
  PERMISSIONS_GROUP = 'PERMISSIONS_GROUP',
  INVITE_TO_LIST = 'INVITE_TO_LIST',
  INVITE_PEOPLE = 'INVITE_PEOPLE',
  PARTICIPATE = 'PARTICIPATE',
  LIST_PARTICIPATE = 'LIST_PARTICIPATE',
  DETAILS_ITEM = 'DETAILS_ITEM',
  MY_ORDERS = 'MY_ORDERS',
  RESERVES = 'RESERVES',
  RESERVE_DETAILS = 'RESERVE_DETAILS',
  SEARCH = 'SEARCH',
  OTHER_INFORMATIONS = 'OTHER_INFORMATIONS',
  TERMS_POLICY = 'TERMS_POLICY',
  LICENCES = 'LICENCES',
  KNOW_APP = 'KNOW_APP',
  PROFILE_UNMANAGE = 'PROFILE_UNMANAGE',
  PROFILE_MANAGE = 'PROFILE_MANAGE',
  SHARE = 'SHARE',
};


export enum ESubtitlesTranslations {
  SUBTITLES_LOGOTIPO_COMPANY = 'SUBTITLES_LOGOTIPO_COMPANY',
  SUBTITLES_PROFILE_PICTURE = 'SUBTITLES_PROFILE_PICTURE',
  SUBTITLES_PROFILE_ALL = 'SUBTITLES_PROFILE_ALL',
  SUBTITLES_INFORMATIONS_COMPANY = 'SUBTITLES_INFORMATIONS_COMPANY',
  SUBTITLES_DATAS_COMPANY = 'SUBTITLES_DATAS_COMPANY',
  SUBTITLES_COMPANY_CONTACT = 'SUBTITLES_COMPANY_CONTACT',
  SUBTITLES_COMPANY_TRANSFER_YES = 'SUBTITLES_COMPANY_TRANSFER_YES',
  SUBTITLES_COMPANY_TRANSFER_NO = 'SUBTITLES_COMPANY_TRANSFER_NO',
  SUBTITLES_COMPANY_ADDRESS = 'SUBTITLES_COMPANY_ADDRESS',
  SUBTITLES_ACCOUNT_TRANSFER_ADD = 'SUBTITLES_ACCOUNT_TRANSFER_ADD',
  SUBTITLES_SETTINGS = 'SUBTITLES_SETTINGS',
  SUBTITLES_CARDS = 'SUBTITLES_CARDS',
  SUBTITLES_PROFILE_PUBLIC = 'SUBTITLES_PROFILE_PUBLIC',
  SUBTITLES_PERMISSIONS_GROUP = 'SUBTITLES_PERMISSIONS_GROUP',
  SUBTITLES_HIGHLIGHT_MAP = 'SUBTITLES_HIGHLIGHT_MAP',
  SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE = 'SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE',
  SUBTITLES_LIST_IN = 'SUBTITLES_LIST_IN',
  SUBTITLES_LIST_INSIDE = 'SUBTITLES_LIST_INSIDE',
  SUBTITLES_LIST_OUTSIDE = 'SUBTITLES_LIST_OUTSIDE',
  SUBTITLES_SEE_ITEMS_MY_LOCATION = 'SUBTITLES_SEE_ITEMS_MY_LOCATION',
  SUBTITLES_KNOW_APP = 'SUBTITLES_KNOW_APP',
};


export enum EValidationsTranslations {
  ONLY_NUMBERS = 'ONLY_NUMBERS',
  VALIDATE_MIN_DIGITS = 'VALIDATE_MIN_DIGITS',
  VALIDATE_MAX_DIGITS = 'VALIDATE_MAX_DIGITS',
  VALIDATE_EXACT_DIGITS = 'VALIDATE_EXACT_DIGITS',
  VALIDATE_MIN_CHAR = 'VALIDATE_MIN_CHAR',
  VALIDATE_MAX_CHAR = 'VALIDATE_MAX_CHAR',
  VALIDATE_EXACT_CHAR = 'VALIDATE_EXACT_CHAR',
  VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS',
  VALIDATE_ALL_SELECTED = 'VALIDATE_ALL_SELECTED',
  VALIDATE_HAS_USER = 'VALIDATE_HAS_USER',
  VALIDATE_ONLY_ONE = 'VALIDATE_ONLY_ONE',
  VALIDATE_ONE_MORE = 'VALIDATE_ONE_MORE',
  VALIDATE_EMAIL_REQUIRED = 'VALIDATE_EMAIL_REQUIRED',
  VALIDATE_EMAIL_VALID = 'VALIDATE_EMAIL_VALID',
  VALIDATE_EMAIL_DOMAIN = 'VALIDATE_EMAIL_DOMAIN',
  VALIDATE_PASSWORD_REQUIRED = 'VALIDATE_PASSWORD_REQUIRED',
  VALIDATE_ID_SHORT_REQUIRED = 'VALIDATE_ID_SHORT_REQUIRED',
  VALIDATE_CPF_VALID = 'VALIDATE_CPF_VALID',
  VALIDATE_USER_CPF_USERNAME = 'VALIDATE_USER_CPF_USERNAME',
  VALIDATE_USERNAME_CHAR_SPECIAL = 'VALIDATE_USERNAME_CHAR_SPECIAL',
  VALIDATE_USER_REQUIRED = 'VALIDATE_USER_REQUIRED',
};



const TranslateInteface = {
  ...EAppTranslations,
  ...EAppTutorialTranslations,
  ...EButtonsTranslations,
  ...ECompanyTypesTranslations,
  ...ECarnivalTranslations,
  ...ECountriesTranslations,
  ...EDrinksTranslations,
  ...EEventsTranslations,
  ...EInterpolatesTranslations,
  ...EKitchensTranslations,
  ...ELabelsTranslations,
  ...ELanguagesTranslations,
  ...EMenuOrderTranslations,
  ...EMessagesEmptysTranslations,
  ...EMessagesErrosTranslations,
  ...EMessagesHelpsTranslations,
  ...EMessagesQuestionsTranslations,
  ...EMessagesSuccessTranslations,
  ...EMusicsTranslations,
  ...EPaymentsTranslations,
  ...EReservesTranslations,
  ...ESocialsTranslations,
  ...ETimeTranslations,
  ...ETitlesTranslations,
  ...ESubtitlesTranslations,
  ...EValidationsTranslations,
};



export default TranslateInteface;
