import NoitadaWS from '../index';



export const ChangeTicketUser = async (eventId: string, username: string) => {
  const url = `/events/lists/${eventId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};


export const CancelTicketUser = async (ticketId: string) => {
  const url = `/events/lists/${ticketId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};

export const GetTicketsFromUser = async (userId: string, period: string) => {
  const url = `/events/tickets/user/${userId}?period=${period}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
