import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  size?: number;
  marginHorizontal: number;
}



export const LightEventContainer = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${(props: IProps) => props.size}px;
  min-width: ${(props: IProps) => props.size}px;
  height: ${(props: IProps) => props.size}px;
  min-height: ${(props: IProps) => props.size}px;
  margin-left: ${(props: IProps) => props.marginHorizontal}px;
  margin-right: ${(props: IProps) => props.marginHorizontal}px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const DashLightEventImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
