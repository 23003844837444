import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EnvBadgeContainer,
  EnvBadgeView,
  EnvBadgeText,
} from './styled';



const BadgeEnv: React.FC = () => {
  const environment = process.env.REACT_APP_NODE_ENV;


  function returnEnvColor(): string {
    if (environment === 'LOCAL') {
      return Colors.danger;
    }

    if (environment === 'DEV') {
      return Colors.sketch;
    }

    return Colors.transparent;
  }



  if (environment === 'LOCAL' || environment === 'DEV') {
    return (

      <EnvBadgeContainer>
        <EnvBadgeView
          backgroundColor={returnEnvColor()}>

          <EnvBadgeText>
            {environment}
          </EnvBadgeText>

        </EnvBadgeView>
      </EnvBadgeContainer>

    );
  }

  return null;
};



export default BadgeEnv;
