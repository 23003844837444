import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  renderCardLogo,
} from '@noitada/shared/config/payments.config';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  ActionSheet,
} from '../../../../components/Composh/web';

import FooterChoose from '../../../../components/Footers/FooterChoose';

import {
  FormatMoney,
} from '../../../../config/payment.config';

import {
  ButtonText,
} from '../../../../styles/styled.layout';

import {
  RevisionFinishedContainer,
  RevisionFinishedItems,
  RevisionFinishedItem,
  RevisionFinishedView,
  RevisionFinishedTitle,
  RevisionFinishedText,
  RevisionFinishedCardImage,
} from './styled';



export interface IProps {
  visible?: boolean;
  backgroundColor?: string;
  data?: any;
  cardLast4?: string;
  cardBrand?: string;
  onCancelPress?: any;
  onPress?: any;
}



const RevisionReserveModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const data = props.data;
  const currect_data = new Intl.DateTimeFormat('pt-BR').format(new Date(data?.forDate));
  const peopleQuantity = props.data?.selected.people?.length;
  const companyName = props.data?.company?.name;



  function renderReservesPrices(title: string, text: string, showBrand = false) {
    return (

      <RevisionFinishedItem
        key={title}>

        <RevisionFinishedView>

          <RevisionFinishedTitle>
            {title}
          </RevisionFinishedTitle>


          <RevisionFinishedText>
            {text}
          </RevisionFinishedText>

        </RevisionFinishedView>


        {props.cardBrand && showBrand && (
          <RevisionFinishedCardImage
            alt={'Card Logo'}
            src={renderCardLogo(props.cardBrand || '')}
          />
        )}

      </RevisionFinishedItem>

    );
  }



  return (

    <ActionSheet
      visible={visibleModal}
      height={370}
      overlayColor={Colors.menuOverlay}>

      <RevisionFinishedContainer>

        <RevisionFinishedItems>

          {renderReservesPrices(
            translate(TranslateConfig.RESERVE_TO),
            peopleQuantity === 1
              ? translate(TranslateConfig.ONLY_YOU)
              : `${peopleQuantity} ${translate(TranslateConfig.PEOPLE)}`,
          )}

          {renderReservesPrices(
            translate(TranslateConfig.PARTNER),
            companyName,
          )}


          {renderReservesPrices(
            translate(TranslateConfig.DATE_HOUR),
            `${currect_data} - ${data?.selected?.time}`,
          )}


          {renderReservesPrices(
            translate(TranslateConfig.RESERVE_PRICE),
            data?.reservation?.price > 0
              ? FormatMoney(data?.reservation?.price)
              : translate(TranslateConfig.RESERVE_FREE),
          )}


          {props.cardLast4 && data?.reservation?.price > 0 && renderReservesPrices(
            translate(TranslateConfig.CARD_SELECTED),
            props.cardLast4
              ? `**** **** **** ${props.cardLast4}`
              : translate(TranslateConfig.EMPTY_CARD_REGISTERED),
            true,
          )}

        </RevisionFinishedItems>



        <FooterChoose
          cancelColor={ArrayColors.arrayCancel}
          cancelLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_CANCEL)}
            </ButtonText>
          }
          cancelPress={props.onCancelPress}

          okColor={ArrayColors.arrayOk}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_CONFIRM)}
            </ButtonText>
          }
          okPress={props.onPress}
        />

      </RevisionFinishedContainer>

    </ActionSheet>

  );
};



export default RevisionReserveModal;
