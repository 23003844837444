import {
  EEventAvailables,
} from '../enums';

import {
  TranslateConfig,
} from '../translations';



export const EventAvailablesArray = [
  {
    label: TranslateConfig.DISCOUNT,
    value: EEventAvailables.DISCOUNT,
  },
  {
    label: TranslateConfig.CASADINHA,
    value: EEventAvailables.CASADINHA,
  },
  {
    label: TranslateConfig.OPEN_BAR,
    value: EEventAvailables.OPEN_BAR,
  },
  {
    label: TranslateConfig.MUSIC,
    value: EEventAvailables.MUSIC,
  },
  {
    label: TranslateConfig.LIST,
    value: EEventAvailables.LIST,
  },
  {
    label: TranslateConfig.VIP,
    value: EEventAvailables.VIP,
  },
];
