import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardCarnivalGuideDetail from '../../../../components/Cards/CardCarnival/CardCarnivalGuideDetail';
import CardCarnivalGuideHeader from '../../../../components/Cards/CardCarnival/CardCarnivalGuideHeader';
import CardCarnivalGuideItem from '../../../../components/Cards/CardCarnival/CardCarnivalGuideItem';

import {
  Accordion,
  ListFlat,
} from '../../../../components/Composh/web';

import EmptyContent from '../../../../components/Empty';

import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  InfoGuideHeaderContent,
  InfoGuideTitle,
  InfoGuideSubtitle,
  InfoGuideFlatList,
  InfoItemsTitleContent,
} from './styled';



export interface IProps {
  loading: boolean;
  data: any;
}



const TabGuideInformations: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const guideObject = props.data || null;

  const [loading, setLoading] = useState<boolean>(props.loading);
  const [arrayData, setarrayData] = useState<any>(guideObject?.length > 0 ? guideObject : []);

  const guideText = 'Explore o carnaval com nosso guia cheio de dicas úteis, informações essenciais e recomendações importantes do que fazer. O que você precisa para aproveitar a festa com segurança e tranquilidade você encontra aqui!';



  function getInformationsList() {
    if (guideObject?.length > 0) {
      setarrayData(guideObject);
    }
    else {
      setarrayData([]);
    }
  }


  function listHeaderComponent() {
    return (

      <InfoGuideHeaderContent>

        <InfoGuideTitle>
          {translate(TranslateConfig.INFORMATIONS)}
        </InfoGuideTitle>


        {guideText && (
          <InfoGuideSubtitle>
            {guideText}
          </InfoGuideSubtitle>
        )}

      </InfoGuideHeaderContent>

    );
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    if (loading) {
      return null;
    }

    return (

      <EmptyContent
        image={Images.noGuides}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_INFOS)}
      />

    );
  }


  function renderCategoriesHeader(item: any, expanded: boolean, changeExpand: any, index: number) {
    return (

      <CardCarnivalGuideItem
        key={index}
        expanded={expanded}
        title={item?.name}
        onPress={() => {
          changeExpand();
        }}
      />

    );
  }


  function renderCategoriesContent(item: any, index: number) {
    return (

      <CardCarnivalGuideDetail
        key={index}
        picture={item?.picture}
        description={item?.description}
      />

    );
  }


  function renderAccordionHeader(item: any, expanded: boolean, changeExpand: any, index: number) {
    return (

      <CardCarnivalGuideHeader
        key={index}
        expanded={expanded}
        title={item?.name}
        onPress={() => {
          changeExpand();
        }}
      />

    );
  }


  function renderCategoryAcordion(item: any, index: any) {
    return (

      <Accordion
        key={index}
        dataArray={[item]}
        // animation
        expanded
        renderHeader={(item: any, expanded: boolean, changeExpand: any, index: number) => renderCategoriesHeader(item, expanded, changeExpand, index)}
        renderContent={(item: any, index: number) => renderCategoriesContent(item, index)}
      />

    );
  }


  function renderAccordionContent(item: any, index: number) {
    return (

      <InfoItemsTitleContent
        key={index}>

        <ListFlat
          data={item?.details}
          renderItem={renderCategoryAcordion}
        />

      </InfoItemsTitleContent>

    );
  }


  function renderAcordion(item: any, index: number) {
    return (

      <Accordion
        key={index}
        dataArray={[item]}
        // animation
        expanded
        renderHeader={(item: any, expanded: boolean, changeExpand: any, index: number) => renderAccordionHeader(item, expanded, changeExpand, index)}
        renderContent={(item: any, index: number) => renderAccordionContent(item, index)}
      />

    );
  }



  useEffect(() => {
    getInformationsList();
  }, [guideObject]);


  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);



  return (

    <InfoGuideFlatList
      loading={loading}
      data={arrayData}
      renderItem={renderAcordion}
      listHeaderComponent={listHeaderComponent()}
      listLoadingComponent={renderLoading()}
      listFooterComponent={renderFooter()}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabGuideInformations;
