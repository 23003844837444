import React from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Body,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconThumbDown,
  IconThumbMiddle,
  IconThumbUp,
} from '../../../../components/Icons';

import {
  openWhatsApp,
} from '../../../../config/linking.config';

import {
  ContentPadder,
} from '../../../../styles/styled.layout';

import {
  FaqSectionTitle,
  FaqCategoryDescription,
} from '../styled';

import {
  HelpVoteContainer,
  HelpVoteText,
  VoteButtonsContent,
  HelpVoteButton,
  HelpContactButton,
  FaqCategoryBullet,
} from './styled';



export interface IRouteParams {
  category: string;
  bullet: any;
}



const FaqBulletScreen: React.FC = () => {
  const routeLocation = useLocation<IRouteParams>();
  const params = routeLocation?.state;


  const itemCategory = params?.category;
  const itemFaq = params?.bullet;

  const utillityNo = false;
  const utillityMiddle = false;
  const utillityYes = false;



  function renderButtonVote(vote: boolean, icon: any, onPress: any) {
    return (

      <HelpVoteButton
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={onPress}
        style={vote
          ? {
            backgroundColor: vote ? Colors.primary : Colors.transparent,
            borderColor: Colors.primary,
          }
          : undefined
        }>

        {icon}

      </HelpVoteButton>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              Ajuda
            </Title>

            <SubTitle
              color={Colors.white}>
              {itemCategory}
            </SubTitle>
          </Body>
        }
      />


      <Content>
        <ContentPadder>

          <FaqSectionTitle
            color={Colors.buttonOkLight}>
            {itemFaq.text}
          </FaqSectionTitle>


          <FaqCategoryDescription
            color={Colors.white}>
            {itemFaq.description}
          </FaqCategoryDescription>



          <HelpVoteContainer>

            <HelpVoteText>
              Essa informação te ajudou?
            </HelpVoteText>


            <VoteButtonsContent>

              {renderButtonVote(
                utillityNo,
                <IconThumbDown
                  color={utillityNo ? Colors.white : Colors.gray}
                  size={28}
                />,
                () => {
                  // TODO
                },
              )}


              {renderButtonVote(
                utillityMiddle,
                <IconThumbMiddle
                  color={utillityMiddle ? Colors.white : Colors.gray}
                  size={28}
                />,
                () => {
                  // TODO
                },
              )}


              {renderButtonVote(
                utillityYes,
                <IconThumbUp
                  color={utillityYes ? Colors.white : Colors.gray}
                  size={28}
                />,
                () => {
                  // TODO
                },
              )}

            </VoteButtonsContent>

          </HelpVoteContainer>



          <HelpVoteContainer>

            <HelpVoteText>
              Ainda tem dúvidas?
            </HelpVoteText>



            <HelpContactButton
              // activeOpacity={Sizes.ButtonActiveOpacity}
              onClick={() => {
                openWhatsApp();
              }}>

              <FaqCategoryBullet
                color={Colors.buttonOkAccent}>
                Entrar em Contato
              </FaqCategoryBullet>

            </HelpContactButton>

          </HelpVoteContainer>

        </ContentPadder>
      </Content>

    </Screen>

  );
};



export default FaqBulletScreen;
