import {
  Colors,
} from '../constants';



export const ArrayColors = {
  arrayDisabled: [
    Colors.grayLight,
    Colors.gray,
  ],


  arrayCancel: [
    Colors.buttonCancel,
    Colors.buttonCancelDark,
  ],


  arrayEdit: [
    Colors.buttonAttention,
    Colors.buttonAttentionAccent,
  ],


  arrayAction: [
    Colors.buttonAction,
    Colors.buttonActionDark,
  ],


  arrayOk: [
    Colors.buttonOk,
    Colors.buttonOkDark,
  ],
};



export default ArrayColors;
