import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';



export const CardEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;

  @media ${PlatformDevices.minMobileS} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const CardEventContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
`;

export const CardPlaceImageButton = styled.a`
  display: flex;
  flex-direction: column;
`;

export const CardPlaceLogo = styled.img`
  height: 65px;
  width: 65px;
  margin-right: 15px;
  background-color: ${Colors.black};
  border-radius: 3px;
  object-fit: cover;
`;



// width: 100%;
export const CardInfoHeader = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
`;

export const CardEventName = styled.h1`
  color: ${Colors.white};
  font-size: 20px;
`;

export const CardEventPlace = styled.h2`
  color: ${Colors.textSubtitle};
  font-size: 15px;
  margin-bottom: 2px;
`;

export const CardEventDate = styled.h3`
  color: ${Colors.textSubDescription};
  font-size: 12.5px;
`;



export const CardEventBannerContainer = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
`;

// height: ${Dimensions.get('window').width}px;
export const CardEventBanner = styled.img`
  width: 100%;
  height: 270px;
  background-color: ${Colors.black};
  object-fit: cover;
`;



export const CardFooter = styled.a`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 8px;
  align-items: center;
`;

export const CardItemsPlace = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-right: 3px;
`;

export const GenreIconContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const CardEventLocation = styled.p`
  display: flex;
  flex: 1;
  padding-top: 2px;
  color: ${Colors.white};
  font-size: 15px;
`;


export const CardItemsAvailable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  min-width: 60px;
`;

export const CardAvailableViewItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
`;
