import styled from 'styled-components';

import {
  SubtitleText,
} from '../../../../../styles/styled.layout';



export const TextRules = styled(SubtitleText)`
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const TextIndexRules = styled.p`
  font-weight: bold;
`;
