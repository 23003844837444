import React from 'react';
import ReactDOM from 'react-dom';

import {
  HelmetProvider,
} from 'react-helmet-async';

import {
  CssBaseline,
} from '@material-ui/core';

import AppEnvironments from '@noitada/shared/environments/application';

import '@noitada/shared/translations';

// import {
//   getAnalytics,
// } from 'firebase/analytics';

import {
  initializeApp,
} from 'firebase/app';

import App from './App';


initializeApp(AppEnvironments().firebaseWebConfig);
// getAnalytics(app);



ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <CssBaseline />
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
