import styled from 'styled-components';



export const CardHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;



export const CardHistoryImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 14px;
`;



export const HistoryInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;

export const HistorySheetTitle = styled.h1`
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Segoe UI';
`;


export const HistoryStatusView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;

export const HistorySheetStatusText = styled.h2`
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const HistorySheetHourText = styled.h3`
  color: darkgray;
  font-size: 14px;
`;



export const HistorySheetPriceText = styled.h3`
  margin-left: 10px;
  color: #FFFFFF;
  font-size: 16px;
`;
