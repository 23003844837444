import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CopiedItem from '../../Contents/CopiedItem';

import {
  IconWifiInformation,
} from '../../Icons';

import {
  WifiServiceContainer,
  WifiServiceIcon,
  WifiServiceInfoView,
  WifiServiceName,
  WifiServiceBoldName,
  WifiServiceCopyText,
} from './styled';



export interface IProps {
  copied?: boolean;
  name?: string;
  password?: string;
  onCopyPress?: any;
}



const DetailsWifi: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <WifiServiceContainer
      onClick={props.onCopyPress}>

      <WifiServiceIcon>
        <CopiedItem
          copied={props.copied}
          icon={
            <IconWifiInformation
              size={45}
              color={Colors.white}
            />
          }
        />
      </WifiServiceIcon>



      <WifiServiceInfoView>

        <WifiServiceName>
          <WifiServiceBoldName>
            {translate(TranslateConfig.NAME)}:
          </WifiServiceBoldName>
          {' '}
          {props.name || '-'}
        </WifiServiceName>


        <WifiServiceName>
          <WifiServiceBoldName>
            {translate(TranslateConfig.PASSWORD)}:
          </WifiServiceBoldName>
          {' '}
          {props.password || '-'}
        </WifiServiceName>


        {props.onCopyPress && (
          <WifiServiceCopyText>
            {translate(TranslateConfig.ACTION_COPY_PASSWORD)}
          </WifiServiceCopyText>
        )}

      </WifiServiceInfoView>

    </WifiServiceContainer>

  );
};



export default DetailsWifi;
