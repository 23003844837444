import React from 'react';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';
import {
  Content,
} from '../../../../../components/Composh/web';

import ItemsList from '../../../../../components/Items/ItemsList';

import {
  ContentPadderHorizontal,
} from '../../../../../styles/styled.layout';


import {
  EventImageView,
  EventImageCheckout,
  PriceContent,
  PriceText,
  ToPayText,
  EventInfoView,
  EventNameText,
  EventPlaceText,
  TicketInfoView,
  TicketTypeImage,
  TicketPriceText,
  TableContent,
  TableView,
  TableComponent,
  TableWrapperComponent,
  TableColComponent,
  TableRowsComponent,
  InfoTicketText,
  InfoTotalText,
  PricesTicketText,
  PricesView,
  PricesTotalText,
  PricesParcelText,
} from './styled';



const TabCheckout: React.FC = () => {
  const tableItems = {
    tableHeight: [
      30,
      30,
      30,
      60,
    ],

    tableTitle: [
      [
        <InfoTicketText>
          Subtotal:
        </InfoTicketText>,
      ],
      [
        <InfoTicketText>
          Encargos:
        </InfoTicketText>,
      ],
      [
        <InfoTicketText>
          Desconto:
        </InfoTicketText>,
      ],
      [
        <InfoTotalText>
          Total:
        </InfoTotalText>,
      ],
    ],

    tableData: [
      [
        <PricesTicketText>
          {'R$ 1.335,00'}
        </PricesTicketText>,
      ],
      [
        <PricesTicketText>
          {'R$ 133,50'}
        </PricesTicketText>,
      ],
      [
        <PricesTicketText>
          {'- R$ 70,00'}
        </PricesTicketText>,
      ],
      [
        <PricesView>
          <PricesTotalText>
            {'R$ 1.398,50'}
          </PricesTotalText>

          <PricesParcelText>
            {true
              ? `${12}X de ${'R$ 253,00'}`
              : 'À vista'
            }
          </PricesParcelText>
        </PricesView>,
      ],
    ],
  };



  return (

    <Content>

      <EventImageView>
        <EventImageCheckout
          src={Images.placeholderEvent}
        />


        <PriceContent>
          <PriceText>
            R$ 1.398,50
          </PriceText>

          <ToPayText>
            A Pagar
          </ToPayText>
        </PriceContent>
      </EventImageView>



      <ContentPadderHorizontal>

        <EventInfoView>
          <EventNameText>
            Nome do Evento com duas linhas para ter noção até onde vai o texto.
          </EventNameText>

          <EventPlaceText>
            Nome do local com apenas uma linha
          </EventPlaceText>
        </EventInfoView>


        <TicketInfoView>
          <ItemsList
            iconLeft={
              <TicketTypeImage
                src={Images.logoIconAppWhite}
              />
            }
            title={'Nome do Ingresso'}
            titleColor="white"
            subtitle={'Tipo do Ingresso'}
            subtitleColor={'slategray'}
            thirdtitle={null}
            thirdtitleColor={Colors.textApp}
            iconRight={
              <TicketPriceText>
                R$ 1.335,00
              </TicketPriceText>
            }
            border={false}
          />
        </TicketInfoView>


        <TableContent>
          <TableView>

            <TableComponent>
              <TableWrapperComponent>

                <TableColComponent
                  data={tableItems.tableTitle}
                  heightArr={tableItems.tableHeight}
                  width={130}
                />

                <TableRowsComponent
                  data={tableItems.tableData}
                  flexArr={[1, 0]}
                  heightArr={tableItems.tableHeight}
                />

              </TableWrapperComponent>
            </TableComponent>

          </TableView>
        </TableContent>

      </ContentPadderHorizontal>

    </Content>

  );
};



export default TabCheckout;
