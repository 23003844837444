import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Divider,
  Items,
} from '../../../../../components/Composh/web';

import {
  ContentPadderHorizontal,
} from '../../../../../styles/styled.layout';



// Profiles search

export const SearchLabelContainer = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  padding-top: ${Sizes.paddingContainer}px;
`;

export const SearchLabelTypeText = styled.h3`
  margin-top: 2px;
  margin-bottom: 15px;
  color: ${Colors.white};
  font-size: 18px;
  text-align: center;
`;



export const DividerSearch = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 20px;
`;



export const SearchParamsContainer = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${Sizes.paddingContainer}px;
`;


export const SearchTogglesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
`;

export const SearchToggle25Grid = styled(Items.Grid)`
  width: 25%;
  min-width: 25%;
  max-width: 25%;
  margin-top: 5px;
`;

export const SearchToggle33Grid = styled(Items.Grid)`
  width: 33%;
  min-width: 33%;
  max-width: 33%;
  margin-top: 5px;
`;

export const SearchChipContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;
