import React from 'react';

import {
  Colors,
  Keys,
  Sizes,
} from '@noitada/shared/constants';

import {
  IconSocialEmail,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialLinkedIn,
  IconSocialTwitter,
  IconSocialYoutube,
} from '../Icons';

import {
  TermsListInline,
  TermsSocialButton,
} from './styled';



const SocialButtons: React.FC = () => {
  return (

    <TermsListInline>

      {[
        {
          icon: (
            <IconSocialFacebook
              color={Colors.white}
              size={Sizes.iconTermsPages}
            />
          ),
          link: Keys.facebookLink,
        },
        {
          icon: (
            <IconSocialInstagram
              color={Colors.white}
              size={Sizes.iconTermsPages}
            />
          ),
          link: Keys.instagramLink,
        },
        {
          icon: (
            <IconSocialTwitter
              color={Colors.white}
              size={Sizes.iconTermsPages}
            />
          ),
          link: Keys.twitterLink,
        },
        {
          icon: (
            <IconSocialYoutube
              color={Colors.white}
              size={Sizes.iconTermsPages}
            />
          ),
          link: Keys.youtubeLink,
        },
        {
          icon: (
            <IconSocialLinkedIn
              color={Colors.white}
              size={Sizes.iconTermsPages}
            />
          ),
          link: Keys.linkedInLink,
        },
        {
          icon: (
            <IconSocialEmail
              color={Colors.white}
              size={Sizes.iconTermsPages}
            />
          ),
          link: Keys.noitadaEmailContact,
        },
      ].map((itemSocial) => (

        <TermsSocialButton
          href={itemSocial.link}
          target={'_blank'}>
          {itemSocial.icon}
        </TermsSocialButton>

      ))}

    </TermsListInline>

  );
};



export default SocialButtons;
