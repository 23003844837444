import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Title,
  Screen,
} from '../../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import TabBarStyled from '../../../../components/TabBar';

import TabReservesPast from './Tabs/TabPast';
import TabReservesUpcomming from './Tabs/TabUpcomming';



const UserReservesScreen: React.FC = () => {
  const { t: translate } = useTranslation();


  const screenTabs = [
    {
      name: 'ReservesUpcomming',
      title: translate(TranslateConfig.NEXT),
      component: <TabReservesUpcomming />,
    },
    {
      name: 'ReservesPast',
      title: translate(TranslateConfig.PAST),
      component: <TabReservesPast />,
    },
  ];


  const [stateIndex, setStateIndex] = useState(0);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <Title color={Colors.white}>
            Minhas reservas
          </Title>
        }
        rightIcon={
          <ButtonMore
            color={Colors.white}
          />
        }
        noSubToolbarPadding>


        <TabBarStyled
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tabIndex: number) => {
            setStateIndex(tabIndex);
          }}
        />

      </HeaderScreen>



      {stateIndex === 0 && screenTabs[0].component}

      {stateIndex === 1 && screenTabs[1].component}

    </Screen>

  );
};



export default UserReservesScreen;
