import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



// Section Banner

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: ${Sizes.imageBannerSize}px;
  width: 100%;
  background-color: ${(props: IProps) => props.backgroundColor};
`;
// shadowOffset: { width: 5, height: 5 },
// shadowColor: 'gray',
// shadowOpacity: 0.7,
// shadowRadius: 4,
// elevation: 4,


export const EventPicContentView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;



export const BannerImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const BannerBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
`;

export const BannerImageOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardOverlay};
`;



export const BannerImage = styled.img`
  position: absolute;
  width: ${Sizes.imageBannerSize - 30}px;
  height: ${Sizes.imageBannerSize - 30}px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
  z-index: 5;
`;
