import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const MenuLevelImageContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${Colors.profileToolbar};
  width: ${Sizes.imageDrawer}px;
  height: ${Sizes.imageDrawer}px;
  border-style: solid;
  border-width: 3px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const MenuImageLevel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MenuPlaceImage = styled.img`
  height: 53px;
  width: 53px;
  margin-bottom: 3px;
  background-color: ${Colors.profileToolbar};
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const MenuTextLevel = styled.p`
  height: 22px;
  padding-top: 1px;
  color: white;
  font-size: 14px;
  text-align: center;
`;
