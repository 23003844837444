import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  AppBar,
  Body,
  SubTitle,
  Title,
  Toolbar,
} from '../../Composh/web';

import {
  IconClose,
} from '../../Icons';

import {
  TravelHeaderCloseIcon,
} from './styled';



export interface IProps {
  transparent?: boolean;
  title: string;
  subtitle?: string;
  onPress?: any;
}



const HeaderModal: React.FC<IProps> = (props) => {
  return (

    <AppBar
      transparent={props.transparent}
      backgroundColor={props.transparent ? Colors.transparent : Colors.toolbarBackground}>

      <Toolbar
        backgroundColor={props.transparent ? Colors.transparent : Colors.toolbarBackground}
        centerContent={
          <Body>
            <Title>
              {props.title}
            </Title>

            {props.subtitle && (
              <SubTitle>
                {props.subtitle}
              </SubTitle>
            )}
          </Body>
        }
        rightIcon={
          <TravelHeaderCloseIcon
            onClick={props.onPress}>
            <IconClose
              color={Colors.white}
              size={28}
            />
          </TravelHeaderCloseIcon>
        }
      />

    </AppBar>

  );
};



export default HeaderModal;
