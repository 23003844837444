import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Card,
} from '../../../Composh/web';



export const CarnivalCabinCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 170px;
  min-height: 170px;
  max-height: 170px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const CarnivalCabinBackgroundImage = styled(Card.View)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  border-color: ${Colors.toolbarLine};
  border-style: solid;
  border-width: 0.5px;
`;

export const CarnivalCabinBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



export const CarnivalCabinContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${Colors.menuOverlay};
  flex: 1;
  padding-top: 10px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 10px;
  z-index: 5;
`;

export const CarnivalCabinImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
`;

export const CabinButtonExpandedContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  padding-bottom: 5px;
`;

export const CarnivalCabinInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const CarnivalCabinTitle = styled.p`
  display: flex;
  flex: 1;
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CarnivalStreetButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 31px;
  padding-top: 5px;
`;


export const CarnivalCabinArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`;



export const CarnivalCabinDescText = styled.p`
  width: 100%;
  color: lightgray;
  font-size: 14px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
