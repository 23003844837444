import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Body,
  Overlay,
  SubTitle,
  Title,
} from '../../components/Composh/web';

import {
  ButtonBack,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import HeaderScreen from '../../components/Headers/HeaderScreen';
import CreditCardDash from '../../components/Payments/CreditCardDash';

import {
  PaymentModalContainer,
  PaymentPadderHorizontal,
} from './styled';



export interface IProps {
  visible: boolean;
  data: any;
  onCancelPress?: any;
  onPress?: any;
}



const PaymentsModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const paymentsData = props.data || null;



  function goBack() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  };


  function renderItem(item: any, index: any) {
    return (

      <CreditCardDash
        key={index} // key={item.pagarme_card_id}
        current={item.main}
        cardBrand={item.card_brand}
        name={item.name}
        last4={item.last4}
        onPress={() => {
          if (props.onPress) {
            props.onPress(item);
          }
        }}
      />

    );
  }


  function listEmptyComponent() {
    // if (loading) {
    //   return null;
    // }

    return (

      <EmptyContent
        image={Images.logoIconAppWhite}
        title={translate(TranslateConfig.EMPTY_PAYMENT)}
      />

    );
  }


  // async function refreshList() {
  //   setRefreshing(true);
  //   await getRules();
  //   setRefreshing(false);
  // }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <PaymentModalContainer>

        <HeaderScreen
          transparent
          centerContent={
            <Body>
              <Title
                color={Colors.white}>
                {translate(TranslateConfig.CARDS)}
              </Title>

              <SubTitle
                color={Colors.gray}>
                {translate(TranslateConfig.SUBTITLES_CARDS)}
              </SubTitle>
            </Body>
          }
          rightIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={() => {
                goBack();
              }}
            />
          }
        />



        <PaymentPadderHorizontal style={{
          justifyContent: paymentsData?.length === 0 ? 'center' : 'flex-start',
        }}>


          {(!paymentsData || paymentsData?.length === 0) && (
            listEmptyComponent()
          )}


          {paymentsData?.length !== 0 && paymentsData?.map((item: any, index: any) => (
            renderItem(item, index)
          ))}

        </PaymentPadderHorizontal>



        {/* <FooterChoose
          cancelColor={ArrayColors.arrayCancel}
          cancelLabel={
            <ButtonText
              color={Colors.white}>
              Cancelar
            </ButtonText>
          }
          cancelPress={() => {
            goBack();
          }}

          okDisabled={!dirty || !isValid}
          okColor={!dirty || !isValid
            ? ArrayColors.arrayDisabled
            : ArrayColors.arrayOk
          }
          okLabel={
            <ButtonText
              color={Colors.white}>
              Pedir por Check-In
            </ButtonText>
          }
          okPress={() => {
            handleSubmit();
          }}
        /> */}

      </PaymentModalContainer>

    </Overlay>

  );
};



export default PaymentsModal;
