import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  BagPlaceAddItemsButton,
  BagPlaceAddItemsText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  title: string;
  onPress?: any;
}



const ButtonSeeMore: React.FC<IProps> = (props: IProps) => {
  function hasOnPress() {
    if (props.onPress && !props.disabled) {
      props.onPress();
    }
  }



  return (

    <BagPlaceAddItemsButton
      style={{
        backgroundColor: props.disabled ? Colors.calendarColorGray : Colors.calendarColorLight,
      }}
      onClick={() => {
        hasOnPress();
      }}>
      <BagPlaceAddItemsText
        style={{
          color: props.disabled ? Colors.gray : Colors.accent,
        }}>
        {props.title}
      </BagPlaceAddItemsText>
    </BagPlaceAddItemsButton>

  );
};



export default ButtonSeeMore;
