import styled from 'styled-components';

import {
  Container,
} from '../../../../../components/Composh/web';



export const ListTicketContentFlex = styled(Container)`
`;

export const ListTicketContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;

  @media (max-width: 320px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
