import React,
{
  useRef,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Formik,
  FormikProps,
} from 'formik';

import CheckinRequestModel from '@noitada/axios-config/models/Checkin/checkin-request.model';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CheckinRequestValidationSchema from '@noitada/shared/validations/Checkin/checkin-request.validations';

import {
  Title,
  Overlay,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import InputText from '../../../../components/Inputs/InputText';

import {
  ButtonText,
} from '../../../../styles/styled.layout';

import {
  CheckinModalContainer,
  CheckinPadderHorizontal,
  CheckinModalText,
} from './styled';



export interface IProps {
  visible?: boolean;
  onCancelPress?: any;
  onPress?: any;
}



const CheckInModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const widthLine = 320;
  const heightLine = 460;

  const formik = useRef<FormikProps<typeof CheckinRequestModel>>(null);



  function goBack() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  };


  function sendRequestCheckIn(values: typeof CheckinRequestModel) {
    props.onPress(values);
    props.onCancelPress();
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <Formik
        enableReinitialize
        validateOnMount={false}
        innerRef={formik}
        initialValues={CheckinRequestModel}
        validationSchema={CheckinRequestValidationSchema}
        onSubmit={(values) => {
          sendRequestCheckIn(values);
        }}>
        {({
          dirty,
          isValid,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
        }) => (

          <CheckinModalContainer
            width={widthLine}
            height={heightLine}>

            <HeaderScreen
              transparent
              centerContent={
                <Title>
                  {translate(TranslateConfig.CHECKIN)}
                </Title>
              }
              rightIcon={
                <ButtonBack
                  close
                  color={Colors.white}
                  onPress={() => {
                    goBack();
                  }}
                />
              }
            />



            <CheckinPadderHorizontal>

              <CheckinModalText>
                {translate(TranslateConfig.HELP_CHECKIN)}
              </CheckinModalText>


              <InputText
                type={'TEXT'}
                labelText={translate(TranslateConfig.PUBLIC_PARTNER_ID)}
                placeholderText={translate(TranslateConfig.PUBLIC_ID)}
                helpText={translate(errors.idShort || '')}
                countLimit={Values.shortIdCount}
                value={values.idShort}
                onChange={handleChange('idShort')}
                onBlur={handleBlur('idShort')}
              />


              <InputText
                type={'TEXT'}
                labelText={translate(TranslateConfig.NUMBER_TABLE_ENTRANCE)}
                placeholderText={translate(TranslateConfig.TABLE_ENTRANCE)}
                helpText={translate(errors.tableEntrance || '')}
                countLimit={Values.tableCount}
                value={values.tableEntrance}
                onChange={handleChange('tableEntrance')}
                onBlur={handleBlur('tableEntrance')}
              />

            </CheckinPadderHorizontal>



            <FooterChoose
              cancelColor={ArrayColors.arrayCancel}
              cancelLabel={
                <ButtonText
                  color={Colors.white}>
                  {translate(TranslateConfig.ACTION_CANCEL)}
                </ButtonText>
              }
              cancelPress={() => {
                goBack();
              }}

              okDisabled={!dirty || !isValid}
              okColor={!dirty || !isValid
                ? ArrayColors.arrayDisabled
                : ArrayColors.arrayOk
              }
              okLabel={
                <ButtonText
                  color={Colors.white}>
                  {translate(TranslateConfig.ACTION_ASK_CHECKIN)}
                </ButtonText>
              }
              okPress={() => {
                handleSubmit();
              }}
            />

          </CheckinModalContainer>

        )}
      </Formik>

    </Overlay>

  );
};



export default CheckInModal;
