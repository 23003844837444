import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Chip,
} from '../Composh/web';

import {
  IconAdd,
  IconCheck,
} from '../Icons';



export interface IProps {
  disabled?: any;
  selected?: any;
  title?: any;
  onPress?: any;
}



const ChipApp: React.FC<IProps> = (props: IProps) => {
  return (

    <Chip
      onCloseDisabled
      disabled={props.disabled}
      selected={props.selected}
      title={props.title}
      textColor={Colors.white}
      backgroundColor={Colors.cardBackground}
      selectedTextColor={Colors.white}
      selectedBackgroundColor={Colors.primary}
      icon={props.selected
        ? (
          <IconCheck
            size={18}
            color={Colors.white}
          />
        )
        : (
          <IconAdd
            size={18}
            color={Colors.white}
          />
        )
      }
      onPress={props.onPress}
    />

  );
};



export default ChipApp;
