import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Images,
} from '@noitada/shared/constants';

import {
  EEventOcurrence,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CardUserReserveContainer,
  MyReservePlaceContent,
  MyReservePlaceInfo,
  MyReserveDate,
  MyReserveCompanyName,
  MyReservePlaceImage,
  MyReserveTableNumber,
  MyReservePeopleNumber,
  MyReserveStatus,
} from './styled';



export interface IProps {
  id?: string;
  date?: string;
  status?: string;
  time?: string;
  companyPicture?: string;
  companyName?: string;
  environment?: string;
  peopleNumber?: string;
  onPress?: any;
}



const CardUserReserves: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <CardUserReserveContainer
      onClick={props.onPress}>

      <MyReservePlaceContent>

        <MyReservePlaceInfo>

          <MyReserveDate>
            {props.date}
          </MyReserveDate>

          <MyReserveDate>
            {props.time} • {props.environment}
          </MyReserveDate>

          <MyReserveCompanyName>
            {translate(TranslateConfig.ID)}: #{props.id}
          </MyReserveCompanyName>

        </MyReservePlaceInfo>



        <MyReservePlaceImage
          alt={'Logo'}
          src={props.companyPicture || Images.placeholderAvatarCompany}
        />

      </MyReservePlaceContent>



      <MyReserveTableNumber>
        {props.companyName}
      </MyReserveTableNumber>


      <MyReservePeopleNumber>
        {translate(TranslateConfig.RESERVE_PEOPLE)}: {props.peopleNumber}
      </MyReservePeopleNumber>


      {props?.status === EEventOcurrence.CANCELED && (
        <MyReserveStatus>
          {translate(TranslateConfig.CANCELED)}
        </MyReserveStatus>
      )}

    </CardUserReserveContainer>

  );
};



export default CardUserReserves;
