import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  MODAL_LOCATION_APP_ACTION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconMapLocation,
} from '../../Icons';

import {
  ButtonCityUserStyle,
  ButtonCityView,
  ButtonCityParent,
  MenuIconCity,
  MenuTextCity,
} from './styled';



const ButtonCityUser: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);



  return (

    <ButtonCityUserStyle
      onClick={() => {
        dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: true });
      }}>

      <ButtonCityView>

        <ButtonCityParent>
          <MenuIconCity>
            <IconMapLocation
              size={19}
              color={Colors.white}
            />
          </MenuIconCity>


          {/* // FIXME: Se o nome da cidade usar tail (...) fazer o nome se mexer para mostrar todo */}
          <MenuTextCity>
            {localities.city || translate(TranslateConfig.CITY)}
          </MenuTextCity>
        </ButtonCityParent>

      </ButtonCityView>

    </ButtonCityUserStyle>

  );
};



export default ButtonCityUser;
