import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';

import {
  Card,
  LinearGradient,
} from '../../../Composh/web';



export const CarnivalDistrictResponsive = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;

  @media ${PlatformDevices.minMobileS} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTabletM} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 4px;
    padding-right: 4px;
  }
`;


export const CarnivalDistrictContainer = styled(Card.View)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 150px;
  border-radius: 3px;
  border-color: ${Colors.toolbarLine};
  border-style: solid;
  border-width: 0.5px;
`;

export const CarnivalDistrictBackgroundGradient = styled(LinearGradient)`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: -1px;
`;


export const CarnivalDistrictPerson = styled.img`
  height: 140px;
  width: 140px;
  margin-bottom: -1px;
  object-fit: cover;
`;



export const CarnivalDistrictContentItems = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 15px;
`;

export const CarnivalDistrictHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
`;

export const CarnivalDistrictInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CarnivalDistrictHour = styled.p`
  min-width: 95px;
  font-weight: bold;
  color: ${Colors.textApp};
  font-size: 18px;
  text-align: left;
`;

export const CarnivalDistrictTitle = styled.p`
  margin-bottom: 3px;
  color: ${Colors.white};
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
`;


export const CarnivalDistrictPlace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CarnivalDistrictMarker = styled.img`
  height: 35px;
  width: 35px;
  margin-right: 10px;
  border-radius: 4px;
  object-fit: contain;
`;

export const CarnivalDistrictText = styled.p`
  flex:1;
  color: lightgray;
  font-size: 14px;
`;
