import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  noMargin?: boolean;
}



export const MyLocationButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(props: IProps) => props.noMargin ? 0 : '20px'};
  padding: 15px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const LocationIconView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: ${Colors.primary};
  border-radius: 50px;
`;

export const LocationInfoTextView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
`;

export const LocationTitle = styled.p`
  margin-bottom: 2px;
  color: white;
  font-size: 18px;
`;

export const LocationSubtitle = styled.p`
  color: gray;
  font-size: 14px;
`;



export const LocationCountryView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  height: 50px;
`;

export const LocationCountryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
