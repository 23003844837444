import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Content,
} from '../../components/Composh/web';

import {
  GetPlatform,
} from '../../config/plataform.config';



export const ScrollbarHideStyle = css`
  ::-webkit-scrollbar {
    
    box-shadow: inset 0 0 10px 10px green;
    border: solid 3px transparent;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;



export const CompanyInfoImageButton = styled.a`
  display: flex;
  flex-direction: column;
  height: 35px;
  width: 35px;
  margin-left: 2px;
  margin-right: 12px;
`;

export const ImageCompanyInfo = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 2px;
`;

export const CompanyImageButton = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 35px;
  width: 35px;
  margin-right: 5px;
  background-color: ${Colors.black};
`;



export const ScrollbarItemsStyle = css`
  margin-top: -4px;
  padding-bottom: 0;

  ::-webkit-scrollbar {
    height: 9px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.toolbarBackground};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: ${Colors.toolbarBackground};
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 4px;
  }
`;

export const TypeItemsView = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;

  ${GetPlatform() === 'WEB' ? ScrollbarItemsStyle : ScrollbarHideStyle}
`;


export const MenuSheetContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;



export const FlatListCategory = styled(Content)`
  display: flex;
  flex-direction: column;
  width: 27%;
  min-width: 27%;
  max-width: 27%;
  background-color: ${Colors.menuBackground};
  border-right-color: ${Colors.menuSeparator};
  border-right-style: solid;
  border-right-width: 0.5px;
  
  overflow-x: hidden;
  overflow-y: auto;

  ${GetPlatform() !== 'WEB' && ScrollbarHideStyle}
`;

export const FlatListItems = styled(Content)`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.appBackground};
  
  overflow-x: hidden;
  overflow-y: auto;

  ${GetPlatform() !== 'WEB' && ScrollbarHideStyle}
`;



export const CategoryMenuView = styled.a`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 12px;
  border-top-color: ${Colors.menuSeparator};
  border-top-style: solid;
  border-top-width: 0.25px;
  border-bottom-color: ${Colors.menuSeparator};
  border-bottom-style: solid;
  border-bottom-width: 0.25px;
`;

export const MenuSheetViewText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 44px;
`;

export const MenuSheetText = styled.p`
  color: white;
  font-size: 15px;
  line-height: 19.5px;
`;


