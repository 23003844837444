import React,
{
  memo,
} from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import BadgeNotConfirmed from '../../../Badges/BadgeNotConfirmed';

import {
  CarnivalAttractionsResponsive,
  CarnivalAttractionsContainer,
  CarnivalAttractionsBackgroundGradient,
  CarnivalAttractionsPersonContainer,
  CarnivalAttractionsPerson,
  CarnivalAttractionsInfoContainer,
  CarnivalAttractionsInfoView,
  CarnivalAttractionsText,
  CarnivalAttractionsTitle,
  CarnivalAttractionsSeparator,
  CarnivalAttractionsBlocHour,
  CarnivalAttractionsBanner,
  CarnivalAttractionsHour,
} from './styled';



export interface IProps {
  confirmed?: boolean;
  expanded?: boolean;

  title?: string;
  description?: string;
  hour?: string;
  backgroundImage?: any;

  blocName?: string;
  singerName?: string;
  singerImage?: string;
  blocImage?: string;

  cabinLogo?: any;
}



const CardCarnivalAttraction: React.FC<IProps> = (props: IProps) => {
  const ImageSinger = props.singerImage && props.singerImage !== ''
    ? props.singerImage
    : Images.logoNoitadaCarnivalIcon;

  const confirmedItem = !props.confirmed;



  function generateBackgroundColor() {
    const colors = [
      Colors.carnivalBlue,
      Colors.carnivalGreen,
      Colors.carnivalPurple,
      Colors.carnivalRed,
      Colors.carnivalYellow,
    ];
    return colors[Math.floor(Math.random() * colors.length) + 0];
  }



  return (

    <CarnivalAttractionsResponsive>
      <CarnivalAttractionsContainer
        backgroundColor={generateBackgroundColor()}
        borderRadius={Sizes.cardCarnivalRadius}>


        {confirmedItem && (
          <BadgeNotConfirmed />
        )}


        <CarnivalAttractionsBackgroundGradient
          colors={['transparent', 'black']}
        // start={{ x: 0, y: 1 }}
        // end={{ x: 1, y: 1 }}
        // locations={[0, 0.85]}
        >

          <CarnivalAttractionsPersonContainer>
            <CarnivalAttractionsPerson
              alt={'attraction'}
              src={ImageSinger}
            />
          </CarnivalAttractionsPersonContainer>


          <CarnivalAttractionsInfoContainer>
            <CarnivalAttractionsInfoView>
              {props.blocName && props.blocName !== '' && (
                <CarnivalAttractionsText>
                  {props.blocName}
                </CarnivalAttractionsText>
              )}

              <CarnivalAttractionsTitle>
                {props.singerName ? props.singerName.toUpperCase() : '-'}
              </CarnivalAttractionsTitle>
            </CarnivalAttractionsInfoView>


            <CarnivalAttractionsSeparator
              option={'VERTICAL'}
            />


            <CarnivalAttractionsBlocHour>
              {props.blocImage && props.blocImage !== '' && (
                <CarnivalAttractionsBanner
                  alt={'attraction'}
                  src={props.blocImage}
                />
              )}

              <CarnivalAttractionsHour>
                {props.hour || '-'}
              </CarnivalAttractionsHour>
            </CarnivalAttractionsBlocHour>

          </CarnivalAttractionsInfoContainer>

        </CarnivalAttractionsBackgroundGradient>

      </CarnivalAttractionsContainer>
    </CarnivalAttractionsResponsive>

  );
};



export default memo(CardCarnivalAttraction);
