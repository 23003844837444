import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  ICountry,
} from '@noitada/axios-config/interfaces/App/country.interface';

import {
  RootState,
} from '@noitada/redux-config';

import {
  USER_LANGUAGE,
} from '@noitada/redux-config/reducers/localities.store';

import {
  languagesOption,
} from '@noitada/shared/arrays/languages.array';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardLanguage from '../../../components/Cards/CardLanguage';

import {
  Overlay,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import InputText from '../../../components/Inputs/InputText';

import {
  setCurrentLanguage,
} from '../../../config/language.config';

import {
  BankModalView,
  FilteredInput,
  FilteredBanksContent,
  FilteredListSection,
} from './styled';



export interface IProps {
  visible?: boolean;
  onPress?: any;
  onOverlayPress?: any;
}



const LanguagesModal: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation();


  const languageSelected = useSelector((state: RootState) => state.localities.language);


  const widthLine = 320;
  const heightLine = 500;

  const visibleModal = props.visible;

  const [filterBank, setFilterBank] = useState('');

  const [filterArrayLanguages, setFilterArrayLanguages] = useState<Array<ICountry>>([]);



  function onLanguagePress(language: string) {
    i18n.changeLanguage(language);

    setCurrentLanguage(language);
    props.onOverlayPress();
  }


  function renderItem(item: ICountry, index: number) {
    return (

      <CardLanguage
        key={index}
        selected={item?.languageIso === languageSelected}
        title={translate(item?.label)}
        languageIso={item?.languageIso ? (item?.languageIso).replace('_', '-') : '-'}
        image={item?.flagImage}
        onPress={() => {
          dispatch({ type: USER_LANGUAGE, payload: item?.languageIso });
          onLanguagePress(item?.languageIso);
        }}
      />

    );
  }


  function returnListFiltered() {
    const getAllTofilterBankArray = languagesOption?.filter((bankFilter: ICountry) => (bankFilter.label.toLowerCase().includes(filterBank.toLowerCase())) || filterBank === '');
    setFilterArrayLanguages(getAllTofilterBankArray);
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.EMPTY_LANGUAGES)}
      />

    );
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onOverlayPress}>

      <BankModalView
        width={widthLine}
        height={heightLine}>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.LANGUAGE)}
            </Title>
          }
          rightIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={props.onOverlayPress}
            />
          }
        />



        <FilteredInput>
          <InputText
            type={'TEXT'}
            labelText={translate(TranslateConfig.LANGUAGE)}
            placeholderText={translate(TranslateConfig.NAME)}
            value={filterBank}
            // helpText={errorMessage}
            // countLimit={Values.cpfCount}
            onChange={(rawText: string) => {
              setFilterBank(rawText);
              returnListFiltered();
            }}
          />
        </FilteredInput>



        <FilteredBanksContent>

          {(!languagesOption || languagesOption?.length === 0 || (filterBank !== '' && filterArrayLanguages.length === 0)) && (
            listEmptyComponent()
          )}



          {languagesOption?.length !== 0 && filterArrayLanguages.length !== 0 && filterBank !== '' && (
            <FilteredListSection>
              {filterArrayLanguages.map((itemFiltered: ICountry, index: number) => renderItem(itemFiltered, index))}
            </FilteredListSection>
          )}



          {languagesOption?.length !== 0 && filterBank === '' && (
            <FilteredListSection>
              {languagesOption.map((itemFiltered: ICountry, index: number) => (
                renderItem(itemFiltered, index)
              ))}
            </FilteredListSection>
          )}

        </FilteredBanksContent>

      </BankModalView>

    </Overlay>

  );
};



export default LanguagesModal;
