import React from 'react';

import {
  Images,
} from '@noitada/shared/constants';

import ButtonAddCount from '../../../Buttons/ButtonAddCount';

import {
  CardBagBuyButton,
  BagPlaceImage,
  CardBagBuyView,
  CardBagText,
  CardBagSubtitle,
  CardBagCommentsText,
  CardBagControlsView,
  CardBagPriceText,
} from './styled';



export interface IProps {
  title?: string;
  description?: string;
  comments?: string;
  image?: string;
  price?: string;
  titleGroup?: string;
  quantity?: number;
  updateQuantity?: any;
}



const CardOrderBagBuy: React.FC<IProps> = (props: any) => {
  return (

    <CardBagBuyButton>

      <BagPlaceImage
        alt={'cover'}
        src={props.image || Images.placeholderAvatarCompany}
        onError={(e: any) => {
          e.target.src = Images.placeholderAvatarCompany;
        }}
      />



      <CardBagBuyView>

        <CardBagText>
          {props.title} {props?.titleGroup ? `(${props?.titleGroup})` : ''}
        </CardBagText>


        <CardBagSubtitle>
          {props.description}
        </CardBagSubtitle>


        <CardBagCommentsText>
          {props.comments || ''}
        </CardBagCommentsText>



        <CardBagControlsView>

          <ButtonAddCount
            quantity={props.quantity}
            removePress={() => {
              props.updateQuantity(-1);
            }}
            addPress={() => {
              props.updateQuantity(1);
            }}
          />


          <CardBagPriceText>
            {props.price}
          </CardBagPriceText>

        </CardBagControlsView>

      </CardBagBuyView>

    </CardBagBuyButton>

  );
};



export default CardOrderBagBuy;
