import {
  ECompanyType,
} from '../enums';

import {
  TranslateConfig,
} from '../translations';



export const companyTypeArray = [
  {
    label: TranslateConfig.EVENT_PRODUCERS,
    word: TranslateConfig.EVENT_PRODUCER,
    value: ECompanyType.EVENT_PRODUCER,
  },
  {
    label: TranslateConfig.BARS,
    word: TranslateConfig.BAR,
    value: ECompanyType.BAR,
  },
  {
    label: TranslateConfig.PUBS,
    word: TranslateConfig.PUB,
    value: ECompanyType.PUB,
  },
  {
    label: TranslateConfig.NIGHT_CLUBS,
    word: TranslateConfig.NIGHT_CLUB,
    value: ECompanyType.NIGHT_CLUB,
  },
  {
    label: TranslateConfig.RESTAURANTS,
    word: TranslateConfig.RESTAURANT,
    value: ECompanyType.RESTAURANT,
  },
  {
    label: TranslateConfig.PIZZERIAS,
    word: TranslateConfig.PIZZERIA,
    value: ECompanyType.PIZZERIA,
  },
  {
    label: TranslateConfig.STEAKHOUSES,
    word: TranslateConfig.STEAKHOUSE,
    value: ECompanyType.STEAKHOUSE,
  },
  {
    label: TranslateConfig.HAMBURGUERS,
    word: TranslateConfig.HAMBURGUER,
    value: ECompanyType.HAMBURGUER,
  },
  {
    label: TranslateConfig.COFFEE_SHOPS,
    word: TranslateConfig.COFFEE_SHOP,
    value: ECompanyType.COFFEE_SHOP,
  },
];



export const companyCompleteTypeArray = [
  ...companyTypeArray,
  {
    label: TranslateConfig.CARNIVAL_PRODUCERS,
    word: TranslateConfig.CARNIVAL_PRODUCER,
    value: ECompanyType.CARNIVAL_PRODUCER,
  },
  {
    label: TranslateConfig.PARTY_PRODUCERS,
    word: TranslateConfig.PARTY_PRODUCER,
    value: ECompanyType.PARTY_PRODUCER,
  },
  {
    label: TranslateConfig.GOVERNS,
    word: TranslateConfig.GOVERN,
    value: ECompanyType.GOVERN,
  },
];
