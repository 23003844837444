import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconClose,
} from '../../../Icons';

import {
  CardMapEventContainer,
  CardMapEventBackground,
  CardMapEventOverlay,
  CardMapEventContent,
  CardMapEventImage,
  CardMapEventInfoView,
  CardMapEventDateText,
  CardMapEventInfoText,
  CardMapTitleInfoView,
  CardMapEventNameText,
  CardMapEventPlaceText,
  CardMapEventPlaceView,
  CardMapPlaceImage,
  CardMapEventNamePlaceText,
  CardMapEventsButtonsView,
  CardMapEventSeeButton,
  CardMapEventSeeText,
  CardMapCloseEventButton,
  CardMapEventImageButton,
  CardMapEventInfoButton,
} from './styled';



export interface IProps {
  date?: string;
  name?: string;
  eventImage?: string;
  placeName?: string;
  companyName?: string;
  companyImage?: string;
  onEventPress?: any;
  onCompanyPress?: any;
  onListPress?: any;
  onTicketsPress?: any;
  onClosePress?: any;
}



const CardMapEvent: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <CardMapEventContainer>

      <CardMapEventBackground
        src={props.eventImage === '' || props.eventImage === null || props.eventImage === undefined
          ? Images.placeholderEvent
          : props.eventImage
        }
      />

      <CardMapEventOverlay />



      <CardMapEventContent>

        <CardMapEventImageButton
          onClick={props.onEventPress}>
          <CardMapEventImage
            src={props.eventImage === '' || props.eventImage === null || props.eventImage === undefined
              ? Images.placeholderEvent
              : props.eventImage
            }
          />
        </CardMapEventImageButton>



        <CardMapEventInfoView>

          <CardMapEventInfoButton
            onClick={props.onEventPress}>

            <CardMapEventDateText>
              {props.date}
            </CardMapEventDateText>


            <CardMapEventInfoText>
              {translate(TranslateConfig.ACTION_MORE_DETAILS)}
            </CardMapEventInfoText>


            <CardMapTitleInfoView>
              <CardMapEventNameText>
                {props.name}
              </CardMapEventNameText>


              <CardMapEventPlaceText>
                {props.placeName}
              </CardMapEventPlaceText>
            </CardMapTitleInfoView>

          </CardMapEventInfoButton>



          <CardMapEventPlaceView
            onClick={props.onCompanyPress}>

            <CardMapEventNamePlaceText>
              {props.companyName}
            </CardMapEventNamePlaceText>


            <CardMapPlaceImage
              src={
                props.companyImage === '' || props.companyImage === null || props.companyImage === undefined
                  ? Images.placeholderAvatarCompany
                  : props.companyImage
              }
            />

          </CardMapEventPlaceView>

        </CardMapEventInfoView>

      </CardMapEventContent>



      <CardMapEventsButtonsView>

        <CardMapEventSeeButton
          // activeOpacity={Sizes.ButtonActiveOpacity}
          active={props.onTicketsPress}
          onClick={() => {
            if (props.onTicketsPress) {
              props.onTicketsPress();
            }
          }}>
          <CardMapEventSeeText>
            {props.onTicketsPress
              ? translate(TranslateConfig.ACTION_TICKET_SEE)
              : translate(TranslateConfig.ERROR_TICKET_UNAVAILABLES)
            }
          </CardMapEventSeeText>
        </CardMapEventSeeButton>


        <CardMapEventSeeButton
          // activeOpacity={Sizes.ButtonActiveOpacity}
          active={props.onListPress}
          onClick={() => {
            if (props.onListPress) {
              props.onTicketsPress();
            }
          }}>
          <CardMapEventSeeText>
            {props.onListPress
              ? translate(TranslateConfig.ACTION_LISTS_SEE)
              : translate(TranslateConfig.ERROR_LISTS_UNAVAILABLES)
            }
          </CardMapEventSeeText>
        </CardMapEventSeeButton>


        <CardMapCloseEventButton
          // activeOpacity={Sizes.ButtonActiveOpacity}
          onClick={props.onClosePress}>
          <IconClose
            color={Colors.white}
            size={24}
          />
        </CardMapCloseEventButton>

      </CardMapEventsButtonsView>

    </CardMapEventContainer>

  );
};



export default CardMapEvent;
