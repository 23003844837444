import React from 'react';

import Separators from '../../../components/Separators';

import {
  FooterChooseStyle,
  SeparatorVerticalFooter,
  DetailsButtonFooter,
  ActionButtonView,
  FooterButtonView,
  FooterButtonGradient,
} from './styled';



export interface IProps {
  backgroundColor?: string;
  hideDivider?: boolean;

  hideCancelButton?: boolean;
  // cancelType?: string;
  cancelDisabled?: boolean;
  cancelColor?: string | string[];
  cancelLabel?: any;
  cancelPress?: any;

  hideOkButton?: boolean;
  // okType?: string;
  okDisabled?: boolean;
  okColor?: string | string[];
  okLabel?: any;
  okPress?: any;
}



const FooterChoose: React.FC<IProps> = (props: IProps) => {
  return (

    <FooterChooseStyle
      style={{
        height: 60,
        paddingLeft: 5,
        paddingRight: 5,
        backgroundColor: props.backgroundColor,
      }}>


      {!props.hideDivider && (
        <SeparatorVerticalFooter
          option={'VERTICAL'}
        />
      )}


      <DetailsButtonFooter
        style={{
          paddingBottom: 4,
        }}>

        {!props.hideCancelButton && (
          <ActionButtonView
            onClick={props.cancelDisabled ? null : props.cancelPress}>

            {typeof props.cancelColor === 'string' && (
              <FooterButtonView
                // disabled={props.cancelDisabled}
                // activeOpacity={Sizes.ButtonActiveOpacity}
                // type={props.cancelType || 'button'}
                backgroundColor={props.cancelColor}
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}>
                {props.cancelLabel}
              </FooterButtonView>
            )}


            {Array.isArray(props.cancelColor) && (
              <FooterButtonGradient
                angle={180}
                colors={props.cancelColor}
                locations={[
                  0,
                  1,
                ]}>
                {props.cancelLabel}
              </FooterButtonGradient>
            )}

          </ActionButtonView>
        )}


        {!(props.hideCancelButton || props.hideOkButton) && (
          <Separators />
        )}


        {!props.hideOkButton && (
          <ActionButtonView
            onClick={props.okDisabled ? null : props.okPress}>

            {typeof props.okColor === 'string' && (
              <FooterButtonView
                // disabled={props.okDisabled}
                // activeOpacity={Sizes.ButtonActiveOpacity}
                // type={props.okType || 'button'}
                backgroundColor={props.okColor}
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                }}>
                {props.okLabel}
              </FooterButtonView>
            )}


            {Array.isArray(props.okColor) && (
              <FooterButtonGradient
                angle={180}
                colors={props.okColor}
                locations={[
                  0,
                  1,
                ]}>
                {props.okLabel}
              </FooterButtonGradient>
            )}

          </ActionButtonView>
        )}

      </DetailsButtonFooter>

    </FooterChooseStyle>

  );
};



export default FooterChoose;
