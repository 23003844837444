import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/payment.config';

import {
  BottomCartStyle,
  ContainNumberItems,
  TextCart,
  PriceCart,
} from './styled';



export interface IProps {
  items?: Array<any>;
  totalItems?: string;
  price?: string;
  onPress?: any;
}



const DetailsCart: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function returnPriceValue() {
    if (!props.items) {
      return 0;
    }
    if (props.price) {
      return props?.price;
    }
    return '';
  }



  return (

    <BottomCartStyle
      onClick={props.onPress}>

      <ContainNumberItems>
        {props.totalItems || 0}
      </ContainNumberItems>


      <TextCart>
        {translate(TranslateConfig.ACTION_BAG_SEE)}
      </TextCart>

      <PriceCart>
        {FormatMoney(returnPriceValue())}
      </PriceCart>

    </BottomCartStyle>

  );
};



export default DetailsCart;
