import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

// import {
//   ActionSheetStyle,
// } from '~/styles/sheet.layout';

import {
  Colors,
} from '@noitada/shared/constants';

import CardCarnivalCircuit from '../../../../components/Cards/CardCarnival/CardCarnivalCircuit/intex';

import {
  ActionSheet,
  Content,
} from '../../../../components/Composh/web';

import {
  ListContainer,
  ListCarnivalTitle,
} from './styled';



export interface IProps {
  visible?: boolean;
  title?: string;
  data: Array<any>;
  onPress?: any;
  onClose?: any;
}



const CarnivalActionSheet: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const arrayTitle = props.title || '-';
  const arrayData = props.data && props.data?.length > 0 ? props.data : [];


  function renderCircuitItem(item: any, index: number) {
    return (

      <CardCarnivalCircuit
        key={index}
        name={translate(item?.label)}
        circuit={item?.circuit}
        onPress={() => {
          props.onPress(item);
        }}
      />

    );
  }



  return (

    <ActionSheet
      visible={props.visible}
      // onOpen={props.onOpen}
      height={230}
      overlayColor={Colors.alertOverlay}
      closeOnDragDown
      closeOnPressMask
      backgroundColor={Colors.cardBackground}
      // customStyles={ActionSheetStyle}
      onClose={props.onClose}>

      <Content>

        <ListCarnivalTitle>
          {arrayTitle.toUpperCase()}
        </ListCarnivalTitle>


        <ListContainer>

          {arrayData.map((route: any, index: number) => (
            renderCircuitItem(route, index)
          ))}

        </ListContainer>

      </Content>

    </ActionSheet>

  );
};



export default CarnivalActionSheet;
