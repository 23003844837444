import React from 'react';

import { Colors } from '@noitada/shared/constants';

import { Screen } from '../../../components/Composh/web';

// import { Container } from './styles';



const FestivalGridScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <h1 style={{ color: 'white' }}>
        FestivalGridScreen
      </h1>

    </Screen>

  );
};



export default FestivalGridScreen;
