
const SearchProfileModel = {
  usernameOrName: '',
  type: '',
  modality: [],
  services: [],
};



export default SearchProfileModel;
