import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Accordion,
  ListFlat,
  Shimmer,
} from '../../../../components/Composh/web';



export const HighlightMenuSeparator = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 50px;
  padding-left: 13px;
  padding-right: 10px;
`;

export const HighlightsSeparator = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  margin-right: 5px;
  padding-bottom: 7px;
`;

export const ShimmerMargin = styled(Shimmer)`
  margin-right: 5px;
`;



// Street Tab Style

export const StreetCarnivalFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



// District Tab Style

export const DistrictsTitle = styled.p`
  color: ${Colors.textTitle};
  font-size: 20px;
`;

export const DistrictsCarnivalFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DistrictCarnivalAccordion = styled(Accordion)`
  width: 100%;
`;

export const DistrictCarnivalContentAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


// Attraction Tab Style

export const AttractionsCarnivalFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



// Cabin Carnival

export const CabinCarnivalFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CabinCarnivalAccordion = styled(Accordion)`
  width: 100%;
`;

export const CabinCarnivalContentAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


// Map Carnival

export const CarnivalMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  z-index: 0;
`;

export const CarnivalMapRefreshButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  left: 8px;
  width: 40px;
  height: 40px;
  background-color: ${Colors.white};
  border-radius: 50px;
`;

export const CarnivalMapTypeButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 8px;
  width: 55px;
  height: 55px;
  padding: 5px;
  background-color: ${Colors.white};
  border-radius: 50px;
`;

export const CarnivalMapTypeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
