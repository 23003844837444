import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Content,
} from '../../components/Composh/web';



export const PaymentModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  width: 320px;
  height: 400px;
`;


export const PaymentPadderHorizontal = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
`;


export const PaymentModalText = styled.p`
  margin-bottom: 15px;
  color: ${Colors.textApp};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;
