import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardUserOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const CardUserOrderHeader = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const CardOrderCompanyImage = styled.img`
  height: 75px;
  width: 75px;
  background-color: ${Colors.black};
  object-fit: cover;
  border-radius: 3px;
`;


export const CardUserInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
`;

export const CardUserDate = styled.h1`
  color: ${Colors.accent};
  font-size: 18px;
  font-weight: 400;
`;

export const CardUserPlaceName = styled.h2`
  color: ${Colors.white};
  font-size: 22px;
`;

export const CardUserTypeOrderText = styled.h2`
  color: gray;
  font-size: 17px;
  font-weight: 400;
`;



export const CardUserOrderBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-top-color: ${Colors.toolbarLine};
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: ${Colors.toolbarLine};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

export const CardUserAvaliationView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardUserPriceView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
`;

export const CardUserPrice = styled.h2`
  color: ${Colors.buttonOkLight};
`;



export const CategoryButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 8px;
`;



export const ActionCardButton = styled.a`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  max-height: 45px;
`;

export const ActionCardIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 7px;
`;

export const ActionCardText = styled.p`
  color: ${Colors.primary};
  font-size: 14px;
`;
