import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  OpenDirectionWase,
  OpenDirectionUber,
  OpenDirectionImage,
  OpenDirectionText,
} from './styled';



export interface IProps {
  type: 'WAZE' | 'UBER';
  disabled?: boolean;
  onPress?: any;
}



const ButtonSocial: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  switch (props.type) {
    case 'WAZE':
      return (

        <OpenDirectionWase
          disabled={props.disabled}
          backgroundColor={'#12b5cb'}
          onClick={props.disabled ? undefined : props.onPress}>

          <OpenDirectionImage
            alt={'waze'}
            src={Images.logoWazeIcon}
          />

          <OpenDirectionText>
            {translate(TranslateConfig.ACTION_OPEN_WAZE)}
          </OpenDirectionText>

        </OpenDirectionWase>

      );

    case 'UBER':
      return (

        <OpenDirectionUber
          disabled={props.disabled}
          backgroundColor={Colors.black}
          onClick={props.disabled ? undefined : props.onPress}>

          <OpenDirectionImage
            alt={'uber'}
            src={Images.logoUberIcon}
          />

          <OpenDirectionText>
            {translate(TranslateConfig.ACTION_OPEN_UBER)}
          </OpenDirectionText>

        </OpenDirectionUber>

      );

    default:
      return null;
  }
};



export default ButtonSocial;
