import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconMapPin,
  IconStarFull,
} from '../../../Icons';

import {
  DashProfileContainer,
  DashProfileImageView,
  DashProfileHeaderImage,
  ImageOverlayUser,
  DashProfileInfoContainer,
  DashProfileInfoView,
  DashLeftInfoView,
  DashProfileImage,
  DashProfileStarView,
  DashProfileRatingText,
  DashProfileTextsView,
  DashProfileNameText,
  DashProfileRatingView,
  DashProfileLocationView,
  DashProfileLocationText,
  DashProfileOpenedText,
  DashProfileFlexStarView,
} from './styled';



export interface IProps {
  image?: string;

  name?: string;
  hour?: string;
  rating?: string;
  place?: string;
  onPress?: any;
}



const CardProfileLight: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <DashProfileContainer
      onClick={props.onPress}>

      <DashProfileImageView>
        <DashProfileHeaderImage
          // blurRadius={12}
          alt={'profile_header'}
          src={
            props.image === '' || props.image === null || props.image === undefined
              ? Images.placeholderEvent
              : props.image
          }
        />

        <ImageOverlayUser />
      </DashProfileImageView>



      <DashProfileInfoContainer>

        <DashProfileInfoView>

          <DashLeftInfoView>
            <DashProfileImage
              alt={'prof'}
              src={
                props.image === '' || props.image === null || props.image === undefined
                  ? Images.placeholderEvent
                  : props.image
              }
            />


            {props.rating && (
              <DashProfileStarView>
                <IconStarFull
                  color={'white'}
                  size={13}
                />

                <DashProfileRatingText>
                  {props.rating || '--'}
                </DashProfileRatingText>
              </DashProfileStarView>
            )}
          </DashLeftInfoView>


          <DashProfileTextsView>
            <DashProfileNameText>
              {props.name || '-'}
            </DashProfileNameText>
          </DashProfileTextsView>

        </DashProfileInfoView>



        <DashProfileRatingView>

          <DashProfileLocationView>
            <IconMapPin
              size={14}
              color={Colors.white}
            />

            <DashProfileLocationText>
              {props.place || '-'}
            </DashProfileLocationText>
          </DashProfileLocationView>


          <DashProfileFlexStarView>
            <DashProfileOpenedText>
              {props.hour
                ? props.hour
                : translate(TranslateConfig.ACTION_HOUR_SEE)
              }
            </DashProfileOpenedText>
          </DashProfileFlexStarView>

        </DashProfileRatingView>

      </DashProfileInfoContainer>

    </DashProfileContainer>

  );
};



export default CardProfileLight;
