import styled from 'styled-components';



export const EventTypeContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
`;



export const SearchAvailableContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
`;



export const PrideImage = styled.img`
  width: 30px;
  height: 20px;
  object-fit: contain;
`;
