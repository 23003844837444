import {
  configureStore,
} from '@reduxjs/toolkit';

import {
  combineReducers,
} from 'redux';

import {
  persistReducer,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import appReducer from './reducers/app.store';
import carnivalReducer from './reducers/carnival-festivals.store';
import comandaReducer from './reducers/comanda.store';
import companyReducer from './reducers/company.store';
import eventReducer from './reducers/event.store';
import favoritesReducer from './reducers/favorites.store';
import localitiesReducer from './reducers/localities.store';
import orderBagStore from './reducers/order-bag.store';
import userReducer from './reducers/user.store';


const reducers = combineReducers({
  app: appReducer,
  comanda: comandaReducer,
  company: companyReducer,
  event: eventReducer,
  favorites: favoritesReducer,
  localities: localitiesReducer,
  sacola: orderBagStore,
  user: userReducer,
  carnivalFestivals: carnivalReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['navigation'], // navigation will not be persisted
  // whitelist: ['navigation'], // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);



const storeReducers = configureStore({
  reducer: reducers,
});

const storePersisted = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});



export type RootState = ReturnType<typeof storeReducers.getState>;

export {
  storeReducers,
  storePersisted,
};
