import React,
{
  useState,
} from 'react';

import {
  useSelector,
} from 'react-redux';

import { useHistory } from 'react-router-dom';
import { RootState } from '@noitada/redux-config';

import { ArrayColors } from '@noitada/shared/arrays';
import { Colors } from '@noitada/shared/constants';

import { Body, Screen, StepIndicator, SubTitle, Title } from '../../../../components/Composh/web';

import { ButtonBack } from '../../../../components/Controls';

import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import { ButtonText } from '../../../../styles/styled.layout';
import BuyFinishedScreen from './Tabs/BuyFinished';
import TabCheckout from './Tabs/Checkout';
import TabChoosePayment from './Tabs/ChoosePayment';
import TabInstallments from './Tabs/Installments';
import TabListEntrances from './Tabs/ListEntrances';

// import { Container } from './styles';



const BuyEntrancesScreen: React.FC = () => {
  const event = useSelector((state: RootState) => state.event.selected);
  const history = useHistory();

  const tickets = event.event_tickets;

  const [currentStep, setCurrentStep] = useState(0);
  const [entrance, setEntrance] = useState<any>();
  const [steps] = useState([
    'Ingressos',
    'Pagamento',
    'Parcelas',
    'Checkout',
  ]);



  function returnLabelOkButton() {
    if (currentStep === 3) {
      return 'Finalizar';
    }
    else if (currentStep === 4) {
      return 'Fechar';
    }
    else {
      return 'Avançar';
    }
  }

  function okButtonPress() {
    let stepValue = currentStep;

    // if ((currentStep + 1) < steps.length) {
    if (currentStep !== 4) {
      setCurrentStep(++stepValue);
    }
    else {
      history.goBack();
      setTimeout(() => {
        setCurrentStep(0);
      }, 1000);
    }
  }



  function returnLabelCancelButton() {
    if (currentStep === 0) {
      return 'Cancelar';
    }
    else {
      return 'Voltar';
    }
  }

  function cancelButtonPress() {
    let stepValue = currentStep;

    if (currentStep === 0) {
      history.goBack();
      setCurrentStep(0);
    }
    else {
      // if (currentStep > 0) {
      setCurrentStep(--stepValue);
    }
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title>
              Comprar ingressos
            </Title>

            <SubTitle>
              {event?.name}
            </SubTitle>
          </Body>
        }>

        {currentStep !== 4 && (
          <StepIndicator
            colorAccent={Colors.primary}
            colorMoreStep={Colors.white}
            textColorPassed={Colors.white}
            colorPassed={Colors.secondary}
            data={steps}
            stepCurrent={currentStep}
          />
        )}

      </HeaderScreen>



      {currentStep === 0 &&
        <TabListEntrances
          selectedId={entrance?.id}
          tickets={tickets}
          onPress={(id: number) => setEntrance(tickets.find((ticket) => ticket.id === id))}
        />
      }
      {currentStep === 1 &&
        <TabChoosePayment />
      }
      {currentStep === 2 &&
        <TabInstallments />
      }
      {currentStep === 3 &&
        <TabCheckout />
      }
      {currentStep === 4 &&
        <BuyFinishedScreen />
      }



      <FooterChoose
        hideCancelButton={currentStep === 3 || currentStep === 4}
        cancelColor={ArrayColors.arrayCancel}
        cancelLabel={
          <ButtonText
            color={Colors.white}>
            {returnLabelCancelButton()}
          </ButtonText>
        }
        cancelPress={() => {
          cancelButtonPress();
        }}

        okColor={Colors.buttonOk}
        okLabel={
          <ButtonText
            color={Colors.white}>
            {returnLabelOkButton()}
          </ButtonText>
        }
        okPress={() => {
          okButtonPress();
        }}
      // okLabel={
      //   <ButtonText
      //     color={Colors.white}>
      //     {loading ?
      //       (
      //         <ActivityIndicator
      //           size={'small'}
      //           color={'white'}
      //         />
      //       ) : (
      //         <ButtonText
      //           style={{
      //             color: 'white',
      //           }}>
      //           {returnLabelOkButton()}
      //         </ButtonText>
      //       )
      //     }
      //   </ButtonText>
      // }
      />

    </Screen>

  );
};



export default BuyEntrancesScreen;
