import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECompanyType,
} from '@noitada/shared/enums';

import {
  IconCheckAll,
  IconTypeBar,
  IconTypeCarnivalProducer,
  IconTypeCoffeeShop,
  IconTypeEventProducer,
  IconTypeHamburguer,
  IconTypeNightClub,
  IconTypePartyProducer,
  IconTypePizzeria,
  IconTypePub,
  IconTypeRestaurant,
  IconTypeSteakhouse,
} from '../components/Icons';



export function returnIconCompanyType(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  switch (value) {
    case 'ALL':
      return (
        <IconCheckAll
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.BAR:
      return (
        <IconTypeBar
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.PUB:
      return (
        <IconTypePub
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.NIGHT_CLUB:
      return (
        <IconTypeNightClub
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.RESTAURANT:
      return (
        <IconTypeRestaurant
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.PIZZERIA:
      return (
        <IconTypePizzeria
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.STEAKHOUSE:
      return (
        <IconTypeSteakhouse
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.HAMBURGUER:
      return (
        <IconTypeHamburguer
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.COFFEE_SHOP:
      return (
        <IconTypeCoffeeShop
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.EVENT_PRODUCER:
      return (
        <IconTypeEventProducer
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.CARNIVAL_PRODUCER:
      return (
        <IconTypeCarnivalProducer
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyType.PARTY_PRODUCER:
      return (
        <IconTypePartyProducer
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      break;
  }
};
