import React from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import BadgeNotConfirmed from '../../../Badges/BadgeNotConfirmed';

import {
  CarnivalDistrictResponsive,
  CarnivalDistrictContainer,
  CarnivalDistrictContentItems,
  CarnivalDistrictHeader,
  CarnivalDistrictInfo,
  CarnivalDistrictPlace,
  CarnivalDistrictMarker,
  CarnivalDistrictTitle,
  CarnivalDistrictHour,
  CarnivalDistrictText,
  CarnivalDistrictBackgroundGradient,
  CarnivalDistrictPerson,
} from './styled';



export interface IProps {
  confirmed?: boolean;
  hour?: string;

  blocName?: string;
  singerName?: string;
  singerImage?: string;
  blocImage?: string;
}



const CardCarnivalDistrict: React.FC<IProps> = (props: IProps) => {
  const ImageSinger = props.singerImage && props.singerImage !== ''
    ? props.singerImage
    : Images.logoNoitadaCarnivalIcon;

  const confirmedItem = !props.confirmed;



  function generateBackgroundColor() {
    const colors = [
      Colors.carnivalBlue,
      Colors.carnivalGreen,
      Colors.carnivalPurple,
      Colors.carnivalRed,
      Colors.carnivalYellow,
    ];
    return colors[Math.floor(Math.random() * colors.length) + 0];
  }



  return (

    <CarnivalDistrictResponsive>
      <CarnivalDistrictContainer
        backgroundColor={generateBackgroundColor()}
        borderRadius={Sizes.cardCarnivalRadius}>


        {confirmedItem && (
          <BadgeNotConfirmed />
        )}


        <CarnivalDistrictBackgroundGradient
          colors={['black', 'transparent']}
        // start={{ x: 0, y: 1 }}
        // end={{ x: 1, y: 1 }}
        // locations={[0.1, 1]}
        >

          <CarnivalDistrictContentItems>

            <CarnivalDistrictHeader>

              <CarnivalDistrictInfo>
                <CarnivalDistrictHour>
                  {props.hour || '-'}
                </CarnivalDistrictHour>

                <CarnivalDistrictTitle
                // adjustsFontSizeToFit
                >
                  {props.singerName ? props.singerName.toUpperCase() : '-'}
                </CarnivalDistrictTitle>
              </CarnivalDistrictInfo>


              <CarnivalDistrictPlace>
                {(props.blocImage || props.blocName) && (
                  <CarnivalDistrictMarker
                    alt={'marker'}
                    src={!props.blocImage && props.blocName
                      ? Images.markerNStreet
                      : props.blocImage
                    }
                  />
                )}

                {props.blocName && props.blocName !== '' && (
                  <CarnivalDistrictText>
                    {props.blocName || '-'}
                  </CarnivalDistrictText>
                )}
              </CarnivalDistrictPlace>

            </CarnivalDistrictHeader>


            <CarnivalDistrictPerson
              alt={'attraction'}
              src={ImageSinger}
            />
          </CarnivalDistrictContentItems>

        </CarnivalDistrictBackgroundGradient>

      </CarnivalDistrictContainer>
    </CarnivalDistrictResponsive>

  );
};



export default CardCarnivalDistrict;
