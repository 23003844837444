import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  USER_LANGUAGE_MODAL,
} from '@noitada/redux-config/reducers/localities.store';

import {
  languagesOption,
} from '@noitada/shared/arrays/languages.array';

import {
  _setLevelText,
  _setLevelColor,
} from '@noitada/shared/config/user-levels.config';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  ETokenAsync,
} from '@noitada/shared/enums';

import Vars from '@noitada/shared/environments/variables';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import * as PackageInfo from '../../../../package.json';

import {
  Container,
  Content,
  Epigraph,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconAddress,
  IconCreditCard,
  IconLanguages,
  IconNoitada,
  IconPerson,
  IconTermUse,
  IconVerified,
} from '../../../components/Icons';

import ItemsList from '../../../components/Items/ItemsList';

import {
  openSite,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  ContentPadderHorizontal,
  DetailsSectionView,
} from '../../../styles/styled.layout';

import {
  TouchableEditUser,
  SettingsUserView,
  UserImage,
  BadgeSettingUser,
  UserVerifiedContent,
  TextUser,
  IconVerifiedStyled,
  TextName,
  TextEditProfile,
  FooterView,
  FooterText,
} from './styled';



const PreferencesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const languageSelected = useSelector((state: RootState) => state.localities.language);
  const languageFiltered = languagesOption?.find((item) => item?.languageIso === languageSelected);


  const token = localStorage.getItem(ETokenAsync.USER_TOKEN);


  const projectPackage = PackageInfo;
  const infoVersion = projectPackage.version;



  function openWebScreen(title: string, link: string, action?: string | null) {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title,
        link,
        action,
      },
    });
  }


  function openEditProfile() {
    if (availableUser) {
      openWebScreen(
        translate(TranslateConfig.PROFILE_DETAILS),
        Vars().globalAccountProfile,
      );
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  function openExternalSite(link: string) {
    const commonParams = `?token=${token}`;
    openSite(`${link}${commonParams}`);
  }


  function renderItemList(
    icon: any,
    title: string,
    subtitle: string | null,
    onPress: any,
    disabled = false,
  ) {
    return (

      <ItemsList
        disabled={disabled}
        onPress={onPress}
        iconLeft={icon}
        title={title}
        titleColor={Colors.white}
        subtitle={subtitle}
        subtitleColor={Colors.textApp}
      />

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {`${translate(TranslateConfig.SETTINGS)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>
      </Helmet>



      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.SETTINGS)}
            </Title>
          }
        />



        <Content>
          <ContentPadderHorizontal>

            <DetailsSectionView>
              <TouchableEditUser
                style={{
                  opacity: availableUser ? 0.95 : Sizes.disabledOpacity,
                }}
                onClick={() => {
                  openEditProfile();
                }}>

                <SettingsUserView>
                  <UserImage
                    style={{
                      opacity: availableUser ? 1 : 0.75,
                    }}
                    src={availableUser
                      ? user.picture === '' || user.picture === null || user.picture === undefined
                        ? Images.placeholderAvatarUser
                        : user.picture
                      : Images.logoIconAppWhite
                    }
                  />

                  {availableUser && (
                    <BadgeSettingUser
                      style={{
                        backgroundColor: _setLevelColor(user.userInfo?.level),
                      }}>
                      {_setLevelText(user.userInfo?.level)}
                    </BadgeSettingUser>
                  )}


                  {availableUser && (
                    <UserVerifiedContent>
                      <TextUser>
                        @{user?.username}
                      </TextUser>


                      {user.userInfo?.verified && (
                        <IconVerifiedStyled>
                          <IconVerified
                            color={Colors.accent}
                            size={14}
                          />
                        </IconVerifiedStyled>
                      )}
                    </UserVerifiedContent>
                  )}


                  <TextName>
                    {availableUser
                      ? user?.name
                      : translate(TranslateConfig.HELP_LOGIN_TO_SEE)
                    }
                  </TextName>


                  {availableUser && (
                    <TextEditProfile>
                      {translate(TranslateConfig.ACTION_TO_SEE_PROFILE)}
                    </TextEditProfile>
                  )}
                </SettingsUserView>

              </TouchableEditUser>
            </DetailsSectionView>



            {availableUser && (
              <DetailsSectionView>

                <Epigraph.Section
                  text={translate(TranslateConfig.MY_ACCOUNT)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />


                {renderItemList(
                  <IconPerson
                    size={Sizes.iconAndroidSize}
                    color={Colors.white}
                  />,
                  translate(TranslateConfig.ACCOUNT_SEE),
                  null,
                  () => {
                    openExternalSite(Vars().globalAccountUser);
                  },
                )}


                {renderItemList(
                  <IconAddress
                    size={Sizes.iconAndroidSize}
                    color={Colors.white}
                  />,
                  translate(TranslateConfig.ADDRESSES),
                  translate(TranslateConfig.ACTION_ADDRESS_SEE_YOURS),
                  () => {
                    openWebScreen(
                      translate(TranslateConfig.ADDRESSES),
                      Vars().globalAccountAddress,
                      translate(TranslateConfig.ACTION_ADDRESS_SEE),
                    );
                  },
                )}

              </DetailsSectionView>
            )}



            {availableUser && (
              <DetailsSectionView>

                <Epigraph.Section
                  text={translate(TranslateConfig.ACCOUNT_ACTIONS)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />


                {renderItemList(
                  <IconCreditCard
                    size={Sizes.iconAndroidSize}
                    color={Colors.white}
                  />,
                  translate(TranslateConfig.PAYMENTS_FORMS),
                  null,
                  () => {
                    openWebScreen(
                      translate(TranslateConfig.PAYMENTS_FORMS),
                      Vars().globalAccountCreditCard,
                      translate(TranslateConfig.ACTION_PAY_FORMS_SEE),
                    );
                  },
                )}


                {/* TODO: Fazer os descontos
                {renderItemList(
                  <Icon
                    type={'material-community'}
                    name={'ticket-percent'}
                    size={Platform.select({ android: Sizes.iconAndroidSize, ios: Sizes.iconIosSize })}
                    color="white"
                  />,
                  'Cupons de Descontos',
                  null,
                  () => {
                    navigation.dispatch(StackActions.push('CouponsManagement'));
                  },
                )}
                */}

              </DetailsSectionView>
            )}



            <DetailsSectionView>

              <Epigraph.Section
                text={translate(TranslateConfig.PREFERENCES)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              {renderItemList(
                <IconLanguages
                  size={Sizes.iconAndroidSize}
                  color={Colors.white}
                />,
                translate(TranslateConfig.LANGUAGE),
                translate(languageFiltered?.label || '') || '-', // FIXME: Integrar com a conta global
                () => {
                  dispatch({ type: USER_LANGUAGE_MODAL, payload: true });
                },
              )}

            </DetailsSectionView>



            <DetailsSectionView>

              <Epigraph.Section
                text={translate(TranslateConfig.OTHER_INFORMATIONS)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              {renderItemList(
                <IconNoitada
                  size={Sizes.iconAndroidSize}
                  color={Colors.white}
                />,
                translate(TranslateConfig.ABOUT_APP),
                null,
                () => {
                  history.push(NameRoutes.AboutScreen);
                },
              )}


              {/* TODO
              {availableUser && renderItemList(
                <Icon
                  type={Platform.select({ android: 'ionicon', ios: 'material-community' })}
                  name={Platform.select({ android: 'md-chatbox-ellipses', ios: 'chat-processing' })}
                  size={Platform.select({ android: Sizes.iconAndroidSize, ios: Sizes.iconIosSize })}
                  color="white"
                />,
                'Sugerir Local',
                null,
                () => {
                  navigation.dispatch(StackActions.push('SuggestLocation'));
                },
              )}
              */}


              {renderItemList(
                <IconTermUse
                  size={Sizes.iconAndroidSize}
                  color={Colors.white}
                />,
                translate(TranslateConfig.TERMS_POLICY),
                null,
                () => {
                  history.push(NameRoutes.TermsPolicyScreen);
                },
              )}

            </DetailsSectionView>

          </ContentPadderHorizontal>



          <FooterView>
            <FooterText>
              {translate(TranslateConfig.VERSION)}: {infoVersion}
            </FooterText>
          </FooterView>

        </Content>

      </Container>

    </Screen>

  );
};



export default PreferencesScreen;
