export enum EDrinks {
  WATER = 'WATER',
  SODA = 'SODA',
  VODKA = 'VODKA',
  BEER = 'BEER',
  WINE = 'WINE',
  TEA = 'TEA',
  COFFEE = 'COFFEE',
  JUICE = 'JUICE',
  WHISKY = 'WHISKY',
  LIQUOR = 'LIQUOR',
  COCKTAIL = 'COCKTAIL',
  CHAMPAGNE = 'CHAMPAGNE',
  GIN = 'GIN',
  RUM = 'RUM',
  ENERGY_DRINK = 'ENERGY_DRINK',
  SMOOTHIE = 'SMOOTHIE',
  YERBA_MATE = 'YERBA_MATE',
  SAKE = 'SAKE',
  TEQUILA = 'TEQUILA',
}



export default EDrinks;
