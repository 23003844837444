import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  Mask,
} from '../../../../../components/Composh/plugins';

import {
  IconPhone,
  IconPhoneCell,
  IconSocialWhatsApp,
} from '../../../../../components/Icons';

import {
  openCompanyWhatsApp,
  openPhone,
} from '../../../../../config/linking.config';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../styles/styled.layout';

import {
  CompanyContactContainer,
  CompanyContactIconLeft,
  CompanyContactInfoView,
  CompanyContactInfoTitle,
  CompanyContactInfoText,
  CompanyContactIconRight,
} from './styled';



export interface IProps {
  companyContact: any;
}



const SectionPhones: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const companyContact = props.companyContact;



  function renderItemList(
    icon: any,
    isWhatsApp: boolean,
    ddi: string,
    number: string,
    subTitle: string | null,
  ) {
    const normalizeNumber = ddi + number;

    return (

      <CompanyContactContainer>

        <CompanyContactIconLeft>
          {icon}
        </CompanyContactIconLeft>



        <CompanyContactInfoView
          onClick={() => {
            openPhone(number);
          }}>

          <CompanyContactInfoTitle>
            {Mask.toMask('phone', number, {
              maskType: 'BRL',
              withDDD: true,
              dddMask: '(99) ',
            })}
          </CompanyContactInfoTitle>


          <CompanyContactInfoText>
            {subTitle}
          </CompanyContactInfoText>

        </CompanyContactInfoView>



        {isWhatsApp && (
          <CompanyContactIconRight
            onClick={() => {
              openCompanyWhatsApp(normalizeNumber);
            }}>
            <IconSocialWhatsApp
              color={Colors.white}
              size={24}
            />
          </CompanyContactIconRight>
        )}

      </CompanyContactContainer>

    );
  }



  return (

    <DetailsSectionView>

      <SubtitleDetails>
        {translate(TranslateConfig.PARTNER_PHONES)}
      </SubtitleDetails>



      {validateString(companyContact?.number_primary) &&
        renderItemList(
          companyContact?.length === 11
            ? (
              <IconPhoneCell
                size={24}
                color={Colors.accent}
              />
            )
            : (
              <IconPhone
                size={24}
                color={Colors.accent}
              />
            ),
          companyContact?.number_primary_whatsapp,
          companyContact?.ddi_primary,
          companyContact?.number_primary,
          translate(TranslateConfig.NUMBER_PRIMARY),
        )
      }



      {validateString(companyContact?.number_secondary) &&
        renderItemList(
          companyContact?.length === 11
            ? (
              <IconPhoneCell
                size={24}
                color={Colors.accent}
              />
            )
            : (
              <IconPhone
                size={24}
                color={Colors.accent}
              />
            ),
          companyContact?.number_secondary_whatsapp,
          companyContact?.ddi_secondary,
          companyContact?.number_secondary,
          translate(TranslateConfig.NUMBER_SECONDARY),
        )
      }

    </DetailsSectionView>

  );
};



export default SectionPhones;
