import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadderHorizontal,
} from '../../../../styles/styled.layout';



export interface IProps {
  width?: number;
  maxWidth?: number;
  height?: number;
}



export const CheckinModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.width && `width: ${props.width}px`};
  ${(props: IProps) => props.width && `max-width: ${props.maxWidth}px`};
  ${(props: IProps) => props.height && `height: ${props.height}px`};
`;


export const CheckinPadderHorizontal = styled(ContentPadderHorizontal)`
  flex: 1;
  padding-top: 5px;
  padding-bottom: 10px;
`;


export const CheckinModalText = styled.p`
  margin-bottom: 15px;
  color: ${Colors.textApp};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;
