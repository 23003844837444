import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadder,
} from '../../../../../styles/styled.layout';



export interface IProps {
  width?: number;
  maxWidth?: number;
  height?: number;
}



export const EventListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.width && `width: ${props.width}px`};
  ${(props: IProps) => props.width && `max-width: ${props.maxWidth}px`};
  ${(props: IProps) => props.height && `height: ${props.height}px`};
`;



export const EventListInfoView = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const EventListInfoImage = styled.img`
  margin-top: 20px;
  margin-bottom: 30px;
  width: 200px;
  height: 200px;
`;

export const EventListTitle = styled.h3`
  font-size: 16px;
  color: ${Colors.white};
  text-align: center;
`;

export const EventListFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`;
