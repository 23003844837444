import NoitadaWS from '../index';



export const AddPersonReserve = async (data) => {
  const url = '/users/noitada/findandcreateuser';
  const response = await NoitadaWS.post(url, data);

  return response.data;
};



export const FindUser = async (username) => {
  const url = `/users/noitada/findusername/${username}`;
  const response = await NoitadaWS.get(url);

  return response.data;
};


export const GetUserCreditCard = async () => {
  const url = '/users/credit-card';
  const response = await NoitadaWS.get(url);

  return response.data;
};
