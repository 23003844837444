import {
  EProTypes,
} from '../enums';



export function SetProInstallment(proReceived?: EProTypes) {
  const itemPro = proReceived;

  if (!itemPro) {
    return 3;
  }
  switch (itemPro) {
    case EProTypes.NO_PRO:
      return 3;

    case EProTypes.LIGHT:
      return 8;

    case EProTypes.MEDIUM:
      return 12;

    case EProTypes.SUPER:
      return 8;

    case EProTypes.PERSONALITY:
      return 8;

    default:
      return 3;
  }
};
