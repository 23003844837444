import {
  Images,
} from '../constants';

import {
  ECarnivalCircuit,
} from '../enums/Carnival/carnival-circuit.enum';

import {
  ECarnivalLocation,
} from '../enums/Carnival/carnival-location.enum';

import {
  ECarnivalPeriod,
} from '../enums/Carnival/carnival-period.enum';

import {
  TranslateConfig,
} from '../translations';



export function FormatCarnivalPeriodType(type: ECarnivalPeriod): string {
  if (!type) {
    return '-';
  }

  if (type === ECarnivalPeriod.OFFICIAL) {
    return TranslateConfig.CARNIVAL_OFFICIAL;
  }

  if (type === ECarnivalPeriod.MICARETA) {
    return TranslateConfig.CARNIVAL_MICARETA;
  }

  return '-';
};



export function FormatCarnivalCircuitType(type: ECarnivalCircuit): string {
  if (!type) {
    return '-';
  }

  if (type === ECarnivalCircuit.CIRCUIT) {
    return TranslateConfig.CIRCUIT_OFFICIAL;
  }

  if (type === ECarnivalCircuit.DISTRICT) {
    return TranslateConfig.DISTRICT;
  }

  return '-';
};



export function FormatCarnivalMarkerType(type: ECarnivalLocation): string {
  if (type === ECarnivalLocation.CIRCUIT) {
    return TranslateConfig.CIRCUIT;
  }

  if (type === ECarnivalLocation.BLOC) {
    return TranslateConfig.BLOC;
  }

  if (type === ECarnivalLocation.CABIN) {
    return TranslateConfig.CABIN;
  }

  if (type === ECarnivalLocation.POLICE) {
    return TranslateConfig.POLICE;
  }

  if (type === ECarnivalLocation.BUS) {
    return TranslateConfig.BUS;
  }

  if (type === ECarnivalLocation.TAXI) {
    return TranslateConfig.TAXI;
  }

  if (type === ECarnivalLocation.BATHROOM) {
    return TranslateConfig.BATHROOM;
  }

  if (type === ECarnivalLocation.FIREMAN) {
    return TranslateConfig.FIREMAN;
  }

  if (type === ECarnivalLocation.SAVE_LIFE) {
    return TranslateConfig.SAVE_LIFE;
  }

  if (type === ECarnivalLocation.MEDICAL_POINT) {
    return TranslateConfig.MEDICAL_POINT;
  }

  if (type === ECarnivalLocation.JUVENILE) {
    return TranslateConfig.JUVENILE;
  }

  if (type === ECarnivalLocation.OTHER_ATTRACTION) {
    return TranslateConfig.OTHER_ATTRACTION;
  }

  if (type === ECarnivalLocation.NOITADA) {
    return TranslateConfig.NAME_APP;
  }

  if (type === ECarnivalLocation.AGENCY_FLOW) {
    return TranslateConfig.AGENCY_FLOW;
  }

  if (type === ECarnivalLocation.AGENCY_PUBLIC) {
    return TranslateConfig.AGENCY_PUBLIC;
  }

  if (type === ECarnivalLocation.CLEAN_HEALTH) {
    return TranslateConfig.CLEAN_HEALTH;
  }

  if (type === ECarnivalLocation.AREA_RESERVED) {
    return TranslateConfig.AREA_RESERVED;
  }

  return '-';
};



export function SwitchMarkerTypeImage(type: string | null | undefined, pinName?: string | null | undefined): string {
  if (pinName === 'pinBecoDasCores') {
    return Images.pinBecoDasCores;
  }

  if (pinName === 'pinParadaFantasias') {
    return Images.pinFantasyParades;
  }

  if (pinName === 'pinPortoes') {
    return Images.pinParadeGate;
  }

  if (pinName === 'pinOutrasColetas') {
    return Images.pinSelectiveOther;
  }

  if (pinName === 'pinColetaSeletiva') {
    return Images.pinSelectiveCollect;
  }

  if (pinName === 'pinPoliceCivil') {
    return Images.pinPoliceCivil;
  }

  if (pinName === 'pinPoliceMilitar') {
    return Images.pinPoliceMilitar;
  }

  if (pinName === 'pinVigilanciaSanitaria') {
    return Images.pinHealthSurveillance;
  }

  // if (pinName === 'pinJuizadoMenores') {
  //   return Images.pinJuizadoMenores;
  // }

  if (type === ECarnivalLocation.POLICE) {
    return Images.pinPoliceMilitar;
  }

  if (type === ECarnivalLocation.OTHER_ATTRACTION) {
    return Images.markerNEvent;
  }

  if (type === ECarnivalLocation.MEDICAL_POINT) {
    return Images.pinMedical;
  }

  if (type === ECarnivalLocation.BUS) {
    return Images.pinBus;
  }

  if (type === ECarnivalLocation.TAXI) {
    return Images.pinTaxi;
  }

  if (type === ECarnivalLocation.BATHROOM) {
    return Images.pinBathroom;
  }

  if (type === ECarnivalLocation.FIREMAN) {
    return Images.pinFire;
  }

  if (type === ECarnivalLocation.JUVENILE) {
    return Images.pinChild;
  }

  if (type === ECarnivalLocation.SAVE_LIFE) {
    return Images.pinLifeguard;
  }

  if (type === ECarnivalLocation.NOITADA) {
    return Images.logoIconAppBlack;
  }

  if (type === ECarnivalLocation.AGENCY_FLOW) {
    return Images.pinTransitAgent;
  }

  if (type === ECarnivalLocation.AGENCY_PUBLIC) {
    return Images.pinPublicAgency;
  }

  if (type === ECarnivalLocation.CLEAN_HEALTH) {
    return Images.pinCleanHealth;
  }

  if (type === ECarnivalLocation.AREA_RESERVED) {
    return Images.pinReservedArea;
  }

  return Images.markerNCompany;
}
