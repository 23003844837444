import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  GetCompany,
} from '@noitada/axios-config/endpoints/companies.endpoints';

import {
  PostFavoriteCompany,
} from '@noitada/axios-config/endpoints/favorites.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  FAVORITES_SET,
} from '@noitada/redux-config/reducers/favorites.store';

import {
  companyTypesKitchenArray,
} from '@noitada/shared/arrays/company-kitchen.array';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  formatNumber,
  validateString,
} from '@noitada/shared/utils/string.utils';

import DetailsAddress from '../../../components/Address';

import {
  Container,
  Content,
  Screen,
  Title,
  ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ButtonBack,
  ButtonFavorite,
  ButtonLanguage,
  ButtonShare,
} from '../../../components/Controls';

import DetailsModules from '../../../components/Details/DetailsModules';
import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import ShareModal from '../../../modals/Share';

import NameRoutes from '../../../navigation/names';

import {
  ContentPadder,
} from '../../../styles/styled.layout';

import ManageCompanyModal from './Modals';

import SectionAboutCompany from './Sections/SectionAboutCompany';
import SectionGallery from './Sections/SectionGallery';
import SectionInformations from './Sections/SectionInformations';
import SectionKitchen from './Sections/SectionKitchen';
import SectionOpenHours from './Sections/SectionOpenHours';
import SectionPaymentsOn from './Sections/SectionPaymentsOn';
import SectionPhones from './Sections/SectionPhones';
import SectionRules from './Sections/SectionRules';
import SectionServices from './Sections/SectionServices';
import SectionSocial from './Sections/SectionSocial';

import {
  FavoriteOpacityView,
  ServicesPlace,
  SeeCountCompanyText,
} from './styled';



export interface IRouteParams {
  showOptions: boolean;
}



const DetailsCompanyScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const {
    company_username,
  } = useParams() as any;

  const routeLocation = useLocation<IRouteParams>();
  const params = routeLocation?.state;

  const options = params?.showOptions === false ? false : true;


  const company = useSelector((state: RootState) => state.company?.selected);
  const hasUserManager = company?.managerUser;

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const favoritesUser: Array<any> = useSelector((state: RootState) => state.favorites.actualFavorites);
  const verifyItem = favoritesUser?.length > 0 ? favoritesUser?.some((valueItem) => valueItem?.relationId === company?.id) : false;


  const [showManageModal, setShowManageModal] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getCompanyByParams() {
    try {
      setLoading(true);

      const response = await GetCompany(company_username);
      if (response) {
        dispatch({ type: USER_SET_CURRENT_COMPANY, payload: response });
      }
      else {
        dispatch({ type: USER_SET_CURRENT_COMPANY, payload: null });
      }
    }
    catch (error: any) {
      console.error(error);
      dispatch({ type: USER_SET_CURRENT_COMPANY, payload: null });

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.EMPTY_COMPANY_NOT_FOUND)}
      />

    );
  }


  async function onFavoritePress() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const payload = {
        id: company?.id,
      };

      const data = await PostFavoriteCompany(availableUser, payload);

      if (data && !data?.hasError) {
        dispatch({ type: FAVORITES_SET, payload: data || [] });

        showSnackBarProps(Colors.accept,
          verifyItem
            ? translate(TranslateConfig.SUCCESS_FAVORITE_REMOVE)
            : translate(TranslateConfig.SUCCESS_FAVORITE_ADD),
        );
      }
      else {
        showSnackBarProps(Colors.accept,
          verifyItem
            ? translate(TranslateConfig.ERROR_FAVORITE_REMOVE)
            : translate(TranslateConfig.ERROR_FAVORITE_ADD),
        );
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderFavoriteButton() {
    if (!availableUser) {
      return (

        <FavoriteOpacityView>
          <ButtonFavorite
            color={Colors.white}
            onPress={() => {
              history.push(NameRoutes.AccessScreen);
            }}
          />
        </FavoriteOpacityView>

      );
    }


    return (

      <ButtonFavorite
        active={verifyItem}
        color={verifyItem ? Colors.secondary : Colors.white}
        onPress={() => {
          onFavoritePress();
        }}
      />

    );
  }


  function setKeywordsScreen(): string {
    let defaultKeywords: Array<string> = [];

    const ketchensKeys = company?.company_about?.kitchenType;
    if (ketchensKeys) {
      const splitItem: Array<string> = ketchensKeys.split(', ');
      const filteredItem = splitItem.map((value) => {
        const item = companyTypesKitchenArray.find((item) => item.value === value);
        return translate(item?.label || '');
      });

      defaultKeywords = [
        ...filteredItem,
      ];
    }

    return defaultKeywords.join(', ');
  }



  useEffect(() => {
    dispatch({ type: USER_SET_CURRENT_COMPANY, payload: null });
    getCompanyByParams();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {company?.name
            ? `${company?.name} | ${translate(TranslateConfig.NAME_APP)}`
            : translate(TranslateConfig.NAME_APP)
          }
        </title>
        <meta name='description' content={company?.slogan} />
        <meta name='keywords' content={setKeywordsScreen()} />
        <link rel="canonical" href={company?.name
          ? `${company?.name} | ${translate(TranslateConfig.NAME_APP)}`
          : translate(TranslateConfig.NAME_APP)
        } />
      </Helmet>



      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.COMPANY_DETAILS)}
            </Title>
          }
          rightIcon={
            <ToolbarGroup>
              {renderFavoriteButton()}

              <ButtonLanguage
                color={Colors.white}
              />

              <ButtonShare
                color={Colors.white}
                onPress={() => {
                  setShowShare(true);
                }}
              />
            </ToolbarGroup>
          }
        />


        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {!loading && !company && (
          listEmptyComponent()
        )}



        {!loading && company && (
          <Content>
            <ContentPadder>

              <SectionInformations
                hasManager={hasUserManager}
                name={company?.name}
                idShort={company?.idShort}
                picture={company?.picture}
                slogan={company?.slogan}
                params={company?.company_params}
                updatedAt={company?.updated_at}
                onManagerPress={() => {
                  setShowManageModal(true);
                }}
              />



              {options && (
                <ServicesPlace>
                  <DetailsModules
                    showBackground={true}
                    type={company?.type}

                    menuDisable={company?.modules?.menuSheet}
                    menuPress={() => {
                      history.push({
                        pathname: NameRoutes.MenuSheetScreen.replace(
                          ':company_username',
                          company_username,
                        ),
                      });
                    }}

                    eventDisable={company?.modules?.events}
                    eventPress={() => {
                      history.push({
                        pathname: NameRoutes.AllCompanyEventsScreen.replace(
                          ':company_username',
                          company_username,
                        ),
                      });
                    }}

                    reserveDisable={company?.modules?.reserves}
                    reservePress={() => {
                      history.push({
                        pathname: NameRoutes.CompanyReservesScreen.replace(
                          ':company_username',
                          company_username,
                        ),
                      });
                    }}
                  />
                </ServicesPlace>
              )}



              <SectionSocial
                username={company?.company_username}
                facebookUsername={company?.company_contact?.facebook}
                facebookId={company?.company_contact?.facebook_id}
                instagram={company?.company_contact?.instagram}
                type={company?.type}
              />

            </ContentPadder>



            <DetailsAddress
              companyName={company?.name}
              address={company?.address}
              noWaze={!options}
              noUber={!options}
            />



            <ContentPadder>

              {validateString(company?.company_about?.about) && (
                <SectionAboutCompany
                  about={company?.company_about?.about}
                />
              )}



              {company?.company_operation && (
                <SectionOpenHours
                  operation={company?.company_operation}
                />
              )}



              {company?.company_about?.services && (
                <SectionServices
                  services={company?.company_about?.services}
                />
              )}



              {company?.company_gallery?.length > 0 && (
                <SectionGallery
                  companyUsername={company?.company_username}
                  gallery={company?.company_gallery}
                />
              )}



              <SectionPhones
                companyContact={company?.company_contact}
              />



              {company?.company_about?.kitchenType && (
                <SectionKitchen
                  kitchens={company?.company_about?.kitchenType}
                />
              )}



              {company?.company_payment_type && (
                <SectionPaymentsOn
                  paymentType={company?.company_payment_type}
                />
              )}



              {company?.company_rules?.length !== 0 && (
                <SectionRules
                  rules={company?.company_rules}
                />
              )}



              {company?.views !== 0 && (
                <SeeCountCompanyText>
                  {translate(TranslateConfig.PAGE_VIEWS)}: {formatNumber(company?.views)}
                </SeeCountCompanyText>
              )}

            </ContentPadder>
          </Content>
        )}

      </Container>



      <ManageCompanyModal
        visible={showManageModal}
        hasManager={hasUserManager}
        idShort={company?.idShort}
        name={company?.name}
        onCancelPress={() => {
          setShowManageModal(false);
        }}
      />



      <ShareModal
        type={'COMPANY'}
        visible={showShare}
        idEvent={company?.company_username}
        name={company?.name}
        image={company?.picture}
        onClose={() => {
          setShowShare(false);
        }}
      />

    </Screen>

  );
};



export default DetailsCompanyScreen;
