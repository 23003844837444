import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  companyCompleteTypeArray,
} from '@noitada/shared/arrays/company-type.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  IconSocialFacebook,
  IconSocialInstagram,
} from '../../../../../components/Icons';

import {
  returnIconCompanyType,
} from '../../../../../config/icon-types-company.config';

import {
  openSite,
} from '../../../../../config/linking.config';

import {
  SocialButton,
} from '../../../../../styles/styled.layout';

import {
  AboutSocialContainer,
  AboutSocialPlace,
  AboutPlaceUsername,
  AboutPlaceInfoTitle,
  AboutPlaceInfoText,
  AboutSocialType,
  AboutCompanyTypeView,
  AboutPlaceCompanyText,
} from './styled';



export interface IProps {
  username: string;
  facebookUsername: string;
  facebookId: string;
  instagram: string;
  type: string;
}



const SectionSocial: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const typeCompany = props.type;
  const labelTypeCompany = companyCompleteTypeArray.find((item) => item.value === props.type);
  const labelWordType = labelTypeCompany?.word || '';


  return (

    <AboutSocialContainer>

      {/*
          <DetailsAboutInfo
            activeOpacity={0.7}>

            <ShimmerReportText
              width={130}
              height={26}
              visible={availableCompany}>
              <SubtitleDetails>
                123 Avaliações
              </SubtitleDetails>
            </ShimmerReportText>


            <StarRatingView>
              <Shimmer
                width={110}
                height={28}
                visible={availableCompany}>
                <Rating
                  disabled
                  maxStars={5}
                  rating={3}
                  fullStarColor={Colors.buttonOkAccent}
                  halfStarColor={Colors.buttonOkAccent}
                  emptyStarColor={Colors.buttonOkAccent}
                  starSize={23}
                />
              </Shimmer>

              <ShimmerRatingText
                width={32}
                height={28}
                visible={availableCompany}>
                <RatingDetailsText>
                  3.5
                </RatingDetailsText>
              </ShimmerRatingText>
            </StarRatingView>
          </DetailsAboutInfo>
          */}



      <AboutSocialPlace>

        {validateString(props.username) && (
          <AboutPlaceUsername>
            <AboutPlaceInfoTitle>
              {translate(TranslateConfig.USERNAME)}
            </AboutPlaceInfoTitle>

            <AboutPlaceInfoText>
              @{props.username}
            </AboutPlaceInfoText>
          </AboutPlaceUsername>
        )}


        {validateString(props.facebookUsername) && validateString(props.facebookId) && (
          <SocialButton
            onClick={() => {
              openSite(`http://facebook.com/${props.facebookUsername}`);
            }}>
            <IconSocialFacebook
              size={25}
              color={Colors.accent}
            />
          </SocialButton>
        )}


        {validateString(props.instagram) && (
          <SocialButton
            onClick={() => {
              openSite(`http://instagram.com/_u/${props.instagram}`);
            }}>
            <IconSocialInstagram
              size={25}
              color={Colors.accent}
            />
          </SocialButton>
        )}

      </AboutSocialPlace>



      <AboutSocialType>

        <AboutPlaceUsername>
          <AboutPlaceInfoTitle>
            {translate(TranslateConfig.PARTNER_TYPE)}
          </AboutPlaceInfoTitle>

          <AboutCompanyTypeView>
            {returnIconCompanyType(typeCompany,
              Colors.white,
              18,
            )}

            <AboutPlaceCompanyText>
              {translate(labelWordType)}
            </AboutPlaceCompanyText>
          </AboutCompanyTypeView>

        </AboutPlaceUsername>

      </AboutSocialType>

    </AboutSocialContainer>

  );
};



export default SectionSocial;
