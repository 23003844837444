import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Container,
  Screen,
} from '../../components/Composh/web';

import HeaderScreen from '../../components/Headers/HeaderScreen';

import NameRoutes from '../../navigation/names';

import {
  TutorialSkipButton,
  TutorialSkipTextButton,
  TutorialBottomToolbar,
  TutorialBottomLeft,
  TutorialBottomTextLeft,
  TutorialBottomCenter,
  TutorialBottomDots,
  TutorialBottomRight,
  TutorialBottomTextRight,
} from './styled';

import TutorialTabContainer from './Tabs';



const TutorialScreen: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();



  const renderDots = (selected: boolean, index: number) => {
    return (

      <TutorialBottomDots
        key={index}
        selected={selected}
      />

    );
  };


  function onSkipPress() {
    if (true) {
      history.replace(NameRoutes.GridEventsScreen);
      return;
    }

    // TODO
    // if (carnival) {
    //   history.replace(NameRoutes.CarnivalGridScreen);
    //   return;
    // }

    history.replace(NameRoutes.PermissionsScreen);
  }


  const tabsTutorial = [
    {
      image: Images.tutorial1,
      title: translate(TranslateConfig.TUTORIAL_TITLE_1),
      description: translate(TranslateConfig.TUTORIAL_TEXT_1),
      onPress: null,
    },
    {
      image: Images.tutorial2,
      title: translate(TranslateConfig.TUTORIAL_TITLE_2),
      description: translate(TranslateConfig.TUTORIAL_TEXT_2),
      onPress: null,
    },
    {
      image: Images.tutorial3,
      title: translate(TranslateConfig.TUTORIAL_TITLE_3),
      description: translate(TranslateConfig.TUTORIAL_TEXT_3),
      onPress: null,
    },
    {
      image: Images.logoIconAppWhite,
      title: translate(TranslateConfig.TUTORIAL_TITLE_4),
      description: '',
      onPress: () => {
        onSkipPress();
      },
    },
  ];



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        transparent
        rightIcon={

          <TutorialSkipButton
            onClick={() => {
              onSkipPress();
            }}>
            <TutorialSkipTextButton>
              {translate(TranslateConfig.ACTION_SKIP)}
            </TutorialSkipTextButton>

          </TutorialSkipButton>

        }
      />



      <Container>

        <TutorialTabContainer
          image={tabsTutorial[0].image}
          title={tabsTutorial[0].title}
          description={tabsTutorial[0].description}
          onPress={tabsTutorial[0].onPress}
        />

      </Container>



      <TutorialBottomToolbar>

        <TutorialBottomLeft
          onClick={() => {
            // TODO
          }}>

          <TutorialBottomTextLeft>
            {translate(TranslateConfig.ACTION_BACK)}
          </TutorialBottomTextLeft>

        </TutorialBottomLeft>



        <TutorialBottomCenter>
          {tabsTutorial.map((item: any, index: number) => (
            renderDots(true, index)
          ))}
        </TutorialBottomCenter>



        <TutorialBottomRight
          onClick={() => {
            // TODO
          }}>

          <TutorialBottomTextRight>
            {translate(TranslateConfig.ACTION_FORWARD)}
          </TutorialBottomTextRight>

        </TutorialBottomRight>

      </TutorialBottomToolbar>

    </Screen>

  );
};



export default TutorialScreen;
