import NoitadaWS from '../index';



export const GetOrderUser = async (type: string) => {
  const url = `/comanda/user?type=${type}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const UpdateStatusOrder = async (checkinId: string, companyId: string, status: string) => {
  const url = '/comanda/checkin/';

  const response = await NoitadaWS.put(url, {
    checkinId,
    companyId,
    status,
  });
  return response.data;
};



export const GetUserAwaitCheckin = async () => {
  const url = '/comanda/await-checkin';

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const GetUserCurrentCheckin = async (id?: string) => {
  const url = id
    ? `/comanda/checkin/${id}`
    : '/comanda/checkin';

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const MakeCheckin = async (companyId: string, table: string, method: string) => {
  const url = '/comanda/checkin';

  const response = await NoitadaWS.post(url, {
    companyId,
    table,
    method,
  });
  return response.data;
};



export const cancelCheckin = async () => {
  const url = '/comanda/giveup';

  const response = await NoitadaWS.post(url);
  if (response) {
    return true;
  }
  else {
    return false;
  }
};



export const MakeCheckout = async () => {
  const url = '/comanda/checkout';

  const response = await NoitadaWS.post(url);
  return response.data;
};



export const AddItem = async (payload: any) => {
  const url = '/comanda/order';

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};



export const PayInAppRequest = async (payload: any) => {
  const url = '/comanda/payment';

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};
