import styled from 'styled-components';



export const CheckInButton = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  padding-bottom: 2px;
  padding-left: 2px;
  border-radius: 50px;
`;
