import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadderHorizontal,
} from '../../../../../styles/styled.layout';



export interface IProps {
  active?: boolean;
}



export const CloseOrderContent = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CloseOrderTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 7px;
  font-size: 17px;
  color: ${Colors.primary};
`;

export const CloseOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
`;



export const CloseOrderButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;


export const ListImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  margin-right: 15px;
`;

export const ListItemImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const ListCompanyImage = styled.img`
  height: 90%;
  width: 90%;
  object-fit: cover;
  border-radius: ${Sizes.cardRadius}px;
`;


export const CloseOrderView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CloseOrderLabel = styled.p`
  color: ${(props: IProps) => props.active ? Colors.white : Colors.gray};
  font-size: 17px;
`;

export const CloseOrderSubTitle = styled.p`
  margin-top: 3px;
  color: ${Colors.gray};
  font-size: 14px;
`;
