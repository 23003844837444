import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  openSite,
} from '../../../config/linking.config';

import {
  GetDeviceType,
} from '../../../config/plataform.config';

import {
  IconMapDirection,
  IconMapPin,
} from '../../Icons';

import {
  BubbleMarkerContainer,
  BubbleInfoViewContainer,
  BubbleInfoContent,
  BubbleInfoTriangle,
  BubbleInfoTextContent,
  BubbleInfoTitle,
  BubbleInfoPinName,
  BubbleLatLngView,
  BubbleInfoLatLng,
  BubbleInfoButtons,
  BubbleInfoDirectionButton,
  BubbleCloseView,
  BubbleCloseText,
} from './styled';



export interface IProps {
  visible: boolean;
  title: string;
  subtitle?: string;
  lat?: string;
  lng?: string;
  onPress?: any;
  onDirectionPress?: any;
  children?: any;
}



const MarkerBubble: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();
  function onOpenDialogMap() {
    if (GetDeviceType() === 'MOBILE') {
      window.open(`google.navigation:q=${props?.lat},${props?.lng}&mode=d`, '_system');
    }
    else {
      const url = `http://www.google.com/maps/place/${props?.lat},${props?.lng}`;
      openSite(url);
    }
  }



  return (

    <BubbleMarkerContainer>

      {props.visible && (
        <BubbleInfoViewContainer>

          <BubbleInfoContent>

            <BubbleInfoTextContent
              onClick={() => {
                if (props.onPress) {
                  props.onPress();
                }
              }}>

              <BubbleInfoTitle>
                {props.title || '-'}
              </BubbleInfoTitle>


              <BubbleInfoPinName>
                {props.subtitle || '-'}
              </BubbleInfoPinName>


              <BubbleLatLngView>

                <IconMapPin
                  color={Colors.gray}
                  size={14}
                />

                <BubbleInfoLatLng>
                  {props.lat}, {props.lng}
                </BubbleInfoLatLng>

              </BubbleLatLngView>

            </BubbleInfoTextContent>



            <BubbleInfoButtons>

              <BubbleInfoDirectionButton
                onClick={() => {
                  if (props?.lat && props?.lng) {
                    onOpenDialogMap();
                  }
                }}>
                <IconMapDirection
                  color={Colors.black}
                  size={26}
                />
              </BubbleInfoDirectionButton>



              <BubbleInfoTriangle />

            </BubbleInfoButtons>

          </BubbleInfoContent>



          <BubbleCloseView>
            <BubbleCloseText>
              {translate(TranslateConfig.ACTION_CLICK_CLOSE)}
            </BubbleCloseText>
          </BubbleCloseView>

        </BubbleInfoViewContainer>
      )}


      {props.children}


    </BubbleMarkerContainer>

  );
};



export default MarkerBubble;
