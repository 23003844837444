import styled from 'styled-components';



export const CardTicketContainer = styled.a`
`;


export const CardTicketContainer1 = styled.div`
`;


export const CardTicketText = styled.p`
`;

export const CardTicketLot = styled.p`
`;

export const CardTicketSalesUntil = styled.p`
`;

export const CardTicketSalesPrice = styled.p`
`;


export const CardTicketSalesImage = styled.img`
`;

