import React from 'react';

import {
  ActionViewButton,
  ActionViewText,
} from './styled';



export interface IProps {
  activeOpacity?: number;
  width?: number;
  noShadow?: boolean;

  title: string;
  onPress?: any;
}



const ButtonAction: React.FC<IProps> = (props: IProps) => {
  return (

    <ActionViewButton
      // activeOpacity={props.activeOpacity}
      onClick={props.onPress}
      width={props.width}
      noShadow={props.noShadow}>

      <ActionViewText>
        {props.title}
      </ActionViewText>

    </ActionViewButton>

  );
};



export default ButtonAction;
