import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  onPress?: any;
}



export const MarkerMapPin = styled.a`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
  cursor: ${(props: IProps) => (props.onPress ? 'pointer' : 'default')};
  
  &:hover {
    z-index: 1;
  }
`;


export const MarkerMapContainer = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background-color: ${Colors.cardBackground};
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border-color: ${Colors.accent};
  border-style: solid;
  border-width: 2px;
  cursor: ${(props: IProps) => (props.onPress ? 'pointer' : 'default')};
  
  &:hover {
    z-index: 1;
  }
`;



export const MarkerMapImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
`;
