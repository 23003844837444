export enum ECompanyServices {
  WIFI = 'WIFI',
  KIDS_AREA = 'KIDS_AREA',
  PARKING = 'PARKING',
  SMOKING = 'SMOKING',
  AIR_CONDITIONER = 'AIR_CONDITIONER',
  ACCESSIBILITY = 'ACCESSIBILITY',
}



export default ECompanyServices;
