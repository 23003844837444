import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  wifiModal: false,
  checkin: null as any,
};



// Action Types

export const USER_SET_WIFI_MODAL = '@user/SET_WIFI_MODAL';

export const USER_SET_CHECKIN = '@user/SET_CHECKIN';
export const USER_RESET_CHECKIN = '@user/RESET_CHECKIN';



// Action Creators

export const setWifiModalAction = createAction<object>(USER_SET_WIFI_MODAL);

export const setCheckinAction = createAction<object>(USER_SET_CHECKIN);
export const resetCheckinAction = createAction<object>(USER_RESET_CHECKIN);



// Handlers

const modalWifiReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  wifiModal: action.payload,
});


const setCheckin = (state = INITIAL_STATE, action: any) => ({
  ...state,
  checkin: action.payload,
});


const resetCheckinStates = () => ({
  ...INITIAL_STATE,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [setWifiModalAction.type]: modalWifiReducer,

  [setCheckinAction.type]: setCheckin,
  [resetCheckinAction.type]: resetCheckinStates,
});
