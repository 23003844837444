import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconAdd,
} from '../../Icons';

import {
  AddNewButton,
  AddNewButtonText,
} from './styled';



export interface IProps {
  noPadding?: boolean;
  title?: string;
  color?: string;
  onPress?: any;
}



const ButtonAddLine: React.FC<IProps> = (props: IProps) => {
  return (

    <AddNewButton
      noPadding={!props.noPadding}
      onClick={props.onPress}>

      <IconAdd
        color={props.color || Colors.primary}
        size={20}
      />


      <AddNewButtonText
        color={props.color || Colors.primary}>
        {props.title}
      </AddNewButtonText>

    </AddNewButton>

  );
};



export default ButtonAddLine;
