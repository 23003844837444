import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  noPadding?: boolean;
  color?: string;
}



export const AddNewPaddingStyle = css`
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;
`;

export const AddNewButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props: IProps) => props.noPadding && AddNewPaddingStyle};
`;

export const AddNewButtonText = styled.p`
  margin-left: 5px;
  color: ${(props: IProps) => props.color || Colors.primary};
  font-size: 15px;
`;
