import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const WifiModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 320px;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const WifiModalImage = styled.img`
  margin-bottom: 5px;
  width: 130px;
  height: 130px;
  object-fit: contain;
`;

export const WifiModalTitle = styled.h1`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 22px;
  font-weight: 400;
  text-align: center;
`;

export const WifiModalSpanTitle = styled.span`
  font-size: 26px;
  font-weight: bold;
`;


export const WifiModalInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const WifiCompanyModalImage = styled.img`
  margin-bottom: 10px;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: ${Sizes.cardRadius}px;
`;

export const WifiCompanyModalName = styled.p`
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 17px;
`;

export const WifiCompanyModalRouteName = styled.p`
  color: ${Colors.white};
  font-size: 18px;
`;

export const WifiCompanyModalCyanText = styled.span`
  color: ${Colors.accent};
`;



export const WifiCopyButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const WifiCopyText = styled.p`
  margin-top: 3px;
  margin-left: 8px;
  color: ${Colors.white};
  font-size: 13px;
  font-style: italic;
`;
