import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconAdd,
  IconCheck,
} from '../../Icons';

import {
  CardListContainer,
  CardListInfoContent,
  CardListInfoTile,
  CardListFinalDate,
  CardListDescription,
  CardListView,
  CardListMoreText,
  CardListParticipeStatus,
  ListButtonView,
  ListIcon,
  ListStatusText,
} from './styled';



export interface IProps {
  name?: string;
  finalDate?: string;
  description?: string;
  inList?: boolean;
  onPress?: any;
}



const CardLists: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const userInList = props.inList;



  function renderStringClose(): string {
    return new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }).format(new Date(`${props.finalDate}`));
  }



  return (

    <CardListContainer
      onClick={props.onPress}>

      <CardListInfoContent>

        <CardListInfoTile>
          {props.name}
        </CardListInfoTile>


        <CardListFinalDate>
          {translate(TranslateConfig.CLOSE_IN)}: {renderStringClose()}
        </CardListFinalDate>


        {props.description && (
          <CardListDescription>
            {props.description}
          </CardListDescription>
        )}


        <CardListView>
          <CardListMoreText>
            {userInList
              ? translate(TranslateConfig.ACTION_CLICK_TO_MANAGE)
              : translate(TranslateConfig.ACTION_MORE_DETAILS)
            }

          </CardListMoreText>
        </CardListView>

      </CardListInfoContent>



      <CardListParticipeStatus>

        <ListButtonView
          style={{
            backgroundColor: userInList
              ? '#006400'
              : Colors.calendarColor,
          }}>

          <ListIcon>
            {userInList
              ? (
                <IconCheck
                  size={Sizes.iconInputSize}
                  color={Colors.white}
                />
              )
              : (
                <IconAdd
                  size={Sizes.iconInputSize}
                  color={Colors.white}
                />
              )
            }
          </ListIcon>



          <ListStatusText>
            {userInList
              ? TranslateConfig.SUBTITLES_LIST_IN
              : TranslateConfig.ACTION_LIST_PARTICIPATE
            }
          </ListStatusText>

        </ListButtonView>

      </CardListParticipeStatus>

    </CardListContainer>

  );
};



export default CardLists;
