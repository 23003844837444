import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
`;


export const ListCarnivalTitle = styled.p`
  margin-top: 17px;
  margin-left: ${Sizes.paddingContainer}px;
  margin-right: ${Sizes.paddingContainer}px;
  margin-bottom: 5px;
  font-size: 16px;
  color: ${Colors.primary};
  text-align: left;
`;
