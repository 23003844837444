import React from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../components/Composh/web';

import {
  ModalFinishedContainer,
  ModalFinishedTitle,
  ModalFinishedImage,
} from './styled';



export interface IProps {
  visible?: boolean;

  // backgroundColor?: string;

  // image?: any;

  title?: string;
  buttonText?: string;

  activeOpacity?: number;
  onPress?: any;
}



const ModalFinished: React.FC<IProps> = (props: any) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }



  return (

    <Overlay
      noPress
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}>

      <ModalFinishedContainer
        style={{
          backgroundColor: 'black',
        }}>


        <ModalFinishedTitle>
          {(props.title || '').toUpperCase()}
        </ModalFinishedTitle>



        <ModalFinishedImage
          alt={'cover'}
          src={Images.smile}
        />



        {props.buttonText && (
          <ButtonAction
            width={150}
            activeOpacity={Sizes.ButtonActiveOpacity}
            title={props.buttonText}
            onPress={props.onPress}
          />
        )}

      </ModalFinishedContainer>

    </Overlay>

  );
};



export default ModalFinished;
