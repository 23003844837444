const CompanyAbout = {
  id: '',
  about: '',
  created_at: '',
  updated_by: '',
  updated_at: '',
  services: '',
  kitchenType: '',
};



export default CompanyAbout;
