import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EEventType,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconTypeNightParty,
  IconTypeParty,
} from '../components/Icons';



export function renderTypeIconEvent(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  switch (value) {
    case EEventType.PARTY:
      return (
        <IconTypeParty
          color={colorProp}
          size={sizeProp}
        />
      );

    case EEventType.NIGHT_PARTY:
      return (
        <IconTypeNightParty
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      break;
  }
};


export function renderTypeTextEvent(type: string) {
  switch (type) {
    case EEventType.PARTY:
      return TranslateConfig.PARTY;

    case EEventType.NIGHT_PARTY:
      return TranslateConfig.NIGHT_PARTY;

    default:
      return TranslateConfig.TYPE;
  }
}
