import {
  useHistory,
} from 'react-router-dom';

import IAppRoute from '../../interfaces/app-route.interface';

import CarnivalGridScreen from '../../screens/Carmival/Grid';
import UserListsScreen from '../../screens/Entrances/Lists/UserLists';
import UserTicketsScreen from '../../screens/Entrances/Tickets/UserTickets/AllTickets';
import FavoritesScreen from '../../screens/Favorites';
import FestivalGridScreen from '../../screens/Festivals/Grid';
import HelpFaqScreen from '../../screens/Help/FAQ';
import GridEventsScreen from '../../screens/Home';
import UserOrdersScreen from '../../screens/Orders/UserOrders/AllOrders';
import UserReservesScreen from '../../screens/Reserves/UserReserves/AllReserves';
import ProfileScreen from '../../screens/User/ViewProfile';

import NameRoutes from '../names';
import TermsNoitadaPrivacityScreen from '../../screens/Privacy';



const DrawerRoutes: IAppRoute[] = [

  {
    path: NameRoutes.GridEventsScreen,
    page: GridEventsScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.CarnivalGridScreen,
    page: CarnivalGridScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.PrivacyScreen,
    page: TermsNoitadaPrivacityScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: '/carnaval',
    page: () => {
      const navigate = useHistory();
      navigate.replace(NameRoutes.CarnivalGridScreen);
      return null;
    },
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.FestivalGridScreen,
    page: FestivalGridScreen,
    isPublic: true,
    exact: true,
  },



  // {
  //   path: NameRoutes.PromotionsScreen,
  //   page: PromotionsScreen,
  //   isPublic: false,
  //   exact: true,
  // },

  {
    path: NameRoutes.FavoritesScreen,
    page: FavoritesScreen,
    isPublic: false,
    exact: true,
  },



  {
    path: NameRoutes.UserTicketsScreen,
    page: UserTicketsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.UserListsScreen,
    page: UserListsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.UserOrdersScreen,
    page: UserOrdersScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.UserReservesScreen,
    page: UserReservesScreen,
    isPublic: false,
    exact: true,
  },



  {
    path: NameRoutes.ProfileScreen,
    page: ProfileScreen,
    isPublic: false,
    exact: true,
  },


  {
    path: NameRoutes.HelpFaqScreen,
    page: HelpFaqScreen,
    isPublic: false,
    exact: true,
  },

];



export default DrawerRoutes;
