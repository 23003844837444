import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  onPress?: any;
}



export const MarkerCarnivalMapPin = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
  
  &:hover {
    z-index: 1;
  }
`;


export const MarkerCarnivalMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  padding: 2px;
  background-color: ${Colors.black};
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border-color: ${Colors.accent};
  border-style: solid;
  border-width: 2px;
  
  &:hover {
    z-index: 1;
  }
`;



export const MarkerMapImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  z-index: 5;
`;
