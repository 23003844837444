import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  itemCarnival: {
    id: 0,
    country: '',
    state: '',
    city: '',
    type: '',
    visible_at: '',
    start_at: '',
    end_at: '',
    year: {
      id: 0,
      year: '',
    },
  },


  selectedCircuit: null as any,
  carnivalSheetCircuits: {
    show: false,
    title: '',
    data: [],
  } as any,
};



// Action Types

export const CARNIVAL_SET_PERIOD_CURRECT = '@carnival/SET_PERIOD_CURRECT';

export const CARNIVAL_SELECTED_CIRCUITS = '@company/CARNIVAL_SELECTED_CIRCUITS';
export const CARNIVAL_ACTIONSHEET_CIRCUITS = '@company/CARNIVAL_ACTIONSHEET_CIRCUITS';



// Action Creators

export const setCarnivalPeriodCurrect = createAction<object>(CARNIVAL_SET_PERIOD_CURRECT);

export const carnivalSelectedCircuitsAction = createAction<object>(CARNIVAL_SELECTED_CIRCUITS);
export const carnivalActionSheetCircuitsAction = createAction<object>(CARNIVAL_ACTIONSHEET_CIRCUITS);



// Handlers

const setPeriod = (state = INITIAL_STATE, action: any) => ({
  ...state,
  itemCarnival: action.payload,
});


const carnivalSelectedCircuitsReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  selectedCircuit: action.payload,
});


const carnivalActionSheetCircuitsReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  carnivalSheetCircuits: action.payload,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [setCarnivalPeriodCurrect.type]: setPeriod,

  [carnivalSelectedCircuitsAction.type]: carnivalSelectedCircuitsReducer,
  [carnivalActionSheetCircuitsAction.type]: carnivalActionSheetCircuitsReducer,
});
