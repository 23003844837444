import React from 'react';

import {
  CircularProgress,
} from '@material-ui/core';



export interface IProps {
  color?: string;
  size?: number;
}



const LoadingProgress: React.FC<IProps> = (props: IProps) => {
  return (

    <CircularProgress
      size={props.size || 35}
      style={{ color: props.color || '#ca4a14' }}
    />

  );
};



export default LoadingProgress;
