import styled from 'styled-components';

import {
  SubtitleDetails,
  SubtitleText,
} from '../../../../../styles/styled.layout';



export const AboutSocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 5px;
`;



// export const DetailsAboutInfo = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// export const ShimmerReportText = styled(Shimmer)`
//   margin-bottom: 5px;
// `;

// export const StarRatingView = styled.div`
//   flex-direction: row;
//   align-items: center;
// `;

// export const ShimmerRatingText = styled(Shimmer)`
//   margin-left: 15px;
// `;

// export const RatingDetailsText = styled.Text`
//   min-height: 28px;
//   min-width: 32px;
//   color: ${Colors.accent};
//   font-size: 18px;
// `;



export const AboutSocialPlace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

export const AboutPlaceUsername = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AboutPlaceInfoTitle = styled(SubtitleDetails)`
  margin-bottom: 0px;
`;

export const AboutPlaceInfoText = styled(SubtitleText)`
  margin-top: 0px;
`;


export const AboutSocialType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AboutCompanyTypeView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AboutPlaceCompanyText = styled(SubtitleText)`
  margin-top: 2px;
  margin-left: 8px;
`;
