import styled from 'styled-components';

import {
  Content,
} from '../../../../components/Composh/web';



export const ListEventFlatList = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
