import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconArrowExpand,
  // IconArrowExpanded,
} from '../../Icons';

import {
  CarnivalButtonActionSheet,
  CircuitTextContent,
  CarnivalCircuitText,
  CarnivalCircuitName,
  CircuitIcon,
} from './styled';



export interface IProps {
  disabled?: any;
  activeOpacity?: number;

  title?: string;
  subtitle?: string;

  onPress?: any;
}



const ButtonSelect: React.FC<IProps> = (props: any) => {
  return (

    <CarnivalButtonActionSheet
      disabled={props.disabled}
      onClick={() => {
        if (!props.disabled && props.onPress) {
          props.onPress();
        }
      }}>

      <CircuitTextContent>
        <CarnivalCircuitText>
          {props.title}
        </CarnivalCircuitText>


        {props.subtitle && (
          <CarnivalCircuitName>
            {props.subtitle}
          </CarnivalCircuitName>
        )}
      </CircuitTextContent>


      <CircuitIcon>
        <IconArrowExpand
          size={16}
          color={Colors.accent}
        />
      </CircuitIcon>

    </CarnivalButtonActionSheet>

  );
};



export default ButtonSelect;
