import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  companyTypesKitchenArray,
} from '@noitada/shared/arrays/company-kitchen.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Chip,
} from '../../../../../components/Composh/web';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../styles/styled.layout';

import {
  SearchChipContent,
} from './styled';



export interface IProps {
  kitchens: string;
}



const SectionKitchen: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [typesKitchen, setTypesKitchen] = useState<Array<any>>([]);



  function setKitchensForms() {
    if (props.kitchens) {
      const splitItem: Array<string> = props.kitchens.split(', ');
      const filteredItem = splitItem.map((value) => {
        return companyTypesKitchenArray.find((item) => item.value === value);
      });

      setTypesKitchen(filteredItem);
    }
  }


  function renderChipItem(item: any, index: number) {
    return (

      <Chip
        key={index}
        onCloseDisabled
        selected
        title={translate(item.label)}
        textColor={Colors.white}
        selectedBackgroundColor={Colors.cardBackground}
      />

    );
  }


  useEffect(() => {
    setKitchensForms();
  }, []);



  return typesKitchen?.length !== 0
    ? (

      <DetailsSectionView>
        <SubtitleDetails>
          {translate(TranslateConfig.PARTNER_TYPES_KITCHEN)}
        </SubtitleDetails>


        <SearchChipContent>
          {typesKitchen.map((item: any, index: number) => (
            renderChipItem(item, index)
          ))}
        </SearchChipContent>
      </DetailsSectionView>

    )
    : null;
};



export default SectionKitchen;
