import {
  Colors,
  Images,
} from '../constants';



export function renderCardColor(cardType: string) {
  if (!cardType) {
    return Colors.gray;
  }
  switch (cardType.toUpperCase()) {
    case 'AMEX':
      return '#2577b6';

    case 'DINERSCLUB':
      return '#2E3192';

    case 'ELO':
      return '#000000';

    case 'HIPERCARD':
      return '#7e1016';

    case 'MASTERCARD':
      return '#a23c01';

    case 'NU':
      return '#5F249F';

    case 'VISA':
      return '#1a1f71';

    default:
      return Colors.gray;
  }
}



export function renderImageFront(cardType: string) {
  if (!cardType) {
    return Images.creditCardFrontDefault;
  }
  switch (cardType.toUpperCase()) {
    case 'AMEX':
      return Images.creditCardFrontAmerican;

    case 'DINERSCLUB':
      return Images.creditCardFrontDiners;

    case 'ELO':
      return Images.creditCardFrontElo;

    case 'HIPERCARD':
      return Images.creditCardFrontHipercard;

    case 'MASTERCARD':
      return Images.creditCardFrontMastercard;

    case 'NU':
      return Images.creditCardFrontNubank;

    case 'VISA':
      return Images.creditCardFrontVisa;

    default:
      return Images.creditCardFrontDefault;
  }
}



export function renderImageBack(cardType: string) {
  if (!cardType) {
    return Images.creditCardBackDefault;
  }
  switch (cardType.toUpperCase()) {
    case 'AMEX':
      return Images.creditCardBackAmerican;

    case 'DINERSCLUB':
      return Images.creditCardBackDiners;

    case 'ELO':
      return Images.creditCardBackElo;

    case 'HIPERCARD':
      return Images.creditCardBackHipercard;

    case 'MASTERCARD':
      return Images.creditCardBackMastercard;

    case 'NU':
      return Images.creditCardBackNubank;

    case 'VISA':
      return Images.creditCardBackVisa;

    default:
      return Images.creditCardBackDefault;
  }
}



export function renderCardFlag(cardType: string) {
  if (!cardType) {
    return Images.flagCardAmerican;
  }
  switch (cardType.toUpperCase()) {
    case 'AMEX':
      return Images.flagCardAmerican;

    case 'DINERSCLUB':
      return Images.flagCardDiners;

    case 'ELO':
      return Images.flagCardElo;

    case 'HIPERCARD':
      return Images.flagCardHipercard;

    case 'MASTERCARD':
      return Images.flagCardMastercard;

    case 'NU':
      return Images.flagCardNubank;

    case 'VISA':
      return Images.flagCardVisa;

    default:
      return Images.flagDefault;
  }
}



export function renderCardLogo(cardType: string) {
  if (!cardType) {
    return Images.logoIconAppWhite;
  }
  switch (cardType.toUpperCase()) {
    case 'AMEX':
      return Images.logoAmerican;

    case 'DINERSCLUB':
      return Images.logoDiners;

    case 'ELO':
      return Images.logoElo;

    case 'HIPERCARD':
      return Images.logoHipercard;

    case 'MASTERCARD':
      return Images.logoMastercard;

    case 'NU':
      return Images.logoNubank;

    case 'VISA':
      return Images.logoVisa;

    default:
      return Images.logoIconAppWhite;
  }
}
