export enum ECarnivalLocation {
  CIRCUIT = 'CIRCUIT',
  BLOC = 'BLOC',
  CABIN = 'CABIN',
  POLICE = 'POLICE',
  BUS = 'BUS',
  TAXI = 'TAXI',
  BATHROOM = 'BATHROOM',
  FIREMAN = 'FIREMAN',
  SAVE_LIFE = 'SAVE_LIFE',
  MEDICAL_POINT = 'MEDICAL_POINT',
  JUVENILE = 'JUVENILE',
  OTHER_ATTRACTION = 'OTHER_ATTRACTION',
  NOITADA = 'NOITADA',
  AGENCY_FLOW = 'AGENCY_FLOW',
  AGENCY_PUBLIC = 'AGENCY_PUBLIC',
  CLEAN_HEALTH = 'CLEAN_HEALTH',
  AREA_RESERVED = 'AREA_RESERVED',
}
