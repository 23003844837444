import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const BadgeUnconfirmedView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.attention};
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  border-bottom-right-radius: ${Sizes.cardRadius}px;
`;

export const BadgeUnconfirmedText = styled.p`
  color: ${Colors.black};
  font-size: 13px;
  font-weight: bold;
`;
