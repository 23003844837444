import React from 'react';

import moment from 'moment';
import {
  Colors,
} from '@noitada/shared/constants';

import CardBuyTicket from '../../../../../components/Cards/CardBuyTicket';
import { Content } from '../../../../../components/Composh/web';



export interface IProps {
  tickets: any;
  selectedId?: string;
  onPress: Function;
}



const TabListEntrances: React.FC<IProps> = (props) => {
  const { tickets } = props;
  
  function renderItem(item: any) {
    return (
      <CardBuyTicket
        ticketName={item.name}
        lot={item.lots.find((l) => moment(l.saleStartDate).isAfter() && moment(l.saleEndDate).isBefore() && l.quantity > 0)?.turn}
        type={item.type}
        endSale={moment(item.saleEndDate).format('DD/MM/YYYY HH:mm')}
        price={item.price}
        gender={item.gender}
        backgroundColor={item.id !== props.selectedId ? Colors.transparent : Colors.buttonActionDark}
        onPress={() => props.onPress(item.id)}
      />
    );
  }



  return (

    <Content>
    
      {tickets.filter((ticket) => ticket.lots.find((l) => true || moment(l.saleStartDate).isAfter() && moment(l.saleEndDate).isBefore() && l.quantity > 0)).map((ticket) => renderItem(ticket))}

    </Content>

  );
};



export default TabListEntrances;
