import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const OrderMenuContainer = styled.a`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
  border-top-color: ${Colors.menuSeparator};
  border-top-style: solid;
  border-top-width: 0.25px;
  border-bottom-color: ${Colors.menuSeparator};
  border-bottom-style: solid;
  border-bottom-width: 0.25px;
`;


export const OrdeMenuItemViewImage = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  height: 110px;
  min-height: 110px;
  max-height: 110px;
  margin-right: 12px;
  border-radius: 5px;
  background-color: ${Colors.alertBackground};
`;

export const OrdeMenuItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const OrderTypesItemView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-top: 3px;
  padding-left: 2px;
  padding-right: 1px;
  padding-bottom: 2px;
  background-color: ${Colors.alertOverlay};
  border-top-left-radius: 6px;
`;

export const OrderTypesItemIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1px;
  margin-right: 1px;
`;


export const OrderMenuInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const OrderMenuTitle = styled.h1`
  color: ${Colors.white};
  font-size: 16px;
  line-height: 21px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const OrderMenuDescription = styled.p`
  height: 45px;
  margin-top: 5px;
  color: #B4B4B4;
  font-size: 14px;
  line-height: 21px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;



export const OrderMenuFooterView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 6px;
`;

export const OrderMenuPrice = styled.h3`
  color: ${Colors.buttonOkLight};
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
