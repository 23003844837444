export enum EUserLevel {
  BASIC = 'BASIC',
  PRIME = 'SUPER',
  PERSAS = 'PERSAS',
  STAR = 'STAR',
  KING = 'KING',
}



export default EUserLevel;
