const CompanyContact = {
  id: '',
  facebook: '',
  facebook_id: '',
  instagram: '',
  number_primary: '',
  number_secondary: '',
};



export default CompanyContact;
