export default class EventEntrance {
  id: number;
  lobby: number;
  listName: number;
  openBar: number;
  allInclusive: number;
  cabin: number;
  lounge: number;
  vip: number;
  lot: string;
  validTime: Date;
}
