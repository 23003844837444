import React from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  IconArrowExpand,
  IconArrowExpanded,
} from '../../../Icons';

import {
  CarnivalStreetCardStyle,
  CarnivalStreetBackgroundImage,
  CarnivalStreetBackground,
  CarnivalStreetContainer,
  CarnivalStreetHeaderTitle,
  CarnivalStreetTitle,
  CarnivalStreetButtonView,
  CarnivalStreetArrowContainer,
  CarnivalStreetDescText,
} from './styled';



export interface IProps {
  expanded?: boolean;
  canExpanded?: boolean;

  confirmed?: boolean;
  title?: string;
  description?: string;
  backgroundImage?: any;
  onPress?: any;
}



const CardCarnivalStreet: React.FC<IProps> = (props: IProps) => {
  function renderExpandableIcon() {
    if (props.expanded) {
      return (

        <IconArrowExpanded
          color={Colors.white}
          size={Sizes.iconNavigationBottomSize}
        />

      );
    }


    return (

      <IconArrowExpand
        color={Colors.white}
        size={Sizes.iconNavigationBottomSize}
      />

    );
  }



  return (

    <CarnivalStreetCardStyle>
      <CarnivalStreetBackgroundImage
        backgroundColor={Colors.cardBackgroundAccent}
        borderRadius={Sizes.cardCarnivalRadius}
        onPress={() => {
          if (props.canExpanded && props.onPress) {
            props.onPress();
          }
        }}>

        <CarnivalStreetBackground
          alt={'background'}
          src={props.backgroundImage === '' || props.backgroundImage === null
            ? Images.placeholderEvent
            : props.backgroundImage
          }
        />



        <CarnivalStreetContainer>

          <CarnivalStreetHeaderTitle>

            <CarnivalStreetTitle>
              {props.title ? props.title.toUpperCase() : '-'}
            </CarnivalStreetTitle>


            <CarnivalStreetButtonView>

              {props.canExpanded && (
                <CarnivalStreetArrowContainer>
                  {renderExpandableIcon()}
                </CarnivalStreetArrowContainer>
              )}

            </CarnivalStreetButtonView>

          </CarnivalStreetHeaderTitle>



          <CarnivalStreetDescText>
            {props.description}
          </CarnivalStreetDescText>

        </CarnivalStreetContainer>

      </CarnivalStreetBackgroundImage>
    </CarnivalStreetCardStyle>

  );
};



export default CardCarnivalStreet;
