import NoitadaWS from '../index';



export const GetLocalitiesApp = async () => {
  const url = '/localities';
  const response = await NoitadaWS.get(url);

  return response.data;
};
