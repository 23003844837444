import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const ParentalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 4px;
  padding-left: 5px;
`;

export const ParentalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
`;



export const ParentalIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 25px;
  margin-right: 10px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: 3px;
`;

export const ParentalSymbolText = styled.p`
  color: ${Colors.white};
  font-size: ${Sizes.TextSubtitle}px;
  font-weight: bold;
`;

export const ParentalText = styled.p`
  color: ${Colors.textApp};
  font-size: ${Sizes.TextSubtitle}px;
`;
