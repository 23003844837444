import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPattern,
} from '@noitada/shared/utils/date.utils';

import {
  Shapes,
} from '../../Composh/web';

import {
  CardListContainer,
  CardListStub,
  CardListTopStub,
  CardListTopNumInvitation,
  CardListTopInvitationSpan,
  CardListTopAdmit,
  CardListTopLineInvite,
  CardListTopInfoCompany,
  CardListTopImageRow,
  CardListTopInfoColumn,
  CardListTopImage,
  CardListTopNumber,
  CardListTopInvite,
  ViewDotsContent,
  CardListCheckContent,
  CardListCheckTitle,
  CardListChecInfoView,
  CardListDateSection,
  CardListHourSection,
  CardListChecInfoText,
  CardListCheckNameSection,
} from './styled';



export interface IProps {
  onPress?: any;
  date: string;
  companyImage: string;
  companyName: string;
  listId: string;
  listName: string;
  eventImage: string;
  eventName: string;
  eventType: string;
  city: string;
  state: string;
}



const CardListUser: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const dateEvent = FormatIsoDateWithPattern(props.date, 'dd/MM/yyyy');
  const hourEvent = FormatIsoDateWithPattern(props.date, 'HH:mm');



  return (

    <CardListContainer
      onClick={props.onPress}>

      <CardListStub>

        <CardListTopStub>
          <CardListTopNumInvitation>
            {translate(TranslateConfig.PARTICIPATION)}:
          </CardListTopNumInvitation>

          <CardListTopInvitationSpan>
            #{props.listId}
          </CardListTopInvitationSpan>
        </CardListTopStub>


        <CardListTopLineInvite />

        <CardListTopAdmit>
          {props.eventName}
        </CardListTopAdmit>



        <CardListTopInfoCompany>

          <CardListTopLineInvite />



          <CardListTopImageRow>

            <CardListTopImage
              src={props.companyImage ? props.companyImage : Images.placeholderAvatarCompany}
            />


            <CardListTopInfoColumn>
              <CardListTopNumber>
                @{props.companyName}
              </CardListTopNumber>


              <CardListTopInvite>
                {translate(TranslateConfig.WAIT_YOU)}
              </CardListTopInvite>
            </CardListTopInfoColumn>

          </CardListTopImageRow>

        </CardListTopInfoCompany>

      </CardListStub>



      <ViewDotsContent>
        <Shapes.LineTear
          color={Colors.cardBackground}
          tearSize={8}
          width={170}
        />
      </ViewDotsContent>



      <CardListCheckContent>

        <CardListCheckTitle>
          {translate(TranslateConfig.SUBTITLES_LIST_IN)}
        </CardListCheckTitle>



        <CardListChecInfoView>

          <CardListDateSection>
            <CardListTopLineInvite />

            <CardListTopNumInvitation>
              {translate(TranslateConfig.DATE)}
            </CardListTopNumInvitation>


            <CardListChecInfoText>
              {dateEvent || '-'}
            </CardListChecInfoText>
          </CardListDateSection>



          <CardListHourSection>
            <CardListTopLineInvite />

            <CardListTopNumInvitation>
              {translate(TranslateConfig.HOUR)}
            </CardListTopNumInvitation>


            <CardListChecInfoText>
              {hourEvent || '-'}
            </CardListChecInfoText>
          </CardListHourSection>



          <CardListCheckNameSection>
            <CardListTopLineInvite />

            <CardListTopNumInvitation>
              {translate(TranslateConfig.LIST_NAME)}
            </CardListTopNumInvitation>


            <CardListChecInfoText>
              {props.listName}
            </CardListChecInfoText>
          </CardListCheckNameSection>

        </CardListChecInfoView>

      </CardListCheckContent>

    </CardListContainer>

  );
};



export default CardListUser;
