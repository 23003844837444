import React from 'react';

import {
  _setLevelText,
  _setLevelImage,
  _setLevelColor,
} from '@noitada/shared/config/user-levels.config';

import {
  MenuLevelImageContent,
  MenuImageLevel,
  MenuPlaceImage,
  MenuTextLevel,
} from './styled';



interface IProps {
  userLevel?: string;
  style?: any;
}



const LevelUser: React.FC<IProps> = (props: any) => {
  return (

    <MenuLevelImageContent
      style={{
        borderColor: _setLevelColor(props.userLevel),
      }}>

      <MenuImageLevel>
        <MenuPlaceImage
          alt={'LevelUser'}
          src={_setLevelImage(props.userLevel)}
        />
      </MenuImageLevel>



      <MenuTextLevel
        style={{
          backgroundColor: _setLevelColor(props.userLevel),
        }}>
        {_setLevelText(props.userLevel)}
      </MenuTextLevel>

    </MenuLevelImageContent>

  );
};



export default LevelUser;
