import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import Company from '@noitada/axios-config/models/Company';



const INITIAL_STATE = {
  allCompanies: [],
  selected: Company,
};



// Action Types

export const USER_SET_ALL_COMPANIES = '@user/SET_ALL_COMPANIES';
export const USER_SET_CURRENT_COMPANY = '@user/SET_CURRENT_COMPANY';
export const USER_RESET_ALL_COMPANIES = '@user/RESET_ALL_COMPANIES';



// Action Creators

export const setAllCompaniesAction = createAction<object>(USER_SET_ALL_COMPANIES);
export const setSelectedCompany = createAction<object>(USER_SET_CURRENT_COMPANY);
export const resetAllCompaniesAction = createAction<object>(USER_RESET_ALL_COMPANIES);



// Handlers

const setAllCompanies = (state = INITIAL_STATE, action) => ({
  ...state,
  allCompanies: action.payload,
});


const setCurrentCompany = (state = INITIAL_STATE, action) => ({
  ...state,
  selected: action.payload,
});


const resetAllCompanies = () => ({
  ...INITIAL_STATE,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [setAllCompaniesAction.type]: setAllCompanies,
  [setSelectedCompany.type]: setCurrentCompany,
  [resetAllCompaniesAction.type]: resetAllCompanies,
});
