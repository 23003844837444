const environment = {
  production: true,
  environment: 'PROD',
  title: 'Production Environment Heading',
  apiUrl: 'https://api.noitada.app',
  firebaseWebConfig: {
    apiKey: 'AIzaSyAdyFkLRRTnaZjjmIVohhmuCXQjei7tELY',
    authDomain: 'noitada-app-prod.firebaseapp.com',
    projectId: 'noitada-app-prod',
    storageBucket: 'noitada-app-prod.appspot.com',
    messagingSenderId: '502800545091',
    appId: '1:502800545091:web:75f7ed932453c2fbe76bf6',
    measurementId: 'G-MJ64P5ZSP3',
  },
};



export default environment;
