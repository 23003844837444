import styled from 'styled-components';

import Content from '../Content';



export const ComposhFlatListStyle = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

export const ComposhFlatListContent = styled.div`
  display: flex;
  flex-direction: column;
`;
