import React from 'react';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  IconMapPin,
} from '../../../Icons';

import {
  ListItemRoute,
  ListButton,
  ListCarnivalIcon,
  ListLabel,
  ListSubtitle,
} from './styled';



export interface IProps {
  name?: string;
  circuit?: string;
  onPress?: any;
}



const CardCarnivalCircuit: React.FC<IProps> = (props: IProps) => {
  return (

    <ListButton
      // activeOpacity={Sizes.ButtonActiveOpacity}
      onClick={props.onPress}>

      <ListCarnivalIcon>
        <IconMapPin
          color={Colors.white}
          size={Sizes.iconAndroidSize}
        />
      </ListCarnivalIcon>


      <ListItemRoute>
        <ListLabel>
          {props.name || '-'}
        </ListLabel>

        {props.circuit && (
          <ListSubtitle>
            {props.circuit}
          </ListSubtitle>
        )}
      </ListItemRoute>

    </ListButton>

  );
};



export default CardCarnivalCircuit;
