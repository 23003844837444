import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const ShareEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 295px;
  background-color: ${Colors.alertBackground};
  border-radius: ${Sizes.cardRadius}px;
  box-shadow: 0 2px 45px 4px rgba(5,0,6,.2);
  z-index: 55;
`;


export const ShareEventBannerView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 160px;
`;

export const ShareEventBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const ShareEventContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  padding-top: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 7px;
`;

export const ShareEventItemButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 40%;
  max-width: 50%;
  margin-top: 6px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 6px;
  padding-left: 7px;
  padding-right: 7px;
  height: 45px;
  border-color: ${Colors.menuLine};
  border-style: solid;
  border-width: 1px;
`;

export const ShareEventItemIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const ShareEventItemText = styled.p`
  margin-left: 5px;
  color: ${Colors.white};
  font-size: 13px;
`;
