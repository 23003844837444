import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Table,
} from '../../../../../components/Composh/web';



export const AboutTableList = styled(Table.Table)`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
`;

export const AboutTableWrapper = styled(Table.TableWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2px;
`;

export const AboutTableCol = styled(Table.Col)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AboutTableColTitle = styled.h2`
  color: ${Colors.textTitle};
  font-size: 15px;
  font-weight: normal;
  text-align: left;
`;

export const TableRowsStyle = styled(Table.Rows).attrs({
  style: {
    display: 'flex',
    flexDirection: 'row',
    height: 30,
  },
})`
`;

export const AboutTableRowText = styled.p`
  color: ${Colors.textApp};
  font-size: 14.5px;
  text-align: left;
`;
