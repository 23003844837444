import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const BubbleMarkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;


export const BubbleInfoViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 55px;
  background-color: ${Colors.white};
  border-radius: ${Sizes.cardRadius}px;
  border-color: ${Colors.accent};
  border-style: solid;
  border-width: 2px;
  z-index: 99999;
`;

export const BubbleInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  height: 80px;
  padding: 10px;
`;

export const BubbleInfoTriangle = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: ${Colors.accent} transparent transparent transparent;
`;


export const BubbleInfoTextContent = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BubbleInfoTitle = styled.p`
  min-height: 21px;
  color: ${Colors.black};
  font-size: 17px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  word-wrap: break-word;
`;

export const BubbleInfoPinName = styled.p`
  margin-top: 2px;
  color: ${Colors.black};
`;

export const BubbleLatLngView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
`;

export const BubbleInfoLatLng = styled.p`
  margin-left: 6px;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.gray};
`;

export const BubbleInfoButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const BubbleInfoDirectionButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 35px;
`;



export const BubbleCloseView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: ${Colors.accent};
`;

export const BubbleCloseText = styled.p`
  color: ${Colors.black};
  font-size: 11px;
  font-style: italic;
  font-weight: bold;
`;
