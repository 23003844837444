import React,
{
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import Vars from '@noitada/shared/environments/variables';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  Content,
  Screen,
  Shapes,
  Title,
} from '../../../components/Composh/web';

import LevelUser from '../../../components/Contents/UserLevel';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconEmail,
  IconPerson,
  IconPhoneCell,
  IconProfile,
} from '../../../components/Icons';

import {
  openSite,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  ContentPadder,
} from '../../../styles/styled.layout';

import {
  ProfileHeaderContainer,
  ProfileInfoContainer,
  ProfileName,
  ProfileDivider,
  ProfileUserView,
  ProfileUserImage,
  ProfileDataContainer,
  ProfileAbout,
  ProfileViewUsername,
  ProfileUsername,
  IconVerifiedComponent,
  ProfileInfoView,
  ProfileInfoTextContainer,
  ProfileTextInformations,
  ActionButtonContainer,
  EditButtonView,
  IconButtonContent,
  TextContentView,
} from './styled';



const ProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const imageDimension = 290;
  const shapeDistance = 120;
  const iconSize = 26;
  const iconColor = Colors.white;



  function openWebScreen(title: string, link: string) {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title,
        link,
      },
    });
  }


  function renderEditsButtons(
    iconButton: any,
    textButton: string,
    onPressButton: any,
  ) {
    return (

      <EditButtonView
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={onPressButton}>

        <IconButtonContent>
          {iconButton}
        </IconButtonContent>

        <TextContentView>
          {textButton}
        </TextContentView>

      </EditButtonView>

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.PROFILE });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.PROFILE)}
          </Title>
        }
        rightIcon={
          <ButtonMore
            color={Colors.white}
          />
        }
      />



      <Content>

        <ProfileHeaderContainer
          height={imageDimension}>

          <Shapes.Quadrilateral
            dimension={imageDimension}
            distance={shapeDistance}
            color={Colors.profileColorTop}
          />


          <ProfileInfoContainer
            style={{
              right: imageDimension - (shapeDistance - 25),
            }}>

            {/*
              <Badge.Circle
                containerStyle={styles.badgeProfileType}
                color={Colors.primary}
                value="Promoter"
              />
              */}


            <ProfileName>
              {user?.publicName}
            </ProfileName>


            <ProfileDivider
              height={1.5}
              width={'90%'}
              color={Colors.menuSeparator}
            />


            <ProfileViewUsername>
              <ProfileUsername>
                @{user?.username}
              </ProfileUsername>


              {user?.userInfo?.verified && (
                <IconVerifiedComponent
                  color={Colors.accent}
                  size={13}
                />
              )}
            </ProfileViewUsername>

          </ProfileInfoContainer>



          <ProfileUserView>
            <ProfileUserImage
              style={{
                width: imageDimension - 20,
                height: imageDimension,
              }}
              alt={'User Image'}
              src={
                user?.picture === '' || user?.picture === null || user?.picture === undefined
                  ? Images.placeholderAvatarCompany // FIX
                  : user?.picture
              }
            />
          </ProfileUserView>

        </ProfileHeaderContainer>



        <ContentPadder>

          {validateString(user?.aboutMe) && (
            <ProfileDataContainer>
              <ProfileAbout>
                &quot;
                {validateString(user?.aboutMe)}
                &quot;
              </ProfileAbout>
            </ProfileDataContainer>
          )}



          <ProfileInfoView>

            <ProfileInfoTextContainer>
              <ProfileTextInformations>
                {validateString(user?.name)}
              </ProfileTextInformations>


              {/* // FIXME: Data de Aniversario
              <Shimmer
                height={20}
                width={180}
                visible={availableUser}>
                {true && (
                  <ProfileTextInformations>
                    format(user.birthday, 'DD/MM/YYYY')}
                  </ProfileTextInformations>
                )}
              </Shimmer>
              */}


              <ProfileTextInformations>
                {validateString(user?.email)}
              </ProfileTextInformations>

            </ProfileInfoTextContainer>


            <LevelUser
              userLevel={user.userInfo?.level}
            />

          </ProfileInfoView>



          <ActionButtonContainer>

            {renderEditsButtons(
              <IconPerson
                size={iconSize}
                color={iconColor}
              />,
              translate(TranslateConfig.ACCOUNT_SEE),
              () => {
                openSite(Vars().globalAccountUser);
              },
            )}


            {renderEditsButtons(
              <IconProfile
                size={iconSize}
                color={iconColor}
              />,
              translate(TranslateConfig.PROFILE),
              () => {
                openWebScreen(
                  translate(TranslateConfig.PROFILE),
                  Vars().globalAccountProfile,
                );
              },
            )}


            {renderEditsButtons(
              <IconEmail
                size={iconSize}
                color={iconColor}
              />,
              translate(TranslateConfig.ADDRESSES),
              () => {
                openWebScreen(
                  translate(TranslateConfig.ADDRESSES),
                  Vars().globalAccountAddress,
                );
              },
            )}


            {/*
            {renderEditsButtons(
              <IconPhoneCell
                size={iconSize}
                color={iconColor}
              />,
              'Duo Noitada',
              () => {
                // navigation.dispatch(StackActions.push(NameRoutes.CellphoneFormScreen)),
              },
            )}
            */}

          </ActionButtonContainer>

        </ContentPadder>
      </Content>

    </Screen>

  );
};



export default ProfileScreen;
