import {
  ICountry,
} from '@noitada/axios-config/interfaces/App/country.interface';

import {
  Images,
} from '../constants';

import {
  ELanguage,
} from '../enums';

import {
  ELanguagesTranslations,
} from '../translations/translates.keys';



export const languagesOption: Array<ICountry & { label: ELanguagesTranslations, languageIso: ELanguage }> = [
  {
    code: 'BRA',
    namePt: 'Brasil',
    label: ELanguagesTranslations.PT_BR,
    languageIso: ELanguage.PT_BR,
    flagImage: Images.countryBrazilFlag,
    moneyFull: 'BRL (R$)',
    moneySymbol: 'R$',
  },
  {
    code: 'USA',
    namePt: 'Estados Unidos',
    label: ELanguagesTranslations.EN_US,
    languageIso: ELanguage.EN_US,
    flagImage: Images.countryUnitedStatesFlag,
    moneyFull: 'USA ($)',
    moneySymbol: '$',
  },
  {
    code: 'ESP',
    namePt: 'Espanha',
    label: ELanguagesTranslations.ES_AR,
    languageIso: ELanguage.ES_AR,
    flagImage: Images.countrySpainFlag,
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'ITA',
    namePt: 'Itália',
    label: ELanguagesTranslations.IT_IT,
    languageIso: ELanguage.IT_IT,
    flagImage: Images.countryItalyFlag,
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'FRA',
    namePt: 'França',
    label: ELanguagesTranslations.FR_FR,
    languageIso: ELanguage.FR_FR,
    flagImage: Images.countryFranceFlag,
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
];
