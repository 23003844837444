import React from 'react';

import GoogleMapReact from 'google-map-react';

import {
  AppMapStyle,
} from '@noitada/shared/assets/raw/app.mapstyle';

import getEnvironments from '@noitada/shared/environments/application';



const MapGoogle = ({ children, ...props }) => {
  const apiKey = getEnvironments().firebaseWebConfig.apiKey;

  const defaultProps = {
    center: {
      lat: -12.9892618,
      lng: -38.4846558,
    },
    zoom: 11,
  };



  return (

    <GoogleMapReact
      options={{
        draggable: props.draggable || true,
        fullscreenControl: false,
        styles: AppMapStyle,
      }}

      bootstrapURLKeys={{ key: apiKey }}
      defaultCenter={props.center || defaultProps.center}
      defaultZoom={props.zoom || defaultProps.zoom}
      {...props}>

      {children}

    </GoogleMapReact>

  );
};



export default MapGoogle;
