import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  color?: string;
}



export const DetailsCompanyHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;



export const DetailsCompanyInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 25px;
  min-height: 40px;
`;

export const DetailsCompanyTitle = styled.h1`
  color: ${Colors.white};
  font-size: 25px;
  font-weight: bold;
`;



export const DetailsAbout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const DetailsCompanyHeader = styled.div`
  display: flex;
  flex-direction: row;
`;



export const PlaceLogo = styled.img`
  height: 85px;
  width: 85px;
  background-color: black;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;


export const CompanyInfoHomeView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;


export const CompanyManageButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CompanyManageIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const CompanyManageInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyManageText = styled.div`
  color: ${Colors.textApp};
  font-size: 14px;
  text-align: left;
`;

export const CompanyManageSubText = styled.div`
  color: ${Colors.accent};
  font-size: 11px;
  text-align: left;
`;



export const ProfileServiceText = styled.p`
  margin-top: 5px;
  color: ${Colors.grayLight};
  font-size: 14px;
`;


export const ProfileUpdateCompanyText = styled.p`
  margin-top: 8px;
  color: ${Colors.gray};
  font-size: 12px;
`;



export const SloganText = styled.p`
  margin-top: 20px;
  color: ${Colors.textApp};
  font-size: 17px;
  font-style: italic;
`;
