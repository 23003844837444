import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadder,
} from '../../styles/styled.layout';



export interface IProps {
  selected?: boolean;
}



export const PermissionContent = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;


export const BodyQr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

export const PermissionTitle = styled.h1`
  margin-top: 20px;
  color: ${Colors.white};
  font-size: 26px;
  text-align: center;
`;

export const PermissionText = styled.p`
  margin-top: 20px;
  color: ${Colors.gray};
  font-size: 15px;
  text-align: center;
`;

export const PermissionImage = styled.img`
  margin-top: 40px;
  width: 230px;
  height: 230px;
  object-fit: contain;
`;



export const TermsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const TextTerms = styled.p`
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  color: ${Colors.gray};
  font-size: 13px;
  line-height: 20px;
  text-align: center;
`;

export const TextBoldTerms = styled.a`
  color: ${Colors.white};
`;



export const PermissionBottomToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${Sizes.BOTTOM_TOOLBAR_ANDROID}px;
  background-color: ${'rgba(0, 0, 0, 0.1)'};
`;

export const PermissionBottomButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
`;

export const TutorialBottomTextLeft = styled.p`
  color: ${Colors.white};
  font-size: 17px;
  line-height: 19px;
  text-align: center;
`;

export const TutorialBottomTextRight = styled.p`
  color: ${Colors.accent};
  font-size: 17px;
  line-height: 19px;
  text-align: center;
`;
