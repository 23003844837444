import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CountTimeView = styled.div`
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #E3F9FB;
  border-radius: ${Sizes.cardRadius}px;
`;

export const CountTimeText = styled.p`
  font-size: 28px;
  color: ${Colors.primary};
  text-align: center;
`;

export const CountHelpText = styled.p`
  margin-top: 5px;
  font-size: 15px;
  color: ${Colors.primary};
  text-align: center;
`;
