import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Divider,
  Shapes,
} from '../../../../../components/Composh/web';



export const ModalView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
`;


export const TicketInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;



export const LinerTearTop = styled(Shapes.LineTear)`
  margin-top: -14px;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
`;

export const TitleModal = styled.p`
  margin-bottom: 5px;
  color: black;
  font-size: 22px;
`;

export const CloseModalButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 15px;
  height: 36px;
  width: 36px;
  padding: 5px;
`;



export const TicketsIdView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
`;

export const IdTicketText = styled.p`
  margin-bottom: 7px;
  color: ${Colors.gray};
  font-size: 16px;
  font-weight: 300;
`;

export const NameTicketBoldText = styled.p`
  margin-bottom: 7px;
  color: ${Colors.black};
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const EventNameText = styled.p`
  color: black;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
`;



export const DateEventStatusView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
`;

export const DateEventText = styled.p`
  color: ${Colors.textDescription};
  font-size: 13.5px;
`;



export const TicketSeparator = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;



export const InfoTicketView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const PlaceNameText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.textSubDescription};
  font-size: 14px;
  text-align: center;
`;

export const DetailsText = styled.p`
  color: ${Colors.black};
  font-size: 15px;
  text-align: center;
`;



export const EventDirectionsPlaceContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 15px;
`;

export const GiveDirectionsButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-bottom: 10px;
`;



export const LinerTearBottom = styled(Shapes.LineTear)`
  margin-bottom: -13px;
`;

export const TicketItemsContent = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${Colors.cardBackground};
`;

export const ItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  margin-bottom: -5px;
  padding-top: 12px;
  padding-left: 35px;
  padding-right: 35px;
`;

export const UsernameText = styled.p`
  color: white;
  font-size: 12px;
  font-weight: bold;
`;

export const TicketTypeText = styled.p`
  color: white;
  font-size: 22px;
  font-weight: bold;
`;
