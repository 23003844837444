import NoitadaWS from '../index';



export const GetCarnivalAttractions = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/carnival/attractions';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalCabins = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/carnival/cabins';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalDistricts = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/carnival/districts';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalStreet = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/carnival/streets';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalLounges = async (from: string, localities: any) => {
  const payload = {
    ...localities,
    from: from,
  } as any;

  const url = '/carnival/lounges';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCircuits = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/carnival/circuits';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalMap = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/carnival/locations';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalGuides = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/carnival/guides';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};
