import styled from 'styled-components';

import {
  Toggle,
} from '../../../../../components/Composh/web';

import {
  SubtitleText,
} from '../../../../../styles/styled.layout';



export const DetailsPayToggle = styled(Toggle)`
`;



export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const PaymentsText = styled(SubtitleText)`
  margin-top: 7px;
  margin-bottom: 3px;
`;

export const PaymentsContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PaymentsImage = styled.img`
  width: 55px;
  height: 25px;
  object-fit: contain;
`;
