import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconGallery,
} from '../../../../../components/Icons';

import NameRoutes from '../../../../../navigation/names';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../styles/styled.layout';

import {
  SectionGalleryButton,
  SectionGalleryContainer,
  SectionGalleryImage,
  SectionGalleryMore6View,
  SectionGalleryMore6Text,
} from './styled';



export interface IProps {
  companyUsername: string;
  gallery: any;
}



const SectionGallery: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const imagesArray: Array<any> = props.gallery;


  function gridTemplateColumns() {
    if (imagesArray.length === 1) {
      return 'repeat(1, 1fr)';
    }

    if (imagesArray.length === 2) {
      return 'repeat(2, 2fr)';
    }

    return 'repeat(3, 3fr)';
  }


  function renderItem(item: any, index: number) {
    return (

      <SectionGalleryImage
        key={index}
        src={item?.link || Images.placeholderAvatarCompany}
      />

    );
  }



  return (

    <DetailsSectionView>
      <SubtitleDetails>
        {translate(TranslateConfig.GALLERY)}
      </SubtitleDetails>


      <SectionGalleryButton
        onClick={() => {
          history.push(NameRoutes.CompanyGalleryScreen.replace(
            ':company_username',
            props.companyUsername,
          ));
        }}>

        <SectionGalleryContainer
          style={{
            gridTemplateColumns: gridTemplateColumns(),
          }}>

          {imagesArray.map((item: any, index: number) => {
            if (index < 5) {
              return renderItem(item, index);
            }

            if (imagesArray?.length === 6 && index === 5) {
              return renderItem(item, index);
            }
          })}


          {imagesArray.length > 6 && (
            <SectionGalleryMore6View>

              <SectionGalleryMore6Text>
                {translate(TranslateConfig.ACTION_OPEN_GALLERY)}
              </SectionGalleryMore6Text>

              <IconGallery
                color={Colors.white}
                size={22}
              />

            </SectionGalleryMore6View>
          )}

        </SectionGalleryContainer>
      </SectionGalleryButton>

    </DetailsSectionView>

  );
};



export default SectionGallery;
