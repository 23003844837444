import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const ModalFinishedContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  width: 320px;
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
  border-radius: ${Sizes.cardRadius}px;
  border-color: ${Colors.accent};
  border-style: solid;
  border-width: 1px;
`;


export const ModalFinishedTitle = styled.h1`
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

export const ModalFinishedImage = styled.img`
  width: 220px;
  height: 220px;
  margin-top: 15px;
  margin-bottom: 35px;
`;
