import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  BottomNavigationAction,
} from '@material-ui/core';

import {
  GetUserCurrentCheckin,
} from '@noitada/axios-config/endpoints/comanda.endpoints';

import {
  GetCompany,
} from '@noitada/axios-config/endpoints/companies.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  USER_SET_CHECKIN,
} from '@noitada/redux-config/reducers/comanda.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  USER_CLEAR_SACOLA,
} from '@noitada/redux-config/reducers/order-bag.store';

import {
  Colors,
} from '@noitada/shared/constants';

import NameRoutes from '../../../navigation/names';

import {
  IconNoitada,
  IconOrderSheet,
  IconQrCode,
} from '../../Icons';

import LoadingProgress from '../../Loadings/LoadingProgress';

import {
  CheckInButton,
} from './styled';



const ButtonUserCheckin: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);


  const [loading, setLoading] = useState(false);

  const [check, setCheck] = useState<any>(checkinUser);



  function useInterval(callback, delay) {
    const savedCallback = useRef() as any;

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);


    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }


  async function handleCheck() {
    if (!user?.id) {
      dispatch({ type: USER_CLEAR_SACOLA });
      return false;
    }
    try {
      const checkin = await GetUserCurrentCheckin();
      if (!checkin.id) {
        setCheck(undefined);
        dispatch({ type: USER_SET_CHECKIN, payload: null });
        dispatch({ type: USER_CLEAR_SACOLA });
      }
      else {
        setCheck(checkin);
      }
      return checkin;
    }
    catch (error: any) {
      setCheck(undefined);
      dispatch({ type: USER_SET_CHECKIN, payload: null });
      dispatch({ type: USER_CLEAR_SACOLA });
      return false;
    }
  }


  async function onClickAppButton() {
    if (loading) return;
    setLoading(true);

    if (!user?.id) {
      setLoading(false);
      history.push(NameRoutes.AccessScreen);
      return;
    }

    try {
      const checkin = await handleCheck();
      if (!checkin) {
        setLoading(false);
        history.push(NameRoutes.QrCodeScreen);
        return;
      }

      // Set company
      const data = await GetCompany(checkin.company.idShort);
      dispatch({ type: USER_SET_CURRENT_COMPANY, payload: data });

      dispatch({ type: USER_SET_CHECKIN, payload: checkin });
      setLoading(false);
      history.push(NameRoutes.OrderSheetScreen);
    }
    catch (error: any) {
      setLoading(false);
      dispatch({ type: USER_CLEAR_SACOLA });
      history.push(NameRoutes.QrCodeScreen);
    }
  }


  const renderIcon = () => {
    if (loading) {
      return (
        <LoadingProgress
          color={Colors.white}
          size={35}
        />
      );
    }

    if (!check?.id && user?.id) {
      return (
        <IconQrCode
          color={Colors.white}
          size={35}
        />
      );
    }

    if (check?.id && user?.id) {
      return (
        <IconOrderSheet
          color={Colors.white}
          size={35}
        />
      );
    }

    return (
      <IconNoitada
        size={35}
      />
    );
  };



  useInterval(() => {
    handleCheck();
  }, 5000);



  useEffect(() => {
    handleCheck();
  }, []);



  return (

    <BottomNavigationAction
      id={'order-sheet-button'}
      onClick={() => {
        onClickAppButton();
      }}
      icon={
        <CheckInButton
          style={{
            backgroundColor: user.id ? Colors.buttonOk : Colors.disabled,
          }}>
          {renderIcon()}
        </CheckInButton>
      }
    />

  );
};



export default ButtonUserCheckin;
