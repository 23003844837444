import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Epigraph,
  Input,
} from '../../../../../../components/Composh/web';

import InputText from '../../../../../../components/Inputs/InputText';

import {
  DetailsSectionView,
  DetailsTopViewItems,
  RowFlexLine,
  RowLine,
  ViewSeparator,
} from '../../../../../../styles/styled.layout';



export interface IProps {
  loading?: any;
  errors: any;
  values: any;
  setFieldValue: any;
  handleBlur: any;
}



const SectionDateEvent: React.FC<IProps> = (props) => {
  const { t: translate } = useTranslation();


  const loading = props.loading;
  const errors = props.errors;
  const values = props.values;
  const setFieldValue = props.setFieldValue;
  const handleBlur = props.handleBlur;



  return (

    <DetailsTopViewItems>

      <Epigraph.Section
        text={translate(TranslateConfig.EVENT_DATE)}
        textColor={Colors.primary}
        borderBottomColor={Colors.primary}
      />


      <DetailsSectionView>

        <Input.Label
          labelText={`${translate(TranslateConfig.EVENT_DATE_INITIAL)} *`}
          labelColor={Colors.primary}
        />


        <RowLine>
          <RowFlexLine>
            <InputText
              disabled={loading}
              type={'DATETIME'}
              options={{
                format: 'DD/MM/YYYY',
              }}
              placeholderText={translate(TranslateConfig.INITIAL_DATE)}
              helpText={translate(errors.startDate || '')}
              countLimit={Values.dateCount}
              value={values.startDate}
              onChange={(_rawText: string, maskedText: string) => {
                setFieldValue('startDate', maskedText);
              }}
              onBlur={handleBlur('startDate')}
            />
          </RowFlexLine>


          <ViewSeparator />


          <RowFlexLine>
            <InputText
              disabled={loading}
              type={'DATETIME'}
              options={{
                format: 'HH:mm',
              }}
              placeholderText={translate(TranslateConfig.INITIAL_HOUR)}
              helpText={translate(errors.startTime || '')}
              countLimit={Values.hourCount}
              value={values.startTime}
              onChange={(_rawText: string, maskedText: string) => {
                setFieldValue('startTime', maskedText);
              }}
              onBlur={handleBlur('startTime')}
            />
          </RowFlexLine>
        </RowLine>

      </DetailsSectionView>



      <DetailsSectionView>

        <Input.Label
          labelText={`${translate(TranslateConfig.EVENT_DATE_FINAL)} *`}
          labelColor={Colors.primary}
        />


        <RowLine>
          <RowFlexLine>
            <InputText
              disabled={loading}
              type={'DATETIME'}
              options={{
                format: 'DD/MM/YYYY',
              }}
              placeholderText={translate(TranslateConfig.FINAL_DATE)}
              helpText={translate(errors.endDate || '')}
              countLimit={Values.dateCount}
              value={values.endDate}
              onChange={(_rawText: string, maskedText: string) => {
                setFieldValue('endDate', maskedText);
              }}
              onBlur={handleBlur('endDate')}
            />
          </RowFlexLine>


          <ViewSeparator />


          <RowFlexLine>
            <InputText
              disabled={loading}
              type={'DATETIME'}
              options={{
                format: 'HH:mm',
              }}
              placeholderText={translate(TranslateConfig.FINAL_HOUR)}
              helpText={translate(errors.endTime || '')}
              countLimit={Values.hourCount}
              value={values.endTime}
              onChange={(_rawText: string, maskedText: string) => {
                setFieldValue('endTime', maskedText);
              }}
              onBlur={handleBlur('endTime')}
            />
          </RowFlexLine>
        </RowLine>

      </DetailsSectionView>

    </DetailsTopViewItems>

  );
};



export default SectionDateEvent;
