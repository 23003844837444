import React,
{
  memo,
} from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconPhone,
} from '../../../Icons';

import {
  PhoneGuideItemList,
  PhoneButtonInfoView,
  PhoneButtonInfoTitle,
  PhoneButtonInfoSubTitle,
  PhoneButtonInfoIcon,
} from './styled';



export interface IProps {
  title?: any;
  phone?: any;
  onPress?: any;
}



const CardCarnivalGuidePhone: React.FC<IProps> = (props: IProps) => {
  function generateBackgroundColor() {
    const colors = [
      Colors.carnivalBlue,
      Colors.carnivalGreen,
      Colors.carnivalPurple,
      Colors.carnivalRed,
      Colors.carnivalYellow,
    ];
    return colors[Math.floor(Math.random() * colors.length) + 0];
  }



  return (

    <PhoneGuideItemList
      // activeOpacity={Sizes.ButtonActiveOpacity}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <PhoneButtonInfoView>
        <PhoneButtonInfoTitle>
          {props.title || ''}
        </PhoneButtonInfoTitle>

        <PhoneButtonInfoSubTitle>
          {props.phone || ''}
        </PhoneButtonInfoSubTitle>
      </PhoneButtonInfoView>


      <PhoneButtonInfoIcon>
        <IconPhone
          size={24}
          color={generateBackgroundColor()}
        />
      </PhoneButtonInfoIcon>

    </PhoneGuideItemList>

  );
};



export default memo(CardCarnivalGuidePhone);
