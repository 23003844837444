import styled,
{
  css,
} from 'styled-components';

import Color from 'color';

import {
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  fullWidth?: boolean;
  noMargin?: boolean;
  type?: string;
  backgroundColor?: string;
  color?: string;
  size?: number;
}



export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  ${(props: IProps) => !props.noMargin && `
    margin-top: 10px;
    margin-bottom: 15px;
  `};

  padding-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 8px;

  ${(props: IProps) => props.fullWidth && `
    width: 100%
  `};
  
  background-color: ${(props: IProps) => props.backgroundColor};

  border-color: ${(props: IProps) => props.color};
  border-style: solid;
  border-width: 1px;
  
  border-radius: ${Sizes.cardRadius}px;

  elevation: 4;

  shadow-offset: 4px 4px;
  shadow-color: ${(props: IProps) => props.backgroundColor};
  shadow-radius: 4px;
  shadow-opacity: 0.3;
`;



export const ColumnBoxStyle = css`
  flex-direction: column;
  align-items: flex-start;
`;

export const RowBoxStyle = css`
  flex-direction: row;
`;

export const EditWarningView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props: IProps) => (props.type === 'column' || props.type === 'box') && ColumnBoxStyle};
  ${(props: IProps) => props.type === 'row' && RowBoxStyle};
`;

export const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const EditWarningText = styled.p`
  display: flex;
  flex: 1;
  color: ${(props: IProps) => props.color};
  font-size: ${(props: IProps) => props.size ? props.size : 13}px;
`;



export const WarningActionText = styled.p`
  margin-top: 10px;
  color: ${(props: IProps) => Color(props.color).darken(0.4).toString()};
  font-size: ${(props: IProps) => props.size ? props.size - 1.5 : 11.5}px;
  font-weight: bold;
  text-align: center;
`;
