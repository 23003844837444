import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Body,
  Container,
  Screen,
  SubTitle,
  Title,
} from '../../../../../components/Composh/web';



// import { Container } from './styles';



const BuyFinishedScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>
        <Body>
          <Title>
            Compra Finalizada
          </Title>

          <SubTitle>
            event.name
          </SubTitle>
        </Body>
      </Container>

    </Screen>

  );
};



export default BuyFinishedScreen;
