import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../../../components/Composh/web';

import {
  IconCompanyManageOff,
  IconCompanyManageOn,
} from '../../../../components/Icons';

import {
  manageCompanyWhatsApp,
} from '../../../../config/linking.config';

import {
  CategoryChooseContainer,
  VerifiedModalItemView,
  VerifiedModalTitle,
  VerifiedModalTextsView,
  VerifiedModalDescription,
  VerifiedModalMoreButton,
  VerifiedModalMoreText,
} from './styled';



export interface IProps {
  visible?: boolean;
  hasManager: boolean;
  idShort: string;
  name: string;
  onCancelPress?: any;
}



const ManageCompanyModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const hasUserManager = props.hasManager;
  const sizeUserManager = 26;



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <CategoryChooseContainer>

        <VerifiedModalItemView>
          {!hasUserManager
            ? (
              <IconCompanyManageOff
                color={Colors.textApp}
                size={sizeUserManager}
              />
            )
            : (
              <IconCompanyManageOn
                color={Colors.textApp}
                size={sizeUserManager}
              />
            )
          }


          <VerifiedModalTitle>
            {!hasUserManager
              ? translate(TranslateConfig.PROFILE_UNMANAGE)
              : translate(TranslateConfig.PROFILE_MANAGE)
            }
          </VerifiedModalTitle>

        </VerifiedModalItemView>



        <VerifiedModalTextsView>

          <VerifiedModalDescription>
            {!hasUserManager
              ? translate(TranslateConfig.HELP_PROFILE_UNMANAGE)
              : translate(TranslateConfig.HELP_PROFILE_MANAGE)
            }
          </VerifiedModalDescription>


          {!hasUserManager && (
            <VerifiedModalMoreButton
              onClick={() => {
                manageCompanyWhatsApp(props?.idShort, props?.name);
              }}>

              <VerifiedModalMoreText>
                {translate(TranslateConfig.ACTION_PROFILE_MANAGE)}
              </VerifiedModalMoreText>

            </VerifiedModalMoreButton>
          )}

        </VerifiedModalTextsView>



        <ButtonAction
          width={150}
          title={translate(TranslateConfig.ACTION_CLOSE)}
          onPress={() => {
            if (props.onCancelPress) {
              props.onCancelPress();
            }
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default ManageCompanyModal;
