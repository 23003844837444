import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconMathMinus,
  IconMathPlus,
} from '../../Icons';

import {
  CardBagCountView,
  CardBagCountButton,
  CardBagCountText,
} from './styled';



export interface IProps {
  removePress: any;
  quantity: any;
  addPress: any;
}



const ButtonAddCount: React.FC<IProps> = (props: IProps) => {
  return (

    <CardBagCountView>

      <CardBagCountButton
        onClick={props.removePress}>
        <IconMathMinus
          color={Colors.white}
        />
      </CardBagCountButton>


      <CardBagCountText>
        {props.quantity}
      </CardBagCountText>


      <CardBagCountButton
        onClick={props.addPress}>
        <IconMathPlus
          color={Colors.white}
        />
      </CardBagCountButton>

    </CardBagCountView>

  );
};



export default ButtonAddCount;
