import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  TabTutorialContainer,
  TabTutorialImage,
  TabTutorialTitle,
  TabTutorialText,
  TabTutorialButtonView,
} from './styled';



export interface IProps {
  image: string;
  title: string;
  description?: string;
  onPress?: any;
}



const TutorialTabContainer: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <TabTutorialContainer>

      <TabTutorialImage
        src={props.image}
      />


      <TabTutorialTitle>
        {props.title}
      </TabTutorialTitle>


      {props.description && (
        <TabTutorialText>
          {props.description}
        </TabTutorialText>
      )}


      {props.onPress && (
        <TabTutorialButtonView>
          <ButtonAction
            title={translate(TranslateConfig.ACTION_COME_ON)}
            onPress={props.onPress}
          />
        </TabTutorialButtonView>
      )}

    </TabTutorialContainer>

  );
};



export default TutorialTabContainer;
