import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CardTicketContainer,
  CardTicketContainer1,
  CardTicketText,
  CardTicketLot,
  CardTicketSalesUntil,
  CardTicketSalesPrice,
  CardTicketSalesImage,
} from './styled';



interface IProps {
  ticketName: string;
  lot: string;
  type?: string;
  endSale?: any;
  price: any;
  gender: string;
  backgroundColor?: string;

  onPress?: any;
}



const CardBuyTicket: React.FC<IProps> = (props: any) => {
  const { t: translate } = useTranslation();



  // function getImageGenderTicket(gender) {
  //   let image = null;
  //   switch (gender) {
  //     case 'MALE':
  //       image = Images.colorTicketMale;
  //       break;
  //     case 'FEMALE':
  //       image = Images.colorTicketFemale;
  //       break;
  //     default:
  //       image = Images.colorTicketUnissex;
  //   }
  //   return image;
  // }



  return (

    <CardTicketContainer
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginBottom: 5,
        // paddingBottom: 5,
        backgroundColor: props.backgroundColor,
        borderBottomColor: 'gray',
        borderBottomWidth: 1,
      }}
      // activeOpacity={Sizes.CardActiveOpacity}
      onClick={props.onPress}>

      <CardTicketContainer1 style={{
        flex: 1,
        flexDirection: 'column',
      }}>

        <CardTicketText style={{
          color: 'white',
          fontWeight: 'bold',
          fontSize: 17,
        }}>
          {props.ticketName}
        </CardTicketText>


        <CardTicketLot style={{
          color: Colors.textSubtitle,
          marginBottom: 4,
          fontWeight: '700',
          fontSize: 15,
        }}>
          {props.lot} - {props.type}
        </CardTicketLot>

        <CardTicketSalesUntil style={{
          color: Colors.textSubtitle,
          marginBottom: 4,
          fontWeight: '700',
          fontSize: 15,
        }}>
          {/* Vendas até: {format(props.endSale, 'DD/MM/YYYY HH:mm')} */}
          {translate(TranslateConfig.TICKETS_SALES_UNTIL)}: {props.endSale}
        </CardTicketSalesUntil>

        <CardTicketSalesPrice style={{
          color: Colors.primary,
          fontSize: 14,
          fontWeight: '500',
          marginBottom: 6,
        }}>
          {props.price}
        </CardTicketSalesPrice>
      </CardTicketContainer1>


      <CardTicketSalesImage
        style={{
          height: 50,
          width: 50,
          marginTop: 7,
          marginBottom: 7,
        }}
        // source={getImageGenderTicket(props.gender)}
        src={Images.logoIconAppWhite}
      />
    </CardTicketContainer>

  );
};



export default CardBuyTicket;
