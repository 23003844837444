import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  companyServicesArray,
} from '@noitada/shared/arrays/company-services.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  returnIconCompanyServices,
} from '../../../../../config/icon-services.config';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../styles/styled.layout';

import {
  SearchToggle33Grid,
  SearchTogglesContent,
} from './styled';



export interface IProps {
  services: string;
}



const SectionServices: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [servicesCompany, setServicesCompany] = useState<Array<any>>([]);



  function setServicesForms() {
    if (props.services) {
      const splitItem: Array<string> = props.services.split(', ');
      const filteredItem = splitItem.map((value) => {
        return companyServicesArray.find((item) => item.value === value);
      });
      setServicesCompany(filteredItem);
    }
  }


  function renderServicesItem(item: any, index: number) {
    return (

      <SearchToggle33Grid
        key={index}
        icon={returnIconCompanyServices(
          item.value,
          Colors.accent,
          35,
        )}
        iconGridLabel={translate(item.label)}
        iconGridColor={Colors.accent}
      />

    );
  }


  useEffect(() => {
    setServicesForms();
  }, []);



  return servicesCompany?.length !== 0
    ? (

      <DetailsSectionView>
        <SubtitleDetails>
          {translate(TranslateConfig.PARTNER_SERVICES)}
        </SubtitleDetails>


        <SearchTogglesContent>
          {servicesCompany?.map((item: any, index: number) => (
            renderServicesItem(item, index)
          ))}
        </SearchTogglesContent>
      </DetailsSectionView>

    )
    : null;
};



export default SectionServices;
