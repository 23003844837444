const Address = {
  place_name: '',
  country: '',
  zip: '',
  state: '',
  city: '',
  district: '',
  street: '',
  number: '',
  complement: '',
  latitude: null,
  longitude: null,
};



export default Address;
