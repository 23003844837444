import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  size?: number;
}



export const HomeTitleText = styled.h3`
  margin-top: 7px;
  margin-bottom: 7px;
  color: ${Colors.white};
  font-size: ${(props: IProps) => props.size}px;
  line-height: ${(props: IProps) => props.size}px;
  font-weight: bold;
`;
