import {
  Mask,
} from '../components/Composh/plugins';



export function FormatMoney(value: string | number | null | undefined): string {
  if (!value || value === '' || value === null || value === undefined) {
    return '-';
  }

  return Mask.toMask('money', String(value), {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: 'R$ ',
    suffixUnit: '',
  });
};
