import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  BadgeUnconfirmedView,
  BadgeUnconfirmedText,
} from './styled';



const BadgeNotConfirmed: React.FC = () => {
  const { t: translate } = useTranslation();



  return (

    <BadgeUnconfirmedView>

      <BadgeUnconfirmedText>
        {translate(TranslateConfig.NOT_CONFIRMED)}
      </BadgeUnconfirmedText>

    </BadgeUnconfirmedView>

  );
};



export default BadgeNotConfirmed;
