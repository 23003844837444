import React from 'react';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  IconArrowExpand,
  IconArrowExpanded,
} from '../../../Icons';

import {
  CarnivalStageCardStyle,
  CarnivalStageBackgroundImage,
  CarnivalStageView,
  CarnivalStageTitle,
  CarnivalStageDate,
  CarnivalStageButtonView,
  CarnivalStageArrowContainer,
} from './styled';



export interface IProps {
  expanded?: any;
  canExpanded?: any;
  title?: any;
  date?: any;
  description?: any;
  onPress?: any;
}



const CardCarnivalAccordionStage: React.FC<IProps> = (props: IProps) => {
  function renderExpandableIcon() {
    if (props.expanded) {
      return (

        <IconArrowExpanded
          color={Colors.white}
          size={Sizes.iconNavigationBottomSize}
        />

      );
    }


    return (

      <IconArrowExpand
        color={Colors.white}
        size={Sizes.iconNavigationBottomSize}
      />

    );
  }


  function generateBackgroundColor() {
    return '#1866ac';
  }



  return (

    <CarnivalStageCardStyle>
      <CarnivalStageBackgroundImage
        backgroundColor={generateBackgroundColor()}
        borderRadius={Sizes.cardCarnivalRadius}>

        <CarnivalStageView
          onClick={() => {
            if (props.canExpanded && props.onPress) {
              props.onPress();
            }
          }}>
          <CarnivalStageTitle
          // adjustsFontSizeToFit
          >
            {props.title ? props.title.toUpperCase() : '-'}
          </CarnivalStageTitle>

          <CarnivalStageDate
          // adjustsFontSizeToFit
          >
            {props.date || '-'}
          </CarnivalStageDate>
        </CarnivalStageView>


        <CarnivalStageButtonView>

          {props.canExpanded && (
            <CarnivalStageArrowContainer>
              {renderExpandableIcon()}
            </CarnivalStageArrowContainer>
          )}

        </CarnivalStageButtonView>

      </CarnivalStageBackgroundImage>
    </CarnivalStageCardStyle>

  );
};



export default CardCarnivalAccordionStage;
