import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  DetailsCompanyContainer,
  DetailsCompanyContent,
  CompanyDetailsButton,
  DetailsAboutPlaceLogo,
  CompanyTitle,
  CompanyInformationButton,
} from './styled';



export interface IProps {
  name: string;
  image: string;
  onPress?: any;
};



const DetailsCardCompany: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <DetailsCompanyContainer>

      <DetailsCompanyContent>

        <CompanyDetailsButton
          // disabled={!availableCompany}
          // activeOpacity={availableCompany ? Sizes.ButtonActiveOpacity : 1}
          onClick={props.onPress}>

          <DetailsAboutPlaceLogo
            src={props.image === '' || props.image === null || props.image === undefined
              ? Images.placeholderAvatarCompany
              : props.image
            }
          />


          <CompanyTitle>
            {props.name || '-'}
          </CompanyTitle>


          <CompanyInformationButton>
            {translate(TranslateConfig.ACTION_MORE_DETAILS).toUpperCase()}
          </CompanyInformationButton>

        </CompanyDetailsButton>

      </DetailsCompanyContent>

    </DetailsCompanyContainer>

  );
};



export default DetailsCardCompany;
