import React from 'react';

import {
  Images,
} from '@noitada/shared/constants';

import {
  LightEventContainer,
  DashLightEventImage,
} from './styled';



export interface IProps {
  image?: string;
  size?: number;
  marginHorizontal: number;

  onPress?: any;
}



const CardDashEventLight: React.FC<IProps> = (props: any) => {
  const eventImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.placeholderEvent
    : props.image;



  return (

    <LightEventContainer
      size={props.size}
      marginHorizontal={props.marginHorizontal}
      onClick={props.onPress}>

      <DashLightEventImage
        alt={'Event'}
        src={eventImage}
      />

    </LightEventContainer>

  );
};



CardDashEventLight.defaultProps = {
  size: 200,
};



export default CardDashEventLight;
