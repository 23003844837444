import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';



const NotificationsScreen: React.FC = () => {
  const { t: translate } = useTranslation();



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.NOTIFICATIONS)}
          </Title>
        }
      />



      <EmptyContent
        image={Images.noNotifications}
        title={translate(TranslateConfig.EMPTY_NOTIFICATIONS)}
      />

    </Screen>

  );
};



export default NotificationsScreen;
