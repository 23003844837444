import {
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '../../translations';

import Yup from '../base-validation.yup';



const AddPesonValidationSchema = Yup.object().shape({
  emptyPerson: Yup.boolean(),


  usernameCpf: Yup.string()
    .when('emptyPerson', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .test('is-cpf-or-username', TranslateConfig.VALIDATE_USER_CPF_USERNAME,
          (value) => {
            if (/^[0-9]/.test(value)) {
              return Yup.string()
                .required(TranslateConfig.VALIDATE_USER_REQUIRED)
                .cpfValidation(TranslateConfig.VALIDATE_CPF_VALID)
                .min(Values.cpfCount, TranslateConfig.VALIDATE_EXACT_DIGITS.replace('{count}', String(Values.cpfCount)))
                .isValidSync(value);
            }
            else {
              return Yup.string()
                .required(TranslateConfig.VALIDATE_USER_REQUIRED)
                .usernameLetterValidation(TranslateConfig.VALIDATE_USERNAME_CHAR_SPECIAL)
                .min(Values.usernameMinCount, TranslateConfig.VALIDATE_MIN_CHAR.replace('{count}', String(Values.usernameMinCount)))
                .max(Values.usernameMaxCount, TranslateConfig.VALIDATE_MAX_CHAR.replace('{count}', String(Values.usernameMaxCount)))
                .isValidSync(value);
            }
          }),
    }),



  email: Yup.string()
    .when('emptyPerson', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .email(TranslateConfig.VALIDATE_EMAIL_VALID)
        .emailDomainValidation(TranslateConfig.VALIDATE_EMAIL_DOMAIN)
        .min(Values.emailMinCount, TranslateConfig.VALIDATE_MIN_CHAR.replace('{count}', String(Values.emailMinCount)))
        .max(Values.emailMaxCount, TranslateConfig.VALIDATE_MAX_CHAR.replace('{count}', String(Values.emailMaxCount))),
    }),
});



export default AddPesonValidationSchema;
