import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  DetailsSectionView,
  SubtitleDetails,
  SubtitleText,
} from '../../../../../../styles/styled.layout';



export interface IProps {
  attractions?: string;
}



const DetailsAttractions: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <DetailsSectionView>
      <SubtitleDetails>
        {translate(TranslateConfig.EVENT_ATTRACTIONS)}
      </SubtitleDetails>


      <SubtitleText>
        {props.attractions}
      </SubtitleText>
    </DetailsSectionView>

  );
};



export default DetailsAttractions;
