const CompanyOperation = {
  id: '',
  sunday_open: false,
  sunday_hour_open: '',
  sunday_hour_close: '',
  monday_open: false,
  monday_hour_open: '',
  monday_hour_close: '',
  tuesday_open: false,
  tuesday_hour_open: '',
  tuesday_hour_close: '',
  wednesday_open: false,
  wednesday_hour_open: '',
  wednesday_hour_close: '',
  thursday_open: false,
  thursday_hour_open: '',
  thursday_hour_close: '',
  friday_open: false,
  friday_hour_open: '',
  friday_hour_close: '',
  saturday_open: false,
  saturday_hour_open: '',
  saturday_hour_close: '',
};



export default CompanyOperation;
