export enum EEventOcurrence {
  // When event is created
  CREATED = 'CREATED',

  // When event is publish and visible
  PUBLISHED = 'PUBLISHED',

  // When event is unpublished
  UNPUBLISHED = 'UNPUBLISHED',

  // When event starts
  STARTED = 'STARTED',

  // When there is a hard change in the event
  UPDATED = 'UPDATED',

  // When event finishs
  FINISHED = 'FINISHED',

  // Status to invisible event
  CANCELED = 'CANCELED',
  DELETED = 'DELETED',
}


export default EEventOcurrence;
