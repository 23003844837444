import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

const borderRadiusSwitch = 30;



export interface IProps {
  color?: string;
  backgroundColor?: string;
}



export const ButtonMapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  position: absolute;
  bottom: 30px;
`;

export const ButtonMapContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 190px;
  height: 38px;
  padding: 4px;
  background-color: ${Colors.cardBackground};
  border-radius: ${borderRadiusSwitch}px;
`;


export const ButtonMapTouchable = styled.a`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: ${borderRadiusSwitch}px;
`;

export const ButtonMapText = styled.p`
  color: #ffffff;
  font-size: 13px;
`;
