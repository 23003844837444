import React from 'react';

import {
  Images,
} from '@noitada/shared/constants';

import {
  MarkerMapPin,
  MarkerMapContainer,
  MarkerMapImage,
} from './styled';



// export interface IProps {
//   type: any;
//   onPress?: any;
// }



const MapAppMarker = ({ ...props }) => {
  function switchMarkerTypeImage() {
    switch (props.type) {
      case 'EVENT':
        return Images.markerNEvent;

      case 'COMPANY':
        return Images.markerNCompany;

      default:
        return Images.markerNCompany;
    }
  }


  if (props.renderPin) {
    return (

      <MarkerMapPin
        {...props}>

        <MarkerMapImage
          // alt={text}
          src={switchMarkerTypeImage()}
          onClick={props.onPress}
        />

      </MarkerMapPin>

    );
  }
  else {
    return (

      <MarkerMapContainer
        {...props}>

        <MarkerMapImage
          // alt={text}
          src={
            props.image === '' || props.image === null || props.image === undefined
              ? Images.placeholderEvent
              : props.image
          }
          onClick={props.onPress}
        />

      </MarkerMapContainer>

    );
  }
};



export default MapAppMarker;
