import {
  EEventOcurrence,
} from '@noitada/shared/enums';

import NoitadaWS from '../index';



export const GetFutureEvents = async (from: string, localities: any) => {
  const payload = {
    ...localities,
    status: [EEventOcurrence.PUBLISHED, EEventOcurrence.UPDATED, EEventOcurrence.STARTED].join(', '),
    from: from,
  } as any;

  const url = '/events';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetDetailsEventByIdShort = async (idShort: string) => {
  const url = `/events/${idShort}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const GetEventByIdShort = async (from: string, idShort: string) => {
  const payload = {
    status: [EEventOcurrence.PUBLISHED, EEventOcurrence.UPDATED, EEventOcurrence.STARTED].join(', '),
    from: from,
  } as any;

  // if (to) {
  //   payload.to = to;
  // }

  const url = `/events/noitada/company/${idShort}`;

  const response = await NoitadaWS.get(url, {
    params: payload,
  });
  return response.data;
};
