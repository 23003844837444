import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonCityCarnival from '../../../../components/Buttons/ButtonCitiesApp/CityCarnival';

import {
  Container,
  Shimmer,
  ToolbarGroup,
} from '../../../../components/Composh/web';

import {
  ButtonCarnivalGuide,
  ButtonCarnivalMusic,
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import NameRoutes from '../../../../navigation/names';

import {
  ChangeEventTypeButton,
  ChangeEventTypeImage,
} from '../../../../styles/styled.layout';

import {
  HighlightMenuSeparator,
  HighlightsSeparator,
} from './styled';



export interface IProps {
  loading?: boolean;
  hasCarnival?: boolean;
}



const TabNoCarnivalScreen: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const loading = props.loading;
  const hasCarnival = props.hasCarnival;



  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noAttractions}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_NONE)}
      />

    );
  }



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityCarnival />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonCarnivalMusic
              color={Colors.white}
            />

            <ButtonCarnivalGuide
              color={Colors.white}
            />

            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
        middleToolbar={
          <HighlightMenuSeparator>

            <HighlightsSeparator>

              <Shimmer
                height={40}
                width={140}
                opacity={Sizes.shimmerOpacity}
                borderRadius={Sizes.cardRadius}
                visible={!loading}
              />

            </HighlightsSeparator>


            <ChangeEventTypeButton
              onClick={() =>
                history.replace(NameRoutes.GridEventsScreen)
              }>
              <ChangeEventTypeImage
                alt={'change'}
                src={Images.noitadaHomeImageButton}
              />
            </ChangeEventTypeButton>

          </HighlightMenuSeparator>
        }
        noSubToolbarPadding>


        <></>

      </HeaderScreen>


      {loading && renderLoading()}

      {!loading && !hasCarnival && listEmptyComponent()}

    </Container>

  );
};



export default TabNoCarnivalScreen;
