import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useLocation,
} from 'react-router-dom';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  ETokenAsync,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Body,
  Screen,
} from '../../components/Composh/web';

import {
  ButtonBack,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import FooterChoose from '../../components/Footers/FooterChoose';
import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  openSite,
} from '../../config/linking.config';

import {
  ButtonText,
} from '../../styles/styled.layout';

import {
  WebViewTitle,
  WebViewSubTitle,
  WebViewComponent,
  WebViewContainer,
} from './styled';



export interface IRouteParams {
  title: string;
  link: string;
  action?: string;
  paramCustom?: string;
}



const WebViewScreen: React.FC = () => {
  const { t: translate } = useTranslation();

  const routeLocation = useLocation<IRouteParams>();

  const params = routeLocation?.state;
  const token = localStorage.getItem(ETokenAsync.USER_TOKEN);

  const title = params.title;
  const link = params.link;
  const action = params.action || null;
  const paramCustom = params.paramCustom || '';

  const commonParams = `?toolbar=false&token=${token}${paramCustom}`;



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <WebViewTitle
              color={Colors.white}>
              {title}
            </WebViewTitle>

            <WebViewSubTitle
              color={Colors.white}>
              {link}
            </WebViewSubTitle>
          </Body>
        }
      />



      {(link === '' || link === '-' || link === null || link === undefined) && (
        <EmptyContent
          image={Images.logoIconAppWhite}
          title={translate(TranslateConfig.EMPTY_LINK_SEE)}
        />
      )}



      {(link !== '' && link !== '-' && link !== null && link !== undefined) && (
        <WebViewContainer>
          <WebViewComponent
            src={`${link}${commonParams}`}
          />
        </WebViewContainer>
      )}



      {action && (
        <FooterChoose
          hideCancelButton

          okColor={ArrayColors.arrayOk}
          okPress={() => {
            openSite(link);
          }}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {action}
            </ButtonText>
          }
        />
      )}

    </Screen>

  );
};



export default WebViewScreen;
