import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Content,
  ListFlat,
} from '../../../../components/Composh/web';



export interface IProps {
  backgroundColor?: string;
}



// Phones Screen Styles

export const PhoneGuideHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  border-bottom-color: ${Colors.menuSeparator};
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
`;

export const PhoneGuideTitle = styled.p`
  margin-top: 20px;
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 25px;
  font-weight: bold;
`;

export const PhoneGuideSearchItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PhoneGuideClearButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;


export const FilteredListSection = styled(Content)`
  padding-top: 5px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 6px;
`;

export const PhoneGuideFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



// Guides Screen Styles

export const InfoGuideHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  padding-right: 13px;
  margin-bottom: 12px;
`;

export const InfoGuideTitle = styled.p`
  margin-top: 15px;
  margin-bottom: 20px;
  color: ${Colors.white};
  font-size: 25px;
  font-weight: bold;
`;

export const InfoGuideSubtitle = styled.p`
  width: 100%;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: gray;
  font-size: 14px;
  text-align: center;
`;



export const InfoGuideFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 5,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const InfoItemsTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
