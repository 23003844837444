import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserCreditCard,
} from '@noitada/axios-config/endpoints/user.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  ActionSheet,
} from '../../../../../components/Composh/web';

import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../../navigation/names';

import {
  CloseOrderContent,
  CloseOrderTitle,
  CloseOrderContainer,
  CloseOrderButton,
  ListImageContainer,
  ListItemImage,
  ListCompanyImage,
  CloseOrderView,
  CloseOrderLabel,
  CloseOrderSubTitle,
} from './styled';



export interface IProps {
  visible?: boolean;
  company: any;
  onOpen?: any;
  onClose?: any;
}



const CloseOrderActionSheet: React.FC<IProps> = (props: IProps) => {
  if (!props.visible) {
    return null;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const [mounted, setMounted] = useState(false);
  const [havePayments, setHavePayments] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getPaymentsType() {
    try {
      const data = await GetUserCreditCard();

      if (!data || data?.length === 0) {
        return;
      }

      setHavePayments(true);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setMounted(true);
    }
  }



  useEffect(() => {
    getPaymentsType();
  }, []);



  return (

    <ActionSheet
      visible={props.visible}
      overlayColor={Colors.menuOverlay}
      backgroundColor={Colors.cardBackground}
      // customStyles={ActionSheetStyle}
      onOpen={props.onOpen}
      onClose={props.onClose}
      height={220}
      closeOnDragDown
      closeOnPressMask>


      {!mounted && (
        <LoadingScreen
          type={'SCREEN'}
          color={Colors.primary}
        />
      )}



      {mounted && (
        <CloseOrderContent>

          <CloseOrderTitle>
            {(translate(TranslateConfig.PAYMENT_OPTIONS)).toUpperCase()}
          </CloseOrderTitle>


          <CloseOrderContainer>

            <CloseOrderButton
              onClick={() => {
                if (havePayments) {
                  history.push(NameRoutes.OrderSheetPayAppScreen, {
                    payApp: true,
                  });
                  props.onClose();
                }
              }}>


              <ListImageContainer>
                <ListItemImage
                  alt={'cover'}
                  src={Images.ordersheetPayApp}
                />
              </ListImageContainer>


              <CloseOrderView>
                <CloseOrderLabel
                  active={havePayments}>
                  {havePayments
                    ? translate(TranslateConfig.ACTION_PAY_APP)
                    : translate(TranslateConfig.HELP_PAY_UNAVAILABLE)
                  }
                </CloseOrderLabel>

                <CloseOrderSubTitle>
                  {translate(TranslateConfig.PAY_BY_APP)}
                </CloseOrderSubTitle>
              </CloseOrderView>

            </CloseOrderButton>



            <CloseOrderButton
              onClick={() => {
                history.push(NameRoutes.OrderSheetPayAppScreen, {
                  payApp: false,
                });
                props.onClose();
              }}>


              <ListImageContainer>
                <ListCompanyImage
                  alt={'cover'}
                  src={props.company?.picture === '' || props.company?.picture === null || props.company?.picture === undefined
                    ? Images.placeholderAvatarCompany
                    : props.company?.picture
                  }
                />
              </ListImageContainer>


              <CloseOrderView>
                <CloseOrderLabel
                  active>
                  {translate(TranslateConfig.PAY_PRESENTIAL)}
                </CloseOrderLabel>

                <CloseOrderSubTitle>
                  {`${translate(TranslateConfig.PAY_TO)} ${props.company?.name}`}
                </CloseOrderSubTitle>
              </CloseOrderView>

            </CloseOrderButton>

          </CloseOrderContainer>

        </CloseOrderContent>
      )}

    </ActionSheet>

  );
};



export default CloseOrderActionSheet;
