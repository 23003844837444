import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ContentPadder,
} from '../../../../styles/styled.layout';



export const MoreEventsContainer = styled(ContentPadder)`
  display: flex;
`;


export const MoreEventsTitle = styled.h3`
  margin-top: 10px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.white};
`;

export const MoreEventsFlatList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
`;
