import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  active?: boolean;
}



export const CardMapEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  bottom: 85px;
  left: 10px;
  right: 10px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const CardMapEventBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CardMapEventOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.menuOverlay};
`;



export const CardMapEventContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  z-index: 5;
`;



export const CardMapEventImageButton = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 43%;
  aspect-ratio: 1;
  border-radius: ${Sizes.cardRadius}px;
`;


export const CardMapEventImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const CardMapEventInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  margin-left: 10px;
`;

export const CardMapEventInfoButton = styled.a`
  display: flex;
  flex-direction: column;
`;

export const CardMapEventDateText = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
`;

export const CardMapEventInfoText = styled.p`
  color: #B4B4B4;
  font-size: 12px;
  font-style: italic;
  text-align: right;
`;


export const CardMapTitleInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const CardMapEventNameText = styled.p`
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
`;

export const CardMapEventPlaceText = styled.p`
  margin-top: 2px;
  color: #B4B4B4;
  font-size: 12px;
  text-align: right;
`;



export const CardMapEventPlaceView = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const CardMapPlaceImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const CardMapEventNamePlaceText = styled.p`
  display: flex;
  flex: 1;
  margin-right: 7px;
  color: #B4B4B4;
  text-align: right;
`;



export const CardMapEventsButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 5px 8px;
  z-index: 5;
`;

export const CardMapEventSeeButton = styled.a`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  height: 32px;
  margin-left: 6px;
  margin-right: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: ${(props: IProps) => props.active ? Colors.primary : Colors.gray};
  border-radius: ${Sizes.cardRadius}px;
`;

export const CardMapEventSeeText = styled.p`
  color: #ffffff;
  font-size: 14px;
`;

export const CardMapCloseEventButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${Colors.alertOverlay};
  border-radius: ${Sizes.cardRadius}px;
`;
