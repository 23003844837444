import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Epigraph,
} from '../../../../../../components/Composh/web';

import InputText from '../../../../../../components/Inputs/InputText';

import {
  DetailsSectionView,
  DetailsTopViewItems,
  RowFlexLine,
  RowLine,
  ViewSeparator,
} from '../../../../../../styles/styled.layout';



export interface IProps {
  loading?: any;
  errors: any;
  values: any;
  setFieldValue: any;
  handleBlur: any;
}



const SectionTicketValues: React.FC<IProps> = (props) => {
  const { t: translate } = useTranslation();


  const loading = props.loading;
  const errors = props.errors;
  const values = props.values;
  const setFieldValue = props.setFieldValue;
  const handleBlur = props.handleBlur;



  return (

    <DetailsTopViewItems>

      <Epigraph.Section
        text={translate(TranslateConfig.TICKET_ENTRANCE_PRICE)}
        textColor={Colors.primary}
        borderBottomColor={Colors.primary}
      />


      <DetailsSectionView>

        <RowLine>
          <RowFlexLine>
            <InputText
              disabled={loading}
              type={'MONEY'}
              options={{
                precision: 2,
                separator: ',',
                delimiter: '.',
                unit: 'R$',
                suffixUnit: '',
              }}
              placeholderText={translate(TranslateConfig.INITIAL_VALUE)}
              helpText={translate(errors.ticketStart || '')}
              countLimit={Values.priceValue}
              value={values.ticketStart}
              onChange={(rawText: string) => {
                setFieldValue('ticketStart', rawText);
              }}
              onBlur={handleBlur('ticketStart')}
            />
          </RowFlexLine>


          <ViewSeparator />


          <RowFlexLine>
            <InputText
              disabled={loading}
              type={'MONEY'}
              options={{
                precision: 2,
                separator: ',',
                delimiter: '.',
                unit: 'R$',
                suffixUnit: '',
              }}
              placeholderText={translate(TranslateConfig.FINAL_VALUE)}
              helpText={translate(errors.ticketEnd || '')}
              countLimit={Values.priceValue}
              value={values.ticketEnd}
              onChange={(rawText: string) => {
                setFieldValue('ticketEnd', rawText);
              }}
              onBlur={handleBlur('ticketEnd')}
            />
          </RowFlexLine>
        </RowLine>

      </DetailsSectionView>

    </DetailsTopViewItems>

  );
};



export default SectionTicketValues;
