import EDrinks from '../enums/Items/drinks.enum';

import {
  TranslateConfig,
} from './../translations/index';



export const companyDrinksArray = [
  {
    label: TranslateConfig.WATER,
    value: EDrinks.WATER,
  },
  {
    label: TranslateConfig.SODA,
    value: EDrinks.SODA,
  },
  {
    label: TranslateConfig.VODKA,
    value: EDrinks.VODKA,
  },
  {
    label: TranslateConfig.BEER,
    value: EDrinks.BEER,
  },
  {
    label: TranslateConfig.WINE,
    value: EDrinks.WINE,
  },
  {
    label: TranslateConfig.TEA,
    value: EDrinks.TEA,
  },
  {
    label: TranslateConfig.COFFEE,
    value: EDrinks.COFFEE,
  },
  {
    label: TranslateConfig.JUICE,
    value: EDrinks.JUICE,
  },
  {
    label: TranslateConfig.WHISKY,
    value: EDrinks.WHISKY,
  },
  {
    label: TranslateConfig.LIQUOR,
    value: EDrinks.LIQUOR,
  },
  {
    label: TranslateConfig.COCKTAIL,
    value: EDrinks.COCKTAIL,
  },
  {
    label: TranslateConfig.CHAMPAGNE,
    value: EDrinks.CHAMPAGNE,
  },
  {
    label: TranslateConfig.GIN,
    value: EDrinks.GIN,
  },
  {
    label: TranslateConfig.RUM,
    value: EDrinks.RUM,
  },
  {
    label: TranslateConfig.ENERGY_DRINK,
    value: EDrinks.ENERGY_DRINK,
  },
  {
    label: TranslateConfig.SMOOTHIE,
    value: EDrinks.SMOOTHIE,
  },
  {
    label: TranslateConfig.YERBA_MATE,
    value: EDrinks.YERBA_MATE,
  },
  {
    label: TranslateConfig.SAKE,
    value: EDrinks.SAKE,
  },
  {
    label: TranslateConfig.TEQUILA,
    value: EDrinks.TEQUILA,
  },
];
