import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';


export const ListButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 18px;
  padding-right: 18px;
`;


export const ListCarnivalIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-left: -5px;
  margin-right: 12px;
`;


export const ListItemRoute = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ListLabel = styled.p`
  font-size: 14px;
  color: ${Colors.white};
  text-align: left;
`;

export const ListSubtitle = styled.p`
  font-size: 12px;
  color: ${Colors.textSubtitle};
  text-align: left;
`;
