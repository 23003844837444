import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconCheck,
} from '../../Icons';

import {
  CardLanguageContainer,
  CardLanguageImage,
  CardLanguageInfoView,
  CardLanguageTitle,
  CardLanguageSubTitle,
} from './styled';



export interface IProps {
  selected?: boolean;
  title: string;
  languageIso: string;
  image: string;
  onPress?: any;
}



const CardLanguage: React.FC<IProps> = (props: IProps) => {
  return (

    <CardLanguageContainer
      onClick={props.onPress}>

      <CardLanguageImage
        src={props.image}
      />



      <CardLanguageInfoView>

        <CardLanguageTitle>
          {props.title || '-'}
        </CardLanguageTitle>

        <CardLanguageSubTitle>
          {props.languageIso || '-'}
        </CardLanguageSubTitle>

      </CardLanguageInfoView>



      {props.selected && (
        <IconCheck
          color={Colors.accent}
          size={22}
        />
      )}

    </CardLanguageContainer>

  );
};



export default CardLanguage;
