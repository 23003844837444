import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ValidateAddressCompany,
} from '@noitada/shared/utils/company.utils';

import {
  validateNumber,
  validateString,
} from '@noitada/shared/utils/string.utils';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';
import ButtonSocial from '../../../../../components/Buttons/ButtonSocial';

import {
  Overlay,
} from '../../../../../components/Composh/web';

import {
  IconClose,
  IconEventStart,
} from '../../../../../components/Icons';

import {
  openUber,
  openWaze,
} from '../../../../../config/linking.config';

import {
  ModalView,
  TicketInfoContent,
  LinerTearTop,
  InfoContent,
  TitleModal,
  CloseModalButton,
  TicketsIdView,
  IdTicketText,
  NameTicketBoldText,
  EventNameText,
  EventDirectionsPlaceContent,
  DateEventStatusView,
  DateEventText,
  TicketSeparator,
  PlaceNameText,
  GiveDirectionsButton,
  LinerTearBottom,
  TicketItemsContent,
  ItemsContent,
  UsernameText,
  TicketTypeText,
  DetailsText,
  InfoTicketView,
} from './styled';



export interface IProps {
  visible?: boolean;
  address: any;
  onCancelPress?: any;
}



const TicketDetails: React.FC<IProps> = (props: IProps) => {
  const modalTopHeight = 560;
  const widthLine = 310;

  const addressValid = ValidateAddressCompany(
    props.address?.street,
    props.address?.district,
    props.address?.city,
    props.address?.state,
    props.address?.zip,
    props.address?.country,
  );

  const placeName = 'Nome do local com uma linha';



  return (

    <Overlay
      visible={props.visible}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <ModalView
        style={{
          width: widthLine + 25,
        }}>


        <TicketInfoContent
          // activeOpacity={1}
          style={{
            // widht: widthLine,
            height: modalTopHeight,
          }}>

          <LinerTearTop
            color={'white'}
            tearSize={18}
            width={widthLine}
          />


          <InfoContent>

            <TitleModal>
              Detalhes
            </TitleModal>


            <CloseModalButton
              // activeOpacity={Sizes.ButtonActiveOpacity}
              onClick={props.onCancelPress}>
              <IconClose
                color={Colors.black}
              />
            </CloseModalButton>



            <TicketsIdView>
              <IdTicketText>
                Ingresso ID: #{'452115541'}
              </IdTicketText>

              <NameTicketBoldText>
                Nome do Evento com duas linhas do evento
              </NameTicketBoldText>


              <EventNameText>
                {placeName}
              </EventNameText>

            </TicketsIdView>



            <DateEventStatusView>
              <IconEventStart
                style={{
                  marginRight: 10,
                }}
                size={17}
                color={Colors.textDescription}
              />

              <DateEventText>
                {`Início em: ${'22/08/2020 - 21:00'}`}
              </DateEventText>
            </DateEventStatusView>



            <InfoTicketView>

              <TicketSeparator
                height={1.5}
                width={250}
                color={Colors.menuSeparator}
              />


              <PlaceNameText>
                Compra do ingresso
              </PlaceNameText>

              <DetailsText>
                27 de fevereiro de 2019, 22:08
              </DetailsText>
            </InfoTicketView>



            <InfoTicketView>
              <PlaceNameText>
                Comprado por
              </PlaceNameText>

              <DetailsText>
                @username
              </DetailsText>

              <DetailsText>
                Nome social
              </DetailsText>
            </InfoTicketView>



            <InfoTicketView>
              <DetailsText>
                Acesso: Entrada A, Portão 3, Fileira 12, assento 44
              </DetailsText>

              <PlaceNameText>
                Nome do ingresso
              </PlaceNameText>
            </InfoTicketView>



            <EventDirectionsPlaceContent>

              <GiveDirectionsButton>
                <ButtonSocial
                  type={'WAZE'}
                  disabled={addressValid}
                  onPress={() => {
                    openWaze(
                      validateNumber(props.address?.latitude),
                      validateNumber(props.address?.longitude),
                    );
                  }}
                />


                <ButtonSocial
                  type={'UBER'}
                  disabled={addressValid}
                  onPress={() => {
                    openUber(
                      validateString(placeName),
                      validateNumber(props.address?.latitude),
                      validateNumber(props.address?.longitude),
                      validateString(props.address?.street),
                      validateString(props.address?.number),
                      validateString(props.address?.district),
                    );
                  }}
                />
              </GiveDirectionsButton>



              <ButtonAction
                title='Trocar titularidade'
              />
            </EventDirectionsPlaceContent>

          </InfoContent>


          <LinerTearBottom
            color={Colors.cardBackground}
            tearSize={18}
            width={widthLine}
          />

        </TicketInfoContent>



        <TicketItemsContent>

          <ItemsContent>
            <UsernameText>
              Evento Id: #{'AASG51'}
            </UsernameText>


            <TicketTypeText>
              Ver evento
            </TicketTypeText>
          </ItemsContent>


          <LinerTearBottom
            color={Colors.cardBackground}
            tearSize={18}
            width={widthLine}
          />

        </TicketItemsContent>


      </ModalView>

    </Overlay>

  );
};



export default TicketDetails;
