import React from 'react';

import {
  AppBar,
  Toolbar,
  SubToolbar,
} from '../../Composh/web';



export interface IProps {
  transparent?: boolean;
  absolute?: boolean;

  leftIcon?: any;
  rightIcon?: any;
  centerContent?: any;

  backgroundColor?: string;
  borderBottomColor?: string;
  subBackgroundColor?: string;

  noSubToolbarPadding?: boolean;

  middleToolbar?: any;
  children?: any;
}



const HeaderScreen: React.FC<IProps> = (props: IProps) => {
  return (

    <AppBar
      transparent={props.transparent}
      backgroundColor={props.transparent ? 'transparent' : props.backgroundColor}
      absolute={props.absolute}
      borderBottomColor={props.borderBottomColor}>

      {(props.leftIcon || props.centerContent || props.rightIcon) && (
        <Toolbar
          backgroundColor={props.transparent ? 'transparent' : props.backgroundColor}
          leftIcon={props.leftIcon}
          centerContent={props.centerContent}
          rightIcon={props.rightIcon}
        />
      )}


      {props.middleToolbar && (
        <SubToolbar
          backgroundColor={props.transparent ? 'transparent' : props.subBackgroundColor}
          noSubToolbarPadding={props.noSubToolbarPadding}>
          {props.middleToolbar}
        </SubToolbar>
      )}


      {props.children && (
        <SubToolbar
          backgroundColor={props.transparent ? 'transparent' : props.subBackgroundColor}
          noSubToolbarPadding={props.noSubToolbarPadding}>
          {props.children}
        </SubToolbar>
      )}

    </AppBar>

  );
};



export default HeaderScreen;
