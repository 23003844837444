import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  selected?: boolean;
}



export const TutorialSkipButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
`;

export const TutorialSkipTextButton = styled.p`
  color: ${Colors.accent};
  font-size: 16px;
  text-align: center;
`;



export const TutorialBottomToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${Sizes.BOTTOM_TOOLBAR_ANDROID}px;
  background-color: ${Colors.navigationBottomBackground};
  padding-left: 15px;
  padding-right: 15px;
`;

export const TutorialBottomLeft = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
`;

export const TutorialBottomTextLeft = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
  text-align: left;
`;


export const TutorialBottomCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 2;
  margin-left: 10px;
  margin-right: 10px;
`;

export const TutorialBottomDots = styled.div`
  display: flex;
  flex-direction: column;
  width: 8px;
  height: 8px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: ${(props: IProps) => props.selected ? Colors.accent : Colors.tableBackground};
  border-radius: 50%;
`;


export const TutorialBottomRight = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
`;

export const TutorialBottomTextRight = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
  text-align: right;
`;
