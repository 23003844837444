import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const PaymentChooseContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;



export const PaymentInfoChooseView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PaymentChooseTitle = styled.h1`
  margin-bottom: 4px;
  color: ${Colors.textApp};
  font-size: 14px;
  font-weight: 400;
`;

export const PaymentChooseCardNumber = styled.h2`
  color: ${Colors.white};
  font-size: 19px;
  font-weight: bold;
  line-height: 24px;
`;

export const PaymentChooseSubtitle = styled.p`
  margin-top: 4px;
  color: gray;
  font-size: 13px;
  line-height: 16px;
`;



export const PaymentChooseImage = styled.img`
  width: 65px;
  height: 44px;
  object-fit: contain;
  border-radius: ${Sizes.cardRadius}px;
`;
