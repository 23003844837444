import styled from 'styled-components';

import {
  Epigraph,
} from '../../../components/Composh/web';



export const LocationListHeader = styled(Epigraph.Header)`
  display: flex;
  flex-direction: row;
  height: 50px;
`;
