import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const PhoneGuideItemList = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 68px;
  min-height: 68px;
  margin-top: 6px;
  margin-left: 4px;
  margin-right: 6px;
  margin-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 6px;
  border-bottom-color: ${Colors.menuSeparator};
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
`;

export const PhoneButtonInfoView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const PhoneButtonInfoTitle = styled.p`
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 15px;
`;

export const PhoneButtonInfoSubTitle = styled.p`
  color: ${Colors.gray};
  font-size: 12px;
`;

export const PhoneButtonInfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 55px;
`;
