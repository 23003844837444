import React from 'react';

import {
  IconInformation,
} from '../../Icons';

import {
  WarningContainer,
  EditWarningView,
  InfoIcon,
  EditWarningText,
  WarningActionText,
} from './styled';



export interface IProps {
  type?: 'column' | 'row';
  fullWidth?: boolean;
  noMargin?: boolean;

  text: string;
  subtext?: string;

  icon?: any;
  iconSize?: number;

  backgroundColor?: string;
  color?: string;
  size?: number;

  onPress?: any;
}



const WarningItemContent: React.FC<IProps> = (props: IProps) => {
  const className: any = { ...props };

  const sizeNumber = props.size || 13;



  function renderIcon() {
    if (props.icon) {
      return props.icon;
    }


    return (

      <InfoIcon>
        <IconInformation
          size={props.iconSize || props.type === 'column' ? sizeNumber * 2 : sizeNumber * 2.4}
          color={props.color}
        />
      </InfoIcon>

    );
  };



  return (

    <WarningContainer
      noMargin={props.noMargin}
      fullWidth={props.fullWidth}
      backgroundColor={props.backgroundColor}
      color={props.color}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <EditWarningView
        className={className?.className}
        type={props.type}>


        {renderIcon()}


        <EditWarningText
          color={props.color}
          size={props.size}>
          {props.text}
        </EditWarningText>

      </EditWarningView>



      <WarningActionText
        color={props.color}
        size={props.size}>
        {props.subtext}
      </WarningActionText>

    </WarningContainer>

  );
};



WarningItemContent.defaultProps = {
  type: 'row',
  color: '#a15e1b',
  backgroundColor: '#F0E6DB',
};



export default WarningItemContent;
