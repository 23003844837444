export enum EMusic {
  ELECTRONIC = 'ELECTRONIC',
  FUNK = 'FUNK',
  COUNTRY_MUSIC = 'COUNTRY_MUSIC',
  POP = 'POP',
  RAP = 'RAP',
  ROCK = 'ROCK',
  MPB = 'MPB',
  AXE = 'AXE',
  SAMBA = 'SAMBA',
  REGGAE = 'REGGAE',
  FORRO = 'FORRO',
  GOSPEL = 'GOSPEL',
  HIP_HOP = 'HIP_HOP',
  JAZZ = 'JAZZ',
  CLASSIC_MUSIC = 'CLASSIC_MUSIC',
  PAGODE = 'PAGODE',
  BLUES = 'BLUES',
}



export default EMusic;
