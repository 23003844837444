import {
  ECompanyServices,
} from '../enums';

import {
  TranslateConfig,
} from '../translations';



export const companyServicesArray = [
  {
    label: TranslateConfig.WIFI,
    value: ECompanyServices.WIFI,
  },
  {
    label: TranslateConfig.KIDS_AREA,
    value: ECompanyServices.KIDS_AREA,
  },
  {
    label: TranslateConfig.PARKING,
    value: ECompanyServices.PARKING,
  },
  {
    label: TranslateConfig.SMOKING,
    value: ECompanyServices.SMOKING,
  },
  {
    label: TranslateConfig.AIR_CONDITIONER,
    value: ECompanyServices.AIR_CONDITIONER,
  },
  {
    label: TranslateConfig.ACCESSIBILITY,
    value: ECompanyServices.ACCESSIBILITY,
  },
];
