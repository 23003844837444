export enum EDrawerMenu {
  HOME = 'HOME',
  FAVORITES = 'FAVORITES',

  PROFILE = 'PROFILE',

  TICKETS = 'TICKETS',
  LISTS = 'LISTS',
  REQUESTS = 'REQUESTS',
  RESERVES = 'RESERVES',

  HELP = 'HELP',

  EXIT = 'EXIT',
}



export default EDrawerMenu;
