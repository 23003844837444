import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  GetUserCurrentCheckin,
} from '@noitada/axios-config/endpoints/comanda.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECompanyModality,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import DetailsAddress from '../../../../components/Address';
import CardReportOrderDetail from '../../../../components/Cards/CardReportOrderDetail';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import WarningItemContent from '../../../../components/Contents/WarningItem';

import {
  ButtonBack,
} from '../../../../components/Controls';

import DetailsCompanyRealization from '../../../../components/Details/DetailsCompanyRealization';
import DetailsPayments from '../../../../components/Details/DetailsPayments';
import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PaymentChoose from '../../../../components/Payments/PaymentChoose';

import {
  renderTypeTextModalities,
  returnIconCompanyModalities,
} from '../../../../config/icon-modalities.config';

import {
  openWhatsApp,
} from '../../../../config/linking.config';

import NameRoutes from '../../../../navigation/names';

import {
  ButtonText,
  DetailsSectionView,
  SubtitleDetails,
  SubtitleText,
} from '../../../../styles/styled.layout';

import {
  OrderContentPadder,
  OrderItemComponent,
  CardOrderPaymentView,
  CardOrderPaymentChoose,
} from './styled';



export interface IRouteParams {
  order_id: string;
}



const UserDetailsOrderScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const {
    order_id,
  } = useParams() as IRouteParams;


  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState<any>(null);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getOrderDetailbyId() {
    try {
      setLoading(true);

      const response = await GetUserCurrentCheckin(order_id);
      if (response) {
        setOrder(response);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function renderTicketBuy(item: any, index: number) {
    return (

      <CardReportOrderDetail
        key={index}
        status={item?.status}
        saleName={item?.saleName}
        saleCategory={item?.saleCategory}  /* TODO LUAN -> COLOCAR NOME DA CATEGORIA DO PRODUTO */
        quantity={item?.quantity}
        observations={item?.observations}
        price={item?.price}
      />

    );
  }


  function openHelpOrder() {
    openWhatsApp();
  }



  useEffect(() => {
    getOrderDetailbyId();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          centerContent={
            <Title>
              Detalhe do pedido
            </Title>
          }
        />



        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {!loading && (
          <Content>

            <OrderContentPadder>

              <DetailsCompanyRealization
                name={order?.company?.name || ''}
                image={order?.company?.picture || ''}
                onPress={() => {
                  history.push({
                    pathname: NameRoutes.DetailsCompanyScreen.replace(
                      ':company_username',
                      order?.company?.idShort,
                    ),
                  });
                }}
              />



              <DetailsSectionView>
                <OrderItemComponent
                  iconLeft={returnIconCompanyModalities(ECompanyModality.ORDERSHEET,
                    Colors.white,
                    30,
                  )}
                  subtitle={
                    <SubtitleDetails>
                      {translate(renderTypeTextModalities(ECompanyModality.ORDERSHEET))}
                    </SubtitleDetails>
                  }
                  subtitleColor={Colors.white}
                />


                <SubtitleDetails>
                  ID: #{order?.id}
                </SubtitleDetails>


                <SubtitleText>
                  {`Realizado às ${order && new Intl.DateTimeFormat('pt-BR', { hour: '2-digit', minute: '2-digit' }).format(new Date((order?.checkin)))}`}
                </SubtitleText>


                <SubtitleText>
                  {order && `${new Intl.DateTimeFormat('pt-BR', { weekday: 'long', timeZone: 'America/Sao_Paulo' }).format(new Date(order?.completedAt))}, ${new Intl.DateTimeFormat('pt-BR', { dateStyle: 'long', timeZone: 'America/Sao_Paulo' }).format(new Date(order?.completedAt))}`}
                </SubtitleText>
              </DetailsSectionView>



              {order && (
                <DetailsSectionView>
                  <WarningItemContent
                    text={`Pedido finalizado às ${order && new Intl.DateTimeFormat('pt-BR', { hour: '2-digit', minute: '2-digit' }).format(new Date((order?.completedAt)))}`}
                    backgroundColor={Colors.buttonSolid}
                    color={Colors.primary}
                  />
                </DetailsSectionView>
              )}



              <DetailsSectionView>
                {order && order?.orders?.map((item: any, index: number) => (
                  renderTicketBuy(item, index)
                ))}
              </DetailsSectionView>

            </OrderContentPadder>



            <DetailsAddress
              noWaze
              noUber
              companyName={''}
              address={null}
            />


            {order && order?.payment &&
              <CardOrderPaymentView>
                <DetailsPayments
                  price={parseFloat(order?.totalConsumed)}
                  delivery={0}
                  total={parseFloat(order?.payment?.totalPrice)}
                  discount={(parseFloat(order?.totalConsumed) - parseFloat(order?.payment?.totalPrice))}
                  modality={order?.payment.pagarme_transaction_id === '0' || !order?.payment.pagarme_transaction_id ? 'IN_PLACE' : 'ONLINE'}
                />

                {order?.payment.pagarme_transaction_id !== '0' && order?.payment.pagarme_transaction_id &&
                  <CardOrderPaymentChoose>
                    <PaymentChoose
                      noChangeCard
                      cardBrand={order?.payment?.card_user?.card_brand || 'visa'}
                      last4={order?.payment?.card_user?.last4 || 'XXXX'}
                    />
                  </CardOrderPaymentChoose>
                }
              </CardOrderPaymentView>
            }
          </Content>
        )}



        <FooterChoose
          hideCancelButton

          okDisabled={true}
          okColor={true
            ? ArrayColors.arrayDisabled
            : ArrayColors.arrayOk
          }
          okLabel={
            <ButtonText
              color={Colors.white}>
              Ajuda
            </ButtonText>
          }
          okPress={() => {
            openHelpOrder();
          }}
        />

      </Container>

    </Screen>

  );
};



export default UserDetailsOrderScreen;
