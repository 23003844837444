import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import Event from '@noitada/axios-config/models/Event';


const INITIAL_STATE = {
  AllEvents: [],
  selected: Event,
};



// Action Types

export const USER_SET_ALL_EVENT = '@user/SET_ALL_EVENT';
export const USER_SET_CURRENT_EVENT = '@user/SET_CURRENT_EVENT';
export const USER_RESET_EVENT = '@user/RESET_EVENT';



// Action Creators

export const setAllEventsAction = createAction<object>(USER_SET_ALL_EVENT);
export const setCurrentEventsAction = createAction<object>(USER_SET_CURRENT_EVENT);
export const resetEventsAction = createAction<object>(USER_RESET_EVENT);



// Handlers

const setAllEvents = (state = INITIAL_STATE, action) => ({
  ...state,
  AllEvents: action.payload,
});


const setCurrentCompany = (state = INITIAL_STATE, action) => ({
  ...state,
  selected: action.payload,
});


// const addCompanyEvents = (state = INITIAL_STATE, action) => {
//   const { list } = action;
//   const newState = state;
//   switch (list) {
//     case 'UPCOMING_EVENTS':
//       newState.UPCOMING_EVENTS = [...state.UPCOMING_EVENTS, ...action.events];
//       break;
//     case 'PAST_EVENTS':
//       newState.PAST_EVENTS = [...state.PAST_EVENTS, ...action.events];
//       break;
//     default:
//       newState.FIXED_EVENTS = [...state.FIXED_EVENTS, ...action.events];
//       break;
//   }

//   return { ...newState };
// };


const refreshEvents = (state = INITIAL_STATE, action) => {
  const { list } = action;
  const newState = state;
  /* TODO 
  switch (list) {
    case 'UPCOMING_EVENTS':
      newState.UPCOMING_EVENTS = [...action.events];
      break;
    case 'PAST_EVENTS':
      newState.PAST_EVENTS = [...action.events];
      break;
    default:
      newState.FIXED_EVENTS = [...action.events];
      break;
  }
  */

  return { ...newState };
};


const resetState = () => ({
  ...INITIAL_STATE,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [setAllEventsAction.type]: setAllEvents,
  [setCurrentEventsAction.type]: setCurrentCompany,
  [resetEventsAction.type]: resetState,
});
