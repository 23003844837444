import React from 'react';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../components/Composh/web';

import {
  ModalOkContainer,
  ModalOkImage,
  ModalOkTitle,
  ModalOkDescription,
} from './styled';



export interface IProps {
  visible?: boolean;

  backgroundColor?: string;

  image?: any;

  title?: string;
  description?: string;
  buttonText?: string;

  activeOpacity?: number;
  onPress?: any;
}



const ModalOk: React.FC<IProps> = (props: any) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }



  return (

    <Overlay
      noPress
      visible={props.visible}
      showBackground={true}
      overlayColor={Colors.menuOverlay}>

      <ModalOkContainer
        style={{
          backgroundColor: props.backgroundColor,
        }}>

        {props.image && (
          <ModalOkImage
            alt={'Ok Image'}
            src={props.image}
          />
        )}


        <ModalOkTitle>
          {props.title}
        </ModalOkTitle>


        <ModalOkDescription>
          {props.description}
        </ModalOkDescription>


        <ButtonAction
          width={150}
          activeOpacity={Sizes.CardActiveOpacity}
          title={props.buttonText}
          onPress={props.onPress}
        />

      </ModalOkContainer>

    </Overlay>

  );
};



export default ModalOk;
