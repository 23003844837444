import styled from 'styled-components';

import {
  Sizes,
  Colors,
} from '@noitada/shared/constants';



const TEXT_WIDTH = '80px';
const HEIGHT = '215px';



export const CardTicketContainer = styled.a`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  height: ${HEIGHT};
  min-height: ${HEIGHT};
  max-height: ${HEIGHT};
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const UserTicketEventImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  filter: blur(2px);
`;


export const UserPartView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 20px;
  padding-bottom: 5px;
  z-index: 5;
`;

export const CardOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.menuOverlay};
`;



export const HeaderUserInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-bottom: 10px;
`;

export const UserInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const DateInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
`;



export const TextIconContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  align-items: center;
`;

export const IconStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const TextDate = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.accent};
`;

export const TextHour = styled.p`
  font-weight: normal;
  font-size: 13px;
  color: ${Colors.accent};
`;

export const TextTypeTicket = styled.p`
  color: ${Colors.textApp};
  font-weight: normal;
  font-size: 14px;
  text-align: right;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;



export const EventInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: flex-start;
`;

export const TextEventName = styled.p`
  font-weight: bold;
  font-size: 22px;
  color: ${Colors.textTitle};
`;



export const EventPlaceView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-top: 5px;
`;

export const EventPlaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

export const TextPlace = styled.p`
  margin-bottom: 2px;
  font-size: 12px;
  color: ${Colors.textDescription};
`;

export const IconView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: ${Sizes.cardRadius}px;
`;



// Ticket Separator

export const ViewDotsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -20px;
  margin-right: -5px;
  width: 20px;
  height: 25px;
  transform: rotate(90deg) translateX(95px);
  z-index: 5;
`;



// ID Ticket Session 

export const PlacePartView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: ${TEXT_WIDTH};
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: ${Colors.actionBackground};
  z-index: 5;
`;

export const PlaceInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: ${TEXT_WIDTH};
`;

export const TextIdTitle = styled.p`
  width: 140px;
  font-weight: normal;
  font-size: 16px;
  color: ${Colors.primary};
  transform: rotate(90deg) translateX(65px) translateY(8px);
`;

export const TextIdTicket = styled.p`
  width: 130px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  transform: rotate(90deg) translateX(40px) translateY(25px);

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const TextObs = styled.p`
  width: 135px;
  color: ${Colors.textDescription};
  font-style: italic;
  font-weight: 100;
  font-size: 12.5px;
  transform: rotate(90deg) translateX(25px) translateY(50px);
`;

export const ImagePlace = styled.img`
  height: 45px;
  width: 45px;
  margin-left: 3px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;
