import styled from 'styled-components';

import {
  Tab,
} from '../Composh/web';



export const TabComposhStyled = styled(Tab)`
  height: 100%;
  margin-top: 2px;
  font-size: 15px;
`;
