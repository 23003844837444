import styled from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';

import {
  ListFlat,
} from '../../../components/Composh/web';



export const AllEventScrollFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: Sizes.paddingFlatList,
    paddingLeft: Sizes.paddingFlatList,
    paddingRight: Sizes.paddingFlatList,
    paddingBottom: Sizes.paddingFlatList,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
