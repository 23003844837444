import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



// Section Banner

export const BannerContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 200px;
  width: 100%;
  background-color: ${(props: IProps) => props.backgroundColor};
`;
// shadowOffset: { width: 5, height: 5 },
// shadowColor: 'gray',
// shadowOpacity: 0.7,
// shadowRadius: 4,
// elevation: 4,


export const EventPicContentView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;



export const BannerImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const BannerBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BannerImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardOverlay};
`;



export const BannerInfoView = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 5;
`;

export const BannerImage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const BannerInfoTextsView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  justify-content: space-between;
`;

export const BannerInfoTitle = styled.h3`
  color: ${Colors.white};
  font-size: 22px;
  font-weight: bold;
`;

export const BannerInfoOpenText = styled.p`
  color: ${Colors.gray};
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
`;
