import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const WifiServiceContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;

export const WifiServiceIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 13px;
`;

export const WifiServiceInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WifiServiceName = styled.p`
  color: ${Colors.white};
  font-size: 15px;
`;

export const WifiServiceBoldName = styled.span`
  font-weight: bold;
`;

export const WifiServiceCopyText = styled.p`
  margin-top: 3px;
  color: ${Colors.white};
  font-size: 13px;
  font-style: italic;
`;
