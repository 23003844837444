import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';
import { Badge, Table } from '../../../../../components/Composh/web';



// Tab Choose Payment Style

export const ViewCountContent = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const HelpTicketText = styled.p`
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 12px;
  color: ${Colors.textApp};
  text-align: center;
`;



export const CardFlagImage = styled.img`
  height: 35px;
  width: 50px;
  border-radius: ${Sizes.cardRadius}px;
  margin-left: 5px;
  margin-right: 5px;
  object-fit: contain;
`;


export const BadgeItemListContent = styled.div`
  height: 50px;
  min-width: 50px;
  justify-content: center;
`;

export const BadgeCurrentCard = styled(Badge.View)`
  min-height: 20px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: ${Colors.primary};
`;

export const BadgeCurrentText = styled(Badge.Text)`
  font-size: 12px;
`;



export const AddPaymentButton = styled.a`
  margin-top: 12px;
  margin-bottom: 10px;
  margin-left: 7px;
`;

export const AddPaymentText = styled.p`
  color: ${Colors.buttonOkAccent};
  font-size: 14px;
`;



// Tab Checkout Style

export const EventImageView = styled.div`
  position: relative;
  width: 100%;
`;

export const EventImageCheckout = styled.img`
  height: 250px;
  width: 100%;
  object-fit: cover;
`;


export const PriceContent = styled.div`
  position: absolute;
  right: 0;
  bottom: -20px;
  align-self: flex-start;
  padding-left: 20px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${Colors.primary};
  border-top-left-radius: ${Sizes.borderModalsRadius + 15}px;
`;

export const PriceText = styled.p`
  color: white;
  font-size: 26px;
  font-weight: 700;
  text-align: right;
`;

export const ToPayText = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
`;


export const EventInfoView = styled.div`
  margin-top: 35px;
`;

export const EventNameText = styled.p`
  color: white;
  font-size: 20px;
`;

export const EventPlaceText = styled.p`
  color: ${Colors.textDescription};
  font-size: 14px;
`;


export const TicketInfoView = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TicketTypeImage = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
`;

export const TicketPriceText = styled.p`
  color: white;
  font-size: 16px;
`;



export const TableContent = styled.div`
  width: 100%;
  align-items: flex-end;
`;

export const TableView = styled.div`
  width: 265px;
`;

export const TableComponent = styled(Table.Table)`
  padding-left: 5px;
  padding-right: 5px;
  border-top-width: 1.5px;
  border-color: gray;
`;

export const TableWrapperComponent = styled(Table.TableWrapper)`
  flex-direction: row;
`;

export const TableColComponent = styled(Table.Col)`
  flex: 1;
  justify-content: center;
`;

export const TableRowsComponent = styled(Table.Rows)`
  justify-content: center;
`;


export const InfoTicketStyle = styled.p`
  color: gray;
`;

export const InfoTicketText = styled(InfoTicketStyle)`
  font-size: 14px;
`;

export const InfoTotalText = styled(InfoTicketStyle)`
  font-size: 20px;
  font-weight: bold;
`;



export const PricesTicketStyle = styled.p`
  color: white;
  text-align: right;
`;

export const PricesTicketText = styled(PricesTicketStyle)`
  font-size: 14px;
`;

export const PricesView = styled.div`
  flex: 1;
  justify-content: center;
  margin-top: 4px;
  padding-top: 4px;
  border-top-width: 1.5px;
  border-color: gray;
`;

export const PricesTotalText = styled(PricesTicketStyle)`
  font-size: 20px;
  font-weight: bold;
`;

export const PricesParcelText = styled(PricesTicketStyle)`
  margin-top: 2px;
  color: ${Colors.textDescription};
  font-size: 12px;
`;
