import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECarnivalLocation,
} from '@noitada/shared/enums/Carnival/carnival-location.enum';

import {
  IconCarnivalAgencyFlow,
  IconCarnivalAgencyPublic,
  IconCarnivalAreaReserved,
  IconCarnivalBathroom,
  IconCarnivalBus,
  IconCarnivalCabin,
  IconCarnivalCircuit,
  IconCarnivalCleanHealth,
  IconCarnivalFireman,
  IconCarnivalJuvenile,
  IconCarnivalLifeGuard,
  IconCarnivalMask,
  IconCarnivalMedical,
  IconCarnivalPolice,
  IconCarnivalTaxi,
  IconCarnivalTrio,
  IconNoitada,
} from '../components/Icons';



export function renderCarnivalTypeIcon(value: string, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  switch (value) {
    case ECarnivalLocation.CIRCUIT:
      return (
        <IconCarnivalCircuit
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.BLOC:
      return (
        <IconCarnivalTrio
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.CABIN:
      return (
        <IconCarnivalCabin
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.POLICE:
      return (
        <IconCarnivalPolice
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.BUS:
      return (
        <IconCarnivalBus
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.TAXI:
      return (
        <IconCarnivalTaxi
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.BATHROOM:
      return (
        <IconCarnivalBathroom
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.FIREMAN:
      return (
        <IconCarnivalFireman
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.SAVE_LIFE:
      return (
        <IconCarnivalLifeGuard
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.MEDICAL_POINT:
      return (
        <IconCarnivalMedical
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.JUVENILE:
      return (
        <IconCarnivalJuvenile
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.OTHER_ATTRACTION:
      return (
        <IconCarnivalMask
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.NOITADA:
      return (
        <IconNoitada
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.AGENCY_FLOW:
      return (
        <IconCarnivalAgencyFlow
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.AGENCY_PUBLIC:
      return (
        <IconCarnivalAgencyPublic
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.CLEAN_HEALTH:
      return (
        <IconCarnivalCleanHealth
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECarnivalLocation.AREA_RESERVED:
      return (
        <IconCarnivalAreaReserved
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      break;
  }
};
