import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  USER_LANGUAGE_MODAL,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconClose,
  IconLanguages,
} from '../../components/Icons';

import {
  KnowAppContainer,
  KnowAppImageContainer,
  KnowAppImageLogo,
  KnowAppInfoContent,
  KnowAppInfoTitle,
  KnowAppInfoText,
  KnowAppButton,
} from './styled';



export interface IProps {
  onPress?: any;
  onClosePress?: any;
}



const KnowApp: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const sizeIcon = 24;


  function onOpenTranslateModal() {
    dispatch({ type: USER_LANGUAGE_MODAL, payload: true });
  }



  return (

    <KnowAppContainer>

      <KnowAppImageContainer
        onClick={props.onPress}>
        <KnowAppImageLogo
          src={Images.logoIconAppWhite}
        />
      </KnowAppImageContainer>



      <KnowAppInfoContent
        onClick={props.onPress}>

        <KnowAppInfoTitle>
          {translate(TranslateConfig.KNOW_APP)}
        </KnowAppInfoTitle>

        <KnowAppInfoText>
          {translate(TranslateConfig.SUBTITLES_KNOW_APP)}
        </KnowAppInfoText>

      </KnowAppInfoContent>



      <KnowAppButton
        onClick={() => {
          onOpenTranslateModal();
        }}>
        <IconLanguages
          color={Colors.white}
          size={sizeIcon}
        />
      </KnowAppButton>



      <KnowAppButton
        onClick={props.onClosePress}>
        <IconClose
          color={Colors.white}
          size={sizeIcon}
        />
      </KnowAppButton>

    </KnowAppContainer>

  );
};



export default KnowApp;
