import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const GuideListHeaderContent = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  margin-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.gray};
  border-radius: 3px;
`;

export const InfoGuideListTitle = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
`;

export const InfoGuideListActionsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;
