import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Card,
} from '../../../Composh/web';


export const CarnivalArtistContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 110px;
  min-height: 110px;
  max-height: 110px;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const CarnivalEventsContent = styled(Card.View)`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
  padding-left: 8px;
  border-color: ${Colors.toolbarLine};
  border-style: solid;
  border-width: 0.5px;
`;


export const CarnivalEventsInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  height: 100%;
  padding-top: 4px;
`;

export const CardCarnivalHour = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
`;

export const CardCarnivalDate = styled.p`
  margin-top: 7px;
  color: ${Colors.white};
  font-size: 13px;
  line-height: 14px;
`;

export const EventNameArtirst = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 20px;
  line-height: 22px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;


export const CarnivalArtistPerson = styled.img`
  height: 110px;
  width: 110px;
  object-fit: cover;
`;
