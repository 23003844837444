import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CleanBagButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 80px;
  margin-right: 10px;
`;

export const BagTotalTitle = styled.p`
  font-size: 13px;
  color: white;
`;

export const BagTotalText = styled.p`
  margin-top: -2px;
  color: white;
  font-size: 19px;
  font-weight: bold;
`;



export const HeaderBagView = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 15px;
`;

export const BagPlaceView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 20px;
`;

export const BagPlaceImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const BagInfoPlaceView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${Sizes.paddingContainer}px;
  margin-right: ${Sizes.paddingContainer}px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const BagInfoPlaceNameText = styled.h1`
  color: white;
  font-size: 18px;
`;



export const BagPlaceMinOrderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 5px;
  border-top-color: ${Colors.toolbarLine};
  border-top-style: solid;
  border-top-width: 0.5px;
  border-bottom-color: ${Colors.toolbarLine};
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
`;

export const BagPlaceMinOrderText = styled.p`
  color: ${Colors.textApp};
  font-size: 13.5px;
`;
