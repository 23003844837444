import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadderHorizontal,
} from '../../styles/styled.layout';



export interface IProps {
  width?: number;
  maxWidth?: number;
  height?: number;
}



export const AddPersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.alertBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.width && `width: ${props.width}px`};
  ${(props: IProps) => props.width && `max-width: ${props.maxWidth}px`};
  ${(props: IProps) => props.height && `height: ${props.height}px`};
`;



export const AddPersonContent = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const AddFooterDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  margin-top: 15px;
`;
