import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Button,
  Badge,
} from '../Composh/web';



export const ButtonControl = styled(Button.Control)`
  position: relative;
`;



export const BadgeNotificationView = styled(Badge.View)`
  position: absolute;
  top: 5px;
  left: 22px;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 0 10px ${Colors.danger};
`;
