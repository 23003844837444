import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const DetailsCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
`;


export const DetailsCompanyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const CompanyDetailsButton = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding-left: 10px;
`;


export const DetailsAboutPlaceLogo = styled.img`
  height: 70px;
  width: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
`;

export const CompanyTitle = styled.p`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 19px;
  font-weight: bold;
`;

export const CompanyInformationButton = styled.p`
  height: 18px;
  margin-top: 6px;
  color: ${Colors.textDescription};
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
`;



/* ratingCompanyContainer: {
  flexDirection: 'row',
},

ratingContentButton: {
  flex: 1,
  paddingVertical: 5,
  paddingHorizontal: 15,
  justify-content: 'center',
},

ratingButton: {
  flex: 1,
  backgroundColor: 'purple',
  align-items: 'center',
  justify-content: 'center',
  borderRadius: Sizes.buttonRadius,
},

ratingScreenContent: {
  align-items: 'flex-end',
  justify-content: 'center',
  paddingLeft: 10,
  paddingRight: 20,
},

ratingInfoContent: {
  flexDirection: 'row',
  align-items: 'center',
  justify-content: 'center',
  paddingVertical: 2,
},

ratingIconsContent: {
  marginRight: 10,
},

ratingNumber: {
  color: Colors.primary,
  fontSize: 20,
  fontWeight: 'bold',
},

ratingCommentsNumber: {
  color: 'white',
  fontSize: 14,
}, */
