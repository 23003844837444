import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  USER_LOCATION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  stringUpperCase,
  stringWordCapitalize,
} from '@noitada/shared/utils/string.utils';

import CardLocationCity from '../../../components/Cards/CardLocation/LocationCity';

import {
  Container,
  ListSection,
} from '../../../components/Composh/web';

import EmptyContent from '../../../components/Empty';

import {
  LocationListHeader,
} from './styled';



export interface IProps {
  data?: Array<any>;
  action: 'RETURN' | 'DISPATCH';
  currentCity?: string;
  onCityPress?: any;
  countrySelected?: string;
}



const LocationCitiesScreen: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const arrayLocations = props.data;
  const actualCityName = props.currentCity;

  const countryUser = props.countrySelected;



  // // TODO: Evento a partir da localização
  // function renderLocationButton() {
  //   return (

  //     <LocationUser
  //       onPress={() => {
  //         goBack();
  //       }}
  //     />

  //   );
  // }


  const renderSection = (section: { title: string; }) => {
    return (

      <LocationListHeader
        text={stringUpperCase(section?.title) || ''}
        backgroundColor={Colors.primary}
        textSize={18}
        textColor={Colors.white}
      />

    );
  };


  const renderCard = (title: any, index: any) => {
    return (

      <CardLocationCity
        key={index}
        title={stringWordCapitalize(title) || ''}
        selected={actualCityName === title}
        borderWidth={index ? 1 : 0}
        onPress={() => {
          const dataLocation = {
            city: stringWordCapitalize(title || ''),
            state: stringWordCapitalize(arrayLocations?.find((state: any) => state.data.find((item: any) => item === title)).title || ''),
            country: stringWordCapitalize(countryUser || ''),
          };

          if (props.action === 'DISPATCH') {
            dispatch({ type: USER_LOCATION, payload: dataLocation });
          }

          props.onCityPress(dataLocation);
        }}
      />

    );
  };


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.locationGlobal}
        title={translate(TranslateConfig.EMPTY_PLACES)}
      />

    );
  }



  return (

    <Container
      backgroundColor={Colors.appBackground}>

      {(!arrayLocations || arrayLocations?.length === 0) && (
        listEmptyComponent()
      )}


      {arrayLocations && arrayLocations?.length > 0 && (
        <ListSection
          sections={arrayLocations}
          // ListHeaderComponent={renderLocationButton} // TODO: Fazer o 'A partir da minha Localização'
          keyExtractor={(_: any, index: any) => String(index)}
          renderItem={renderCard}
          renderSectionHeader={renderSection}
        />
      )}

    </Container>

  );
};



export default LocationCitiesScreen;
