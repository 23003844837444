import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  Content,
  Epigraph,
} from '../../../../../components/Composh/web';

import TimeContent from '../../../../../components/Contents/TimeContent';
import ItemsList from '../../../../../components/Items/ItemsList';

import {
  ContentPadderHorizontal,
  DetailsSectionView,
} from '../../../../../styles/styled.layout';

import {
  ViewCountContent,
  HelpTicketText,
  CardFlagImage,
  BadgeItemListContent,
  BadgeCurrentCard,
  BadgeCurrentText,
  AddPaymentButton,
  AddPaymentText,
} from './styled';



const TabChoosePayment: React.FC = () => {
  const history = useHistory();


  function renderImageList(
    badgeType: string,
  ) {
    switch (badgeType) {
      case 'PAYMENT':
        return 'Atual';
        break;

      case 'DISCOUNT':
        return 'Aplicado';
        break;

      default:
        return 'Noitada';
        break;
    }
  }



  function renderPaymentForm(
    typeCard: string,
    imagePayment: any,
    title: string,
    subTitle: string,
    thirdTitle: string | null,
    currentPayment: boolean,
  ) {
    return (

      <ItemsList
        iconLeft={
          <CardFlagImage
            src={imagePayment}
          />
        }
        title={title}
        titleColor="white"
        subtitle={subTitle}
        subtitleColor={'slategray'}
        thirdtitle={thirdTitle}
        thirdtitleColor={Colors.textApp}
        iconRight={
          currentPayment && (
            <BadgeItemListContent>

              <BadgeCurrentCard>
                <BadgeCurrentText>
                  {renderImageList(typeCard)}
                </BadgeCurrentText>
              </BadgeCurrentCard>

            </BadgeItemListContent>
          )}
        border={true}
      />

    );
  }



  return (

    <Content>
      <ContentPadderHorizontal>


        <ViewCountContent>

          <TimeContent
            title={'00:05:00'}
            description={'Por favor, complete a compra dentro do prazo máximo indicado acima sem fechar esta tela.'}
          />

          <HelpTicketText>
            Os ingressos estarão disponíveis no aplicativo e serão enviados por email.
          </HelpTicketText>
        </ViewCountContent>



        <DetailsSectionView>

          <Epigraph.Section
            text={'Cartões'}
            textColor={Colors.primary}
            borderBottomColor={Colors.primary}
          />

          {/* Fazer um FlatList */}
          <a
          // activeOpacity={Sizes.ButtonActiveOpacity}
          >
            {renderPaymentForm(
              'PAYMENT',
              Images.flagCardMastercard,
              '**** **** **** 1644',
              'Mastercard',
              null,
              true,
            )}
          </a>


          <AddPaymentButton
            onClick={() => {
              // history.dispatch(StackActions.push('AddCreditCard'))
            }}>
            <AddPaymentText>
              Adicionar Forma de Pagamento
            </AddPaymentText>
          </AddPaymentButton>

        </DetailsSectionView>



        <DetailsSectionView>

          <Epigraph.Section
            text={'Promoções'}
            textColor={Colors.primary}
            borderBottomColor={Colors.primary}
          />

          {/* Fazer um FlatList */}
          <a
          // activeOpacity={Sizes.ButtonActiveOpacity}
          >
            {renderPaymentForm(
              'DISCOUNT',
              Images.flagDiscount,
              '40% Off na Comanda Digital',
              'Expira em: 20/05/2020 23:59',
              null,
              true,
            )}
          </a>


          <AddPaymentButton
          // onPress={() =>
          //   navigation.dispatch(StackActions.push('AddCreditCard'))
          // }
          >
            <AddPaymentText>
              Adicionar Código Promocional
            </AddPaymentText>
          </AddPaymentButton>

        </DetailsSectionView>


      </ContentPadderHorizontal>
    </Content>

  );
};



export default TabChoosePayment;
