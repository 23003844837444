import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
} from 'formik';

import {
  AuthenticateUser,
  setAuthHeaders,
} from '@noitada/axios-config/endpoints/auth.endpoints';

import LoginModel from '@noitada/axios-config/models/Access/login.model';
import UserModel from '@noitada/axios-config/models/User';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  FAVORITES_SET,
} from '@noitada/redux-config/reducers/favorites.store';

import {
  USER_LANGUAGE_MODAL,
} from '@noitada/redux-config/reducers/localities.store';

import {
  USER_LOGGED,
  USER_SET,
} from '@noitada/redux-config/reducers/user.store';

import {
  Colors,
  Images,
  Values,
} from '@noitada/shared/constants';

import {
  ETokenAsync,
} from '@noitada/shared/enums';

import Vars from '@noitada/shared/environments/variables';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  UserHasAuthorization,
} from '@noitada/shared/utils/user.utils';

import LoginValidationSchema from '@noitada/shared/validations/Access/access.validations';

import {
  Screen,
  Title,
} from '../../components/Composh/web';

import LoginContainer from '../../components/Contents/LoginContainer';

import {
  ButtonBack,
} from '../../components/Controls';

import HeaderScreen from '../../components/Headers/HeaderScreen';
import InputText from '../../components/Inputs/InputText';
import Separators from '../../components/Separators';

import NameRoutes from '../../navigation/names';

import {
  ButtonSecondary,
  ButtonText,
} from '../../styles/styled.layout';

import {
  CircleTopLeft,
  CircleTopRight,
  AccessContent,
  AccessContentPadder,
  LogoContent,
  LogoImage,
  LoginSeparatorHorizontal,
  LogoNameContainer,
  LogoName,
  LogoSlogan,
  LoginContent,
  InputsContent,
  LoginButton,
  ButtonForgetPass,
  ForgetPassText,
  ButtonsContent,
  CircleBottonLeft,
  CircleBottonRight,
  LoginWarningText,
  FooterTermsText,
  ButtonLanguagesStyled,
} from './styled';



const AccessScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);


  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function appSetValues(idUser: string, newToken: string, userData: typeof UserModel) {
    try {
      localStorage.setItem(ETokenAsync.USER_ID, idUser);
      localStorage.setItem(ETokenAsync.USER_TOKEN, newToken);
      localStorage.setItem(ETokenAsync.USER_DATA, JSON.stringify(userData));

      dispatch({ type: USER_LOGGED, payload: true });
      dispatch({ type: USER_SET, payload: userData });

      dispatch({ type: FAVORITES_SET, payload: userData?.favorites || [] });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function appClearValues() {
    try {
      localStorage.clear();
      dispatch({ type: USER_SET, payload: {} });
      dispatch({ type: USER_LOGGED, payload: false });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  async function sendLoginRequest(values: any) {
    const loginForm: typeof LoginModel = {
      email: values.email.toLowerCase(),
      password: values.password,
    };

    try {
      setLoading(true);

      const data = await AuthenticateUser(loginForm);
      const newToken = data?.token;
      const userData = data?.user;
      const idUser = data?.user?.id;

      setAuthHeaders(newToken);

      if (UserHasAuthorization(userData)) {
        try {
          appSetValues(idUser, newToken, userData);

          setLoaded(true);
        }
        catch (error: any) {
          console.error(error);
          appClearValues();

          const message = error?.hasError && error?.message && typeof error?.message === 'string'
            ? error?.message
            : translate(TranslateConfig.TRY_AGAIN);
          showSnackBarProps(Colors.danger, message);
        }
      }
      else {
        appClearValues();

        showSnackBarProps(Colors.danger, translate(TranslateConfig.ACCESS_DENIED));
      }
    }
    catch (error: any) {
      console.error(error);
      appClearValues();

      if (error?.response?.status === 403) {
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ACCESS_DENIED));
        return;
      }

      if (error?.response?.status === 400 || error?.response?.status === 401) {
        showSnackBarProps(Colors.danger, translate(TranslateConfig.PASSWORD_WRONG));
        return;
      }

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function onOpenHomeScreen() {
    history.goBack();
  }


  function onPressPasswordForgot() {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title: translate(TranslateConfig.RECOVER_PASSWORD),
        link: Vars().globalAccountForgotPassword,
      },
    });
  }


  function openRegisterScreen() {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title: translate(TranslateConfig.REGISTER),
        link: Vars().globalAccountRegister,
      },
    });
  }


  async function redirectToHome() {
    if (loggedIn) {
      onOpenHomeScreen();
    }
  }



  useEffect(() => {
    redirectToHome();
  }, []);



  return (

    <Formik
      validateOnMount
      initialValues={LoginModel}
      validationSchema={LoginValidationSchema}
      onSubmit={(values) => {
        // KeyboardModal.dismiss();
        sendLoginRequest(values);
      }}>
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <LoginContainer
            backgroundImage={Images.loginImageBackground}>

            <CircleTopLeft
              colors={[
                Colors.buttonOk,
                Colors.buttonOkDark,
              ]}
            />

            <CircleTopRight
              colors={[
                Colors.secondary,
                Colors.buttonOkDark,
              ]}
            />



            <AccessContent>

              <HeaderScreen
                transparent
                leftIcon={
                  <ButtonBack
                    color={Colors.white}
                    onPress={() => {
                      onOpenHomeScreen();
                    }}
                  />
                }
                centerContent={
                  <Title>
                    {translate(TranslateConfig.ACCESS)}
                  </Title>
                }
              />



              <AccessContentPadder>

                <LogoContent>
                  <LogoImage
                    alt={''}
                    src={Images.logoIconAppWhite}
                  />


                  <LoginSeparatorHorizontal
                    alt={''}
                    src={Images.separatorHorizontal}
                  />


                  <LogoNameContainer>
                    <LogoName>
                      {translate(TranslateConfig.NAME_APP).toUpperCase()}
                    </LogoName>

                    <LogoSlogan>
                      {translate(TranslateConfig.COMPANY_APP)}
                    </LogoSlogan>
                  </LogoNameContainer>
                </LogoContent>



                <LoginContent>

                  <InputsContent>

                    <InputText
                      disabled={loading}
                      type={'EMAIL'}
                      labelText={`${translate(TranslateConfig.EMAIL)} *`}
                      placeholderText={translate(TranslateConfig.EMAIL)}
                      helpText={translate(errors.email || '')}
                      countLimit={Values.emailMaxCount}
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                    />


                    <InputText
                      password
                      disabled={loading}
                      type={'PASSWORD'}
                      labelText={`${translate(TranslateConfig.PASSWORD)} *`}
                      placeholderText={translate(TranslateConfig.PASSWORD)}
                      helpText={translate(errors.password || '')}
                      countLimit={Values.passwordMaxCount}
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                    />



                    <ButtonForgetPass
                      // disabled={loading}
                      // activeOpacity={Sizes.ButtonActiveOpacity}
                      onClick={() => {
                        onPressPasswordForgot();
                      }}>
                      <ForgetPassText
                        color={Colors.buttonOk}>
                        {translate(TranslateConfig.FORGOT_PASSWORD_QUESTION)}
                      </ForgetPassText>
                    </ButtonForgetPass>

                  </InputsContent>



                  <LoginButton
                    loading={loading}
                    loaded={loaded}
                    text={translate(TranslateConfig.ACTION_ACCESS)}
                    color={Colors.buttonOk}
                    circleColor={Colors.buttonOk}
                    onPress={() => {
                      handleSubmit();
                    }}
                    onNextPress={() => {
                      onOpenHomeScreen();
                    }}
                  />


                  <ButtonsContent
                    style={{
                      // opacity: inputAnimation,
                    }}>

                    <Separators
                      option={'VERTICAL'}
                    />


                    <ButtonSecondary
                      // disabled={loading}
                      // activeOpacity={Sizes.ButtonActiveOpacity}
                      style={{
                        backgroundColor: Colors.buttonSolidDark,
                        borderBottomColor: Colors.primary,
                        borderBottomStyle: 'solid',
                      }}
                      onClick={() => {
                        openRegisterScreen();
                      }}>
                      <ButtonText
                        color={Colors.buttonOk}>
                        {translate(TranslateConfig.ACTION_REGISTER)}
                      </ButtonText>
                    </ButtonSecondary>


                    <LoginWarningText>
                      {translate(TranslateConfig.TERMS_ACCESS_APP)}
                      <FooterTermsText
                        onClick={() => {
                          history.push({
                            pathname: NameRoutes.WebViewScreen,
                            state: {
                              title: translate(TranslateConfig.ACCESS_USER_TERMS_USE),
                              link: Vars().termsUseLink,
                            },
                          });
                        }}>
                        {` ${translate(TranslateConfig.ACCESS_USER_TERMS_USE)} `}
                      </FooterTermsText>
                      {translate(TranslateConfig.AND)}
                      <FooterTermsText
                        onClick={() => {
                          history.push({
                            pathname: NameRoutes.WebViewScreen,
                            state: {
                              title: translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY),
                              link: Vars().privacityPolicyLink,
                            },
                          });
                        }}>
                        {` ${translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY)}`}
                      </FooterTermsText>
                    </LoginWarningText>

                  </ButtonsContent>



                  <ButtonLanguagesStyled
                    onPress={() => {
                      dispatch({ type: USER_LANGUAGE_MODAL, payload: true });
                    }}
                  />

                </LoginContent>

              </AccessContentPadder>

            </AccessContent>



            <CircleBottonLeft
              colors={[
                Colors.buttonOk,
                Colors.buttonOkDark,
              ]}
            />

            <CircleBottonRight
              colors={[
                Colors.secondary,
                Colors.buttonOkDark,
              ]}
            />

          </LoginContainer>

        </Screen>

      )}
    </Formik>
  );
};



export default AccessScreen;
