import React,
{
  useEffect,
} from 'react';
import './App.css';

import {
  Provider,
} from 'react-redux';

import {
  persistStore,
} from 'redux-persist';

import {
  PersistGate,
} from 'redux-persist/integration/react';

import {
  storePersisted,
} from '@noitada/redux-config';

import {
  Colors,
  Keys,
} from '@noitada/shared/constants';

import * as PackageInfo from '../package.json';

import BadgeEnv from './components/Badges/BadgeEnv';

import Navigation from './navigation';

const persistor = persistStore(storePersisted);



const App: React.FC = () => {
  const titleProject = 'Noitada App';
  const projectPackage = PackageInfo;
  const environment = process.env.REACT_APP_NODE_ENV;



  useEffect(() => {
    const cssEmoji = `font-size: 50px; text-shadow: 2px 2px 0 ${Colors.primary}`;
    const cssText = `font-size: 17px; color: ${Colors.accent}`;
    console.log(`%c${'\ud83d\udc40'}${'\n'}%c${'Boa noite! Olhando nosso console?'}${'\n'}${'Poderia estar olhando nossas vagas em aberto, para nos ajudar a fazer uma mega Noitada'}${'\n'}${Keys.jobsLink}`, cssEmoji, cssText);

    console.log(`${titleProject} Version: ${projectPackage.version} [${environment}]`);
  }, []);



  return (

    <Provider store={storePersisted}>
      <PersistGate loading={null} persistor={persistor}>

        <BadgeEnv />

        <Navigation />

      </PersistGate>
    </Provider>

  );
};



export default App;
