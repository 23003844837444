import React from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  USER_LANGUAGE_MODAL,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import Vars from '@noitada/shared/environments/variables';

import ButtonLanguages from '../../components/Buttons/ButtonLanguages';

import {
  Container,
  Screen,
} from '../../components/Composh/web';

import {
  IconNoitada,
} from '../../components/Icons';

import NameRoutes from '../../navigation/names';

import {
  PermissionContent,
  BodyQr,
  PermissionTitle,
  PermissionText,
  PermissionImage,
  TermsContent,
  TextTerms,
  TextBoldTerms,
  PermissionBottomToolbar,
  PermissionBottomButton,
  TutorialBottomTextLeft,
  TutorialBottomTextRight,
} from './styled';



const PermissionsConfigAppScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();



  function onExitPress() {
    // TODO
    // history.replace(NameRoutes.GridEventsScreen);
  }


  function onGrantPermissionPress() {
    // TODO
    history.replace(NameRoutes.GridEventsScreen);
  }



  return (

    <Screen
      backgroundColor={Colors.primaryDark}>

      <Container>
        <PermissionContent>

          <BodyQr>
            <IconNoitada
              color={Colors.white}
              size={44}
            />
          </BodyQr>


          <PermissionTitle>
            Garantir permissões
          </PermissionTitle>


          <PermissionText>
            Conceda permissão a Noitada para ter acesso a sua localização e outras funcionalidades.
          </PermissionText>


          <PermissionImage
            src={Images.permissions}
          />



          <ButtonLanguages
            onPress={() => {
              dispatch({ type: USER_LANGUAGE_MODAL, payload: true });
            }}
          />


          <TermsContent>
            <TextTerms>
              {'Ao utilizar a Noitada, você aceita os'}
              {' '}
              <TextBoldTerms
                onClick={() => {
                  history.push({
                    pathname: NameRoutes.WebViewScreen,
                    state: {
                      title: 'Termos de Uso',
                      link: Vars().termsUseLink,
                    },
                  });
                }}>
                {'Termos de Uso'}
              </TextBoldTerms>
              {' '}
              {'e'}
              {' '}
              <TextBoldTerms
                onClick={() => {
                  history.push({
                    pathname: NameRoutes.WebViewScreen,
                    state: {
                      title: 'Políticas de Privacidade',
                      link: Vars().privacityPolicyLink,
                    },
                  });
                }}>
                {'Políticas de Privacidade'}
              </TextBoldTerms>
            </TextTerms>
          </TermsContent>

        </PermissionContent>
      </Container>



      <PermissionBottomToolbar>

        <PermissionBottomButton
          onClick={() => {
            onExitPress();
          }}>

          <TutorialBottomTextLeft>
            Sair
          </TutorialBottomTextLeft>

        </PermissionBottomButton>



        <PermissionBottomButton
          onClick={() => {
            onGrantPermissionPress();
          }}>

          <TutorialBottomTextRight>
            Garantir permissões
          </TutorialBottomTextRight>

        </PermissionBottomButton>

      </PermissionBottomToolbar>

    </Screen>

  );
};



export default PermissionsConfigAppScreen;
