import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Overlay,
  Title,
} from '../../../../components/Composh/web';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  ButtonPrimary,
  ButtonText,
} from '../../../../styles/styled.layout';

import {
  WaitingCheckinModalContainer,
  WaitingCheckinPadderHorizontal,
  WaitingCheckinContent,
  WaitingCheckinModalText,
} from './styled';



export interface IProps {
  visible?: boolean;
  onPress?: any;
}



const WaitCheckinModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const widthLine = 320;
  const heightLine = 230;


  function cancelCheckinPress() {
    props.onPress(true);
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}>

      <WaitingCheckinModalContainer
        width={widthLine}
        height={heightLine}>

        <HeaderScreen
          transparent
          centerContent={
            <Title>
              {translate(TranslateConfig.CHECKIN_WAIT)}
            </Title>
          }
        />



        <WaitingCheckinPadderHorizontal>

          <WaitingCheckinContent>
            <WaitingCheckinModalText>
              {translate(TranslateConfig.HELP_CHECKIN_WAIT)}
            </WaitingCheckinModalText>
          </WaitingCheckinContent>


          <ButtonPrimary
            backgroundColor={Colors.buttonCancel}
            onClick={() => {
              cancelCheckinPress();
            }}>
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_CANCEL_CHECKIN)}
            </ButtonText>
          </ButtonPrimary>

        </WaitingCheckinPadderHorizontal>

      </WaitingCheckinModalContainer>

    </Overlay>

  );
};



export default WaitCheckinModal;
