import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const HistoryOpenOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



export const HistoryOpenOrderInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
`;


export const HistoryOpenInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HistoryOpenStatusOrderText = styled.h1`
  color: ${Colors.white};
  font-size: 21px;
`;

export const HistoryOpenItemsText = styled.h2`
  color: ${Colors.textApp};
  font-size: 16px;
`;

export const HistoryTableText = styled.p`
  margin-top: 2px;
  color: ${Colors.textApp};
  font-size: 15px;
`;

export const HistoryOpenIdText = styled.p`
  margin-top: 8px;
  color: ${Colors.gray};
  font-size: 14px;
  font-style: italic;
`;



export const HistoryOpenInfoRight = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HistoryOpenRightText = styled.p`
  color: ${Colors.gray};
  font-size: 16px;
  text-align: right;
`;

export const HistoryPriceRightText = styled.p`
  margin-top: 2px;
  color: ${Colors.buttonOkLight};
  font-size: 16px;
  text-align: right;
`;
