import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardUserReserveContainer = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const MyReservePlaceContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MyReservePlaceInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MyReserveDate = styled.h1`
  color: ${Colors.white};
  font-size: 19px;
  font-weight: bold;
`;


export const MyReserveStatus = styled.h1`
  color: red;
  font-size: 19px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const MyReserveCompanyName = styled.h3`
  margin-top: 2px;
  color: lightgray;
  font-size: 14px;
`;

export const MyReservePlaceImage = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 3px;
  object-fit: cover;
`;


export const MyReserveTableNumber = styled.h2`
  margin-top: 10px;
  color: white;
  font-size: 19px;
`;

export const MyReservePeopleNumber = styled.h3`
  margin-top: 2px;
  color: darkgray;
  font-size: 15px;
`;
