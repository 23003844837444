import styled,
{
  css,
} from 'styled-components';



export interface IProps {
  width?: number;
  height?: number;
  backgroundColor?: string;
  tintColor?: string;
}



export const AbsoluteFillStyle = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;


export const AbsoluteFillView = styled.div`
  ${AbsoluteFillStyle};
  z-index: -1;
`;

export const ContainerGlue = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-content: center;
  justify-content: center;
`;


export const BackgroundColorView = styled.div`
  ${AbsoluteFillStyle};
  ${(props: IProps) => `background-color: ${props.backgroundColor}`};
`;


export const FlexContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ImageBackgroundStyle = styled.img`
  ${AbsoluteFillStyle};
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${(props: IProps) => props.tintColor};
`;

export const LogoStyle = styled.div`
  ${AbsoluteFillStyle};
  align-items: center;
  justify-content: center;
`;

export const LogoImageStyle = styled.img`
  width: ${(props: IProps) => props.width || 150}px;
  height: ${(props: IProps) => props.height || 150}px;
  object-fit: contain;
`;
