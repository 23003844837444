import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  FooterMini,
} from '../../../styles/styled.layout';



export const TouchableEditUser = styled.a`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 5px;
`;

export const SettingsUserView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UserImage = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const BadgeSettingUser = styled.h3`
  margin-top: -17px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  color: white;
  font-size: 12.5px;
  font-weight: 700;
  border-radius: 50px;
`;

export const UserVerifiedContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 3px;
  padding-right: 7px;
`;

export const TextUser = styled.p`
  color: white;
  font-size: 20px;
`;

export const IconVerifiedStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-left: 8px;
`;


export const TextName = styled.p`
  color: white;
  font-size: 13px;
  font-weight: 300;
`;

export const TextEditProfile = styled.p`
  margin-top: 8px;
  color: ${Colors.primary};
  font-size: 12px;
`;



export const FooterView = styled(FooterMini)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const FooterText = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${Colors.textApp};
`;
