import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const BannerImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 90px;
  min-height: 90px;
`;

export const BannerBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  filter: blur(10px);
`;

export const BannerImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardOverlay};
`;



export const BannerInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 5;
`;

export const BannerImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const BannerInfoTextsView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

export const BannerInfoTitle = styled.h3`
  color: ${Colors.white};
  font-size: 23px;
  font-weight: bold;
`;

export const BannerInfoUsername = styled.p`
  color: ${Colors.gray};
  font-size: 16px;
  font-weight: bold;
`;

export const BannerInfoOpenText = styled.p`
  margin-top: 5px;
  color: ${Colors.accept};
  font-size: 15px;
  font-weight: bold;
`;
