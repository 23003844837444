import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardInfoPersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const CardInfoPersonImageView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardInfoPersonImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const CardInfoPersonTypeBadge = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: -8px;
`;



export const CardReserveInfoView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-self: flex-start;
  align-items: center;
  margin-left: 12px;
  padding-top: 8px;
`;


export const CardInfoPersonInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CardInfoPersonRowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const CardInfoPersonUsernameText = styled.p`
  color: #696969;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
`;

export const CardInfoPersonVerifiedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export const CardInfoPersonCakeImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  object-fit: cover;
`;

export const CardInfoPersonPublicNameText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;
