import styled from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const ProfilePublicButton = styled.div`
  display: flex;
  flex-direction: column;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: ${Sizes.cardRadius}px;
`;
