import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  SwipeableDrawer,
} from '@material-ui/core';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_DRAWER_ACTION,
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_LOGOUT_STATE,
} from '@noitada/redux-config/reducers/user.store';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Epigraph,
} from '../../components/Composh/web';

import LevelUser from '../../components/Contents/UserLevel';

import {
  IconExit,
  IconFavoriteOn,
  IconHelp,
  IconHome,
  IconList,
  IconOrderSheet,
  IconProfile,
  IconReserves,
  IconTicket,
  IconVerified,
} from '../../components/Icons';

import LoadingProgress from '../../components/Loadings/LoadingProgress';

import NameRoutes from '../names';

import {
  MenuContainer,
  MenuHeaderContainer,
  MenuImageContent,
  MenuProfileImage,
  MenuImageHeaderView,
  MenuHeaderInfo,
  MenuVerifiedContent,
  MenuNameText,
  IconVerifiedView,
  MenuSeparator,
  MenuInfoNameText,
  MenuListContent,
  ActiveIndicatorContent,
  MenuContentPlace,
  MenuItemList,
  MenuEpigraphSection,
  ItemMenuText,
  ItemMenuIcon,
  ItemMenuContainer,
} from './styled';



export interface IProps {
  open: boolean;
  onOpen?: any;
  onClose?: any;
}



const DrawerMenu: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const selectedIndex = useSelector((state: RootState) => state.app.menuSelected);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user !== null;


  const [fixMenu] = useState(false);


  const routesHighlights = [
    {
      index: EDrawerMenu.HOME,
      icon: (
        <IconHome
          size={24}
          color={selectedIndex === EDrawerMenu.HOME ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.HOME),
      router: NameRoutes.GridEventsScreen,
      enable: true,
      visible: true,
    },
    {
      index: EDrawerMenu.FAVORITES,
      icon: (
        <IconFavoriteOn
          size={24}
          color={selectedIndex === EDrawerMenu.FAVORITES ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.FAVORITES),
      router: NameRoutes.FavoritesScreen,
      enable: true,
      visible: true,
    },
  ];


  const routesProfile = [
    {
      index: EDrawerMenu.PROFILE,
      icon: (
        <IconProfile
          size={24}
          color={selectedIndex === EDrawerMenu.PROFILE ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.PROFILE),
      router: NameRoutes.ProfileScreen,
      enable: true,
      visible: true,
    },
  ];


  const routesSales = [
    {
      index: EDrawerMenu.TICKETS,
      icon: (
        <IconTicket
          size={24}
          color={selectedIndex === EDrawerMenu.TICKETS ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.TICKETS),
      router: NameRoutes.UserTicketsScreen,
      enable: true,
      visible: true,
    },
    {
      index: EDrawerMenu.LISTS,
      icon: (
        <IconList
          size={24}
          color={selectedIndex === EDrawerMenu.LISTS ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.LISTS),
      router: NameRoutes.UserListsScreen,
      enable: true,
      visible: true,
    },
    {
      index: EDrawerMenu.REQUESTS,
      icon: (
        <IconOrderSheet
          size={24}
          color={selectedIndex === EDrawerMenu.REQUESTS ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.REQUESTS),
      router: NameRoutes.UserOrdersScreen,
      enable: true,
      visible: true,
    },
    {
      index: EDrawerMenu.RESERVES,
      icon: (
        <IconReserves
          size={24}
          color={selectedIndex === EDrawerMenu.RESERVES ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.RESERVES),
      router: NameRoutes.UserReservesScreen,
      enable: true,
      visible: true,
    },
  ];


  const routesOptions = [
    {
      index: EDrawerMenu.HELP,
      icon: (
        <IconHelp
          size={24}
          color={selectedIndex === EDrawerMenu.HELP ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.HELP),
      router: NameRoutes.HelpFaqScreen,
      enable: true,
      visible: true,
    },
    {
      index: EDrawerMenu.EXIT,
      icon: (
        <IconExit
          size={24}
          color={selectedIndex === EDrawerMenu.EXIT ? Colors.white : Colors.textSubtitle}
        />
      ),
      name: translate(TranslateConfig.EXIT),
      router: NameRoutes.AccessScreen,
      enable: true,
      visible: true,
    },
  ];



  const handleListItemClick = (index: EDrawerMenu) => {
    dispatch({ type: APP_DRAWER_ACTION, payload: false });
    if (index !== EDrawerMenu.EXIT) {
      dispatch({ type: APP_MENU_SELECTED_ACTION, payload: index });
    }
  };


  function pushButtonCLick(route: any) {
    if (route === NameRoutes.AccessScreen) {
      dispatch({ type: USER_LOGOUT_STATE, payload: true });
      history.replace(NameRoutes.GridEventsScreen);
    }
    else {
      history.push(route);
    }
  }


  function renderItem(item: any) {
    if (item.visible) {
      return (

        <ItemMenuContainer
          key={item.index}
          id={item.index}
          disabled={!item.enable}
          selected={selectedIndex === item.index}
          onClick={() => {
            if (item.enable) {
              pushButtonCLick(item.router);
              handleListItemClick(item.index);
            }
          }}>

          <ItemMenuIcon>
            {item.icon}
          </ItemMenuIcon>


          <ItemMenuText
            selected={selectedIndex === item.index}>
            {item.name}
          </ItemMenuText>

        </ItemMenuContainer>

      );
    }
  };



  return (

    <SwipeableDrawer
      anchor={'left'}
      disableSwipeToOpen={true}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}>

      <MenuContainer
        style={{
          width: !fixMenu ? 300 : 60,
        }}>

        <MenuHeaderContainer
          colors={[Colors.profileColorTop, Colors.profileColorBottom]}>

          <MenuImageContent
            style={{
              flexDirection: !fixMenu ? 'row' : 'column',
            }}>

            <MenuProfileImage
              alt={'ProfileAvatar'}
              src={
                user?.picture === '' || user?.picture === null || user?.picture === undefined
                  ? Images.placeholderAvatarCompany
                  : user?.picture
              }
              style={{
                width: !fixMenu ? Sizes.imageDrawer : 45,
                height: !fixMenu ? Sizes.imageDrawer : 45,
                // margin: !fixMenu ? '0 10px' : '10px 0',
              }}
            />


            <MenuImageHeaderView
              style={{
                width: !fixMenu ? Sizes.imageDrawer : 45,
                height: !fixMenu ? Sizes.imageDrawer : 65,
                // margin: !fixMenu ? '0 10px' : '10px 0',
              }}>

              <LevelUser
                userLevel={user?.userInfo?.level}
              />
            </MenuImageHeaderView>
          </MenuImageContent>



          {!fixMenu && (
            <MenuHeaderInfo>
              <MenuVerifiedContent>
                <MenuNameText>
                  @{user?.username}
                </MenuNameText>

                {user?.verified && (
                  <IconVerifiedView>
                    <IconVerified
                      color={Colors.accent}
                      size={13}
                    />
                  </IconVerifiedView>
                )}
              </MenuVerifiedContent>


              <MenuSeparator
                height={1.5}
                width={175}
                color={Colors.white}
                style={{ opacity: availableUser ? 1 : 0.2 }}
              />


              <MenuInfoNameText>
                {user?.publicName}
              </MenuInfoNameText>
            </MenuHeaderInfo>
          )}
        </MenuHeaderContainer>



        <MenuListContent>

          {!availableUser && (
            <ActiveIndicatorContent>
              <LoadingProgress
                color={Colors.primary}
                size={20} // 'large'
              />
            </ActiveIndicatorContent>
          )}


          {availableUser && (
            <MenuContentPlace>

              {!routesHighlights.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  {!fixMenu && (
                    <MenuEpigraphSection>
                      <Epigraph.Section
                        text={!fixMenu ? translate(TranslateConfig.HIGHLIGHTS).toUpperCase() : ''}
                        textColor={Colors.accent}
                        borderBottomColor={Colors.accent}
                      />
                    </MenuEpigraphSection>
                  )}

                  {routesHighlights.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesProfile.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  {!fixMenu && (
                    <MenuEpigraphSection>
                      <Epigraph.Section
                        text={!fixMenu ? translate(TranslateConfig.DATAS).toUpperCase() : ''}
                        textColor={Colors.accent}
                        borderBottomColor={Colors.accent}
                      />
                    </MenuEpigraphSection>
                  )}

                  {routesProfile.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesSales.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  {!fixMenu && (
                    <MenuEpigraphSection>
                      <Epigraph.Section
                        text={!fixMenu ? translate(TranslateConfig.SHOPS).toUpperCase() : ''}
                        textColor={Colors.accent}
                        borderBottomColor={Colors.accent}
                      />
                    </MenuEpigraphSection>
                  )}

                  {routesSales.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}



              {!routesOptions.every((currentValue) => !currentValue.visible) && (
                <MenuItemList>
                  {!fixMenu && (
                    <MenuEpigraphSection>
                      <Epigraph.Section
                        text={!fixMenu ? translate(TranslateConfig.OPTIONS).toUpperCase() : ''}
                        textColor={Colors.accent}
                        borderBottomColor={Colors.accent}
                      />
                    </MenuEpigraphSection>
                  )}

                  {routesOptions.map((item) => (
                    renderItem(item)
                  ))}
                </MenuItemList>
              )}

            </MenuContentPlace>
          )}

        </MenuListContent>

      </MenuContainer>
    </SwipeableDrawer>
  );
};



export default DrawerMenu;
