import {
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '../../translations';

import Yup from '../base-validation.yup';



const CheckinRequestValidationSchema = Yup.object().shape({
  idShort: Yup.string()
    .required(TranslateConfig.VALIDATE_ID_SHORT_REQUIRED)
    .min(Values.shortIdCount, TranslateConfig.VALIDATE_MIN_DIGITS.replace('{count}', String(Values.shortIdCount))),

  tableEntrance: Yup.number(),
});



export default CheckinRequestValidationSchema;
