import {
  ECompanyModality,
} from '../enums';

import {
  TranslateConfig,
} from '../translations';



export const companyModalityArray = [
  {
    label: TranslateConfig.ORDERSHEET, // PRESENTIAL
    value: ECompanyModality.ORDERSHEET,
  },
  {
    label: TranslateConfig.REMOVAL_ITEM,
    value: ECompanyModality.REMOVAL_ITEM,
  },
  {
    label: TranslateConfig.DELIVERY,
    value: ECompanyModality.DELIVERY,
  },
];
