import React,
{
  useState,
} from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ButtonMapContainer,
  ButtonMapContent,
  ButtonMapTouchable,
  ButtonMapText,
} from './styled';



export interface IProps {
  selectionMode?: string;
  textOption1?: any;
  textOption2?: any;
  onSelectSwitch?: any;
}



const ButtonMapChoose: React.FC<IProps> = (props: IProps) => {
  const [getSelectionMode, setSelectionMode] = useState(props.selectionMode);


  const updatedSwitchData = (val: 'EVENT' | 'COMPANY') => {
    setSelectionMode(val);
    props.onSelectSwitch(val);
  };



  return (

    <ButtonMapContainer>
      <ButtonMapContent>

        <ButtonMapTouchable
          // activeOpacity={1}
          backgroundColor={getSelectionMode === 'EVENT' ? Colors.carnivalPink : Colors.transparent}
          onClick={() => {
            updatedSwitchData('EVENT');
          }}>
          <ButtonMapText>
            {props.textOption1}
          </ButtonMapText>
        </ButtonMapTouchable>


        <ButtonMapTouchable
          // activeOpacity={1}
          backgroundColor={getSelectionMode === 'COMPANY' ? Colors.primary : Colors.transparent}
          onClick={() => {
            updatedSwitchData('COMPANY');
          }}>
          <ButtonMapText>
            {props.textOption2}
          </ButtonMapText>
        </ButtonMapTouchable>

      </ButtonMapContent>
    </ButtonMapContainer>

  );
};



export default ButtonMapChoose;
