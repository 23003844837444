import styled from 'styled-components';



export const DetailsMusicGenreContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
`;
