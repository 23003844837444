export enum ECompanyKitchens {
  AFRICAN = 'AFRICAN',
  GERMAN = 'GERMAN',
  ARABIC = 'ARABIC',
  ARGENTINA = 'ARGENTINA',
  DRINKS = 'DRINKS',
  BRAZILIAN = 'BRAZILIAN',
  COFFEE = 'COFFEE',
  MEATS = 'MEATS',
  CHINESE = 'CHINESE',
  COLOMBIAN = 'COLOMBIAN',
  KOREAN = 'KOREAN',
  SPANISH = 'SPANISH',
  FRENCH = 'FRENCH',
  SEAFOOD = 'SEAFOOD',
  INDIAN = 'INDIAN',
  ITALIAN = 'ITALIAN',
  JAPANESE = 'JAPANESE',
  MEDITERRANEAN = 'MEDITERRANEAN',
  MEXICAN = 'MEXICAN',
  FRIED_PASTRY = 'FRIED_PASTRY',
  FISH = 'FISH',
  PERUVIAN = 'PERUVIAN',
  PIZZA = 'PIZZA',
  PORTUGUESE = 'PORTUGUESE',
  HEALTHY = 'HEALTHY',
  JUICES = 'JUICES',
  THAI = 'THAI',
  VEGETARIAN = 'VEGETARIAN',
}



export default ECompanyKitchens;
