import NoitadaWS from '../index';



export const GetEnabledReserves = async (companyId: any) => {
  const url = `/reserves/user/available/${companyId}`;
  const response = await NoitadaWS.get(url);
  return response.data;
};


export const GetReserveDetail= async (reserveId: any) => {
  const url = `/reserves/user/${reserveId}`;
  const response = await NoitadaWS.get(url);
  return response.data;
};
