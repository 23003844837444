import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import moment from 'moment';

import {
  CancelTicketUser,
} from '@noitada/axios-config/endpoints/entrance-ticket.endpoints';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import DetailsAddress from '../../../../../components/Address';
import AlertMessage from '../../../../../components/Alerts';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../../components/Controls';

import DetailsCompanyRealization from '../../../../../components/Details/DetailsCompanyRealization';
import DetailsPayments from '../../../../../components/Details/DetailsPayments';
import FooterChoose from '../../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../../components/Headers/HeaderScreen';

import {
  IconEdit,
  IconWatchEnd,
  IconWatchStart,
} from '../../../../../components/Icons';

import PaymentChoose from '../../../../../components/Payments/PaymentChoose';
import PictureEntrance from '../../../../../components/Pictures/PictureEntrance';

import {
  FormatMoney,
} from '../../../../../config/payment.config';

import NameRoutes from '../../../../../navigation/names';

import {
  ButtonText,
  DetailsSectionView,
  SubtitleDetails,
  SubtitleText,
} from '../../../../../styles/styled.layout';

import {
  TicketContentPadder,
  TicketDateContainer,
  TicketDateView,
  TicketDateText,
  CardTicketBuyContent,
  CardTicketBuyHeader,
  CardTicketBuyIconNumber,
  CardTicketBuyNumber,
  CardTicketBuyinfoView,
  CardTicketTitle,
  CardTicketBoughtUsername,
  CardTicketAccess,
  CardTicketPrice,
  CardTicketChangePersonButton,
  CardTicketChangePersonText,
  CardTicketPaymentView,
  CardTicketPaymentChoose,
} from './styled';



export interface IRouteParams {
  item: any;
  editable?: boolean;
}



const TicketBuyDetailsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const routeLocation = useLocation<IRouteParams>();
  const params = routeLocation?.state;

  const ticketBuy = params?.item;
  const { editable } = params;

  const ticketArray = ticketBuy.userTickets.map((item: any) => ({ ...ticketBuy, userTickets: { ...item } }));


  const [changeAlert, setChangeAlert] = useState<boolean>(false);


  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }

  function calculateTicketsTotal() {
    let value = 0;

    ticketArray.forEach((ticket) => {
      value += ticket.userTickets.lot.price;
    });

    return value;
  }

  async function cancelTicket() {
    const ticketId = '';

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await CancelTicketUser(ticketId);

      if (response) {
        showSnackBarProps(Colors.accept, 'Troca realizada com sucesso');
        history.goBack();
      }
      else {
        showSnackBarProps(Colors.danger, 'Erro ao fazer a troca');
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderTicketBuy(item: any, index: number) {
    return (

      <CardTicketBuyContent>

        <CardTicketBuyHeader>

          <CardTicketBuyIconNumber>
            <CardTicketBuyNumber>
              {++index}
            </CardTicketBuyNumber>
          </CardTicketBuyIconNumber>



          <CardTicketBuyinfoView>

            <CardTicketTitle>
              {item.name}
            </CardTicketTitle>

            <CardTicketBoughtUsername>
              Titular: @{item.userTickets?.user?.name}
            </CardTicketBoughtUsername>


            <CardTicketAccess>
              Comprado por: @{item.userTickets?.buyer?.name}
            </CardTicketAccess>

            <CardTicketAccess>
              {item.name}
            </CardTicketAccess>

          </CardTicketBuyinfoView>


          <CardTicketPrice>
            {FormatMoney(item.userTickets?.lot?.price)}
          </CardTicketPrice>

        </CardTicketBuyHeader>



        {false && (
          <CardTicketChangePersonButton
            onClick={() => {
              history.push({
                pathname: NameRoutes.ChangeTicketScreen.replace(
                  ':ticket_id',
                  item.userTickets?.id,
                ),
              });
            }}>

            <IconEdit
              color={Colors.accent}
              size={24}
            />


            <CardTicketChangePersonText>
              Trocar titularidade
            </CardTicketChangePersonText>

          </CardTicketChangePersonButton>
        )}

      </CardTicketBuyContent>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          centerContent={
            <Title>
              Detalhe do ingresso
            </Title>
          }
        />



        <Content>

          <PictureEntrance
            name={ticketBuy.event?.name}
            image={ticketBuy.eventPicture}
            onPress={() => {
              history.push({
                pathname: NameRoutes.DetailsEventScreen.replace(
                  ':event_id',
                  ticketBuy.event?.idShort,
                ),
              });
            }}
          />


          <TicketContentPadder>

            <DetailsCompanyRealization
              name={ticketBuy.event?.company?.name}
              image={ticketBuy.companyPicture}
              onPress={() => {
                history.push({
                  pathname: NameRoutes.DetailsCompanyScreen.replace(
                    ':company_username',
                    ticketBuy.event?.company?.company_username,
                  ),
                });
              }}
            />



            <DetailsSectionView>
              <SubtitleDetails>
                ID: #{ticketArray[0].shortId}
              </SubtitleDetails>


              <SubtitleText>
                {`Comprado às ${moment(ticketArray[0].userTickets?.boughtAt).format('HH:mm')}`}
              </SubtitleText>


              <SubtitleText>
                {moment(ticketArray[0].userTickets?.boughtAt).format('dddd, DD [de] MMMM [de] YYYY')}
              </SubtitleText>
            </DetailsSectionView>



            <DetailsSectionView>
              <SubtitleDetails>
                Detalhes
              </SubtitleDetails>


              <TicketDateContainer>

                <TicketDateView>
                  <IconWatchStart
                    color={Colors.white}
                    size={22}
                  />

                  <TicketDateText>
                    {moment(ticketArray[0]?.saleStartDate).format('ddd, DD [de] MMMM [de] YYYY - HH:mm')}
                  </TicketDateText>
                </TicketDateView>


                <TicketDateView>
                  <IconWatchEnd
                    color={Colors.white}
                    size={22}
                  />

                  <TicketDateText>
                    {moment(ticketArray[0]?.saleEndDate).format('ddd, DD [de] MMMM [de] YYYY - HH:mm')}
                  </TicketDateText>
                </TicketDateView>

              </TicketDateContainer>

            </DetailsSectionView>



            <DetailsSectionView>
              {ticketArray.map((item: any, index: number) => (
                renderTicketBuy(item, index)
              ))}
            </DetailsSectionView>

          </TicketContentPadder>



          <DetailsAddress
            companyName={''}
            address={ticketBuy.event?.address}
          />



          <CardTicketPaymentView>
            <DetailsPayments
              price={calculateTicketsTotal()}
              delivery={0}  //  FIXME
              discount={0} // FIXME
            />


            <CardTicketPaymentChoose>
              <PaymentChoose
                noChangeCard
              />
            </CardTicketPaymentChoose>
          </CardTicketPaymentView>

        </Content>



        {editable &&
          <FooterChoose
            hideCancelButton

            okColor={ArrayColors.arrayCancel}
            okLabel={
              <ButtonText
                color={Colors.white}>
                Cancelar meu ingresso
              </ButtonText>
            }
            okPress={() => {
              setChangeAlert(true);
            }}
          />
        }

      </Container>



      <AlertMessage
        visible={changeAlert}
        title={'Cancelar meu ingresso?'}
        description={'Essa ação não poderá ser desfeita'}
        text={'Ao cancelar o seu ingresso, ou outros ingressos continuarão válidos'}
        cancelText={'Não'}
        cancelPress={() => {
          setChangeAlert(false);
        }}
        okText={'Sim'}
        okPress={() => {
          cancelTicket();
        }}
      />

    </Screen>

  );
};



export default TicketBuyDetailsScreen;
