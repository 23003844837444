import styled from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';



export const ModalOkContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  width: 340px;
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;
  border-radius: ${Sizes.cardRadius}px;
`;


export const ModalOkImage = styled.img`
  width: 180px;
  height: 180px;
  margin-bottom: 20px;
  object-fit: cover;
`;

export const ModalOkTitle = styled.h1`
  margin-bottom: 10px;
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`;

export const ModalOkDescription = styled.p`
  margin-bottom: 25px;
  color: darkgray;
  font-size: 16px;
  text-align: center;
`;
