import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  BannerContainer,
  EventPicContentView,
  BannerImageContainer,
  BannerBackground,
  BannerImageOverlay,
  BannerInfoView,
  BannerImage,
  BannerInfoTextsView,
  BannerInfoTitle,
  BannerInfoOpenText,
} from './styled';



export interface IProps {
  name?: string;
  image?: string;
  onPress?: any;
}



const PictureEntrance: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const eventImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.placeholderEvent
    : props.image;



  return (

    <BannerContainer
      backgroundColor={Colors.primaryDark}
      onClick={props.onPress}>
      <EventPicContentView>

        <BannerImageContainer>
          <BannerBackground
            alt={'picture'}
            src={eventImage}
          />

          <BannerImageOverlay />



          <BannerInfoView>

            <BannerImage
              src={eventImage}
            />



            <BannerInfoTextsView>

              <BannerInfoTitle>
                {props.name}
              </BannerInfoTitle>


              <BannerInfoOpenText>
                {translate(TranslateConfig.ACTION_MORE_DETAILS)}
              </BannerInfoOpenText>

            </BannerInfoTextsView>

          </BannerInfoView>

        </BannerImageContainer>

      </EventPicContentView>
    </BannerContainer>

  );
};



export default PictureEntrance;
