import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  languagesOption,
} from '@noitada/shared/arrays/languages.array';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  stringRawDataText,
} from '@noitada/shared/utils/string.utils';

import CardLocationCountry from '../../../components/Cards/CardLocation/LocationCountry';
import EmptyContent from '../../../components/Empty';

import {
  LocationCountryContent,
} from './styled';



export interface IProps {
  data?: Array<any>;
  onCountryPress?: any;
  countrySelected?: string;
}



const LocationCountriesScreen: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const arrayCountries = props.data;


  function renderCard(item: any, index: number) {
    const filterLangCountry = languagesOption.find((country) => stringRawDataText(country.namePt) === stringRawDataText(item?.title));


    return (

      <CardLocationCountry
        key={index}
        selected={props.countrySelected === item?.title}
        title={translate(filterLangCountry?.namePt || '')}
        code={filterLangCountry?.code}
        currency={filterLangCountry?.moneyFull}
        image={filterLangCountry?.flagImage}
        onPress={() => {
          props.onCountryPress(item?.title);
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.locationGlobal}
        title={translate(TranslateConfig.EMPTY_PLACES)}
      />

    );
  }



  return (

    <LocationCountryContent
      backgroundColor={Colors.appBackground}>

      {(!arrayCountries || arrayCountries?.length === 0) && (
        listEmptyComponent()
      )}

      {arrayCountries && arrayCountries?.length > 0 && arrayCountries?.map((item, index) => (
        renderCard(item, index)
      ))}

    </LocationCountryContent>

  );
};



export default LocationCountriesScreen;
