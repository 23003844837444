import {
  validateString,
} from './string.utils';



export function CompanyOpenHourText(item: any) {
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const newDate = new Date();
  const day = days[newDate.getDay()];

  const open = item?.company_operation && item?.company_operation[`${day}_open`] === true;

  if (open) {
    const openHour = item?.company_operation[`${day}_hour_open`].substring(0, 5);
    const closeHour = item?.company_operation[`${day}_hour_close`].substring(0, 5);

    // const openDate = new Date();
    // openDate.setHours(validateNumber(openHour.substring(0, 2) as any), validateNumber(openHour.substring(3, 5)));

    // const closeDate = new Date();
    // closeDate.setHours(validateNumber(closeHour.substring(0, 2) as any), validateNumber(closeHour.substring(3, 5)));

    return `${openHour} - ${closeHour}`;
  }
  else {
    return '';
  }
}



export function CompanyCloseHourText(item: any) {
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const newDate = new Date();
  const day = days[newDate.getDay()];

  const open = item?.company_operation && item?.company_operation[`${day}_open`] === true;

  if (open) {
    const closeHour = item?.company_operation[`${day}_hour_close`].substring(0, 5);

    return closeHour;
  }
  else {
    return '';
  }
}



export function ValidateAddressCompany(
  street: string,
  district: string,
  city: string,
  state: string,
  zip: string,
  country: string,
): boolean {
  if (validateString(street) &&
    validateString(district) && validateString(city) &&
    validateString(state) && validateString(zip) &&
    validateString(country)
  ) {
    return true;
  }

  return false;
};
