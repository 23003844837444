import ECompanyKitchens from '../enums/Company/company-kitchens.enum';

import {
  TranslateConfig,
} from './../translations/index';



export const companyTypesKitchenArray = [
  {
    label: TranslateConfig.AFRICAN,
    value: ECompanyKitchens.AFRICAN,
  },
  {
    label: TranslateConfig.GERMAN,
    value: ECompanyKitchens.GERMAN,
  },
  {
    label: TranslateConfig.ARABIC,
    value: ECompanyKitchens.ARABIC,
  },
  {
    label: TranslateConfig.ARGENTINA,
    value: ECompanyKitchens.ARGENTINA,
  },
  {
    label: TranslateConfig.DRINKS,
    value: ECompanyKitchens.DRINKS,
  },
  {
    label: TranslateConfig.BRAZILIAN,
    value: ECompanyKitchens.BRAZILIAN,
  },
  {
    label: TranslateConfig.COFFEE,
    value: ECompanyKitchens.COFFEE,
  },
  {
    label: TranslateConfig.MEATS,
    value: ECompanyKitchens.MEATS,
  },
  {
    label: TranslateConfig.CHINESE,
    value: ECompanyKitchens.CHINESE,
  },
  {
    label: TranslateConfig.COLOMBIAN,
    value: ECompanyKitchens.COLOMBIAN,
  },
  {
    label: TranslateConfig.KOREAN,
    value: ECompanyKitchens.KOREAN,
  },
  {
    label: TranslateConfig.SPANISH,
    value: ECompanyKitchens.SPANISH,
  },
  {
    label: TranslateConfig.FRENCH,
    value: ECompanyKitchens.FRENCH,
  },
  {
    label: TranslateConfig.SEAFOOD,
    value: ECompanyKitchens.SEAFOOD,
  },
  {
    label: TranslateConfig.INDIAN,
    value: ECompanyKitchens.INDIAN,
  },
  {
    label: TranslateConfig.ITALIAN,
    value: ECompanyKitchens.ITALIAN,
  },
  {
    label: TranslateConfig.JAPANESE,
    value: ECompanyKitchens.JAPANESE,
  },
  {
    label: TranslateConfig.MEDITERRANEAN,
    value: ECompanyKitchens.MEDITERRANEAN,
  },
  {
    label: TranslateConfig.MEXICAN,
    value: ECompanyKitchens.MEXICAN,
  },
  {
    label: TranslateConfig.FRIED_PASTRY,
    value: ECompanyKitchens.FRIED_PASTRY,
  },
  {
    label: TranslateConfig.FISH,
    value: ECompanyKitchens.FISH,
  },
  {
    label: TranslateConfig.PERUVIAN,
    value: ECompanyKitchens.PERUVIAN,
  },
  {
    label: TranslateConfig.PIZZA,
    value: ECompanyKitchens.PIZZA,
  },
  {
    label: TranslateConfig.PORTUGUESE,
    value: ECompanyKitchens.PORTUGUESE,
  },
  {
    label: TranslateConfig.HEALTHY,
    value: ECompanyKitchens.HEALTHY,
  },
  {
    label: TranslateConfig.JUICES,
    value: ECompanyKitchens.JUICES,
  },
  {
    label: TranslateConfig.THAI,
    value: ECompanyKitchens.THAI,
  },
  {
    label: TranslateConfig.VEGETARIAN,
    value: ECompanyKitchens.VEGETARIAN,
  },
];
