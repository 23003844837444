import React from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  IconArrowExpand,
  IconArrowExpanded,
} from '../../../Icons';

import {
  CarnivalCabinCardStyle,
  CarnivalCabinBackgroundImage,
  CarnivalCabinBackground,
  CarnivalCabinContainer,
  CarnivalCabinImage,
  CabinButtonExpandedContent,
  CarnivalCabinInfoContainer,
  CarnivalCabinTitle,
  CarnivalStreetButtonView,
  CarnivalCabinArrowContainer,
  CarnivalCabinDescText,
} from './styled';



export interface IProps {
  expanded?: boolean;
  canExpanded?: boolean;

  title?: string;
  description?: string;
  hour?: string;
  cabinLogo?: any;
  backgroundImage?: any;

  onPress?: any;
}



const CardCarnivalLounge: React.FC<IProps> = (props: IProps) => {
  function renderExpandableIcon() {
    if (props.expanded) {
      return (

        <IconArrowExpanded
          color={Colors.white}
          size={Sizes.iconNavigationBottomSize}
        />

      );
    }


    return (

      <IconArrowExpand
        color={Colors.white}
        size={Sizes.iconNavigationBottomSize}
      />

    );
  }



  return (

    <CarnivalCabinCardStyle>
      <CarnivalCabinBackgroundImage
        backgroundColor={Colors.cardBackgroundAccent}
        borderRadius={Sizes.cardCarnivalRadius}
        onPress={() => {
          if (props.canExpanded && props.onPress) {
            props.onPress();
          }
        }}>

        <CarnivalCabinBackground
          alt={'background'}
          src={props.backgroundImage === '' || props.backgroundImage === null
            ? Images.placeholderEvent
            : props.backgroundImage
          }
        />



        <CarnivalCabinContainer>

          <CarnivalCabinImage
            alt={'cabin'}
            src={props.cabinLogo}
          />


          <CabinButtonExpandedContent>

            <CarnivalCabinInfoContainer>

              <CarnivalCabinTitle
              // adjustsFontSizeToFit
              >
                {props.title ? props.title.toUpperCase() : '-'}
              </CarnivalCabinTitle>


              <CarnivalStreetButtonView>

                {props.canExpanded && (
                  <CarnivalCabinArrowContainer>
                    {renderExpandableIcon()}
                  </CarnivalCabinArrowContainer>
                )}

              </CarnivalStreetButtonView>

            </CarnivalCabinInfoContainer>


            <CarnivalCabinDescText>
              {props.description}
            </CarnivalCabinDescText>
          </CabinButtonExpandedContent>

        </CarnivalCabinContainer>

      </CarnivalCabinBackgroundImage>
    </CarnivalCabinCardStyle>

  );
};



export default CardCarnivalLounge;
