import React from 'react';

import {
  InfoCarnivalAccordionContent,
  InfoAccordionContentText,
  InfoAccordionContentImage,
} from './styled';



export interface IProps {
  picture?: string;
  description?: string;
}



const CardCarnivalGuideDetail: React.FC<IProps> = (props: IProps) => {
  return (

    <InfoCarnivalAccordionContent>

      <InfoAccordionContentText>
        {props.description}
      </InfoAccordionContentText>


      {props.picture !== '' && (
        <InfoAccordionContentImage
          src={props.picture}
        />
      )}

    </InfoCarnivalAccordionContent>

  );
};



export default CardCarnivalGuideDetail;
