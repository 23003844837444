import styled from 'styled-components';



export const DetailsOpenBarContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
`;
