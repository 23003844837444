export enum EUserStatus {
  GHOST = 'GHOST',
  PRE_REGISTERED = 'PRE_REGISTERED',
  REGISTERED = 'REGISTERED',
}



export default EUserStatus;

