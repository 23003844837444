import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  languagesOption,
} from '@noitada/shared/arrays/languages.array';

import {
  Images,
} from '@noitada/shared/constants';

import {
} from '@noitada/shared/translations';

import {
  ButtonLanguageContainer,
  ButtonLanguageTitle,
  ButtonLanguageImage,
} from './styled';



export interface IProps {
  onPress?: any;
}



const ButtonLanguages: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const className: any = { ...props };


  const languageSelected = useSelector((state: RootState) => state.localities.language);
  const languageFiltered = languagesOption?.find((item) => item?.languageIso === languageSelected);


  return (

    <ButtonLanguageContainer
      className={className?.className}
      onClick={props.onPress}>

      <ButtonLanguageTitle>
        {translate(languageFiltered?.label || '') || '-'}
      </ButtonLanguageTitle>


      <ButtonLanguageImage
        src={languageFiltered?.flagImage || Images.placeholderAvatarCompany}
      />

    </ButtonLanguageContainer>

  );
};



export default ButtonLanguages;
