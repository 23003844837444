import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Items,
} from '../../Composh/web';



export interface IProps {
  disabled?: boolean;
  iconLeft?: any;
  title?: string | null;
  titleColor?: any;
  subtitle?: string | null;
  subtitleColor?: any;
  thirdtitle?: string | null;
  thirdtitleColor?: any;
  iconRight?: any;
  border?: boolean;
  onPress?: any;
}



const ItemsList: React.FC<IProps> = (props: IProps) => {
  return (

    <Items.List
      activeOpacity={0.7}
      disabled={props.disabled}
      border={props.border}
      onPress={props.onPress}
      iconLeft={props.iconLeft}
      title={props.title}
      titleColor={props.titleColor || Colors.white}
      subtitle={props.subtitle}
      subtitleColor={props.subtitleColor || Colors.textApp}
      thirdtitle={props.thirdtitle}
      thirdtitleColor={props.thirdtitleColor || Colors.textApp}
      iconRight={props.iconRight}
    />

  );
};



export default ItemsList;
