import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  SubtitleDetails,
} from '../../styles/styled.layout';



export const CompanyMapView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const CompanySubtitleMap = styled(SubtitleDetails)`
  margin-bottom: 8px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const MapViewStyle = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 160px;
  background-color: ${Colors.cardBackground};
`;



export const DirectionsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;



export const PlaceAddressView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 30px;
`;


export const TextAddressView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
