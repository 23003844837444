import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const ButtonLanguageContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonLanguageTitle = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
`;

export const ButtonLanguageImage = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 7px;
  object-fit: contain;
  border-radius: 3px;
`;
