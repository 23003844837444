import React from 'react';

import QRCode from 'react-qr-code';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  size: number;
  value: string;
}



const QrCodeComponent: React.FC<IProps> = (props: IProps) => {
  return (

    <QRCode
      size={props.size}
      fgColor={Colors.white}
      bgColor={Colors.transparent}
      value={props.value}
    />

  );
};



export default QrCodeComponent;
