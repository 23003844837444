import styled from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';

import Separators from '../../../components/Separators';

import {
  Footer,
} from '../../../styles/styled.layout';

import {
  LinearGradient,
} from '../../Composh/web';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const FooterChooseStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
`;


export const SeparatorVerticalFooter = styled(Separators).attrs({
  style: {
    marginTop: -11,
    marginBottom: -10,
  },
})`
`;

export const DetailsButtonFooter = styled(Footer)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ActionButtonView = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FooterButtonView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${Sizes.buttonHeight}px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: ${(props: IProps) => props.backgroundColor || '#000000'};
  border-radius: 3px;
  border-width: 0;
  z-index: 0;
`;

export const FooterButtonGradient = styled(LinearGradient)`
  height: ${Sizes.buttonHeight}px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  z-index: 0;
`;
