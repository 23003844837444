import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CategoryChooseContainer = styled.div`
    max-width: 300px;
    min-width: 320px;
    padding: 16px 20px 12px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const VerifiedModalItemView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const VerifiedModalTitle = styled.p`
  margin-top: 1px;
  margin-left: 10px;
  color: ${Colors.white};
  font-size: 19px;
`;



export const VerifiedModalTextsView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const VerifiedModalDescription = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;

export const VerifiedModalMoreButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

export const VerifiedModalMoreText = styled.p`
  color: ${Colors.accent};
  font-size: 15px;
  text-align: center;
`;
