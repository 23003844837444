import React from 'react';

import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { Colors, Sizes } from '@noitada/shared/constants';

import Vars from '@noitada/shared/environments/variables';

import { TranslateConfig } from '@noitada/shared/translations';

import { Content, Screen, Title } from '../../../components/Composh/web';

import { ButtonBack } from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconTermUse,
  IconTermLicences,
  IconTermPrivacity,
} from '../../../components/Icons';

import ItemsList from '../../../components/Items/ItemsList';

import NameRoutes from '../../../navigation/names';

import {
  ContentPadderHorizontal,
  DetailsSectionView,
} from '../../../styles/styled.layout';

const TermsPolicyScreen: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();

  function renderItemList(icon: any, title: string, onPress: any) {
    return (
      <ItemsList
        onPress={onPress}
        iconLeft={icon}
        title={title}
        titleColor={Colors.white}
        iconRight={null}
        border={false}
      />
    );
  }

  return (
    <Screen backgroundColor={Colors.appBackground}>
      <Helmet>
        <title>
          {`${translate(TranslateConfig.ABOUT)} | ${translate(
            TranslateConfig.NAME_APP,
          )}`}
        </title>
      </Helmet>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={<ButtonBack color={Colors.white} />}
        centerContent={<Title>{translate(TranslateConfig.TERMS_POLICY)}</Title>}
      />

      <Content>
        <ContentPadderHorizontal>
          <DetailsSectionView>
            {renderItemList(
              <IconTermLicences
                size={Sizes.iconAndroidSize}
                color={Colors.white}
              />,
              translate(TranslateConfig.LICENCES),
              () => {
                history.push({
                  pathname: NameRoutes.WebViewScreen,
                  state: {
                    title: translate(TranslateConfig.LICENCES),
                    link: Vars().licencesLink,
                  },
                });
              },
            )}

            {renderItemList(
              <IconTermUse size={Sizes.iconAndroidSize} color={Colors.white} />,
              translate(TranslateConfig.ACCESS_USER_TERMS_USE),
              () => {
                history.push({
                  pathname: NameRoutes.WebViewScreen,
                  state: {
                    title: translate(TranslateConfig.ACCESS_USER_TERMS_USE),
                    link: Vars().termsUseLink,
                  },
                });
              },
            )}

        
            <a href='/privacidade' style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
              <div style={{ display: 'flex', minWidth: 35, minHeight: 40, marginLeft:3, marginRight:12, alignItems: 'center', justifyContent: 'center' }}> 
              <IconTermPrivacity
              
                size={Sizes.iconAndroidSize}
                color={Colors.white}
              />
              </div>
              <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                {translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY)}
              </h3>
            </a>
          </DetailsSectionView>
        </ContentPadderHorizontal>
      </Content>
    </Screen>
  );
};

export default TermsPolicyScreen;
