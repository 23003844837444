import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  selected?: boolean;
  borderBottomWidth?: number;
}



export const LocationItemContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  margin-left: 20px;
  margin-right: 10px;
  padding-top: 14px;
  padding-left: 6px;
  padding-right: 8px;
  padding-bottom: 14px;
  border-bottom-color: gray;
  border-bottom-style: solid;
  border-bottom-width: ${(props: IProps) => props.borderBottomWidth}px;
`;

export const LocationItemInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LocationItemTitle = styled.h3`
  color: ${(props: IProps) => props.selected ? Colors.accent : Colors.white};
  font-size: 18px;
`;


export const LocationItemIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding-bottom: 7px;
`;
