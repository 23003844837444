import DEV_ENV from './environment.dev';
import LOCAL_ENV from './environment.local';
import PROD_ENV from './environment.prod';



const AppEnvironments = (envInsert?: string) => {
  const env = envInsert || process.env.REACT_APP_NODE_ENV;
  switch (env) {
    case 'DEV': return DEV_ENV;
    case 'PROD': return PROD_ENV;
    case 'LOCAL': return LOCAL_ENV;
    default: return DEV_ENV;
  }
};



export default AppEnvironments;
