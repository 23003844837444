export const ANIMATIONS = {
  animationTime:                        300,
};



export const HELPCOUNT_ADDRESS = {
  zipCount:                             8,
  
  streetMinCount:                       8,
  streetMaxCount:                       256,

  numberMinCount:                       1,
  numberMaxCount:                       30,

  complementMinCount:                   8,
  complementMaxCount:                   256,

  districtMinCount:                     3,
  districtMaxCount:                     256,

  cityMinCount:                         3,
  cityMaxCount:                         256,

  stateMinCount:                        3,
  stateMaxCount:                        100,

  countryMinCount:                      3,
  countryMaxCount:                      100,
};



export const HELPCOUNT_COMMON = {
  shortIdCount:                         5,
  tableCount:                           5,
  dateCount:                            8,
  hourCount:                            4,
};



export const HELPCOUNT_ORDER = {
  nameItem:                             100,
  aboutText:                            250,

  priceValue:                           6,
};



export const HELPCOUNT_PROFILE = {
  nameMinCount:                         10,
  nameMaxCount:                         200,

  cpfCount:                             11,

  cnpjCount:                            14,

  usernameMinCount:                     7,
  usernameMaxCount:                     50,

  birthdayCount:                        10,

  emailMinCount:                        11,
  emailMaxCount:                        60,

  passwordMinCount:                     8,
  passwordMaxCount:                     15,

  phoneMinCount:                        10,
  phoneMaxCount:                        11,
};



export const TIMES = {
  mountTime:                            200,
  snackBarTime:                         1800,
};



const Values = {
  ...ANIMATIONS,
  ...HELPCOUNT_ADDRESS,
  ...HELPCOUNT_COMMON,
  ...HELPCOUNT_ORDER,
  ...HELPCOUNT_PROFILE,
  ...TIMES,
};



export default Values;
