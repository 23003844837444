import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EEventType,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CardDashEventStyle,
  ImageBackgroundEvent,
  ButtonBackgroundEvent,
  TextGradientContainer,
  TextTypeEvent,
  TextEventName,
  TextDate,
} from './styled';



export interface IProps {
  type: 'PARTY' | 'NIGHT_PARTY';
  size: number;

  name?: string;
  image?: string;
  startTime?: string;
  onPress?: any;

  style?: any;
}



const CardDashEvent: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const eventImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.placeholderEvent
    : props.image;



  function iconTypText(eventType: string) {
    switch (eventType) {
      case EEventType.PARTY:
        return translate(TranslateConfig.PARTY);

      case EEventType.NIGHT_PARTY:
        return translate(TranslateConfig.NIGHT_PARTY);

      default:
        return '-';
    }
  }



  return (

    <CardDashEventStyle
      size={props.size}
      onClick={props.onPress}>

      <ButtonBackgroundEvent>

        <ImageBackgroundEvent
          src={eventImage}
        />


        <TextGradientContainer
          angle={180}
          colors={[
            Colors.transparent,
            'rgba(0, 0, 0, 0.7)',
            'rgba(0, 0, 0, 0.9)',
          ]}
          locations={[0, 0.4, 0.99]}>


          <TextTypeEvent>
            {iconTypText(props.type).toUpperCase()}
          </TextTypeEvent>


          <TextEventName>
            {props.name || '-'}
          </TextEventName>


          <TextDate>
            {props.startTime || '-'}
          </TextDate>


        </TextGradientContainer>

      </ButtonBackgroundEvent>

    </CardDashEventStyle>

  );
};



CardDashEvent.defaultProps = {
  size: 350,
};



export default CardDashEvent;
