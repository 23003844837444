import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetCarnivalsFestivals,
  GetHighlights,
} from '@noitada/axios-config/endpoints/highlights.endpoints';

import {
  IHighlights,
} from '@noitada/axios-config/interfaces/highlights.inteface';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  USER_SET_CURRENT_EVENT,
} from '@noitada/redux-config/reducers/event.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CompanyOpenHourText,
} from '@noitada/shared/utils/company.utils';

import {
  getMomentInstance,
  FormatIsoDateWithPattern,
} from '@noitada/shared/utils/date.utils';

import ButtonCityUser from '../../../components/Buttons/ButtonCitiesApp/CityUser';
import CardProfileLight from '../../../components/Cards/CardCompany/CardProfileLight';
import CardDashEvent from '../../../components/Cards/CardEvents/CardDashEvent';
import CardDashEventLight from '../../../components/Cards/CardEvents/CardDashEventLight';
import CardEventsHome from '../../../components/Cards/CardEvents/CardEventsHome';

import {
  Container,
  Content,
  ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
  ButtonNotifications,
  ButtonSearch,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import HomeTitle from '../../../components/Texts/HomeTitle';

import NameRoutes from '../../../navigation/names';

import {
  ChangeEventTypeButton,
  ChangeEventTypeImage,
} from '../../../styles/styled.layout';

import {
  HighlightGridHeader,
  HighlightTextsContent,
  HighlightTitleToday,
  HighlightDate,
  HomeSectionView,
  TitleHomeView,
  TitleHomeEvents,
  ScrollContentFlex,
  HightlightsScrollHorizontalItems,
  HighlightEventScrollFlatList,
} from './styled';



const TabHighlightsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);


  const [localitie, setLocalitie] = useState(localities);

  const [highlights, setHighlights] = useState<IHighlights | null>(null);

  const [availableCarnival, setAvailableCarnival] = useState(false);

  const [availableFestivals, setAvailableFestivals] = useState<any>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getHighlights() {
    try {
      const data = await GetHighlights(localities);
      return data;
    }
    catch (error: any) {
      console.error('getHighlights', JSON.stringify(error.response, null, 2));
      throw Error();
    }
  }


  async function getFestivalsCarnivals() {
    try {
      const data = await GetCarnivalsFestivals(localities);
      return data;
    }
    catch (error: any) {
      console.error('getFestivalsCarnivals', JSON.stringify(error.response, null, 2));
      throw Error();
    }
  };


  async function getAllHighlights() {
    try {
      setLoading(true);

      const [highlightsResponse, itemsFestivalCarnival] = await Promise.all([getHighlights(), getFestivalsCarnivals()]);
      const highlightsData: IHighlights = highlightsResponse;

      if (highlightsData?.highlights.length > 0 || highlightsData?.partners.length > 0 || highlightsData?.partnersEvents.length > 0 || highlightsData?.events.length > 0) {
        setHighlights(highlightsResponse);
      }
      else {
        setHighlights(null);
      }

      if (itemsFestivalCarnival) {
        if (itemsFestivalCarnival?.carnival) {
          setAvailableCarnival(!!itemsFestivalCarnival?.carnival);
        }
        if (itemsFestivalCarnival?.festivals?.length > 0) {
          setAvailableFestivals(!!itemsFestivalCarnival?.festivals);
        }
      }
      else {
        setAvailableCarnival(false);
        setAvailableFestivals(null);
      }
    }
    catch (error: any) {
      console.error(error);
      setHighlights(null);
      setAvailableCarnival(false);
      setAvailableFestivals(null);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderCardDashEvent(item: any, index: any) {
    return (

      <CardDashEvent
        key={index}
        size={280} // (CardWidth) - (MarginLeft + MarginRight)
        name={item.name}
        type={item.type}
        startTime={item.startTime}
        image={item.image}
        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_EVENT, payload: item });
          history.push({
            pathname: NameRoutes.DetailsEventScreen.replace(
              ':event_id',
              item?.idShort,
            ),
          });
        }}
      />

    );
  };


  function renderCardProfileLight(item: any, index: any) {
    return (

      <CardProfileLight
        key={index}
        image={item?.picture}
        name={item?.name}
        hour={CompanyOpenHourText(item) || translate(TranslateConfig.CLOSED)}
        rating={item?.rating}
        place={item?.address?.district}
        onPress={() => {
          history.push(`/${item.company_username}`);
        }}
      />

    );
  }


  function renderCardDashEventLight(item: any, index: any) {
    return (

      <CardDashEventLight
        key={index}
        size={180} // (CardWidth) - (MarginLeft + MarginRight)
        marginHorizontal={8}
        image={item.image}
        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_EVENT, payload: item });
          history.push({
            pathname: NameRoutes.DetailsEventScreen.replace(
              ':event_id',
              item?.idShort,
            ),
          });
        }}
      />

    );
  }


  function listHeaderEvent() {
    return (

      <TitleHomeEvents>
        <HomeTitle
          title={translate(TranslateConfig.EVENTS_MORE)}
          size={20}
        />
      </TitleHomeEvents>

    );
  }


  function renderCardEvent(item: any, index: any) {
    return (

      <CardEventsHome
        key={index}
        companyName={item?.company?.name}
        companyImage={item?.company?.picture}
        eventName={item?.name}
        eventImage={item?.picture}
        startDate={FormatIsoDateWithPattern(item?.startAt, 'dd/MM/yyyy - HH:mm')}
        endDate={FormatIsoDateWithPattern(item?.endAt, 'dd/MM/yyyy - HH:mm')}
        place={item?.address?.district}
        availables={item?.availables}
        onPicturePress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item?.company });
          history.push({
            pathname: NameRoutes.DetailsCompanyScreen.replace(
              ':company_username',
              item?.company?.company_username,
            ),
          });
        }}
        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_EVENT, payload: item });
          history.push({
            pathname: NameRoutes.DetailsEventScreen.replace(
              ':event_id',
              item?.idShort,
            ),
          });
        }}
      />

    );
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.EMPTY_HIGHLIGHTS_ITEMS)}
      />

    );
  }


  function addCarnivalButton() {
    const showCarnivalButton = availableCarnival;

    if (showCarnivalButton) {
      return (

        <ChangeEventTypeButton
          onClick={() => {
            history.replace(NameRoutes.CarnivalGridScreen);
          }}>

          <ChangeEventTypeImage
            alt={'cover'}
            src={Images.carnivalImageButton}
          />
        </ChangeEventTypeButton>

      );
    }
  }


  function addFestivalButton() {
    const showFestivalButton = availableFestivals?.length > 0;

    if (showFestivalButton) {
      return (

        <ChangeEventTypeButton
          onClick={() => {
            history.replace(NameRoutes.FestivalGridScreen);
          }}>

          <ChangeEventTypeImage
            alt={'image'}
            src={Images.festivalImageButton}
          />
        </ChangeEventTypeButton>

      );
    }
  }



  useEffect(() => {
    if (!highlights) {
      setLocalitie(localitie);
      getAllHighlights();
      return;
    }
    if (localitie !== localities) {
      setLocalitie(localitie);
      getAllHighlights();
    }
  }, [localities]);



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityUser />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonSearch
              color={Colors.white}
            />

            <ButtonNotifications
              color={Colors.white}
            />

            <ButtonMore
              color={Colors.white}
            />
          </ToolbarGroup>
        }
        noSubToolbarPadding>


        <HighlightGridHeader>

          <HighlightTextsContent>
            <HighlightTitleToday>
              {translate(TranslateConfig.TODAY)}
            </HighlightTitleToday>

            <HighlightDate>
              {getMomentInstance().format('LL')} {/* TRANSLATE */}
            </HighlightDate>
          </HighlightTextsContent>


          {addFestivalButton()}


          {addCarnivalButton()}

        </HighlightGridHeader>

      </HeaderScreen>



      <Content>

        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {!loading && !highlights && (
          listEmptyComponent()
        )}



        {!loading && highlights && (
          <ScrollContentFlex>

            {highlights?.highlights && highlights?.highlights.length > 0 && (
              <HomeSectionView>
                <TitleHomeView>
                  <HomeTitle
                    title={translate(TranslateConfig.HIGHLIGHTS)}
                    size={20}
                  />
                </TitleHomeView>

                <HightlightsScrollHorizontalItems>
                  {highlights?.highlights?.map((item, index) => (
                    renderCardDashEvent(item, index)
                  ))}
                </HightlightsScrollHorizontalItems>
              </HomeSectionView>
            )}



            {highlights?.partners && highlights?.partners.length > 0 && (
              <HomeSectionView>
                <TitleHomeView>
                  <HomeTitle
                    title={translate(TranslateConfig.PARTNERS_HIGHLIGHTS)}
                    size={20}
                  />
                </TitleHomeView>

                <HightlightsScrollHorizontalItems>
                  {highlights?.partners.map((item, index) => (
                    renderCardProfileLight(item, index)
                  ))}
                </HightlightsScrollHorizontalItems>

              </HomeSectionView>
            )}



            {highlights?.partnersEvents && highlights?.partnersEvents.length > 0 && (
              <HomeSectionView>
                <TitleHomeView>
                  <HomeTitle
                    title={translate(TranslateConfig.EVENTS_HIGHTLIGHTS)}
                    size={20}
                  />
                </TitleHomeView>

                <HightlightsScrollHorizontalItems>
                  {highlights?.partnersEvents?.map((item, index) => (
                    renderCardDashEventLight(item, index)
                  ))}
                </HightlightsScrollHorizontalItems>
              </HomeSectionView>
            )}



            {highlights?.events && highlights?.events?.length > 0 && (
              <HighlightEventScrollFlatList
                loading={loading}
                data={highlights?.events}
                renderItem={renderCardEvent}
                listHeaderComponent={listHeaderEvent()}
                listLoadingComponent={renderLoading()}
              />
            )}

          </ScrollContentFlex>
        )}

      </Content>

    </Container>

  );
};



export default TabHighlightsScreen;
