import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  returnIconAvailables,
} from '../../../../config/icon-availables.config';

import {
  IconMapPin,
} from '../../../Icons';

import {
  CardEventContainer,
  CardEventContent,
  CardHeader,
  CardPlaceImageButton,
  CardPlaceLogo,
  CardInfoHeader,
  CardEventName,
  CardEventPlace,
  CardEventDate,
  CardEventBannerContainer,
  CardEventBanner,
  CardFooter,
  CardItemsPlace,
  GenreIconContent,
  CardEventLocation,
  CardItemsAvailable,
  CardAvailableViewItems,
} from './styled';



export interface IProps {
  companyName?: string;
  companyImage?: any;
  eventName?: string;
  eventImage?: any;
  startDate?: string | null;
  endDate?: string | null;
  place?: string;
  availables?: any;
  onPicturePress?: any;
  onPress?: any;
}



const CardEventsHome: React.FC<IProps> = (props: IProps) => {
  const [availableList, setAvailableList] = useState([]);



  function renderAvailables() {
    const colorIcon = Colors.textDescription;
    const itemAvailableList: any = [];

    const availablesEdit: Array<string> = props.availables?.split(', ') || [];

    if (availablesEdit.length > 0) {
      availablesEdit.forEach((item: any) =>
        itemAvailableList.push(
          returnIconAvailables(item,
            colorIcon,
            23,
          ),
        ),
      );
    }

    setAvailableList(itemAvailableList);
  }



  useEffect(() => {
    renderAvailables();
  }, [props.availables]);



  return (

    <CardEventContainer>
      <CardEventContent>

        <CardHeader>

          <CardPlaceImageButton
            onClick={props.onPicturePress}>

            <CardPlaceLogo
              alt={'logo'}
              src={
                props.companyImage === '' || props.companyImage === null || props.companyImage === undefined
                  ? Images.placeholderAvatarCompany
                  : props.companyImage
              }
            />

          </CardPlaceImageButton>



          <CardInfoHeader
            onClick={props.onPress}>

            <CardEventName>
              {props.eventName || '-'}
            </CardEventName>


            <CardEventPlace>
              {props.companyName || '-'}
            </CardEventPlace>


            {/* FIXME: Melhorar datas */}
            <CardEventDate>
              {props.startDate} --&gt; {props.endDate}
            </CardEventDate>

          </CardInfoHeader>

        </CardHeader>



        <CardEventBannerContainer
          onClick={props.onPress}>
          <CardEventBanner
            alt={'Banner'}
            src={
              props.eventImage === '' || props.eventImage === null || props.eventImage === undefined
                ? Images.placeholderEvent
                : props.eventImage
            }
          />
        </CardEventBannerContainer>



        <CardFooter
          onClick={props.onPress}>

          <CardItemsPlace>
            <GenreIconContent>
              <IconMapPin
                size={14}
                color={Colors.white}
              />
            </GenreIconContent>


            <CardEventLocation>
              {props.place || '-'}
            </CardEventLocation>
          </CardItemsPlace>


          {availableList.length > 0 && (
            <CardItemsAvailable>
              {availableList.map((option: any, index: number) => (
                <CardAvailableViewItems
                  key={index}>
                  {option}
                </CardAvailableViewItems>
              ))}
            </CardItemsAvailable>
          )}

        </CardFooter>

      </CardEventContent>
    </CardEventContainer>

  );
};



export default CardEventsHome;
