import React from 'react';

import {
  CountTimeView,
  CountTimeText,
  CountHelpText,
} from './styled';



export interface IProps {
  title?: string,
  description?: string,
}



const TimeContent: React.FC<IProps> = (props: IProps) => {
  return (

    <CountTimeView>
      <CountTimeText>
        {props.title || '-'}
      </CountTimeText>

      <CountHelpText>
        {props.description || '-'}
      </CountHelpText>
    </CountTimeView>

  );
};



export default TimeContent;
