import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Content,
} from '../../../../components/Composh/web';



export const ReserveRevisionRulesContainer = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const ReserveOkTitle = styled.h3`
  margin-top: 16px;
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 26px;
  font-weight: bold;
  text-align: center;
`;


export const ReserveOkImage = styled.img`
  width: 180px;
  height: 180px;
  object-fit: contain;
`;



export const ReserveRevisionView = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;


export const ReserveRevisionTitle = styled.h1`
  margin-bottom: 20px;
  color: white;
  font-size: 18px;
  text-align: center;
`;

export const ReserveRevisionRulesView = styled.div`
  display: flex;
  flex-direction: column;
`;



export const RevisionRuleCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const RevisionRuleViewIndex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  background-color: ${Colors.primary};
  border-radius: 50px;
`;

export const RevisionRuleIndex = styled.p`
  color: white;
  font-size: 19px;
  font-weight: bold;
`;

export const RevisionRuleText = styled.p`
  margin-left: 15px;
  color: white;
  font-size: 16px;
`;
