import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  DetailsSectionView,
  SubtitleDetails,
  SubtitleText,
} from '../../../../../../styles/styled.layout';

import {
  CommentsView,
} from './styled';



export interface IProps {
  comments?: string;
}



const DetailsComments: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <DetailsSectionView>
      <SubtitleDetails>
        {translate(TranslateConfig.COMMENTS)}
      </SubtitleDetails>


      <CommentsView>

        {props.comments && (
          <SubtitleText>
            {props.comments}
          </SubtitleText>
        )}


        <SubtitleText>
          * {translate(TranslateConfig.HELP_EVENT_RATING)}
        </SubtitleText>


        <SubtitleText>
          * {translate(TranslateConfig.HELP_EVENT_ALCOHOL)}
        </SubtitleText>

      </CommentsView>
    </DetailsSectionView>

  );
};



export default DetailsComments;
