import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  ChangeTicketUser,
} from '@noitada/axios-config/endpoints/entrance-ticket.endpoints';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import AlertMessage from '../../../../../components/Alerts';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../../components/Controls';

import FooterChoose from '../../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../../components/Headers/HeaderScreen';
import InputText from '../../../../../components/Inputs/InputText';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import {
  ButtonText,
  ContentPadder,
  RequiredText,
} from '../../../../../styles/styled.layout';

import {
  ChangeTicketContent,
  ChangeTicketTitle,
  ChangeTicketSubTitle,
} from './styled';



const ChangeTicketScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const formik = useRef<FormikProps<any>>(null);

  const [changeAlert, setChangeAlert] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  async function saveItemCategory(values: any) {
    const ticketId = '';

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ChangeTicketUser(ticketId, values.username);

      if (response) {
        showSnackBarProps(Colors.accept, 'Troca realizada com sucesso');
        history.goBack();
      }
      else {
        showSnackBarProps(Colors.danger, 'Erro ao fazer a troca');
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{
        username: '',
      }}
      // validationSchema={OrderCategoryValidationSchema}
      onSubmit={(values) => {
        // KeyboardModal.dismiss();
        saveItemCategory(values);
      }}>
      {({
        dirty,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>

          <Container>

            <HeaderScreen
              backgroundColor={Colors.toolbarBackground}
              subBackgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.white}
                />
              }
              centerContent={
                <Title>
                  Trocar titularidade
                </Title>
              }
            />



            {!mounted && (
              <LoadingScreen
                type={'SCREEN'}
                color={Colors.primary}
              />
            )}



            {mounted && (
              <Content>
                <ContentPadder>

                  <RequiredText>
                    * Campos obrigatórios
                  </RequiredText>


                  <InputText
                    disabled={loading}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={'TEXT'}
                    labelText={'Nome de usuário *'}
                    placeholderText={'Nome de usuário'}
                    // helpText={translate(errors.username || '')}
                    countLimit={Values.usernameMaxCount}
                    value={values.username}
                    onChange={handleChange('username')}
                    onBlur={handleBlur('username')}
                  />



                  <ChangeTicketContent>

                    <ChangeTicketTitle>
                      Para trocar a titularidade, a outra pessoa deve está cadastrada na Noitada. Insira o nome de usuário da pessoa que deseja fazer a troca e clique em confirmar.
                    </ChangeTicketTitle>


                    <ChangeTicketSubTitle>
                      A titularidade de um ingresso só pode ser trocada apenas uma vez.
                    </ChangeTicketSubTitle>


                    <ChangeTicketSubTitle>
                      Se houver uma troca de titularidade, o ingresso não poderá mais ser cancelado.
                    </ChangeTicketSubTitle>

                  </ChangeTicketContent>

                </ContentPadder>
              </Content>
            )}



            <FooterChoose
              cancelColor={ArrayColors.arrayCancel}
              cancelLabel={
                <ButtonText
                  color={Colors.white}>
                  Cancelar
                </ButtonText>
              }
              cancelPress={() => {
                history.goBack();
              }}

              okDisabled={!dirty || !isValid || loading}
              okColor={!dirty || !isValid || loading
                ? ArrayColors.arrayDisabled
                : ArrayColors.arrayOk
              }
              okLabel={
                <ButtonText
                  color={Colors.white}>
                  Confirmar
                </ButtonText>
              }
              okPress={() => {
                setChangeAlert(true);
              }}
            />

          </Container>



          <AlertMessage
            visible={changeAlert}
            title={'Trocar titularidade?'}
            description={'Essa ação não poderá ser desfeita'}
            cancelText={'Não'}
            cancelPress={() => {
              setChangeAlert(false);
            }}
            okText={'Sim'}
            okPress={() => {
              handleSubmit();
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default ChangeTicketScreen;
