export const OTHERS = {
  BASE:                             16,
  FONT:                             16,
  ICON:                             16,
};



const Metrics = {
  ...OTHERS,
};



export default Metrics;
