import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  BannerImageContainer,
  BannerBackground,
  BannerImageOverlay,
  BannerInfoView,
  BannerImage,
  BannerInfoTextsView,
  BannerInfoTitle,
  BannerInfoUsername,
  BannerInfoOpenText,
} from './styled';



export interface IProps {
  image?: string;
  banner?: string;
  name?: string;
  username?: string;
  opened?: string;
}



const DetailsHeaderBlur: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const eventImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.placeholderEvent
    : props.image;



  return (

    <BannerImageContainer>

      <BannerBackground
        alt={'picture'}
        src={eventImage}
      />

      <BannerImageOverlay />



      <BannerInfoView>

        <BannerImage
          src={eventImage}
        />



        <BannerInfoTextsView>

          <BannerInfoTitle>
            {props?.name}
          </BannerInfoTitle>

          <BannerInfoUsername>
            @{props?.username}
          </BannerInfoUsername>

          {props.opened && (
            <BannerInfoOpenText>
              {translate(TranslateConfig.OPENED_UNTIL)}: {props.opened}
            </BannerInfoOpenText>
          )}
        </BannerInfoTextsView>

      </BannerInfoView>

    </BannerImageContainer>

  );
};



export default DetailsHeaderBlur;
