import styled,
{
  keyframes,
} from 'styled-components';

import {
  Sizes,
  Colors,
} from '@noitada/shared/constants';

import {
  Badge,
  Overlay,
} from '../../../../components/Composh/web';



const imageHeight = '130px';

const productImage = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(calc(-100% + ${imageHeight}));
  }
`;



export const ItemDetailOverlay = styled(Overlay).attrs({
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    width: 'calc(100% - 30px)',
    height: 'calc(100% - 30px)',
    borderRadius: Sizes.cardRadius + 'px',
  },
})`
`;


export const OrderModalView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardBackground};
`;

export const OrderModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 47px;
  padding-left: 12px;
  padding-right: 10px;
  background-color: ${Colors.toolbarBackground};
`;

export const OrderModalTitle = styled.div`
  display: flex;
  flex: 1;
  color: ${Colors.white};
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
`;

export const CloseModalButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  padding: 5px;
  background-color: ${Colors.calendarColorLight};
  border-radius: 50px;
`;



export const ItemInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  position: relative;
`;

export const ItemOrderViewImage = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: ${imageHeight};
`;

export const ItemOrderImage = styled.img`
  width: 100%;
  height: 310px;
  object-fit: cover;
  animation: ${productImage} 10s linear alternate infinite;
`;



export const ItemTypesOrderView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
  padding-left: 2px;
  padding-right: 1px;
  padding-bottom: 3px;
  background-color: ${Colors.alertOverlay};
`;

export const ItemTypesOrderIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 55px;
  margin-left: 2px;
  margin-right: 2px;
`;

export const ItemTypesViewOrderLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const ItemTypesOrderLabel = styled.p`
  margin-top: 3px;
  color: ${Colors.white};
  font-size: 11px;
  line-height: 13px;
  text-align: center;
`;



export const ItemAllDetailsView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
`;

export const ItemGroupsExtrasContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemTitleModal = styled.h1`
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  color: white;
`;

export const ItemDescriptionText = styled.h2`
  min-width: 100%;
  max-width: 100%;
  margin-top: 8px;
  font-size: 13px;
  line-height: 18px;
  color: ${Colors.textTitle};
`;



export const ItemBadgeView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-left: 4px;
  padding-right: 4px;
`;

export const ItemBadgeComp = styled(Badge.View)`
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 2px;
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 10px;
`;

export const ItemBadgeText = styled(Badge.Text)`
  font-size: 13px;
`;



export const ItemPricesFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;



export const ItemMapView = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 45px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const PriceGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;


export const OrderButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 14px;
`;


export const ItemGroupDescriptionView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ItemGroupNameText = styled.h3`
  margin-bottom: 2px;
  color: ${Colors.textApp};
  font-size: 14px;
  font-weight: bold;
`;

export const ItemGroupQuantityText = styled.p`
  margin-bottom: 1px;
  color: ${Colors.textApp};
  font-size: 11px;
`;

export const ItemGroupCodeText = styled.p`
  color: ${Colors.textApp};
  font-size: 11px;
`;

export const ItemPriceText = styled.h3`
  margin-top: 2px;
  color: ${Colors.buttonOkLight};
  font-size: 22px;
  font-weight: bold;
`;
