import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../styles/styled.layout';

import {
  AboutTableList,
  AboutTableWrapper,
  AboutTableCol,
  AboutTableColTitle,
  TableRowsStyle,
  AboutTableRowText,
} from './styled';



export interface IProps {
  operation?: any;
}



const SectionOpenHours: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const receivedHours = props.operation;


  function getHourPlace(open: boolean, hourOpen: string, hourClose: string) {
    if (open && validateString(hourOpen) && validateString(hourClose)) {
      const openHour = hourOpen.substring(0, 5);
      const closeHour = hourClose.substring(0, 5);

      return `${openHour} ${translate(TranslateConfig.AT)} ${closeHour}`;
    }
    else {
      return translate(TranslateConfig.CLOSED);
    }
  }


  const tableTitle = [
    <AboutTableColTitle>
      {translate(TranslateConfig.SUNDAY)}
    </AboutTableColTitle>,
    <AboutTableColTitle>
      {translate(TranslateConfig.MONDAY)}
    </AboutTableColTitle>,
    <AboutTableColTitle>
      {translate(TranslateConfig.TUESDAY)}
    </AboutTableColTitle>,
    <AboutTableColTitle>
      {translate(TranslateConfig.WEDNESDAY)}
    </AboutTableColTitle>,
    <AboutTableColTitle>
      {translate(TranslateConfig.THURSDAY)}
    </AboutTableColTitle>,
    <AboutTableColTitle>
      {translate(TranslateConfig.FRIDAY)}
    </AboutTableColTitle>,
    <AboutTableColTitle>
      {translate(TranslateConfig.SATURDAY)}
    </AboutTableColTitle>,
  ];


  function tableDays() {
    const {
      sunday_open, sunday_hour_open, sunday_hour_close,
      monday_open, monday_hour_open, monday_hour_close,
      tuesday_open, tuesday_hour_open, tuesday_hour_close,
      wednesday_open, wednesday_hour_open, wednesday_hour_close,
      thursday_open, thursday_hour_open, thursday_hour_close,
      friday_open, friday_hour_open, friday_hour_close,
      saturday_open, saturday_hour_open, saturday_hour_close,
    } = receivedHours;


    return [
      [
        <AboutTableRowText>
          {getHourPlace(sunday_open, sunday_hour_open, sunday_hour_close)}
        </AboutTableRowText>,
      ],
      [
        <AboutTableRowText>
          {getHourPlace(monday_open, monday_hour_open, monday_hour_close)}
        </AboutTableRowText>,
      ],
      [
        <AboutTableRowText>
          {getHourPlace(tuesday_open, tuesday_hour_open, tuesday_hour_close)}
        </AboutTableRowText>,
      ],
      [
        <AboutTableRowText>
          {getHourPlace(wednesday_open, wednesday_hour_open, wednesday_hour_close)}
        </AboutTableRowText>,
      ],
      [
        <AboutTableRowText>
          {getHourPlace(thursday_open, thursday_hour_open, thursday_hour_close)}
        </AboutTableRowText>,
      ],
      [
        <AboutTableRowText>
          {getHourPlace(friday_open, friday_hour_open, friday_hour_close)}
        </AboutTableRowText>,
      ],
      [
        <AboutTableRowText>
          {getHourPlace(saturday_open, saturday_hour_open, saturday_hour_close)}
        </AboutTableRowText>,
      ],
    ];
  };



  return (

    <DetailsSectionView>
      <SubtitleDetails>
        {translate(TranslateConfig.PARTNER_OPEN_HOURS)}
      </SubtitleDetails>


      <AboutTableList>
        <AboutTableWrapper>
          <AboutTableCol
            data={tableTitle}
            flex={1}
          />

          <TableRowsStyle
            data={tableDays()}
            flexArr={[1]}
          />
        </AboutTableWrapper>
      </AboutTableList>
    </DetailsSectionView>

  );
};



export default SectionOpenHours;
