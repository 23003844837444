import styled from 'styled-components';

import {
  Colors, Sizes,
} from '@noitada/shared/constants';

import ButtonLanguages from '../../components/Buttons/ButtonLanguages';
import ButtonLogin from '../../components/Buttons/ButtonLogin';

import {
  Content,
  LinearGradient,
} from '../../components/Composh/web';

import {
  ContentPadder,
} from '../../styles/styled.layout';



export const CircleTopLeft = styled(LinearGradient)`
  position: absolute;
  top: -155px;
  right: -10px;

  width: 185px;
  height: 185px;
  border-radius: 355px;
  z-index: 0;
`;

export const CircleTopRight = styled(LinearGradient)`
  position: absolute;
  top: -145px;
  right: -80px;

  width: 185px;
  height: 185px;
  border-radius: 195px;
  z-index: 0;
`;



export const AccessContent = styled(Content)`
  z-index: 5;
`;

export const AccessContentPadder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
`;



export const LogoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const LogoImage = styled.img`
  height: 70px;
  width: 70px;
`;

export const LoginSeparatorHorizontal = styled.img`
  height: 100%;
  width: 40px;
`;

export const LogoNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: center;
`;

export const LogoName = styled.h1`
  color: ${Colors.white};
  font-size: 32px;
  line-height: 40px;
  text-align: center;
`;

export const LogoSlogan = styled.p`
  color: ${Colors.white};
  font-size: 13.5px;
  font-style: italic;
  text-align: center;
  white-space: break-spaces;
`;


export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 13px;
  padding-bottom: 13px;
`;

export const InputsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-bottom: 5px;
`;

export const LoginButton = styled(ButtonLogin)`
  margin-top: 5px;
  margin-bottom: 5px;
`;



export const ButtonForgetPass = styled.a`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  padding-top: 7px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 10px;
`;

export const ForgetPassText = styled.p`
  color: ${Colors.accent} !important;
  font-size: 14px;
  text-align: right;
`;

export const ButtonsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  padding-bottom: 5px;
`;



export const CircleBottonLeft = styled(LinearGradient)`
  position: absolute;
  bottom: -60px;
  left: 30px;

  width: 75px;
  height: 75px;
  border-radius: 195px;
  z-index: 0;
`;

export const CircleBottonRight = styled(LinearGradient)`
  position: absolute;
  bottom: -70px;
  left: -30px;

  width: 100px;
  height: 100px;
  border-radius: 100px;
  z-index: 0;
`;



export const LoginWarningText = styled.p`
  margin-top: 10px;
  color: gray;
  font-size: 12px;
  font-weight: 100;
  text-align: center;
`;

export const FooterTermsText = styled.span`
  color: white;
  font-weight: bold;
  cursor: pointer;
`;



export const ButtonLanguagesStyled = styled(ButtonLanguages)`
  flex: 1;
  margin-top: 5px;
`;

