import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconCheck,
  IconInformation,
} from '../../Icons';

import {
  ProfilePublicButton,
} from './styled';



export interface IProps {
  copied?: boolean;
  icon?: any;
}



const CopiedItem: React.FC<IProps> = (props: IProps) => {
  return (

    <ProfilePublicButton
      backgroundColor={props.copied ? Colors.accept : Colors.transparent}>

      {props.copied
        ? (
          <IconCheck
            size={25}
            color={Colors.white}
          />
        )
        : props.icon || (
          <IconInformation
            size={25}
            color={Colors.primary}
          />
        )
      }

    </ProfilePublicButton>

  );
};



export default CopiedItem;
