export enum EEventAvailables {
  DISCOUNT = 'DISCOUNT',
  CASADINHA = 'CASADINHA',
  OPEN_BAR = 'OPEN_BAR',
  MUSIC = 'MUSIC',
  LIST = 'LIST',
  VIP = 'VIP',
}



export default EEventAvailables;
