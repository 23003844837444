import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const InfoCarnivalHeaderAccordion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  margin-bottom: 1.5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.gray};
  border-radius: 3px;
`;

export const InfoCarnivalAccordionTitle = styled.p`
  margin-left: 5px;
  margin-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: ${Colors.white};
  font-weight: 600;
`;

export const InfoGuideAccordionActionsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;
