import styled from 'styled-components';

import {
  Items,
} from '../../../../../components/Composh/web';



export const SearchTogglesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
`;

export const SearchToggle33Grid = styled(Items.Grid)`
  width: 33%;
  min-width: 33%;
  max-width: 33%;
  margin-top: 5px;
`;
