/* eslint-disable import/no-duplicates */
import {
  format,
} from 'date-fns';

import {
  pt,
} from 'date-fns/locale';

import 'moment/locale/pt-br';
import moment from 'moment';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';



export function FormatIsoDateWithPattern(isoDate: string, pattern: string) {
  try {
    if (validateString(isoDate)) {
      const newDate = new Date(isoDate);
      const dateParse = format(newDate, pattern, { locale: pt });
      return dateParse;
    }
    else {
      return null;
    }
  }
  catch (error) {
    console.error(error);
    return null;
  }
}


// export function convertDateTodayToOffsetDateTime() {
//   return moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS');
// }


// export function convertDateToOffsetDateTime(date: Date) {
//   return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
// }


// export function convertDateWithFormatToOffsetDateTime(date: Date, dateFormat: string) {
//   return moment(date, dateFormat).format('YYYY-MM-DDTHH:mm:ss.SSS');
// }


// export function convertDateWithFormatAndPattern(date: Date, dateFormat: string, pattern: string) {
//   return moment(date, dateFormat).format(pattern);
// }


export function getMomentInstance() {
  return moment();
}


export function formatDateWithPattern(date: Date, pattern: string) {
  return format(date, pattern);
}


// export function formatDateTimeWithPattern(date: number, pattern: string) {
//   return format(date, pattern);
// }

export function formatMomentWithPattern(date: Date, pattern: string) {
  return moment(date).format(pattern);
}

// export function isBefore(source: Date, target: Date) {
//   return moment(source).isBefore(target);
// }


// export function isAfter(source: Date, target: Date) {
//   return moment(source).isAfter(target);
// }


// export const shortDate = (sqlDate: any) => {
//   if (sqlDate)
//     return sqlDate.substring(8) + '/' + sqlDate.substring(5, 7);
//   else
//     return '';
// };



export function RenderHourMinutes(dateStr: string) {
  if (!validateString(dateStr)) {
    return '--:--';
  }
  const date = new Date(dateStr);
  return date.toTimeString().substring(0, 5);
}



// FIXME: Melhorar essa chamada para data-fns
export function getHoursDiff(date: Date) {
  if (!date) return '';

  const now = moment(new Date()); // Todays date
  const end = moment(date); // Another date
  const duration = now.diff(end, 'hours');
  if (duration <= 0) {
    return now.diff(end, 'minutes') + ' minutos';
  }
  return duration + ' horas';
}
