import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TabBar,
} from '../Composh/web';

import {
  TabComposhStyled,
} from './styled';



export interface IProps {
  tabs: Array<any>;
  selected: number;
  onPress: any;
}



const TabBarStyled: React.FC<IProps> = (props: any) => {
  return (

    <TabBar
      backgroundColor={Colors.transparent}
      // indicatorStyle={tabStyle.indicatorTabStyle}
      selectedIndex={props.selected}
      onSelect={(indexTabTab: number) => {
        props.onPress(indexTabTab);
      }}>
      {props.tabs.map((tab: any, index: number) => (
        <TabComposhStyled
          key={tab.name}
          title={tab.title}
          titleStyle={{
            color: props.selected === index ? Colors.primary : '#96a6b3',
            fontWeight: props.selected === index ? 'bold' : 'normal',
          }}
        />
      ))}
    </TabBar>

  );
};



export default TabBarStyled;
