import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import DetailsHeaderBlur from '../../../Details/DetailsHeaderBlur';

import {
  HistoryOpenOrderContainer,
  HistoryOpenOrderInfo,
  HistoryOpenInfoHeader,
  HistoryOpenStatusOrderText,
  HistoryOpenItemsText,
  HistoryTableText,
  HistoryOpenIdText,
  HistoryOpenInfoRight,
  HistoryOpenRightText,
  HistoryPriceRightText,
} from './styled';



export interface IProps {
  id?: string;
  name?: any;
  username?: any;
  date?: any;
  image?: any;
  table?: any;
  quantity?: any;
  price?: any;
  type?: any;
  onPress?: any;
}



const CardHistoryOpenOrder: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function renderStatusOrder(): string {
    if (props.type === 'REQUEST') {
      return translate(TranslateConfig.ORDERSHEET_OPENED);
    }

    // TODO: Fazer status do delivery

    return '-';
  }



  return (

    <HistoryOpenOrderContainer
      onClick={props.onPress}>

      <DetailsHeaderBlur
        image={props.image}
        banner={props.image}
        name={props.name}
        username={props.username}
      />



      <HistoryOpenOrderInfo>

        <HistoryOpenInfoHeader>

          <HistoryOpenStatusOrderText>
            {renderStatusOrder()}
          </HistoryOpenStatusOrderText>


          <HistoryOpenItemsText>
            {translate(TranslateConfig.ORDERSHEET)} • {props?.id || '-'}
          </HistoryOpenItemsText>

          {props?.table &&
            <HistoryTableText>
              {translate(TranslateConfig.TABLE)}: {props?.table || '-'}
            </HistoryTableText>
          }

          <HistoryOpenIdText>
            {translate(TranslateConfig.ACTION_CLICK_OPEN)}
          </HistoryOpenIdText>

        </HistoryOpenInfoHeader>



        <HistoryOpenInfoRight>

          <HistoryOpenRightText>
            {translate(TranslateConfig.ITEMS)}: {props?.quantity || '-'}
          </HistoryOpenRightText>


          <HistoryPriceRightText>
            {props?.price || '-'}
          </HistoryPriceRightText>

        </HistoryOpenInfoRight>

      </HistoryOpenOrderInfo>

    </HistoryOpenOrderContainer>

  );
};



export default CardHistoryOpenOrder;
