import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  BannerContainer,
  EventPicContentView,
  BannerImageContainer,
  BannerBackground,
  BannerImageOverlay,
  BannerImage,
} from './styled';



export interface IProps {
  image?: string;
}



const PictureEventFinished: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const eventImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.placeholderEvent
    : props.image;



  return (

    <BannerContainer
      backgroundColor={Colors.primaryDark}>
      <EventPicContentView>

        <BannerImageContainer>
          <BannerBackground
            alt={'picture'}
            src={eventImage}
          />

          <BannerImageOverlay />

          <BannerImage>
            {translate(TranslateConfig.EMPTY_EVENT_FINISHED)}
          </BannerImage>
        </BannerImageContainer>

      </EventPicContentView>
    </BannerContainer>

  );
};



export default PictureEventFinished;
