import EGeneral from '../enums/Items/general.enum';

import {
  TranslateConfig,
} from '../translations';



export const AllItems = {
  label: TranslateConfig.ALL,
  value: EGeneral.ALL,
};
