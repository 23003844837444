import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetEntranceListWithUser,
} from '@noitada/axios-config/endpoints/entrance-list.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardLists from '../../../../components/Cards/CardLists';

import {
  Body,
  Epigraph,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  ContentPadder,
  DetailsSectionView,
} from '../../../../styles/styled.layout';

import ListParticipateScreen from './Participate';

import {
  ListEventFlatList,
} from './styled';



const JoinListsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const event = useSelector((state: RootState) => state.event.selected);


  const [normalLists, setNormalLists] = useState<Array<any>>([]);
  const [differentLists, setDifferentLists] = useState<Array<any>>([]);

  const [openParticipate, setOpenParticipate] = useState<any | null>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function onPressGetList() {
    try {
      setLoading(true);

      const response = await GetEntranceListWithUser(event?.idShort, user.id);
      if (response) {
        setNormalLists(response.filter((list) => !list.special));
        setDifferentLists(response.filter((list) => list.special));
      }
      else {
        setNormalLists([]);
        setDifferentLists([]);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_LIST_GET_DATA));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }

  async function onCloseAndRecall() {
    setNormalLists([]);
    setOpenParticipate(false);
    setDifferentLists([]);
    await onPressGetList();
  }


  function renderItem(item: any, index: number) {
    return (

      <CardLists
        key={index}
        name={item?.name}
        finalDate={item?.finishDate}
        description={item?.description}

        inList={item?.inList}
        onPress={() => {
          setOpenParticipate(item);
        }}
      />

    );
  }


  function listEmptyComponent() {
    if (loading) {
      return null;
    }

    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.EMPTY_LISTS)}
      />

    );
  }


  // async function refreshList() {
  //   setRefreshing(true);
  //   await getRules();
  //   setRefreshing(false);
  // }


  useEffect(() => {
    onPressGetList();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title>
              {translate(TranslateConfig.LISTS_EVENT)}
            </Title>

            <SubTitle>
              {event?.name}
            </SubTitle>
          </Body>
        }
      />



      <ListEventFlatList style={{
        justifyContent: loading ? 'center' : 'flex-start',
      }}>


        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {!loading && normalLists?.length === 0 && differentLists?.length === 0 && (
          listEmptyComponent()
        )}



        {!loading && (normalLists?.length !== 0 || differentLists?.length !== 0) && (
          <ContentPadder>

            <Title
              color={Colors.white}>
              {translate(TranslateConfig.LISTS_ALL)}
            </Title>



            {normalLists && normalLists.length > 0 && (
              <DetailsSectionView>
                <Epigraph.Section
                  text={translate(TranslateConfig.LISTS_AVAILABLES)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />

                {normalLists && normalLists.length > 0 && normalLists.map((item: any, index: number) =>
                  renderItem(item, index),
                )}
              </DetailsSectionView>
            )}



            {differentLists && differentLists.length > 0 && (
              <DetailsSectionView>
                <Epigraph.Section
                  text={translate(TranslateConfig.LISTS_SPECIALS)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />

                {differentLists && differentLists.length > 0 && differentLists.map((item: any, index: number) =>
                  renderItem(item, index),
                )}
              </DetailsSectionView>
            )}

          </ContentPadder>
        )}

      </ListEventFlatList>



      <ListParticipateScreen
        visible={openParticipate}
        list={openParticipate}
        onCloseAndRecall={() => {
          onCloseAndRecall();
        }}
        editable={true}
        idUser={user?.id}
        canInvite={openParticipate?.canInvite}
        inList={openParticipate?.inList}
        onCancelPress={() => {
          setOpenParticipate(null);
        }}
      />

    </Screen>

  );
};



export default JoinListsScreen;
