import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const OrderSheetInfoButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: white;
  border-radius: ${Sizes.cardRadius}px;
`;



export const CardSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: 10px;
`;


export const HeaderInfoCheckinView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CheckinUserTitle = styled.h1`
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.black};
  font-weight: 400;
`;

export const CheckinUserText = styled.h3`
  color: ${Colors.black};
  font-size: 22px;
  font-weight: bold;
`;


export const OrderLimitTimeView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
`;

export const LimitTimeTitle = styled.h3`
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.primary};
  font-weight: 400;
`;

export const LimitTimeText = styled.h3`
  color: ${Colors.primary};
  font-size: 22px;
  font-weight: bold;
`;



export const HeaderInfoPreparView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

export const TotalItemsPreparView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
`;

export const OrderPreparTitle = styled.h3`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.black};
  font-weight: 400;
`;

export const OrderLartTimeText = styled.h1`
  color: black;
  font-size: 16px;
  font-weight: bold;
`;

export const TotalItemsPreparText = styled.h3`
  margin-top: 5px;
  color: ${Colors.sketch};
  font-size: 18px;
  font-weight: bold;
`;



export const CompleteHistoryButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 11px;
  background-color: ${Colors.buttonOk};
  border-bottom-left-radius: ${Sizes.cardRadius}px;
  border-bottom-right-radius: ${Sizes.cardRadius}px;
`;

export const HistoryText = styled.p`
  flex: 1;
  color: white;
  font-size: 22px;
`;
