import NoitadaWS from '../index';

// import {
//   IHighlights,
// } from '../interfaces/highlights.inteface';

// import {
//   MLocalities,
// } from '../models/Address/localities.model';



export const GetHighlights = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/highlights';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCompaniesEventsMap = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/highlights/map';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalsFestivals = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/highlights/carnivals-festivals';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};
