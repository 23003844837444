import styled from 'styled-components';

import {
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const BottomNavigationStyle = styled(BottomNavigation)`
  width: 100%;
  height: ${Sizes.BOTTOM_TOOLBAR_ANDROID}px;
  background-color: ${Colors.navigationBottomBackground};
  border-top-color: ${Colors.navigationBottomLine};
  border-top-style: solid;
  border-top-width: 2px;

  #order-sheet-button svg {
    fill: white !important;
  }

  .MuiButtonBase-root {
    width: 20%;
    min-width: 20%;
  }

  .Mui-selected {
    font-size: 0.8rem;
    color: ${Colors.buttonOkAccent} !important;
    
    svg {
      margin-bottom: 2px;
      fill: ${Colors.buttonOkAccent} !important;
    }

    .MuiBottomNavigationAction-label {
      margin-top: 2px;
    }
  }
`;

export const ActionBottomStyle = styled(BottomNavigationAction)`
  color: ${Colors.textDescription};

  svg {
    fill: ${Colors.textDescription} !important;
  }
`;
