import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECompanyType,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconEvents,
  IconOrderMenu,
  IconReserves,
} from '../../Icons';

import {
  ProfileButtonsView,
  DashLeftInfoView,
  DashProfileRatingText,
} from './styled';



export interface IProps {
  type: string;
  showBackground?: boolean;

  menuDisable?: boolean;
  menuPress?: any;

  eventDisable?: boolean;
  eventPress?: any;

  reserveDisable?: boolean;
  reservePress?: any;
}



const DetailsModules: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const allowedTypes = [ECompanyType.CARNIVAL_PRODUCER, ECompanyType.PARTY_PRODUCER, ECompanyType.GOVERN, ECompanyType.EVENT_PRODUCER];
  const showOtherModules = !allowedTypes.includes(props?.type as ECompanyType);

  const eventItemShow = true;
  const menuItemShow = showOtherModules;
  const reserveItemShow = showOtherModules;



  return (

    <ProfileButtonsView>

      {eventItemShow && (
        <DashLeftInfoView
          backgroundShow={props.showBackground}
          canClick={props.eventDisable}
          onClick={props.eventDisable ? props.eventPress : null}>

          <IconEvents
            color={props.eventDisable ? Colors.buttonOkAccent : Colors.gray}
            size={19}
          />

          <DashProfileRatingText
            color={props.eventDisable ? Colors.buttonOkAccent : Colors.gray}>
            {translate(TranslateConfig.EVENTS)}
          </DashProfileRatingText>

        </DashLeftInfoView>
      )}



      {menuItemShow && (
        <DashLeftInfoView
          backgroundShow={props.showBackground}
          canClick={props.menuDisable}
          onClick={props.menuDisable ? props.menuPress : null}>

          <IconOrderMenu
            color={props.menuDisable ? Colors.buttonOkAccent : Colors.gray}
            size={22}
          />

          <DashProfileRatingText
            color={props.menuDisable ? Colors.buttonOkAccent : Colors.gray}>
            {translate(TranslateConfig.MENUSHEET)}
          </DashProfileRatingText>

        </DashLeftInfoView>
      )}



      {reserveItemShow && (
        <DashLeftInfoView
          backgroundShow={props.showBackground}
          canClick={props.reserveDisable}
          onClick={props.reserveDisable ? props.reservePress : null}>

          <IconReserves
            color={props.reserveDisable ? Colors.buttonOkAccent : Colors.gray}
            size={19}
          />

          <DashProfileRatingText
            color={props.reserveDisable ? Colors.buttonOkAccent : Colors.gray}>
            {translate(TranslateConfig.RESERVES)}
          </DashProfileRatingText>

        </DashLeftInfoView>
      )}

    </ProfileButtonsView>

  );
};



export default DetailsModules;
