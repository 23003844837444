import styled from 'styled-components';



export const DetailsAvailableContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
`;
