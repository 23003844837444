import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  disabled?: boolean;
  backgroundColor?: string;
}



export const OpenDirectionButton = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
  margin: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: ${Sizes.buttonRadius}px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.gray};
  opacity: ${(props: IProps) => props.disabled ? 0.4 : 1};
`;

export const OpenDirectionWase = styled.a`
  ${OpenDirectionButton};
`;

export const OpenDirectionUber = styled.a`
  ${OpenDirectionButton};
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 1px;
`;

export const OpenDirectionImage = styled.img`
  width: 27px;
  height: 27px;
  margin-right: 10px;
  object-fit: contain;
`;

export const OpenDirectionText = styled.p`
  color: white;
  font-size: 14px;
  line-height: 16px;
`;
