import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetFAQ,
} from '@noitada/axios-config/endpoints/help.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Content,
  Container,
  FAB,
  Screen,
  Title,
  ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconChat,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  openWhatsApp,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  FaqSectionContainer,
  FaqSectionTitle,
  FaqCategorySectionTitle,
  FaqCategoryButton,
  FaqCategoryView,
  FaqCategoryTitle,
  FaqCategoryDescription,
  FaqCategoryBullet,
} from './styled';



const HelpFaqScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  // const faq = useSelector((state: RootState) => state.company.allCompanies);

  const [faq, setFaq] = useState<Array<any>>([]);
  const [frequency, setFrequency] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);
  // const [refreshing, setRefreshing] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getFaq() {
    try {
      setLoading(true);

      const response = await GetFAQ('noitada');

      if (response) {
        const frequencyResponse: Array<any> = [];
        const categoriesResponse: Array<any> = [];

        response?.forEach((item: any) => {
          if (item?.bullets?.length > 0) {
            categoriesResponse.push(item);
          }

          item?.bullets?.forEach((bulletItem: any) => {
            if (bulletItem?.frequent) {
              frequencyResponse.push(bulletItem);
            }
          });
        });

        setFrequency(frequencyResponse);
        setFaq(categoriesResponse);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function renderSection(title: string) {
    return (

      <FaqCategorySectionTitle>
        {title}
      </FaqCategorySectionTitle>

    );
  }


  function renderBullet(item: any, index: number) {
    return (

      <FaqCategoryView
        key={index}
        onClick={() => {
          history.push({
            pathname: NameRoutes.FaqBulletScreen,
            state: {
              bullet: item,
            },
          });
        }}>

        <FaqCategoryBullet
          color={Colors.buttonOkLight}>
          {item?.text}
        </FaqCategoryBullet>

      </FaqCategoryView>

    );
  }


  function renderItem(item: any, index: number) {
    return (

      <FaqCategoryButton
        key={index}
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={() => {
          history.push({
            pathname: NameRoutes.FaqCategoryScreen,
            state: {
              categories: item,
            },
          });
        }}>

        <FaqCategoryTitle
          color={Colors.buttonOkLight}>
          {item.title}
        </FaqCategoryTitle>


        <FaqCategoryDescription
          color={Colors.white}>
          {item.subtitle}
        </FaqCategoryDescription>

      </FaqCategoryButton>

    );
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={'Sem faq aqui, desculpe!'}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.HELP });
    getFaq();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.HELP)}
            </Title>
          }
          rightIcon={
            <ToolbarGroup>
              <ButtonMore
                color={Colors.white}
              />
            </ToolbarGroup>
          }>

          {/* TODO: Fazer um Input de Pesquisa */}

        </HeaderScreen>



        {loading && (
          renderFooter()
        )}


        {!loading && faq?.length === 0 && (
          listEmptyComponent()
        )}


        {!loading && faq.length > 0 && (
          <Content>

            <FaqSectionContainer>
              <FaqSectionTitle>
                {`Oi ${user?.publicName.split(' ')[0]},`}
              </FaqSectionTitle>

              <FaqCategoryDescription
                color={Colors.white}>
                Aqui você tira todas as suas dúvidas sobre a Noitada. Precisa de ajuda? Estamos aqui! {/* TRANSLATE */}
              </FaqCategoryDescription>
            </FaqSectionContainer>



            {frequency.length > 0 && (
              <FaqSectionContainer>
                {renderSection('Perguntas Frequentes')}

                {frequency.map((item: any, index: number) => (
                  renderBullet(item, index)
                ))}
              </FaqSectionContainer>
            )}



            {faq.length > 0 && (
              <FaqSectionContainer>
                {renderSection('Categorias')}

                {faq.map((item: any, index: number) => (
                  renderItem(item, index)
                ))}
              </FaqSectionContainer>
            )}

          </Content>
        )}

      </Container>



      <FAB
        visible={true}
        position={'right'}
        content={
          <IconChat
            color={'white'}
          />
        }
        fabColorBottom={Colors.buttonOk}
        fabColorTop={Colors.buttonOkDark}
        gradientLinear={'linear'}
        overlayColor={Colors.alertOverlay}
        onPress={() => {
          openWhatsApp();
        }}
      />

    </Screen>

  );
};



export default HelpFaqScreen;
