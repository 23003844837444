import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Card,
} from '../../../Composh/web';



export const CarnivalStreetCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 145px;
  min-height: 145px;
  max-height: 145px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const CarnivalStreetBackgroundImage = styled(Card.View)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  border-color: ${Colors.toolbarLine};
  border-style: solid;
  border-width: 0.5px;
`;

export const CarnivalStreetBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



export const CarnivalStreetContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: ${Colors.menuOverlay};
  flex: 1;
  padding-top: 10px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 10px;
  z-index: 5;
`;


export const CarnivalStreetHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const CarnivalStreetTitle = styled.p`
  margin-bottom: 5px;
  color: white;
  font-size: 27px;
  font-weight: bold;
`;

export const CarnivalStreetButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const CarnivalStreetArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`;


export const CarnivalStreetDescText = styled.p`
  color: lightgray;
  font-size: 16px;
`;
