export const AppMapStyle = [
  {
    'featureType': 'all',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#ffffff',
      },
    ],
  },
  {
    'featureType': 'all',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'color': '#000000',
      },
      {
        'lightness': 13,
      },
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#000000',
      },
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#144b53',
      },
      {
        'lightness': 14,
      },
      {
        'weight': 1.4,
      },
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'on',
      },
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#3d4ea1',
      },
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'color': '#000000',
      },
    ],
  },
  {
    'featureType': 'administrative.country',
    'elementType': 'labels.text',
    'stylers': [
      {
        'lightness': '0',
      },
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#08304b',
      },
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#106196',
      },
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#00f3ff',
      },
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#0c4152',
      },
      {
        'lightness': 5,
      },
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#009fff',
      },
    ],
  },
  {
    'featureType': 'poi.attraction',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#bd317b',
      },
    ],
  },
  {
    'featureType': 'poi.business',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'featureType': 'poi.business',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#1c59af',
      },
    ],
  },
  {
    'featureType': 'poi.government',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#0e007a',
      },
    ],
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#a93737',
      },
    ],
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#03b40a',
      },
    ],
  },
  {
    'featureType': 'poi.place_of_worship',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#8a8a8b',
      },
    ],
  },
  {
    'featureType': 'poi.school',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#610980',
      },
    ],
  },
  {
    'featureType': 'poi.sports_complex',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'color': '#1d5326',
      },
    ],
  },
  {
    'featureType': 'road',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#686ad7',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#000000',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#0b434f',
      },
      {
        'lightness': 25,
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#000000',
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#0b3d51',
      },
      {
        'lightness': 16,
      },
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#000000',
      },
    ],
  },
  {
    'featureType': 'transit',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#146474',
      },
    ],
  },
  {
    'featureType': 'water',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#021019',
      },
    ],
  },
];
