import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const TabTutorialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
`;

export const TabTutorialImage = styled.img`
  width: 170px;
  height: 170px;
  object-fit: contain;
`;

export const TabTutorialTitle = styled.h1`
  margin-top: 20px;
  color: ${Colors.white};
  font-size: 26px;
`;

export const TabTutorialText = styled.p`
  margin-top: 10px;
  color: ${Colors.gray};
  font-size: 15px;
  text-align: center;
`;


export const TabTutorialButtonView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
