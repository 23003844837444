import {
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '../../translations';

import Yup from '../base-validation.yup';



const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required(TranslateConfig.VALIDATE_EMAIL_REQUIRED)
    .email(TranslateConfig.VALIDATE_EMAIL_VALID)
    // .emailDomainValidation(TranslateConfig.VALIDATE_EMAIL_DOMAIN) // FIXME:
    .min(Values.emailMinCount, TranslateConfig.VALIDATE_MIN_CHAR.replace('{count}', String(Values.emailMinCount)))
    .max(Values.emailMaxCount, TranslateConfig.VALIDATE_MAX_CHAR.replace('{count}', String(Values.emailMaxCount))),

  password: Yup.string()
    .required(TranslateConfig.VALIDATE_PASSWORD_REQUIRED)
    .min(Values.passwordMinCount, TranslateConfig.VALIDATE_MIN_CHAR.replace('{count}', String(Values.passwordMinCount))),
});



export default LoginValidationSchema;
