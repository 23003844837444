import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  LinearGradient,
} from '../../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../../styles/styled.layout';



export interface IProps {
  disabled?: boolean;
  color?: string;
}



export const OrderSheetHeaderButton = styled(LinearGradient)`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
`;

export const OrderSubToolbar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: -30px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const OrderHelloTitle = styled.h1`
  color: ${Colors.white};
  font-size: 25px;
  font-weight: 700;
`;


export const OrderBagButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  right: 16px;
  width: 70px;
  height: 70px;
`;

export const OrderBagTitle = styled.p`
  margin-top: 9px;
  font-size: 14px;
`;

export const OrderBadgeView = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -12px;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  background-color: ${Colors.danger};
  border-radius: 50px;
`;

export const OrderBadgeText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
`;



export const PlaceSheetView = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.toolbarBackground};
  margin-top: 20px;
`;

export const PlaceImageSheetView = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 5px;
`;

export const SheetPlaceImage = styled.img`
  width: 85px;
  height: 85px;
  margin-top: -25px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;

  elevation: 10;

  shadow-offset: 10px 10px;
  shadow-color: black;
  shadow-radius: 4px;
  shadow-opacity: 0.5;
  z-index: 5;
`;

export const SheetPlaceNameText = styled.h1`
  width: 100%;
  margin-top: 10px;
  color: ${Colors.textSubtitle};
  font-size: 16px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;


export const PlaceItemSheetView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 5px;
`;

export const SheetPlaceTitle = styled.h1`
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 16px;
  color: gray;
`;

export const TableNumberTitle = styled.h1`
  margin-top: -2px;
  color: ${Colors.buttonOkLight};
  font-size: 46px;
  font-weight: bold;
`;


export const PlacePriceSheetView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding-left: 10px;
  padding-right: 5px;
`;

export const TablePriceTitle = styled.h1`
  color: ${Colors.buttonOkLight};
  font-size: 25.5px;
  font-weight: bold;
`;

export const ContentOrderSheet = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
`;


export const OrderSheetActionView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
`;

export const OrderSheetActionButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 49%;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.disabled && 'opacity: 0.6'};
`;

export const OrderSheetActionIcon = styled.div`
  margin-right: 12px;
`;

export const OrderSheetActionText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`;



export const SheetHelpFooterView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

export const SheetHelpView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`;

export const WaiterSheetButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 53%;
  height: 60px;
  padding-left: 8px;
  padding-right: 8px;
  border-style: solid;
  border-width: 0.5px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const WaiterSheetView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const WaiterAttentionIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 25px;
  height: 25px;
  background-color: ${Colors.danger};
  border-radius: 50px;
`;

export const WaiterSheetImage = styled.img`
  width: 47px;
  height: 47px;
  margin-right: 8px;
  border-radius: 50px;
`;

export const WaiterSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WaiterSheetText = styled.p`
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 13px;
  line-height: 16px;
  text-align: center;
`;

export const WaiterSheetCancelText = styled.p`
  color: ${Colors.danger};
  font-size: 11px;
  text-align: center;
`;


export const HelpSheetButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const HelpSheetTitle = styled.p`
  color: ${Colors.white};
  font-size: 18px;
`;

export const HelpSheetText = styled.p`
  color: ${Colors.buttonOkAccent};
  font-size: 13px;
`;
