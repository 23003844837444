import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../styles/styled.layout';

import {
  TextRules,
  TextIndexRules,
} from './styled';



export interface IProps {
  rules: any;
}



const SectionRules: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [rules, setRules] = useState<Array<any>>([]);



  function getRules() {
    if (props.rules) {
      setRules(props.rules);
      return;
    }

    setRules([]);
  };



  useEffect(() => {
    getRules();
  }, []);



  return (

    <DetailsSectionView>
      <SubtitleDetails>
        {translate(TranslateConfig.PARTNER_RULES)}
      </SubtitleDetails>


      {rules?.map((rule: any, index: number) => (
        validateString(rule?.description) && (
          <TextRules
            key={index}>
            <TextIndexRules>
              {index + 1} -
            </TextIndexRules>
            &nbsp; {rule?.description}
          </TextRules>
        )
      ))}

    </DetailsSectionView>

  );
};



export default SectionRules;
