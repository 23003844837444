import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  width?: number;
  maxWidth?: number;
  backgroundColor?: string;
  color?: string;
}



export const CarnivalMapsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 350px;
  background-color: ${Colors.cardCarnival};
  border-radius: ${Sizes.cardRadius}px;
`;


export const CarnivalMapTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
`;



export const CarnivalMapTypesButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 90px;
  margin: 2px;
  padding-top: 4px;
  padding-left: 1px;
  padding-right: 1px;
  background-color: ${(props: IProps) => props.backgroundColor};
`;

export const CarnivalMapTypesText = styled.p`
  width: 100%;
  min-height: 30px;
  margin-top: 10px;
  color: ${(props: IProps) => props.color};
  font-size: 11px;
  line-height: 14px;
  text-align: center;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CarnivalMarkAllView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  margin-top: 2px;
  background-color: ${Colors.cardBackground};
`;

export const CarnivalMarkAllButton = styled.a`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const CarnivalMarkAllIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

export const CarnivalMarkAllText = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  text-align: center;
`;
