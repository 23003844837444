import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECompanyServices,
} from '@noitada/shared/enums';

import {
  IconWifi,
  IconKids,
  IconParking,
  IconSmoking,
  IconAirConditioning,
  IconAccessible,
} from '../components/Icons';



export function returnIconCompanyServices(value: string | undefined, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  if (!value) {
    return;
  }

  switch (value) {
    case ECompanyServices.WIFI:
      return (
        <IconWifi
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyServices.KIDS_AREA:
      return (
        <IconKids
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyServices.PARKING:
      return (
        <IconParking
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyServices.SMOKING:
      return (
        <IconSmoking
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyServices.AIR_CONDITIONER:
      return (
        <IconAirConditioning
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyServices.ACCESSIBILITY:
      return (
        <IconAccessible
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      break;
  }
};
