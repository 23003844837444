import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  ICarnivalPhones,
} from '@noitada/axios-config/interfaces/carnival-phones.interface';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardCarnivalGuidePhone from '../../../../components/Cards/CardCarnival/CardCarnivalGuidePhone';

import {
  Content,
} from '../../../../components/Composh/web';

import EmptyContent from '../../../../components/Empty';

import {
  IconClose,
  IconPhone,
} from '../../../../components/Icons';

import InputText from '../../../../components/Inputs/InputText';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  openPhone,
} from '../../../../config/linking.config';

import {
  FormatPhone,
} from '../../../../config/phone.config';

import {
  PhoneGuideHeaderContent,
  PhoneGuideTitle,
  PhoneGuideSearchItem,
  PhoneGuideClearButton,
  FilteredListSection,
  PhoneGuideFlatList,
} from './styled';



export interface IProps {
  loading: boolean;
  data: Array<ICarnivalPhones>;
}



const TabGuidePhone: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [loading, setLoading] = useState<boolean>(props.loading);
  const [arrayData, setarrayData] = useState<Array<ICarnivalPhones>>(props.data?.length > 0 ? props.data : []);

  const [filterPhone, setFilterPhone] = useState('');
  const [filterArrayPhone, setFilterArrayPhone] = useState<Array<ICarnivalPhones>>([]);



  function returnListFiltered() {
    const getAllTofilterPhoneArray = arrayData?.filter((phoneFilter: any) => (phoneFilter.name.toLowerCase().includes(filterPhone.toLowerCase()) || phoneFilter.name.includes(filterPhone.toLowerCase())) || filterPhone === '');
    setFilterArrayPhone(getAllTofilterPhoneArray);
  }


  function getPhoneList() {
    if (props.data?.length > 0) {
      setarrayData(props.data);
    }
    else {
      setarrayData([]);
    }
  }


  function renderItem(item: any, index: number) {
    return (

      <CardCarnivalGuidePhone
        key={index}
        title={item?.name}
        phone={FormatPhone(item?.phone, 'BRL')}
        onPress={() => {
          openPhone(item?.phone);
        }}
      />

    );
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    if (loading) {
      return null;
    }

    return (

      <EmptyContent
        image={Images.noPhones}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_PHONES)}
      />

    );
  }



  useEffect(() => {
    getPhoneList();
  }, [props.data]);


  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);



  return (

    <Content>

      <PhoneGuideHeaderContent>

        <PhoneGuideTitle>
          {translate(TranslateConfig.PHONES_USEFUL)}
        </PhoneGuideTitle>


        <PhoneGuideSearchItem>
          <InputText
            disabled={loading}
            type={'TEXT'}
            backgroundColor={Colors.cardBackground}
            iconLeft={
              <IconPhone
                color={Colors.white}
                size={28}
              />
            }
            iconRight={filterPhone === ''
              ? null
              : (
                <PhoneGuideClearButton
                  onClick={() => {
                    setFilterPhone('');
                  }}>
                  <IconClose
                    color={Colors.white}
                    size={28}
                  />
                </PhoneGuideClearButton>
              )
            }
            placeholderText={translate(TranslateConfig.NAME)}
            value={filterPhone}
            // helpText={errorMessage}
            // countLimit={Values.cpfCount}
            onChange={(rawText: string) => {
              setFilterPhone(rawText);
              returnListFiltered();
            }}
          // onBlur={handleBlur('cpf')}
          />
        </PhoneGuideSearchItem>

      </PhoneGuideHeaderContent>



      {arrayData?.length !== 0 && filterArrayPhone.length !== 0 && filterPhone !== '' && (
        <FilteredListSection>
          {filterArrayPhone.map((itemFiltered: ICarnivalPhones, index: number) => renderItem(itemFiltered, index))}
        </FilteredListSection>
      )}



      {filterPhone === '' && (
        <PhoneGuideFlatList
          loading={loading}
          data={arrayData}
          renderItem={renderItem}
          listLoadingComponent={renderLoading()}
          listFooterComponent={renderFooter()}
          listEmptyComponent={listEmptyComponent()}
        />
      )}

    </Content>

  );
};



export default TabGuidePhone;
