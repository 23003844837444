import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const RevisionFinishedContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.primaryDark};
`;


export const RevisionFinishedItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const RevisionFinishedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const RevisionFinishedView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RevisionFinishedTitle = styled.h1`
  color: ${Colors.white};
  font-size: 18px;
`;

export const RevisionFinishedText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;

export const RevisionFinishedCardImage = styled.img`
  width: 65px;
  height: 44px;
  object-fit: contain;
  border-radius: ${Sizes.cardRadius}px;
`;
