import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconEmail,
  IconMoreHorizontal,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialTwitter,
  IconSocialWhatsApp,
} from '../../Icons';

import {
  InviteSocialContainer,
  InviteSocialButton,
  InviteSocialIcon,
} from './styled';



export interface IProps {
  whatsAppPress?: any,
  instagramPress?: any,
  twitterPress?: any,
  facebookPress?: any,
  emailPress?: any,
  otherPress?: any,
}



const ShareSocial: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const sizeInviteSocial = 27;



  return (

    <InviteSocialContainer>

      {props.whatsAppPress && (
        <InviteSocialButton
          activeOpacity={Sizes.ButtonActiveOpacity}
          onPress={props.whatsAppPress}
          icon={
            <InviteSocialIcon
              backgroundColor={Colors.primary}>
              <IconSocialWhatsApp
                color={Colors.white}
                size={sizeInviteSocial}
              />
            </InviteSocialIcon>
          }
          iconGridLabel={translate(TranslateConfig.WHATSAPP)}
        />
      )}


      {props.instagramPress && (
        <InviteSocialButton
          activeOpacity={Sizes.ButtonActiveOpacity}
          onPress={props.instagramPress}
          icon={
            <InviteSocialIcon
              backgroundColor={Colors.primary}>
              <IconSocialInstagram
                color={Colors.white}
                size={sizeInviteSocial}
              />
            </InviteSocialIcon>
          }
          iconGridLabel={translate(TranslateConfig.INSTAGRAM)}
        />
      )}


      {props.twitterPress && (
        <InviteSocialButton
          activeOpacity={Sizes.ButtonActiveOpacity}
          onPress={props.twitterPress}
          icon={
            <InviteSocialIcon
              backgroundColor={Colors.primary}>
              <IconSocialTwitter
                color={Colors.white}
                size={sizeInviteSocial}
              />
            </InviteSocialIcon>
          }
          iconGridLabel={translate(TranslateConfig.TWITTER)}
        />
      )}


      {props.facebookPress && (
        <InviteSocialButton
          activeOpacity={Sizes.ButtonActiveOpacity}
          onPress={props.facebookPress}
          icon={
            <InviteSocialIcon
              backgroundColor={Colors.primary}>
              <IconSocialFacebook
                color={Colors.white}
                size={sizeInviteSocial}
              />
            </InviteSocialIcon>
          }
          iconGridLabel={translate(TranslateConfig.FACEBOOK)}
        />
      )}


      {props.emailPress && (
        <InviteSocialButton
          activeOpacity={Sizes.ButtonActiveOpacity}
          onPress={props.emailPress}
          icon={
            <InviteSocialIcon
              backgroundColor={Colors.primary}>
              <IconEmail
                color={Colors.white}
                size={sizeInviteSocial}
              />
            </InviteSocialIcon>
          }
          iconGridLabel={translate(TranslateConfig.EMAIL)}
        />
      )}


      {props.otherPress && (
        <InviteSocialButton
          activeOpacity={Sizes.ButtonActiveOpacity}
          onPress={props.otherPress}
          icon={
            <InviteSocialIcon
              backgroundColor={Colors.primary}>
              <IconMoreHorizontal
                color={Colors.white}
                size={sizeInviteSocial}
              />
            </InviteSocialIcon>
          }
          iconGridLabel={translate(TranslateConfig.OTHER)}
        />
      )}

    </InviteSocialContainer>

  );
};



export default ShareSocial;
