import React from 'react';



const TabInstallments: React.FC = () => {
  return (

    // FlatList bem simples com os TouchableOpacity das quantidades de parcelas
    <div>



    </div>

  );
};



export default TabInstallments;
