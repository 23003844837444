
import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const ButtonCityUserStyle = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonCityStyle = css`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 35px;
  margin-bottom: 1px;
  padding-left: 12px;
  padding-right: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 20px;
  align-items: center;

  /* elevation: 4; */
`;


// App layout
export const ButtonCityView = styled.div`
  ${ButtonCityStyle};
  background-color: ${Colors.primary};
`;


// Carnival layout
export const ButtonCityLinear = styled.div`
  ${ButtonCityStyle};
  background-color: ${Colors.black};
`;


export const ButtonCityParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const MenuIconCity = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const MenuTextCity = styled.h1`
  display: flex;
  flex: 1;
  color: ${Colors.white};
  font-size: 15px;
  line-height: 15px;
  text-align: left;
`;
