export enum EOrderSheet {
  COMPLETED = 'COMPLETED', // FECHADA
  REFUSED = 'REFUSED',  // RECUSADA
  APPROVED = 'APPROVED', // APROVADA || ABERTA
  REQUEST = 'REQUEST', // PENDENTE
  PAYMENT_WAIT = 'PAYMENT_WAIT', // PENDENTE DE PAGAMENTO
  CHECK_WAIT = 'CHECK_WAIT', // PENDENTE DE FECHAMENTO
}



export default EOrderSheet;
