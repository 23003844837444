import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  USER_SET_CURRENT_EVENT,
} from '@noitada/redux-config/reducers/event.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CompanyOpenHourText,
} from '@noitada/shared/utils/company.utils';

import {
  FormatIsoDateWithPattern,
} from '@noitada/shared/utils/date.utils';

import CardProfile from '../../../components/Cards/CardCompany/CardProfile';
import CardEventsHome from '../../../components/Cards/CardEvents/CardEventsHome';

import {
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterChoose from '../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
// import LoadingScreen from '../../../components/Loadings';

import NameRoutes from '../../../navigation/names';

import {
  ButtonText,
} from '../../../styles/styled.layout';

import {
  SearchResultBody,
  SearchFlatList,
} from './styled';



export interface IRouteParams {
  type: 'COMPANY' | 'EVENT';
  results: any;
}



const SearchResultsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const routeLocation = useLocation<IRouteParams>();
  const params = routeLocation?.state;


  const typeReceived = params?.type;
  const resultsReceived = params?.results;

  // const [loading, setLoading] = useState(false);



  function renderCardProfile(item: any, index: any) {
    return (

      <CardProfile
        key={index}
        companyId={item?.id}
        image={item?.picture}
        name={item?.name}
        username={item?.company_username}
        verified={item?.verified}
        type={item?.type}
        district={item?.address?.district}
        open={CompanyOpenHourText(item) || translate(TranslateConfig.CLOSED)}
        // rating={item?.rating}
        params={item?.company_params}

        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push(`/${item?.company_username}`);
        }}

        menuDisable={item?.modules?.menuSheet}
        menuPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push(`/${item?.company_username}/menu`);
        }}

        eventDisable={item?.modules?.events}
        eventPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push(`/${item?.company_username}/events`);
        }}

        reserveDisable={item?.modules?.reserves}
        reservePress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push(`/${item?.company_username}/reserves`);
        }}
      />

    );
  }


  function renderCardEvent(item: any, index: number) {
    return (

      <CardEventsHome
        key={index}
        companyName={item?.company?.name}
        companyImage={item?.company?.picture}
        eventName={item?.name}
        eventImage={item?.picture}
        startDate={FormatIsoDateWithPattern(item?.startAt, 'dd/MM/yyyy - HH:mm')}
        endDate={FormatIsoDateWithPattern(item?.endAt, 'dd/MM/yyyy - HH:mm')}
        place={item?.address?.district}
        availables={item?.availables}
        onPicturePress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push(`/${item?.company_username}`);
        }}
        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_EVENT, payload: item });
          history.push({
            pathname: NameRoutes.DetailsEventScreen.replace(
              ':event_id',
              item?.idShort,
            ),
          });
        }}
      />

    );
  }


  function renderTypeSearch(): string {
    switch (typeReceived) {
      case 'COMPANY':
        return translate(TranslateConfig.PARTNERS);

      case 'EVENT':
        return translate(TranslateConfig.EVENTS);

      default:
        return '-';
    }
  }


  // function renderFooter() {
  //   if (!loading) {
  //     return null;
  //   }

  //   return (

  //     <LoadingScreen
  //       type={'FOOTER'}
  //       color={Colors.primary}
  //     />

  //   );
  // }


  function listEmptyComponent() {
    // if (loading) {
    //   return null;
    // }

    return (

      <EmptyContent
        image={Images.searchEmpty}
        title={translate(TranslateConfig.EMPTY_SEARCH)}
      />

    );
  }


  function remakeSearch() {
    history.replace(NameRoutes.SearchScreen);
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {`${translate(TranslateConfig.SEARCH)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>
      </Helmet>



      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.SEARCH)}
          </Title>
        }>

        <SearchResultBody>
          <Title>
            {`${resultsReceived?.length} resultados`}
          </Title>

          <SubTitle>
            {renderTypeSearch()}
          </SubTitle>
        </SearchResultBody>

      </HeaderScreen>



      {(!resultsReceived || resultsReceived?.length === 0) && (
        listEmptyComponent()
      )}


      {typeReceived === 'COMPANY' && resultsReceived && resultsReceived?.length > 0 && (
        <SearchFlatList>
          {resultsReceived.map((item: any, index: any) => (
            renderCardProfile(item, index)
          ))}
        </SearchFlatList>
      )}


      {typeReceived === 'EVENT' && resultsReceived && resultsReceived?.length > 0 && (
        <SearchFlatList>
          {resultsReceived.map((item: any, index: any) => (
            renderCardEvent(item, index)
          ))}
        </SearchFlatList>
      )}



      <FooterChoose
        hideCancelButton

        okColor={ArrayColors.arrayCancel}
        okLabel={
          <ButtonText
            color={Colors.white}>
            {translate(TranslateConfig.ACTION_RESEARCH)}
          </ButtonText>
        }
        okPress={() => {
          remakeSearch();
        }}
      />

    </Screen>

  );
};



export default SearchResultsScreen;
