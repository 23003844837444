import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  EventAvailablesArray,
} from '@noitada/shared/arrays/event-availables.array';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Items,
  Epigraph,
  Input,
} from '../../../../../../components/Composh/web';

import {
  returnIconAvailables,
} from '../../../../../../config/icon-availables.config';

import {
  DetailsSectionView,
} from '../../../../../../styles/styled.layout';

import {
  SearchAvailableContent,
} from '../styled';



export interface IProps {
  loading?: any;
  setFieldValue: any;
}



const SectionAvailable: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const loading = props.loading;
  const setFieldValue = props.setFieldValue;

  const [availables, setAvailables] = useState<Array<any>>([]);



  function searchAvailables(itemAvailables: string) {
    const oldItems = [...availables];
    const index = oldItems.indexOf(itemAvailables);
    if (index !== -1) {
      oldItems.splice(index, 1);
    }
    else {
      oldItems.push(itemAvailables);
    }
    setAvailables(oldItems);
    setFieldValue('availables', oldItems);
  }


  function renderItemList(item: any, index: number) {
    const verifyitem = availables.some((valueItem) => valueItem === item?.value);


    return (

      <Items.Grid
        key={index}
        disabled={loading}
        activeOpacity={Sizes.ButtonActiveOpacity}
        icon={returnIconAvailables(
          item.value,
          verifyitem
            ? Colors.accent
            : Colors.textApp,
          35,
        )}
        iconGridLabel={translate(item.label)}
        iconGridColor={verifyitem
          ? Colors.accent
          : Colors.textApp
        }
        onPress={() => {
          searchAvailables(item.value);
        }}
      />

    );
  }



  return (

    <DetailsSectionView>

      <Epigraph.Section
        text={translate(TranslateConfig.AVAILABLES)}
        textColor={Colors.primary}
        borderBottomColor={Colors.primary}
      />


      <Input.View
        noShadow
        backgroundColor={Colors.transparent}
        countColor={Colors.textDescription}
        countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>

        <SearchAvailableContent>
          {EventAvailablesArray.map((item: any, index: number) => (
            renderItemList(item, index)
          ))}
        </SearchAvailableContent>

      </Input.View>

    </DetailsSectionView>

  );
};



export default SectionAvailable;
