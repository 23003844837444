import EMusic from '../enums/Items/musics.enum';

import {
  TranslateConfig,
} from '../translations';



export const eventMusicGenreArray = [
  {
    label: TranslateConfig.ELECTRONIC,
    value: EMusic.ELECTRONIC,
  },
  {
    label: TranslateConfig.FUNK,
    value: EMusic.FUNK,
  },
  {
    label: TranslateConfig.COUNTRY_MUSIC,
    value: EMusic.COUNTRY_MUSIC,
  },
  {
    label: TranslateConfig.POP,
    value: EMusic.POP,
  },
  {
    label: TranslateConfig.RAP,
    value: EMusic.RAP,
  },
  {
    label: TranslateConfig.ROCK,
    value: EMusic.ROCK,
  },
  {
    label: TranslateConfig.MPB,
    value: EMusic.MPB,
  },
  {
    label: TranslateConfig.AXE,
    value: EMusic.AXE,
  },
  {
    label: TranslateConfig.SAMBA,
    value: EMusic.SAMBA,
  },
  {
    label: TranslateConfig.REGGAE,
    value: EMusic.REGGAE,
  },
  {
    label: TranslateConfig.FORRO,
    value: EMusic.FORRO,
  },
  {
    label: TranslateConfig.GOSPEL,
    value: EMusic.GOSPEL,
  },
  {
    label: TranslateConfig.HIP_HOP,
    value: EMusic.HIP_HOP,
  },
  {
    label: TranslateConfig.JAZZ,
    value: EMusic.JAZZ,
  },
  {
    label: TranslateConfig.CLASSIC_MUSIC,
    value: EMusic.CLASSIC_MUSIC,
  },
  {
    label: TranslateConfig.PAGODE,
    value: EMusic.PAGODE,
  },
  {
    label: TranslateConfig.BLUES,
    value: EMusic.BLUES,
  },
];
