import {
  EAuthorization,
} from '../enums';



export const UserHasAuthorization = (user: any): boolean => {
  for (let i = 0; i < user.authorizations.length; i++) {
    if (user.authorizations[i].authorization === EAuthorization.NOITADA_USER) {
      return true;
    }
  }

  return false;
};
