import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  selected?: boolean;
}



export const CardLocationSelectedStyle = css`
  border-color: ${Colors.accent};
  border-style: solid;
  border-width: 1px;
`;

export const CardLocationCountryContainer = styled.a`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  position: relative;
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 4px;
  padding: 12px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.selected && CardLocationSelectedStyle};
`;

export const CardLocationCountryImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 5px;
  z-index: 1;
`;

export const CardLocationCountryInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 1;
`;

export const CardLocationInfoNameView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;

export const CardLocationCountryNameText = styled.h1`
  color: ${(props: IProps) => props.selected ? Colors.accent : Colors.white};
  font-size: 20px;
  font-weight: ${(props: IProps) => props.selected ? '600' : '400'};
`;

export const CardLocationInfoIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 7px;
  margin-bottom: 6px;
`;

export const CardLocationCountryCurrencyText = styled.p`
  margin-top: -2px;
  color: ${(props: IProps) => props.selected ? Colors.white : Colors.gray};
  font-size: 13px;
`;

export const CardLocationCountryCodeText = styled.p`
  position: absolute;
  right: 10px;
  bottom: 0;
  color: ${(props: IProps) => props.selected ? Colors.accent : Colors.white};
  font-size: 36px;
  font-style: italic;
  font-weight: bold;
  opacity: 0.1;
  z-index: 0;
`;
