import Address from '../Address';
import Picture from '../Picture';
import CompanyAbout from './company-about';
import CompanyContact from './company-contact';
import CompanyOperation from './company-operation';
import CompanyPaymentType from './company-payment-type';
import CompanyRating from './company-rating';
import CompanyReserve from './company-reserve';
import CompanyRules from './company-rules';
// import Event from '../Event';
// import OrderItemCategory from './Address';
// import OrderItemSale from './Address';
// import UserCompany from './Address';



const Company = {
  id: null,
  name: '',
  enabled: false,
  managerUser: false,
  idShort: '',
  cnpj: '',
  social_reason: '',
  company_username: '',
  slogan: '',
  verified: false,
  address: Address,
  updated_at: null as any,
  views: 0,
  company_gallery: [],
  company_params: null as any,
  company_about: CompanyAbout,
  company_contact: CompanyContact,
  company_operation: CompanyOperation,
  company_payment_type: [],
  company_plan: {
    type: 'NO_PRO',
  },
  company_rating: CompanyRating,
  company_reserve: CompanyReserve,
  company_rules: [], // CompanyRules,
  // picture: Picture,
  picture: '',
  type: '',
  images: [],
  logo: '',
  modules: {
    menuSheet: false,
    events: false,
    reserves: false,
  },
  // events: Event,
  // item_categories: OrderItemCategory,
  // item_sales: OrderItemSale,
  // user_companies: UserCompany,
};



export default Company;
