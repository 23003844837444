import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Card,
} from '../../../Composh/web';



export const CarnivalStageCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  margin-top: 2px;
`;

export const CarnivalStageBackgroundImage = styled(Card.View)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  padding-top: 6px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 6px;
  border-color: ${Colors.toolbarLine};
  border-style: solid;
  border-width: 0.5px;
`;


export const CarnivalStageView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 3px;
`;

export const CarnivalStageTitle = styled.p`
  max-height: 58px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
`;

export const CarnivalStageDate = styled.p`
  margin-top: 2px;
  color: ${Colors.white};
  font-size: 13px;
  font-weight: bold;
`;


export const CarnivalStageButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
`;

export const CarnivalStageArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`;
