import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const GalleryDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GalleryDetailsCardImage = styled.a`
  display: flex;
  max-width: 100%;
  grid-row: 1 / -1;
  grid-column: 1;
  margin-bottom: 10px;
  background-color: ${Colors.cardBackground};
`;

export const GalleryDetailsImage = styled.img`
  width: 100%;
`;
