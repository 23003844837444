import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const CheckInUserLevel = styled.p`
  padding-top: 2px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 2px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.black};
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 11.5px;
  text-align: center;
  border-radius: 10px;
`;
