import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const ChangeTicketContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

export const ChangeTicketTitle = styled.h3`
  margin-top: 15px;
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const ChangeTicketSubTitle = styled.h3`
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${Colors.gray};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;
