import NoitadaWS from '../index';



export const setAuthHeaders = (token) => {
  (NoitadaWS.defaults.headers as any).authorization = token;
};



export const AuthenticateUser = async (data) => {
  const url = '/auth';
  const response = await NoitadaWS.post(url, data);

  if (response.data.token) {
    console.log('TOKEN', response.data.token);

    const newToken = response.data.token;

    const userData = response.data.user;

    return {
      token: newToken,
      user: userData,
    };
  }
  else {
    console.log('TOKEN NOT PRESENT', response.data);
  }
};



export const TryRevalidateSession = async (token) => {
  if (token) {
    const url = '/auth/revalidate';
    const response = await NoitadaWS.post(url, { token });
    return response.data.user;
  }
  else {
    return null;
  }
};
