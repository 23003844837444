const environment = {
  production: false,
  environment: 'DEV',
  title: 'Dev Environment Heading',
  apiUrl: 'https://api-dev.noitada.app',
  firebaseWebConfig: {
    apiKey: 'AIzaSyCwFTm-RXP1nYWx5Odk0Zu5DVU4b78nHYs',
    authDomain: 'noitada-app-dev.firebaseapp.com',
    projectId: 'noitada-app-dev',
    storageBucket: 'noitada-app-dev.appspot.com',
    messagingSenderId: '701218825636',
    appId: '1:701218825636:web:be65f8f6e27e3cf7e74d31',
    measurementId: 'G-Q6T6JVFLJF',
  },
};



export default environment;
