import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const CompanyContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 47px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding-left: 5px;
  padding-right: 5px;
`;


export const CompanyContactIconLeft = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 35px;
  min-height: 40px;
  margin-right: 17px;
  align-items: center;
  justify-content: center;
`;


export const CompanyContactInfoView = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const CompanyContactInfoTitle = styled.h3`
  margin-bottom: 2px;
  font-size: 15px;
  color: ${Colors.accent};
`;

export const CompanyContactInfoText = styled.p`
  font-size: 12px;
  color: ${Colors.primary};
`;


export const CompanyContactIconRight = styled.a`
  display: flex;
  flex-direction: column;
  min-width: 35px;
  min-height: 40px;
  margin-left: 17px;
  align-items: center;
  justify-content: center;
`;
