import NoitadaWS from '../index';



export const GetEntranceList = async (eventId: string | number) => {
  const url = `/events/lists/${eventId}`;
  
  const response = await NoitadaWS.get(url);
  return response.data;
};

export const GetEntranceListWithUser = async (eventId: string | number, userId: string) => {
  const url = `/events/lists/${eventId}/withuser/${userId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};

export const CheckUserInList = async(userId:string, listId: string) => {
  const url = `/events/lists/noitada/checkuser/${listId}/${userId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};

export const EntranceListParticipate = async (listId: string, userId: string) => {
  const url = `/events/lists/noitada/add-person/${listId}/${userId}`;

  const response = await NoitadaWS.put(url);
  return response.data;
};


export const EntranceListLeave = async (listId: string, userId: string) => {
  const url = `/events/lists/noitada/remove-person/${listId}/${userId}`;

  const response = await NoitadaWS.delete(url);
  return response.data;
};


export const EntranceListInvite = async (listId: string, usersId: string[]) => {
  const url = `/events/lists/noitada/changeinvitations/${listId}`;

  const response = await NoitadaWS.put(url, {
    users: usersId,
  });
  return response.data;
};

export const GetDatesGroupReserves = async (period: string) => {
  const url = `/events/lists/user?period=${period}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
