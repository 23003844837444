import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';

import UserModel from '@noitada/axios-config/models/User';



const INITIAL_STATE = {
  loggedIn: false,
  logout: false,
  actualUser: UserModel,
};



// Action Types

export const USER_LOGGED = '@user/LOGGED';
export const USER_LOGOUT_STATE = '@company/LOGOUT_ACTION';

export const USER_SET = '@user/SET';
export const USER_UPDATE = '@user/UPDATE';



// Action Creators

export const userLogged = createAction<object>(USER_LOGGED);
export const logoutAction = createAction<object>(USER_LOGOUT_STATE);

export const userAction = createAction<object>(USER_SET);
export const userUpdateAction = createAction<object>(USER_UPDATE);



// Handlers

const setLogged = (state = INITIAL_STATE, action: any) => ({
  ...state,
  loggedIn: action.payload,
});


const logoutReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  logout: action.payload,
});


const setUser = (state = INITIAL_STATE, action: any) => ({
  ...state,
  actualUser: action.payload,
});


const updateUser = (state = INITIAL_STATE, action: any) => ({
  ...state,
  actualUser: { ...state.actualUser, ...action.payload },
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [userLogged.type]: setLogged,
  [logoutAction.type]: logoutReducer,

  [userAction.type]: setUser,
  [userUpdateAction.type]: updateUser,
});
