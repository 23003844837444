import {
  Mask,
} from '../components/Composh/plugins';



export function FormatPhone(number: string, type = 'BRL'): string {
  if (!number || !type) {
    return '';
  }

  if (number.length <= 5) {
    return number;
  }

  if (number.startsWith('0800') && number.length > 5) {
    const formattedNumber = `0800-${number.substring(4)}`;
    return formattedNumber;
  }

  const phoneMasked = Mask.toMask('phone', number, {
    maskType: 'BRL',
    withDDD: true,
    dddMask: '(99) ',
  });

  return phoneMasked;
};
