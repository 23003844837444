import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  PostFavoriteCompany,
} from '@noitada/axios-config/endpoints/favorites.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  FAVORITES_SET,
} from '@noitada/redux-config/reducers/favorites.store';

import {
  companyCompleteTypeArray,
} from '@noitada/shared/arrays/company-type.array';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  ECompanyType,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import NameRoutes from '../../../../navigation/names';

import {
  Divider,
} from '../../../Composh/web';

import DetailsModules from '../../../Details/DetailsModules';

import {
  IconFavoriteOff,
  IconFavoriteOn,
  IconMapPin,
  IconStarFull,
  IconVerified,
} from '../../../Icons';

import {
  ProfileCardContainer,
  ProfileCardContent,
  ProfileInfoView,
  ProfileLogoView,
  ProfileTypeView,
  ProfileTypeText,
  ProfileImage,
  ProfileTextsView,
  DashProfileUserNameView,
  VerifiedIconComponent,
  ProfileNameText,
  ProfileUserNameText,
  ProfilePlaceView,
  IconMapPinStyle,
  ProfilePlaceText,
  ProfileOpenHoursText,
  ProfileServiceText,
  ProfileFavoriteButton,
  ProfileActionsButtonView,
  ProfileFavoriteView,
  DashProfileRatingView,
  DashProfileRatingIcon,
  ProfileRatingText,
} from './styled';



export interface IProps {
  companyId: string;
  image?: string;
  name: string;
  username: string;
  verified?: boolean;
  type: ECompanyType;
  district?: string;
  open?: string;
  rating?: string;
  params: any;

  menuShow?: boolean;
  menuDisable?: boolean;
  menuPress?: any;

  eventShow?: boolean;
  eventDisable?: boolean;
  eventPress?: any;

  reserveShow?: boolean;
  reserveDisable?: boolean;
  reservePress?: any;

  favoritePress?: any;

  onPress?: any;
}



const CardProfile: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const favoritesUser: Array<any> = useSelector((state: RootState) => state.favorites.actualFavorites);
  const verifyItem = favoritesUser?.length > 0 ? favoritesUser?.some((valueItem) => valueItem?.relationId === props.companyId) : false;


  const companyParams = props.params;
  const ratingNumber = null;
  const sizeFavoriteIcon = 24;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function renderTypeCompany(): string {
    const filterType = companyCompleteTypeArray.find((item: any) => item.value === props.type);

    if (filterType) {
      return translate(filterType?.word);
    }

    return '-';
  }


  function renderParamsAvailable() {
    if (!companyParams) {
      return '-';
    }

    const translations: Array<string> = [];

    if (companyParams?.orderSheet) {
      translations.push(translate(TranslateConfig.ORDERSHEET));
    }

    if (companyParams?.removalItem) {
      translations.push(translate(TranslateConfig.REMOVAL_ITEM));
    }

    if (companyParams?.delivery) {
      translations.push(translate(TranslateConfig.DELIVERY));
    }

    return translations.length > 0 ? translations.join(' • ') : '-';
  }


  async function onFavoritePress() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const data = await PostFavoriteCompany(availableUser, props.companyId);

      if (data && !data?.hasError) {
        dispatch({ type: FAVORITES_SET, payload: data || [] });

        showSnackBarProps(Colors.accept,
          verifyItem
            ? translate(TranslateConfig.SUCCESS_FAVORITE_REMOVE)
            : translate(TranslateConfig.SUCCESS_FAVORITE_ADD),
        );

        if (props.favoritePress) {
          props.favoritePress();
        }
      }
      else {
        showSnackBarProps(Colors.accept,
          verifyItem
            ? translate(TranslateConfig.ERROR_FAVORITE_REMOVE)
            : translate(TranslateConfig.ERROR_FAVORITE_ADD),
        );
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderFavoriteButton() {
    if (!availableUser) {
      return (
        <ProfileFavoriteButton
          style={{ opacity: 0.4 }}
          // activeOpacity={0.65}
          onClick={() => {
            history.push(NameRoutes.AccessScreen);
          }}>
          <IconFavoriteOff
            color={Colors.gray}
            size={sizeFavoriteIcon}
          />
        </ProfileFavoriteButton>
      );
    }


    return (


      <ProfileFavoriteButton
        // activeOpacity={0.65}
        onClick={() => {
          onFavoritePress();
        }}>
        {verifyItem
          ? (
            <IconFavoriteOn
              color={Colors.secondary}
              size={sizeFavoriteIcon}
            />
          )
          : (
            <IconFavoriteOff
              color={Colors.gray}
              size={sizeFavoriteIcon}
            />
          )
        }
      </ProfileFavoriteButton>

    );
  }



  return (

    <ProfileCardContainer>
      <ProfileCardContent>

        <ProfileInfoView>

          <ProfileLogoView
            onClick={props.onPress}>

            <ProfileImage
              alt={props.name}
              src={props.image === '' || props.image === null || props.image === undefined
                ? Images.placeholderAvatarCompany
                : props.image
              }
            />


            <ProfileTypeView>
              <ProfileTypeText>
                {renderTypeCompany()}
              </ProfileTypeText>
            </ProfileTypeView>

          </ProfileLogoView>



          <ProfileTextsView
            onClick={props.onPress}>

            <DashProfileUserNameView>
              <ProfileNameText>
                {props.name || '-'}
              </ProfileNameText>


              {props.verified && (
                <VerifiedIconComponent>
                  <IconVerified
                    color={Colors.accent}
                    size={15}
                  />
                </VerifiedIconComponent>
              )}
            </DashProfileUserNameView>



            <ProfileUserNameText>
              @{props.username || '-'}
            </ProfileUserNameText>



            {props.district && (
              <ProfilePlaceView>
                <IconMapPinStyle>
                  <IconMapPin
                    color={Colors.textApp}
                    size={17}
                  />
                </IconMapPinStyle>


                <ProfilePlaceText>
                  {props.district || '-'}
                </ProfilePlaceText>
              </ProfilePlaceView>
            )}



            <ProfileOpenHoursText>
              {props.open || '-'}
            </ProfileOpenHoursText>



            <ProfileServiceText>
              {renderParamsAvailable()}
            </ProfileServiceText>

          </ProfileTextsView>



          <ProfileActionsButtonView>

            <ProfileFavoriteView>
              {renderFavoriteButton()}
            </ProfileFavoriteView>


            {props.rating && (
              <DashProfileRatingView
                // activeOpacity={0.7}
                onClick={() => {
                  // TODO
                }}>

                <DashProfileRatingIcon>
                  <IconStarFull
                    color={Colors.accent}
                    size={16}
                  />
                </DashProfileRatingIcon>

                <ProfileRatingText>
                  {ratingNumber || '-'}
                </ProfileRatingText>

              </DashProfileRatingView>
            )}

          </ProfileActionsButtonView>

        </ProfileInfoView>



        <Divider
          height={0.3}
          width={'95%'}
          color={Colors.menuSeparator}
        />



        <DetailsModules
          showBackground={false}
          type={props.type}

          menuDisable={props.menuDisable}
          menuPress={props.menuPress}

          eventDisable={props.eventDisable}
          eventPress={props.eventPress}

          reserveDisable={props.reserveDisable}
          reservePress={props.reservePress}

        />

      </ProfileCardContent>
    </ProfileCardContainer>

  );
};



export default CardProfile;
