import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const CardOrderBuyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const CardOrderBuyHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CardOrderBuyIconNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${Colors.primary};
  border-radius: 50%;
`;

export const CardOrderBuyNumber = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
`;


export const CardOrderBuyinfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`;

export const CardOrderTitle = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 600;
`;

export const CardOrderBoughtUsername = styled.p`
  margin-bottom: 5px;
  color: ${Colors.textApp};
  font-size: 16px;
`;

export const HistorySheetStatusText = styled.h2`
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const CardOrderAccess = styled.p`
  margin-top: 1px;
  margin-bottom: 1px;
  color: ${Colors.textApp};
  font-size: 14px;
`;

export const CardOrderPrice = styled.p`
  color: ${Colors.accent};
  font-size: 18px;
  font-weight: 600;
  text-align: right;
`;
