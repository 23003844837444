import styled,
{
  css,
} from 'styled-components';

import {
  QrReader,
} from 'react-qr-reader';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  flashlight?: boolean;
  distanceView?: number;
}



export const CameraQrStyled = styled(QrReader).attrs({
  videoContainerStyle: {
    padding: 0,
    width: '100%',
    heigth: '100%',
  },
  videoStyle: {
    width: '100%',
    heigth: '100%',
    objectFit: 'cover',
  },
})`
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.black};
`;



export const QrReaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
`;



export const BodyQr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
`;


export const QrContainerTitle = styled.p`
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

export const QrContainerSubtitle = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;
`;


export const QrScannerSettingsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 120px;
`;

export const ControlsReaderView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const QRButtonStyle = css`
  background-color: ${Colors.primary};
  border-color: ${Colors.primary};
`;

export const QRControlButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  height: 50px;
  width: 50px;
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 2px;
  border-radius: 50px;

  ${(props: IProps) => props.flashlight && QRButtonStyle};
`;

export const QRManualCheckInButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
  margin-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 2px;
  border-radius: 50px;
`;

export const QRManualCheckInText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;



export const QrCodeContainer = styled.a`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  left: 0;
  bottom: ${(props: IProps) => props.distanceView}px;
  align-items: center;
  height: 500px;
  padding-top: 8px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  background-color: ${Colors.cardBackground};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1000;
`;

export const QrCodeTitle = styled.p`
  margin-top: 10px;
  margin-bottom: 2px;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

export const QrCodeSubtitle = styled.p`
  color: ${Colors.gray};
  font-size: 15px;
  text-align: center;
`;

export const QrCodeOpenCloseText = styled.p`
  margin-top: 10px;
  color: ${Colors.accent};
  text-align: center;
`;


export const UserQrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;


export const UserQrCodeOrientation = styled.p`
  margin-top: 25px;
  color: ${Colors.textApp};
  font-size: 15px;
  line-height: 20px;
  text-align: center;
`;
