import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EEventAvailables,
} from '@noitada/shared/enums';

import {
  IconAvailableComboCircle,
  IconAvailableDoubleCircle,
  IconAvailableListCircle,
  IconAvailableLiveMusicCircle,
  IconAvailableOpenBarCircle,
  IconAvailableVipCircle,
} from '../components/Icons';



export function returnIconAvailables(value: string | undefined, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  if (!value) {
    return;
  }

  switch (value) {
    case EEventAvailables.DISCOUNT:
      return (
        <IconAvailableComboCircle
          color={colorProp}
          size={sizeProp}
        />
      );

    case EEventAvailables.CASADINHA:
      return (
        <IconAvailableDoubleCircle
          color={colorProp}
          size={sizeProp}
        />
      );

    case EEventAvailables.OPEN_BAR:
      return (
        <IconAvailableOpenBarCircle
          color={colorProp}
          size={sizeProp}
        />
      );

    case EEventAvailables.MUSIC:
      return (
        <IconAvailableLiveMusicCircle
          color={colorProp}
          size={sizeProp}
        />
      );

    case EEventAvailables.LIST:
      return (
        <IconAvailableListCircle
          color={colorProp}
          size={sizeProp}
        />
      );

    case EEventAvailables.VIP:
      return (
        <IconAvailableVipCircle
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      break;
  }
};
