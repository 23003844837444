import React from 'react';

import Color from 'color';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  IconArrowExpand,
  IconArrowExpanded,
} from '../../../Icons';

import {
  InfoCarnivalHeaderAccordion,
  InfoCarnivalAccordionTitle,
  InfoGuideAccordionActionsView,
} from './styled';



export interface IProps {
  expanded?: boolean;
  title?: string;
  onPress?: any;
}



const CardCarnivalGuideItem: React.FC<IProps> = (props: IProps) => {
  function generateBackgroundColor() {
    const colors = [
      Colors.carnivalBlue,
      Colors.carnivalGreen,
      Colors.carnivalPurple,
      Colors.carnivalRed,
      Colors.carnivalYellow,
    ];

    return colors[Math.floor(Math.random() * colors.length) + 0];
  }


  function renderExpandableIcon() {
    if (props.expanded) {
      return (

        <IconArrowExpanded
          color={Colors.white}
          size={Sizes.iconNavigationBottomSize}
        />

      );
    }


    return (

      <IconArrowExpand
        color={Colors.white}
        size={Sizes.iconNavigationBottomSize}
      />

    );
  }



  return (

    <InfoCarnivalHeaderAccordion
      backgroundColor={props.expanded
        ? Color(Colors.primary.toString()).darken(0.6).toString()
        : Color(generateBackgroundColor().toString()).darken(0.6).toString()
      }
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <InfoCarnivalAccordionTitle>
        {props.title || '-'}
      </InfoCarnivalAccordionTitle>


      <InfoGuideAccordionActionsView>

        {renderExpandableIcon()}

      </InfoGuideAccordionActionsView>

    </InfoCarnivalHeaderAccordion>

  );
};



export default CardCarnivalGuideItem;
