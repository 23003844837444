import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const PrideDetailsImage = styled.img`
  width: 30px;
  height: 20px;
  object-fit: contain;
`;


export const DisableOrderSheetText = styled.p`
  margin-top: 3px;
  margin-bottom: 8px;
  color: ${Colors.gray};
  font-size: 12px;
  white-space: pre-wrap;
`;


export const SeeCountEventText = styled.p`
  width: 100%;
  margin-top: 8px;
  color: ${Colors.gray};
  font-size: 12px;
  text-align: center;
`;
