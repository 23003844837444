import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';

import {
  Card,
  LinearGradient,
} from '../../../Composh/web';

import Separators from '../../../Separators';



export const CarnivalAttractionsResponsive = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;

  @media ${PlatformDevices.minMobileS} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTabletM} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const CarnivalAttractionsContainer = styled(Card.View)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 190px;
  border-color: ${Colors.toolbarLine};
  border-style: solid;
  border-width: 0.5px;
`;

export const CarnivalAttractionsBackgroundGradient = styled(LinearGradient)`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 190px;
  min-height: 190px;
  margin-bottom: -1px;
`;



export const CarnivalAttractionsPerson = styled.img`
  height: 180px;
  width: 180px;
  margin-bottom: -1px;
  object-fit: cover;
`;



export const CarnivalAttractionsPersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 3px;
`;

export const CarnivalAttractionsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
`;

export const CarnivalAttractionsInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CarnivalAttractionsText = styled.p`
  margin-bottom: 3px;
  color: lightgray;
  font-size: 13px;
  line-height: 17px;
  text-align: right;
`;

export const CarnivalAttractionsTitle = styled.p`
  color: white;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  text-align: right;
`;

export const CarnivalAttractionsBlocHour = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 55px;
`;

export const CarnivalAttractionsSeparator = styled(Separators)`
  margin-right: -15%;
  margin-bottom: -8px;
`;

export const CarnivalAttractionsBanner = styled.img`
  height: 50px;
  width: 50px;
  margin-left: 25px;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  border-radius: 4px;
  object-fit: contain;
`;


export const CarnivalAttractionsHour = styled.p`
  color: white;
  min-width: 95px;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  text-align: right;
`;
