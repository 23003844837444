export enum ECompanyType {
  BAR = 'BAR',
  PUB = 'PUB',
  NIGHT_CLUB = 'NIGHT_CLUB',
  RESTAURANT = 'RESTAURANT',
  PIZZERIA = 'PIZZERIA',
  STEAKHOUSE = 'STEAKHOUSE',
  HAMBURGUER = 'HAMBURGUER',
  COFFEE_SHOP = 'COFFEE_SHOP',
  EVENT_PRODUCER = 'EVENT_PRODUCER',
  CARNIVAL_PRODUCER = 'CARNIVAL_PRODUCER',
  PARTY_PRODUCER = 'PARTY_PRODUCER',
  GOVERN = 'GOVERN',
}



export default ECompanyType;
