import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  openWhatsApp,
} from '../../../../config/linking.config';

import {
  CardUserOrderContainer,
  CardUserOrderHeader,
  CardOrderCompanyImage,
  CardUserInfoHeader,
  CardUserDate,
  CardUserPlaceName,
  CardUserTypeOrderText,
  CardUserOrderBody,
  CardUserAvaliationView,
  CardUserPriceView,
  CardUserPrice,
  CategoryButtonContent,
  ActionCardButton,
  ActionCardIcon,
  ActionCardText,
} from './styled';



export interface IProps {
  date?: any;
  name?: any;
  id?: any;
  image?: any;
  price?: any;
  onPress?: any;
}



const CardHistoryPastOrder: React.FC<IProps> = (props: any) => {
  const { t: translate } = useTranslation();



  function renderButtonAction(titile: string, icon: any, onPress: any) {
    return (

      <ActionCardButton
        onClick={onPress}>

        <ActionCardIcon>
          {icon}
        </ActionCardIcon>

        <ActionCardText>
          {titile}
        </ActionCardText>

      </ActionCardButton>

    );
  }



  return (

    <CardUserOrderContainer>

      <CardUserOrderHeader
        onClick={props.onPress}>

        <CardOrderCompanyImage
          alt={'logo'}
          src={
            props.image === '' || props.image === null
              ? Images.placeholderAvatarCompany
              : props.image
          }
        />


        <CardUserInfoHeader>

          <CardUserDate>
            {props.date || '-'}
          </CardUserDate>


          <CardUserPlaceName>
            {props.name || '-'}
          </CardUserPlaceName>


          <CardUserTypeOrderText>
            {translate(TranslateConfig.ORDERSHEET)} • {props.id || '-'}
          </CardUserTypeOrderText>

        </CardUserInfoHeader>

      </CardUserOrderHeader>



      <CardUserOrderBody>

        <CardUserAvaliationView>
          {/* TODO AVALIAÇAO */}
        </CardUserAvaliationView>


        <CardUserPriceView>
          <CardUserPrice>
            {props.price || '-'}
          </CardUserPrice>
        </CardUserPriceView>

      </CardUserOrderBody>



      <CategoryButtonContent>

        {renderButtonAction(
          translate(TranslateConfig.HELP),
          null,
          () => {
            openWhatsApp();
          },
        )}

      </CategoryButtonContent>

    </CardUserOrderContainer>

  );
};



export default CardHistoryPastOrder;
