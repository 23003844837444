import React,
{
  useState,
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetDatesGroupReserves,
} from '@noitada/axios-config/endpoints/entrance-list.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EPeriodLists from '@noitada/shared/enums/Event/event-lists-period.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardListUser from '../../../../../components/Cards/CardListUser';
import EmptyContent from '../../../../../components/Empty';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import ListParticipateScreen from '../../CompanyLists/Participate';

import {
  ListTicketContainer,
  ListTicketContentFlex,
} from './styled';



const TabListsUpcomming: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const [loading, setLoading] = useState(false);
  // const [refreshing, setRefreshing] = useState(false);

  const [userLists, setUserLists] = useState<Array<any>>([]);
  const [openParticipate, setOpenParticipate] = useState<any | null>(null);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getReservesFunction = async () => {
    try {
      setLoading(true);

      const response = await GetDatesGroupReserves(EPeriodLists.FUTURE);

      if (response) {
        setUserLists(response);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardListUser
        key={index}
        date={item?.event?.startAt}
        companyImage={item?.company_picture}
        companyName={item?.event?.company?.name}
        listId={item?.shortId}
        listName={item?.name}
        eventImage={item?.eventPicture}
        eventName={item?.event?.name}
        eventType={item?.event?.type}
        city={item?.event?.address?.city}
        state={item?.event?.address?.state}
        onPress={() => {
          setOpenParticipate(item);
        }}
      />

    );
  }


  // function renderFooter() {
  //   if (!loading) {
  //     return null;
  //   }

  //   return (

  //     <LoadingScreen
  //       type={'FOOTER'}
  //       color={Colors.primary}
  //     />

  //   );
  // }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.listEventWait}
        title={translate(TranslateConfig.EMPTY_LISTS_FINISHED)}
      />

    );
  }



  useEffect(() => {
    getReservesFunction();
  }, []);



  return (

    <ListTicketContentFlex>

      {loading && (
        <LoadingScreen
          type={'SCREEN'}
          color={Colors.primary}
        />
      )}



      {!loading && userLists?.length === 0 && (
        listEmptyComponent()
      )}



      {!loading && userLists.length > 0 && (
        <ListTicketContainer>
          {userLists.map((item: any, index: any) => (
            renderItem(item, index)
          ))}
        </ListTicketContainer>
      )}



      <ListParticipateScreen
        visible={openParticipate}
        list={openParticipate}
        editable={true}
        idUser={user?.id}
        canInvite={openParticipate?.canInvite && openParticipate?.additionalGuestsNumber > openParticipate?.guests}
        inList={openParticipate?.inList}
        onCancelPress={() => {
          setOpenParticipate(null);
        }}
      />

    </ListTicketContentFlex>

  );
};



export default TabListsUpcomming;
