import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/payment.config';

import {
  DetailsPaymentsHeader,
  DetailsPaymentsContainer,
  DetailsPaymentsRow,
  DetailsPaymentsLabel,
  DetailsPaymentsPrice,
} from './styled';



export interface IProps {
  modality?: 'ONLINE' | 'IN_PLACE';
  delivery?: number;
  discount?: number;
  total?: number;
  price?: number;
  alternative?: boolean;
}



const DetailsPayments: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const renderLabelPayment = () => {
    if (props?.alternative) {
      return translate(TranslateConfig.CHANGE);
    }
    if (props?.total && props?.price) {
      if (props?.total < props?.price) {
        return translate(TranslateConfig.DISCOUNT);
      }
      else {
        return translate(TranslateConfig.TIP);
      }
    }
    return '';
  };


  const renderLabelPaymentColor = () => {
    if (props?.alternative) {
      return '#780bb8';
    }
    if (props?.total && props?.price) {
      if (props?.total < props?.price) {
        return '#069c4e';
      }
      else {
        return '#110bb8';
      }
    }
    return '#111';
  };



  return (

    <DetailsPaymentsContainer>

      {props.modality && props.modality === 'ONLINE' && (
        <DetailsPaymentsHeader>
          <DetailsPaymentsLabel>
            {translate(TranslateConfig.ACTION_PAY_APP)}
          </DetailsPaymentsLabel>
        </DetailsPaymentsHeader>
      )}


      {props.modality && props.modality === 'IN_PLACE' && (
        <DetailsPaymentsHeader>
          <DetailsPaymentsLabel>
            {translate(TranslateConfig.ACTION_PAY_PRESENTIAL)}
          </DetailsPaymentsLabel>
        </DetailsPaymentsHeader>
      )}


      <DetailsPaymentsRow>
        <DetailsPaymentsLabel>
          {translate(TranslateConfig.TOTAL_PAY)}
        </DetailsPaymentsLabel>

        <DetailsPaymentsPrice>
          {props?.total ? FormatMoney(props?.total) : ''}
        </DetailsPaymentsPrice>
      </DetailsPaymentsRow>


      <DetailsPaymentsRow>
        <DetailsPaymentsLabel>
          {translate(TranslateConfig.SUBTOTAL)}
        </DetailsPaymentsLabel>

        <DetailsPaymentsPrice color={Colors.buttonAction}>
          {props?.price ? FormatMoney(props.price) : ''}
        </DetailsPaymentsPrice>
      </DetailsPaymentsRow>



      {false && <DetailsPaymentsRow>
        <DetailsPaymentsLabel>
          {translate(TranslateConfig.TAXES)}
        </DetailsPaymentsLabel>

        <DetailsPaymentsPrice>
          {props?.delivery ? FormatMoney(props.delivery) : ''}
        </DetailsPaymentsPrice>
      </DetailsPaymentsRow>
      }



      <DetailsPaymentsRow>
        <DetailsPaymentsLabel>
          {renderLabelPayment()}
        </DetailsPaymentsLabel>

        <DetailsPaymentsPrice
          color={renderLabelPaymentColor()}>
          {FormatMoney(parseFloat(String(props.discount)) * -1)}
        </DetailsPaymentsPrice>
      </DetailsPaymentsRow>



      {false && (
        // TODO
        <DetailsPaymentsRow>
          <DetailsPaymentsLabel
            color={Colors.accept}>
            {translate(TranslateConfig.TAX_DELIVERY)}
          </DetailsPaymentsLabel>

          <DetailsPaymentsPrice
            color={Colors.accept}>
            R$
          </DetailsPaymentsPrice>
        </DetailsPaymentsRow>
      )}


      <DetailsPaymentsRow>
        <DetailsPaymentsLabel
          color={Colors.buttonOkLight}>
          {translate(TranslateConfig.TOTAL)}
        </DetailsPaymentsLabel>

        <DetailsPaymentsPrice>
          {FormatMoney(props?.total)}
        </DetailsPaymentsPrice>
      </DetailsPaymentsRow>

    </DetailsPaymentsContainer>

  );
};



export default DetailsPayments;
