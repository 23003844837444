import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import moment from 'moment';

import {
  GetCarnivalStreet,
} from '@noitada/axios-config/endpoints/carnival.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPattern,
} from '@noitada/shared/utils/date.utils';

import ButtonCityCarnival from '../../../../components/Buttons/ButtonCitiesApp/CityCarnival';
import CardCarnivalAccordionArtist from '../../../../components/Cards/CardCarnival/CardCarnivalAccordionArtist';
import CardCarnivalStreet from '../../../../components/Cards/CardCarnival/CardCarnivalStreet';

import {
  Container,
  ToolbarGroup,
} from '../../../../components/Composh/web';

import CalendarHorizontal from '../../../../components/Composh/web/CalendarHorizontal';

import {
  ButtonCarnivalGuide,
  ButtonCarnivalMusic,
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../navigation/names';

import {
  ChangeEventTypeButton,
  ChangeEventTypeImage,
} from '../../../../styles/styled.layout';

import {
  HighlightMenuSeparator,
  HighlightsSeparator,
  AttractionsCarnivalFlatList,
  DistrictsTitle,
  DistrictCarnivalAccordion,
  DistrictCarnivalContentAccordion,
} from './styled';



const TabStreetScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const carnivalLocalities = useSelector((state: RootState) => state.localities.selected);

  const periodCarnival = useSelector((state: RootState) => state.carnivalFestivals.itemCarnival);


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [streetEvents, setStreetsEvents] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);
  // const [refreshing, setRefreshing] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    if (loading) {
      return null;
    }

    return (

      <EmptyContent
        image={Images.noAttractions}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_ATTRACTIONS)}
      />

    );
  }


  async function getStreetEvents() {
    try {
      setLoading(true);

      const response = await GetCarnivalStreet({ ...carnivalLocalities, period: periodCarnival?.id });
      const start_date = moment(periodCarnival.start_at).isValid() ? moment(periodCarnival.start_at).add(9, 'hour').toDate() : new Date();
      setStreetsEvents(response);
      setSelectedDate(start_date);
    }
    catch (error: any) {
      setStreetsEvents([]);
      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function renderAccordionHeader(item: any, expanded: boolean, changeExpand: any, index: number) {
    return (

      <CardCarnivalStreet
        key={index}
        expanded={expanded}
        canExpanded={item?.schedules?.length > 0}
        confirmed={item?.confirmed}
        title={item?.name}
        description={item?.description}
        backgroundImage={item?.picture}
        onPress={() => {
          changeExpand();
        }}
      />
    );
  }


  function renderAccordionContent(item: any, index: number) {
    if (item?.schedules?.length <= 0 || item?.schedules === null || item?.schedules === undefined) {
      return null;
    }

    if (item?.schedules?.length > 0) {
      return (

        <DistrictCarnivalContentAccordion
          key={index}>

          {item.schedules.map((itemSales: any) => (
            <CardCarnivalAccordionArtist
              confirmed={itemSales?.confirmed}
              name={itemSales?.name}
              date={itemSales?.date}
              hour={itemSales?.hour}
              picture={itemSales?.picture}
            />
          ))}

        </DistrictCarnivalContentAccordion>

      );
    }
  }


  function renderAcordion(item: any, index: number) {
    return (

      <DistrictCarnivalAccordion
        key={index}
        dataArray={[item]}
        // animation
        expanded
        renderHeader={(item: any, expanded: boolean, changeExpand: any, index: number) => renderAccordionHeader(item, expanded, changeExpand, index)}
        renderContent={(item: any, index: number) => renderAccordionContent(item, index)}
      />

    );
  }


  function formatEventStrets(array: Array<any>) {
    const events_formated = array.filter((e) => {
      if (e?.dates) {
        if (e?.dates.includes(FormatIsoDateWithPattern(selectedDate.toISOString() || '', 'yyyy-MM-dd'))) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }).map((e) => {
      return {
        ...e,
        schedules: e?.schedules.filter((f) => {
          const date = moment(f?.date).format('YYYY-MM-DD');
          if (date === FormatIsoDateWithPattern(selectedDate.toISOString() || '', 'yyyy-MM-dd')) {
            return true;
          }
          return false;
        }),
      };
    });
    return events_formated;
  }



  useEffect(() => {
    if (periodCarnival?.id > 0) {
      const start_date = moment(periodCarnival.start_at).isValid() ? moment(periodCarnival.start_at).toDate() : new Date();
      const end_date = moment(periodCarnival.end_at).isValid() ? moment(periodCarnival.end_at).toDate() : new Date();
      setStartDate(start_date);
      setEndDate(end_date);
      setSelectedDate(start_date);
      getStreetEvents();
    }
  }, []);



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityCarnival />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonCarnivalMusic
              color={Colors.white}
            />

            <ButtonCarnivalGuide
              color={Colors.white}
            />

            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
        middleToolbar={
          <HighlightMenuSeparator>

            <HighlightsSeparator>
              <DistrictsTitle>
                {translate(TranslateConfig.EVENTS_STREET)}
              </DistrictsTitle>
            </HighlightsSeparator>


            <ChangeEventTypeButton
              onClick={() =>
                history.replace(NameRoutes.GridEventsScreen)
              }>
              <ChangeEventTypeImage
                alt={'change'}
                src={Images.noitadaHomeImageButton}
              />
            </ChangeEventTypeButton>

          </HighlightMenuSeparator>
        }
        noSubToolbarPadding>


        {!loading
          ? (
            <CalendarHorizontal
              color={Colors.accent}
              textColor={Colors.white}
              backgroundColor={[
                Colors.transparent,
                Colors.calendarColor,
              ]}
              selectedDate={selectedDate}
              startDate={startDate}
              endDate={endDate}
              onSelectDate={(dateFromCalendar: Date) => {
                setSelectedDate(dateFromCalendar);
              }}
            />
          )
          : <></>
        }

      </HeaderScreen>



      <AttractionsCarnivalFlatList
        loading={loading}
        data={formatEventStrets(streetEvents)}
        renderItem={renderAcordion}
        listLoadingComponent={renderLoading()}
        listFooterComponent={renderFooter()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Container>

  );
};



export default TabStreetScreen;
