import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/payment.config';

import ButtonAddCount from '../../Buttons/ButtonAddCount';

import {
  ItemFooterChooseStyle,
  ItemSeparatorVerticalFooter,
  ItemDetailFooter,
  AddCartTotalButton,
  AddCartTotalText,
} from './styled';



export interface IProps {
  quantity?: number;
  updateQuantity?: any;
  selectItem?: any;
  totalValue?: any;
  onPress?: any;
}



const FooterAddCart: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [quantity, setQuantity] = useState(1);


  function updateQuantity(e: number) {
    if (!props?.selectItem?.id) {
      return;
    }

    if (e < 1 && quantity > 1) {
      setQuantity(quantity - 1);
    }
    else if (e > 0) {
      setQuantity(quantity + 1);
    }
  };


  function renderValue() {
    if (!props?.selectItem?.id) {
      return FormatMoney(0);
    }

    return FormatMoney(props?.selectItem?.price * quantity);
  };



  useEffect(() => {
    setQuantity(1);
  }, [props?.selectItem]);



  return (

    <ItemFooterChooseStyle>

      <ItemSeparatorVerticalFooter
        option={'VERTICAL'}
      />



      <ItemDetailFooter>

        <ButtonAddCount
          quantity={quantity}
          removePress={() => {
            updateQuantity(-1);
          }}
          addPress={() => {
            updateQuantity(1);
          }}
        />



        <AddCartTotalButton
          disable={!props?.selectItem?.id || props?.selectItem?.price === 0}
          onClick={() => {
            if (props?.selectItem?.id && props?.selectItem?.price > 0) {
              props.onPress(props?.selectItem, quantity);
            }
          }}>
          <AddCartTotalText>
            {translate(TranslateConfig.ADD)} {renderValue()}
          </AddCartTotalText>
        </AddCartTotalButton>

      </ItemDetailFooter>

    </ItemFooterChooseStyle>

  );
};



export default FooterAddCart;
