import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useParams,
} from 'react-router-dom';

import {
  GetCompany,
} from '@noitada/axios-config/endpoints/companies.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Body,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  GalleryDetailsContainer,
  GalleryDetailsCardImage,
  GalleryDetailsImage,
} from './styled';



const CompanyGalleryScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const {
    company_username,
  } = useParams() as any;

  const company = useSelector((state: RootState) => state.company?.selected);
  const galleryArray = company?.company_gallery;


  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getCompanyByParams() {
    try {
      setLoading(true);

      const response = await GetCompany(company_username);
      if (response) {
        dispatch({ type: USER_SET_CURRENT_COMPANY, payload: response });
      }
      else {
        dispatch({ type: USER_SET_CURRENT_COMPANY, payload: null });
      }
    }
    catch (error: any) {
      console.error(error);
      dispatch({ type: USER_SET_CURRENT_COMPANY, payload: null });

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function renderItem(item: any, index: number) {
    return (

      <GalleryDetailsCardImage
        key={index}
        onClick={() => {
          // TODO
          // console.error('click pressionado');
        }}>

        <GalleryDetailsImage
          src={item?.link}
        />

      </GalleryDetailsCardImage>

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.EMPTY_COMPANY_GALLERY)}
      />

    );
  }



  useEffect(() => {
    getCompanyByParams();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.GALLERY)}
            </Title>

            <SubTitle
              color={Colors.white}>
              @{company?.company_username}
            </SubTitle>
          </Body>
        }
      />


      {loading && (
        <LoadingScreen
          type={'SCREEN'}
          color={Colors.primary}
        />
      )}



      {!loading && !company && (
        listEmptyComponent()
      )}



      {!loading && company && (
        <Content>
          <GalleryDetailsContainer>

            {galleryArray.map((item: any, index: any) => (
              renderItem(item, index)
            ))}

          </GalleryDetailsContainer>
        </Content>
      )}

    </Screen>

  );
};



export default CompanyGalleryScreen;
