import NoitadaWS from '../index';



export const UserMakeReservation = async (data: any) => {
  const url = '/reserves/user/noitada/create';

  const response = await NoitadaWS.post(url, data);
  return response;
};


export const CancelReservation = async (data: any) => {
  const url = '/reserves/user/noitada/cancel';

  const response = await NoitadaWS.post(url, data);
  return response;
};



export const UserGetReservations = async (period: string) => {
  const url = '/reserves/user/noitada/list';

  const response = await NoitadaWS.get(url, {
    params: {
      period: period,
    },
  });
  return response.data;
};
