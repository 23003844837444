import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Footer,
} from '../../../styles/styled.layout';



export interface IProps {
  backgrondColor?: string;
}



export const DetailsButtonFooter = styled(Footer)`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  height: 50px;
  background-color: ${(props: IProps) => props.backgrondColor};
  border-top-color: ${Colors.navigationBottomLine};
  border-top-style: solid;
  border-top-width: 1px;
`;



export const DetailsButtonStyle = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;


export const DetailsButtonList = styled.a`
  ${DetailsButtonStyle};
`;

export const DetailsButtonBuy = styled.a`
  ${DetailsButtonStyle};
  background-color: ${(props: IProps) => props.backgrondColor};
  border-top-left-radius: ${Sizes.borderModalsRadius + 10}px;
`;



export const ActionButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const DetailsIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const DetailsButtonText = styled.p`
  display: flex;
  flex: 1;
  min-width: 80px;
  color: white;
  font-size: 14px;
  text-align: center;
`;
