import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardBagBuyButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const BagPlaceImage = styled.img`
  width: 90px;
  height: 90px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;



export const CardBagBuyView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 13px;
`;


export const CardBagText = styled.h1`
  color: white;
  font-size: 18px;
  font-weight: bold;
`;

export const CardBagSubtitle = styled.h2`
  margin-top: 2px;
  margin-bottom: 5px;
  color: white;
  font-size: 16px;
`;

export const CardBagCommentsText = styled.h3`
  color: gray;
  font-size: 13px;
`;


export const CardBagControlsView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const CardBagPriceText = styled.p`
  color: ${Colors.buttonOkLight};
  font-size: 20px;
  height: 25px;
  text-align: right;
  font-weight: bold;
`;
