import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardDashContainer = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const LoginImageBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CardDashView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
`;


export const InfoImageHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 10px;
  padding-bottom: 3px;
`;

export const InfoTextHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CreditCardText = styled.h1`
  color: ${Colors.white};
  font-size: 19px;
  font-weight: bold;
`;

export const BadgeTextCard = styled.p`
  height: 18px;
  margin-top: 2px;
  margin-left: 15px;
  padding-left: 12px;
  padding-right: 12px;
  color: ${Colors.white};
  background-color: ${Colors.primary};
  border-radius: 10px;
  font-size: 12px;
`;


export const CardName = styled.h2`
  color: ${Colors.textApp};
  font-size: 17px;
  font-weight: bold;
`;


export const ImageBank = styled.img`
  height: 65px;
  width: 65px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: contain;
`;
