import styled from 'styled-components';



export const SearchChipContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;
