import {
  FormatCarnivalCircuitType,
  FormatCarnivalMarkerType,
  FormatCarnivalPeriodType,
} from '../config/carnival.config';

import {
  ECarnivalCircuit,
} from '../enums/Carnival/carnival-circuit.enum';

import {
  ECarnivalLocation,
} from '../enums/Carnival/carnival-location.enum';

import {
  ECarnivalPeriod,
} from '../enums/Carnival/carnival-period.enum';



export const PeriodTypeArray = [
  {
    id: 1,
    label: FormatCarnivalPeriodType(ECarnivalPeriod.OFFICIAL),
    value: ECarnivalPeriod.OFFICIAL,
  },
  {
    id: 2,
    label: FormatCarnivalPeriodType(ECarnivalPeriod.MICARETA),
    value: ECarnivalPeriod.MICARETA,
  },
];



export const CircuitTypeArray = [
  {
    id: 2,
    label: FormatCarnivalCircuitType(ECarnivalCircuit.CIRCUIT),
    value: ECarnivalCircuit.CIRCUIT,
    type: ECarnivalCircuit.CIRCUIT,
  },
  {
    id: 3,
    label: FormatCarnivalCircuitType(ECarnivalCircuit.DISTRICT),
    value: ECarnivalCircuit.DISTRICT,
    type: ECarnivalCircuit.DISTRICT,
  },
];



export const CarnivalLocationsTypeArray = [
  {
    id: 17,
    label: FormatCarnivalMarkerType(ECarnivalLocation.CIRCUIT),
    value: ECarnivalLocation.CIRCUIT,
  },
  {
    id: 1,
    label: FormatCarnivalMarkerType(ECarnivalLocation.BLOC),
    value: ECarnivalLocation.BLOC,
  },
  {
    id: 2,
    label: FormatCarnivalMarkerType(ECarnivalLocation.CABIN),
    value: ECarnivalLocation.CABIN,
  },
  {
    id: 3,
    label: FormatCarnivalMarkerType(ECarnivalLocation.POLICE),
    value: ECarnivalLocation.POLICE,
  },
  {
    id: 4,
    label: FormatCarnivalMarkerType(ECarnivalLocation.BUS),
    value: ECarnivalLocation.BUS,
  },
  {
    id: 5,
    label: FormatCarnivalMarkerType(ECarnivalLocation.TAXI),
    value: ECarnivalLocation.TAXI,
  },
  {
    id: 6,
    label: FormatCarnivalMarkerType(ECarnivalLocation.BATHROOM),
    value: ECarnivalLocation.BATHROOM,
  },
  {
    id: 7,
    label: FormatCarnivalMarkerType(ECarnivalLocation.FIREMAN),
    value: ECarnivalLocation.FIREMAN,
  },
  {
    id: 8,
    label: FormatCarnivalMarkerType(ECarnivalLocation.SAVE_LIFE),
    value: ECarnivalLocation.SAVE_LIFE,
  },
  {
    id: 9,
    label: FormatCarnivalMarkerType(ECarnivalLocation.MEDICAL_POINT),
    value: ECarnivalLocation.MEDICAL_POINT,
  },
  {
    id: 10,
    label: FormatCarnivalMarkerType(ECarnivalLocation.JUVENILE),
    value: ECarnivalLocation.JUVENILE,
  },
  {
    id: 11,
    label: FormatCarnivalMarkerType(ECarnivalLocation.OTHER_ATTRACTION),
    value: ECarnivalLocation.OTHER_ATTRACTION,
  },
  {
    id: 12,
    label: FormatCarnivalMarkerType(ECarnivalLocation.NOITADA),
    value: ECarnivalLocation.NOITADA,
  },
  {
    id: 13,
    label: FormatCarnivalMarkerType(ECarnivalLocation.AGENCY_FLOW),
    value: ECarnivalLocation.AGENCY_FLOW,
  },
  {
    id: 14,
    label: FormatCarnivalMarkerType(ECarnivalLocation.AGENCY_PUBLIC),
    value: ECarnivalLocation.AGENCY_PUBLIC,
  },
  {
    id: 15,
    label: FormatCarnivalMarkerType(ECarnivalLocation.CLEAN_HEALTH),
    value: ECarnivalLocation.CLEAN_HEALTH,
  },
  {
    id: 16,
    label: FormatCarnivalMarkerType(ECarnivalLocation.AREA_RESERVED),
    value: ECarnivalLocation.AREA_RESERVED,
  },
];
