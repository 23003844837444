import React from 'react';

import {
  _setLevelColor,
  _setLevelText,
} from '@noitada/shared/config/user-levels.config';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EUserLevel,
} from '@noitada/shared/enums';

import {
  CheckInUserLevel,
} from './styled';



export interface IProps {
  level: string;
}



const BadgeLevel: React.FC<IProps> = (props) => {
  const levelProps = props?.level || '';

  function setColorByLevel() {
    return levelProps.toUpperCase() === EUserLevel.KING ? Colors.black : Colors.white;
  }

  function setBackbroundColorByLevel() {
    return _setLevelColor(levelProps);
  }



  return (

    <CheckInUserLevel
      color={setColorByLevel()}
      backgroundColor={setBackbroundColorByLevel()}>
      {_setLevelText(levelProps)}
    </CheckInUserLevel>

  );
};



export default BadgeLevel;
