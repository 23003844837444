import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Epigraph,
} from '../../../../../../components/Composh/web';

import ItemsList from '../../../../../../components/Items/ItemsList';

import {
  DetailsSectionView,
} from '../../../../../../styles/styled.layout';

import {
  PrideImage,
} from '../styled';



export interface IProps {
  setFieldValue: any;
}



const SectionOtherParams: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const setFieldValue = props.setFieldValue;

  const [lgbt, setLgbt] = useState(false);


  function lgbtEvent(value: boolean) {
    setLgbt(value);
    setFieldValue('lgbt', value);
  }


  function renderItemList(
    icon: any,
    title: string | null,
    titleColor: string,
    onPress: any,
  ) {
    return (

      <ItemsList
        iconLeft={icon}
        title={title}
        titleColor={titleColor}
        onPress={onPress}
      />

    );
  }



  return (

    <DetailsSectionView>

      <Epigraph.Section
        text={translate(TranslateConfig.PARAMETERS)}
        textColor={Colors.primary}
        borderBottomColor={Colors.primary}
      />


      {renderItemList(
        <PrideImage
          alt={'pride'}
          src={lgbt ? Images.flagPrideColor : Images.flagPrideGray}
        />,
        translate(TranslateConfig.EVENT_IDEAL_LGBT),
        lgbt ? Colors.primary : Colors.white,
        () => {
          lgbtEvent(!lgbt);
        },
      )}

    </DetailsSectionView>

  );
};



export default SectionOtherParams;
