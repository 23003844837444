import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetCarnivalGuides,
} from '@noitada/axios-config/endpoints/carnival.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Title,
  Screen,
  Body,
  SubTitle,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import TabBarStyled from '../../../components/TabBar';

import TabGuideInformations from './Tabs/TabInformations';
import TabGuidePhone from './Tabs/TabPhone';



const CarnivalGuidesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const carnivalLocalities = useSelector((state: RootState) => state.localities.selected);


  const [stateIndex, setStateIndex] = useState(0);

  const [guideList, setGuideList] = useState<Array<any>>([]);
  const [phonesList, setPhonesList] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);


  const screenTabs = [
    {
      name: 'PhonesCarnival',
      title: translate(TranslateConfig.PHONES_USEFUL),
      component: (
        <TabGuidePhone
          loading={loading}
          data={phonesList}
        />
      ),
    },
    {
      name: 'GuidesCarnival',
      title: translate(TranslateConfig.INFORMATIONS),
      component: (
        <TabGuideInformations
          loading={loading}
          data={guideList}
        />
      ),
    },
  ];



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getMapList() {
    try {
      setLoading(true);

      const response = await GetCarnivalGuides(carnivalLocalities);

      if (response) {
        setPhonesList(response?.phones);
        setGuideList(response?.guides);
      }
      else {
        setPhonesList([]);
        setGuideList([]);

        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_GET_DATA));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }



  useEffect(() => {
    getMapList();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.PHONES_USEFUL)}
            </Title>

            <SubTitle
              color={Colors.gray}>
              {carnivalLocalities?.city || '-'}
            </SubTitle>
          </Body>
        }
        noSubToolbarPadding>


        <TabBarStyled
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tabIndex: number) => {
            setStateIndex(tabIndex);
          }}
        />

      </HeaderScreen>



      {stateIndex === 0 && screenTabs[0].component}

      {stateIndex === 1 && screenTabs[1].component}

    </Screen>

  );
};



export default CarnivalGuidesScreen;
