import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  EventAvailablesArray,
} from '@noitada/shared/arrays/event-availables.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  Items,
} from '../../../../../../components/Composh/web';

import {
  returnIconAvailables,
} from '../../../../../../config/icon-availables.config';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../../styles/styled.layout';

import {
  DetailsAvailableContent,
} from './styled';



export interface IProps {
  availables: string;
}



const DetailsAvailables: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [availableList, setAvailableList] = useState([]);



  function renderAvailables() {
    const colorIcon = Colors.white;
    const itemAvailableList: any = [];

    if (validateString(props.availables)) {
      const availablesEdit: Array<string> = props.availables?.split(', ') || [];

      if (availablesEdit.length > 0) {
        availablesEdit.forEach((item: string) => {
          const labelUser = EventAvailablesArray.find((itemValue) => itemValue.value === item);

          if (labelUser) {
            itemAvailableList.push({
              icon: returnIconAvailables(labelUser?.value, colorIcon, 35),
              label: labelUser?.label,
            });
          }
        });
      }
    }

    setAvailableList(itemAvailableList);
  }


  function renderItemList(item: any, index: number) {
    return (

      <Items.Grid
        key={index}
        icon={item?.icon}
        iconGridLabel={translate(item.label)}
        iconGridColor={Colors.white}
        labelStyle={{ marginTop: 10, fontSize: 14 }}
      />

    );
  }



  useEffect(() => {
    renderAvailables();
  }, [props.availables]);



  return availableList.length > 0
    ? (
      <DetailsSectionView>

        <SubtitleDetails>
          {translate(TranslateConfig.AVAILABLES)}
        </SubtitleDetails>



        <DetailsAvailableContent>
          {availableList.map((item: string, index: number) => (
            renderItemList(item, index)
          ))}
        </DetailsAvailableContent>

      </DetailsSectionView>
    )
    : null;
};



export default DetailsAvailables;
