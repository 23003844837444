import React from 'react';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import BadgeLevel from '../../../Badges/BadgeLevel';

import {
  IconVerified,
} from '../../../Icons';

import {
  CardInfoPersonContainer,
  CardInfoPersonImageView,
  CardInfoPersonImage,
  CardInfoPersonTypeBadge,
  CardReserveInfoView,
  CardInfoPersonInfoView,
  CardInfoPersonRowView,
  CardInfoPersonUsernameText,
  CardInfoPersonVerifiedIcon,
  CardInfoPersonCakeImage,
  CardInfoPersonPublicNameText,
} from './styled';



export interface IProps {
  enabled?: boolean;
  image?: string;
  name?: string;
  username?: string;
  birthday?: boolean;
  verified?: boolean;
  level: string;
  deleteIcon?: any;
}



const CardReserveInfoPerson: React.FC<IProps> = (props: IProps) => {
  return (

    <CardInfoPersonContainer>

      <CardInfoPersonImageView>

        <CardInfoPersonImage
          src={props.image === '' || props.image === null || props.image === undefined
            ? Images.placeholderAvatarUser
            : props.image
          }
        />

        <CardInfoPersonTypeBadge>
          <BadgeLevel
            level={props.level}
          />
        </CardInfoPersonTypeBadge>

      </CardInfoPersonImageView>


      <CardReserveInfoView>

        <CardInfoPersonInfoView>
          <CardInfoPersonRowView>
            <CardInfoPersonUsernameText>
              {props.username || '-'}
            </CardInfoPersonUsernameText>


            {props.verified && (
              <CardInfoPersonVerifiedIcon>
                <IconVerified
                  color={Colors.primary}
                  size={15}
                />
              </CardInfoPersonVerifiedIcon>
            )}
          </CardInfoPersonRowView>


          <CardInfoPersonRowView>
            {props.birthday && (
              <CardInfoPersonCakeImage
                src={Images.stickerCake}
              />
            )}


            <CardInfoPersonPublicNameText>
              {props.name || '-'}
            </CardInfoPersonPublicNameText>
          </CardInfoPersonRowView>

        </CardInfoPersonInfoView>



        {props.deleteIcon}

      </CardReserveInfoView>

    </CardInfoPersonContainer>

  );
};



export default CardReserveInfoPerson;;
