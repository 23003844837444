import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetLocalitiesApp,
} from '@noitada/axios-config/endpoints/localities.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  languagesOption,
} from '@noitada/shared/arrays/languages.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  stringRawDataText,
  stringUpperCase,
} from '@noitada/shared/utils/string.utils';

import {
  Body,
  Container,
  Overlay,
  SubTitle,
  Title,
} from '../../components/Composh/web';

import {
  ButtonBack,
  ButtonBackSkip,
} from '../../components/Controls';

import FooterChoose from '../../components/Footers/FooterChoose';
import HeaderScreen from '../../components/Headers/HeaderScreen';
import LoadingScreen from '../../components/Loadings/LoadingScreen';

import {
  ButtonText,
} from '../../styles/styled.layout';

import LocationCitiesScreen from './Cities';
import LocationCountriesScreen from './Countries';

import {
  CategoryChooseContainer,
  LocationCountryButton,
  LocationCountryImage,
} from './styled';



export interface IProps {
  visible?: boolean;
  type: 'APP' | 'CARNIVAL';
  action: 'RETURN' | 'DISPATCH';
  currentCity?: string;
  onCancelPress?: any;
  onPress?: any;
}



const LocationsScreen: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const localitiesApp = useSelector((state: RootState) => state.localities.selected);

  const appActualCityName = props.currentCity || localitiesApp.city;
  const [appCountryUser, setAppCountryUser] = useState(localitiesApp.country);

  // FIXME: Buscar pelo codigo
  const filterLangCountry = languagesOption.find((country) => stringRawDataText(country.namePt) === stringRawDataText(appCountryUser));


  const widthLine = 330;
  const heightLine = 610;

  const [showCountries, setShowCountries] = useState<boolean>(false);

  const [data, setData] = useState<Array<any>>([]);

  const [countriesNoitada, setCountriesNoitada] = useState<Array<any>>([]);
  const [locationsNoitada, setLocationsNoitada] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function goBack() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  };


  function returnCountryListUser(response: any) {
    const countryArray: Array<any> = [];

    if (response?.length > 0) {
      response.forEach((itemCountry) => {
        countryArray.push({
          title: itemCountry?.countryName,
          code: '', // FIXME 'BRA',
          currency: '', // FIXME 'BRL (R$)',
          image: '', // FIXME
        });
      });

      setCountriesNoitada(countryArray);

      const countryShowList = response?.find((country: any) => {
        const itemA = stringRawDataText(stringUpperCase(country?.countryName));
        const itemB = stringRawDataText(stringUpperCase(appCountryUser));
        return itemA === itemB;
      });

      const arrayStates = countryShowList?.states?.map((e: any) => {
        if (e?.stateName && e?.cities) return { title: e?.stateName, data: e?.cities };
      })?.filter((itemFilter) => !!itemFilter);

      if (!arrayStates[0]) {
        setLocationsNoitada([]);
        return;
      }

      setLocationsNoitada(arrayStates);
    }
    else {
      setCountriesNoitada([]);
      setLocationsNoitada([]);
    }
  }


  const getApiLocalities = async () => {
    try {
      setLoading(true);

      const response = await GetLocalitiesApp();

      if (response) {
        setData(response?.localities);
        returnCountryListUser(response?.localities);
      }
      else {
        setData([]);
        returnCountryListUser([]);
      }
    }
    catch (error: any) {
      console.error(error);
      setData([]);
      returnCountryListUser([]);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    };
  };


  function returnButtonLeft() {
    if (showCountries) {
      return (

        <ButtonBackSkip
          color={Colors.white}
          size={20}
          onPress={() => {
            setShowCountries(false);
          }}
        />

      );
    }
    else {
      return (

        <ButtonBack
          close
          color={Colors.white}
          onPress={() => {
            goBack();
          }}
        />

      );
    }
  };


  function returnTitle() {
    if (showCountries) {
      return (

        <Title>
          {translate(TranslateConfig.COUNTRIES)}
        </Title>

      );
    }
    else {
      return (

        <Body>
          <Title>
            {translate(TranslateConfig.CITIES)}
          </Title>

          <SubTitle>
            {translate(filterLangCountry?.label || '') || '-'}
          </SubTitle>
        </Body>

      );
    }
  };



  useEffect(() => {
    if (appCountryUser && data[0]) {
      returnCountryListUser(data);
    }
  }, [appCountryUser]);


  useEffect(() => {
    getApiLocalities();
  }, []);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <CategoryChooseContainer
        width={widthLine}
        height={heightLine}>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={returnButtonLeft()}
          centerContent={returnTitle()}
          rightIcon={!showCountries && (
            <LocationCountryButton
              onClick={() => {
                setShowCountries(true);
              }}>
              <LocationCountryImage
                alt={appCountryUser}
                src={filterLangCountry?.flagImage}
              />
            </LocationCountryButton>
          )}>

          {/* 
        TODO: Fazer o SearchBar
        <SubTitle>
          SearchBar
        </SubTitle>
        */}

        </HeaderScreen>



        <Container>

          {loading && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}


          {!loading && !showCountries && (
            <LocationCitiesScreen
              data={locationsNoitada}
              action={props.action}
              currentCity={appActualCityName}
              countrySelected={appCountryUser}
              onCityPress={(status: any) => {
                if (props.action === 'RETURN') {
                  if (status && props.onPress) {
                    props.onPress(status);
                  }
                }
                else {
                  goBack();
                }
              }}
            />
          )}


          {!loading && showCountries && (
            <LocationCountriesScreen
              data={countriesNoitada}
              countrySelected={appCountryUser}
              onCountryPress={(country: string | number) => {
                if (country) {
                  setAppCountryUser(String(country));
                  setShowCountries(false);
                }
              }}
            />
          )}

        </Container>



        <FooterChoose
          hideCancelButton

          okColor={ArrayColors.arrayCancel}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_CLOSE)}
            </ButtonText>
          }
          okPress={() => {
            goBack();
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default LocationsScreen;
