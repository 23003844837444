import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const KnowAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  background-color: ${Colors.primaryDark};
`;


export const KnowAppImageContainer = styled.a`
  display: flex;
  flex-direction: column;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  margin-right: 12px;
  padding: 10px;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const KnowAppImageLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;



export const KnowAppInfoContent = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const KnowAppInfoTitle = styled.h2`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
`;

export const KnowAppInfoText = styled.p`
  color: ${Colors.textApp};
  font-size: 13px;
`;



export const KnowAppButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 100%;
  min-height: 100%;
  margin-left: 15px;
`;
