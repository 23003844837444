import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  companyDrinksArray,
} from '@noitada/shared/arrays/company-drinks.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  Chip,
} from '../../../../../../components/Composh/web';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../../styles/styled.layout';

import {
  DetailsMusicGenreContent,
} from './styled';



export interface IProps {
  musicGenre: string;
}



const DetailsMusicGenre: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [availableList, setAvailableList] = useState([]);



  function renderAvailables() {
    const itemAvailableList: any = [];

    if (validateString(props.musicGenre)) {
      const availablesEdit: Array<string> = props.musicGenre?.split(', ') || [];

      if (availablesEdit.length > 0) {
        availablesEdit.forEach((item: string) => {
          const labelUser = companyDrinksArray.find((itemValue) => itemValue.value === item);

          if (labelUser) {
            itemAvailableList.push(item);
          }
        });
      }
    }

    setAvailableList(itemAvailableList);
  }


  function renderItemList(item: any, index: number) {
    return (

      <Chip
        key={index}
        selected
        title={translate(item.label)}
        backgroundColor={Colors.grayLight}
        textColor={Colors.primary}
        selectedBackgroundColor={Colors.primary}
        selectedTextColor={Colors.white}
      />

    );
  }



  useEffect(() => {
    renderAvailables();
  }, [props.musicGenre]);



  return availableList.length > 0
    ? (
      <DetailsSectionView>

        <SubtitleDetails>
          {translate(TranslateConfig.EVENT_MUSICAL_GENRES)}
        </SubtitleDetails>



        <DetailsMusicGenreContent>
          {availableList.map((item: string, index: number) => (
            renderItemList(item, index)
          ))}
        </DetailsMusicGenreContent>

      </DetailsSectionView>
    )
    : null;
};



export default DetailsMusicGenre;
