import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Body,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  ButtonText,
} from '../../../../styles/styled.layout';

import {
  ReserveRevisionRulesContainer,
  ReserveOkTitle,
  ReserveOkImage,
  ReserveRevisionView,
  ReserveRevisionTitle,
  ReserveRevisionRulesView,
  RevisionRuleCard,
  RevisionRuleViewIndex,
  RevisionRuleIndex,
  RevisionRuleText,
} from './styled';



const ReserveConfirmedScreen: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const companySelected = useSelector((state: RootState) => state.company.selected);


  const rules = [
    {
      description: translate(TranslateConfig.HELP_RESERVE_MINUTES),
    },
    {
      description: translate(TranslateConfig.HELP_RESERVE_AGE),
    },
    {
      description: translate(TranslateConfig.HELP_RESERVE_TABLE),
    },
    {
      description: translate(TranslateConfig.HELP_RESERVE_TAXES),
    },
    {
      description: translate(TranslateConfig.HELP_RESERVE_CANCEL),
    },
  ];



  function renderReservesRules(text: string, index: string) {
    return (

      <RevisionRuleCard
        key={index}>

        <RevisionRuleViewIndex>
          <RevisionRuleIndex>
            {index}
          </RevisionRuleIndex>
        </RevisionRuleViewIndex>


        <RevisionRuleText>
          {text}
        </RevisionRuleText>

      </RevisionRuleCard>

    );
  }


  function backPress() {
    history.goBack();
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
            onPress={() => {
              backPress();
            }}
          />
        }
        centerContent={
          <Body>
            <Title>
              {translate(TranslateConfig.RESERVES)}
            </Title>

            <SubTitle>
              {companySelected?.name}
            </SubTitle>
          </Body>
        }
      />


      <ReserveRevisionRulesContainer>

        <ReserveOkTitle>
          Reserva confirmada
        </ReserveOkTitle>



        <ReserveOkImage
          alt={'Ok Image'}
          src={Images.smile}
        />



        <ReserveRevisionView>

          <ReserveRevisionTitle>
            {translate(TranslateConfig.PAY_ATTENTION)}
          </ReserveRevisionTitle>


          <ReserveRevisionRulesView>

            {rules.map((item: any, index: number) => (
              renderReservesRules(
                item?.description,
                (index + 1).toString(),
              )
            ))}

          </ReserveRevisionRulesView>

        </ReserveRevisionView>

      </ReserveRevisionRulesContainer>



      <FooterChoose
        hideCancelButton

        okColor={ArrayColors.arrayOk}
        okLabel={
          <ButtonText
            color={Colors.white}>
            {translate(TranslateConfig.ACTION_CLOSE)}
          </ButtonText>
        }
        okPress={() => {
          backPress();
        }}
      />

    </Screen>

  );
};



export default ReserveConfirmedScreen;
