import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  SubtitleDetails,
  SubtitleText,
} from '../../../../../../styles/styled.layout';



export const TouchableTickets = styled.a`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 50px;
`;

export const TicketsPriceInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 50px;
  justify-content: center;
`;

export const TicketsMoreInfoTitle = styled(SubtitleDetails)`
  color: ${Colors.buttonOkLight};
  font-size: 18px;
`;

export const SeeTicketsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 110px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const TicketsMoreInfoText = styled(SubtitleText)`
  font-style: italic;
`;


export const SeeTicketsText = styled.p`
  color: ${Colors.buttonOkLight};
  font-size: 13px;
  font-weight: 600;
  text-align: center;
`;
