import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';



export const InfoCarnivalAccordionContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  background-color: ${Colors.cardBackground};
`;

export const InfoAccordionContentText = styled.p`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${Colors.white};
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  text-align: left;

  @media ${PlatformDevices.maxTablet} {
    font-size: 14px;
  }
`;

export const InfoAccordionContentImage = styled.img`
  height: 180px;
  width: 180px;
  margin-left: 20px;
  object-fit: cover;
  border-radius: 5px;

  @media ${PlatformDevices.maxTablet} {
    height: 100px;
    width: 100px;
  }
`;
