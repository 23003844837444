import React from 'react';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconArrowLightRight,
} from '../../../../components/Icons';

import NameRoutes from '../../../../navigation/names';

import {
  ContentPadder,
} from '../../../../styles/styled.layout';

import {
  FaqSectionTitle,
  FaqCategoryView,
  FaqCategoryBullet,
} from '../styled';



export interface IRouteParams {
  categories: any;
}



const FaqCategoryScreen: React.FC = () => {
  const history = useHistory();

  const routeLocation = useLocation<IRouteParams>();
  const params = routeLocation?.state;


  const categories = params?.categories;



  function renderItem(item: any) {
    return (

      <FaqCategoryView
        key={item.text}
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={() => {
          history.push({
            pathname: NameRoutes.FaqBulletScreen,
            state: {
              bullet: item,
            },
          });
        }}>

        <FaqCategoryBullet
          color={Colors.white}>
          {item.text}
        </FaqCategoryBullet>


        <IconArrowLightRight
          color={Colors.white}
          size={20}
        />

      </FaqCategoryView>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            Ajuda
          </Title>
        }
      />


      <Content>
        <ContentPadder>

          <FaqSectionTitle
            color={Colors.buttonOkLight}>
            {categories.title}
          </FaqSectionTitle>


          {categories.bullets.map((item) => (
            renderItem(item)
          ))}

        </ContentPadder>
      </Content>

    </Screen>

  );
};



export default FaqCategoryScreen;
