import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const SectionGalleryButton = styled.a`
  display: flex;
  flex-direction: column;
`;



export const SectionGalleryContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  margin-top: 5px;
`;

export const SectionGalleryImage = styled.img`
  width: 100%;
  max-height: 105;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const SectionGalleryMore6View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const SectionGalleryMore6Text = styled.p`
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 12px;
  font-style: italic;
  line-height: 20px;
  text-align: center;
`;
