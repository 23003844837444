import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Items,
} from '../../Composh/web';


export interface IProps {
  backgroundColor?: string;
}



export const InviteSocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const InviteSocialButton = styled(Items.Grid).attrs({
  labelStyle: {
    fontSize: 11,
  },
  iconGridColor: Colors.white,
})`
`;

export const InviteSocialIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.primary};
  border-radius: 2px;
`;
