import NoitadaWS from '../index';

// import {
//   MLocalities,
// } from '../models/Address/localities.model';



export const GetCompanies = async (payload: any) => {
  const url = '/companies';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetCompany = async (companyShortIdUsernameUsername: string) => {
  const url = `/companies/${companyShortIdUsernameUsername}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
