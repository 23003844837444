import styled from 'styled-components';

import {
  Body,
  Content,
} from '../../../components/Composh/web';



export const SearchResultBody = styled(Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7px;
`;

export const SearchFlatList = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
`;
