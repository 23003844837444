import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../../styles/styled.layout';

import {
  ParentalContainer,
  ParentalContent,
  ParentalIcon,
  ParentalSymbolText,
  ParentalText,
} from './styled';



export interface IProps {
  parentalRating: string;
}



const DetailsParentalGuidelines: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [availableList, setAvailableList] = useState([]);



  function parentalBackground(constAge: string) {
    switch (constAge) {
      case '0':
        return Colors.plus0;

      case '14':
        return Colors.plus14;

      case '16':
        return Colors.plus16;

      case '18':
        return Colors.plus18;

      default:
        return Colors.primaryDark;
    }
  }


  function parentalFullText(constAge: string) {
    switch (constAge) {
      case '0':
        return translate(TranslateConfig.EVENT_PARENTAL_NONE);

      case '14':
        return translate(TranslateConfig.EVENT_PARENTAL_14);

      case '16':
        return translate(TranslateConfig.EVENT_PARENTAL_16);

      case '18':
        return translate(TranslateConfig.EVENT_PARENTAL_18);

      default:
        return 'Classificação';
    }
  }


  function parentalValue(constAge: string) {
    switch (constAge) {
      case '0':
        return 'L';

      case '14':
        return '+14';

      case '16':
        return '+16';

      case '18':
        return '+18';

      default:
        return 'C';
    }
  }



  function renderAvailables() {
    const itemAvailableList: any = [];

    if (validateString(props.parentalRating)) {
      const availablesEdit: Array<string> = props.parentalRating?.split(', ') || [];

      if (availablesEdit.length > 0) {
        availablesEdit.forEach((item: string) => {
          itemAvailableList.push(item);
        });
      }
    }

    setAvailableList(itemAvailableList);
  }


  function renderItemList(item: string, index: number) {
    return (

      <ParentalContent
        key={index}>

        <ParentalIcon
          backgroundColor={parentalBackground(item)}>

          <ParentalSymbolText>
            {parentalValue(item)}
          </ParentalSymbolText>

        </ParentalIcon>



        <ParentalText>
          {parentalFullText(item)}
        </ParentalText>

      </ParentalContent>

    );
  }



  useEffect(() => {
    renderAvailables();
  }, [props.parentalRating]);



  return availableList?.length > 0
    ? (
      <DetailsSectionView>

        <SubtitleDetails>
          {translate(TranslateConfig.EVENT_PARENTAL_RATING)}
        </SubtitleDetails>



        <ParentalContainer>
          {availableList?.map((item: string, index: number) => (
            renderItemList(item, index)
          ))}
        </ParentalContainer>

      </DetailsSectionView>
    )
    : null;
};



export default DetailsParentalGuidelines;
