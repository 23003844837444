import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CardHistoryContainer,
  CardHistoryImage,
  HistoryInfoView,
  HistorySheetTitle,
  HistoryStatusView,
  HistorySheetStatusText,
  HistorySheetHourText,
  HistorySheetPriceText,
} from './styled';



export interface IProps {
  image?: string | null;
  title?: string | null;
  time?: string | null;
  status?: string | null;
  price?: string | null;
}



const CardOrderSheetHistory: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function renderStatusColor() {
    if (!props.status) {
      return Colors.gray;
    }
    switch (props.status?.toUpperCase()) {
      case 'CREATED':
        return Colors.gray;

      case 'PREPARING':
        return Colors.attention;

      case 'COMPLETE':
        return Colors.sketch;

      case 'CANCELLED':
        return Colors.danger;

      case 'FINISH':
        return Colors.accept;

      case 'DECLINE':
        return Colors.danger;

      default:
        return Colors.gray;
    }
  }


  function renderStatusText() {
    if (!props.status) {
      return '-';
    }
    switch (props.status?.toUpperCase()) {
      case 'CREATED':
        return translate(TranslateConfig.ORDER_PLACED);

      case 'PREPARING':
        return translate(TranslateConfig.ORDER_PREPARING);

      case 'COMPLETE':
        return translate(TranslateConfig.ORDER_TO_DELIVER);

      case 'CANCELLED':
        return translate(TranslateConfig.CANCELED);

      case 'FINISH':
        return translate(TranslateConfig.FINISHED);

      case 'DECLINE':
        return translate(TranslateConfig.ORDER_REFUSED);
    }
  }



  return (

    <CardHistoryContainer>

      <CardHistoryImage
        src={props.image || Images.placeholderAvatarCompany}
        onError={(e: any) => {
          e.target.src = Images.placeholderAvatarCompany;
        }}
      />

      <HistoryInfoView>

        <HistorySheetTitle>
          {props.title}
        </HistorySheetTitle>


        <HistoryStatusView>
          {props.status && (
            <HistorySheetStatusText
              style={{
                color: renderStatusColor(),
              }}>
              {renderStatusText()}
            </HistorySheetStatusText>
          )}


          <HistorySheetHourText>
            {props.time}
          </HistorySheetHourText>
        </HistoryStatusView>

      </HistoryInfoView>



      {props.price && (
        <HistorySheetPriceText>
          {`${props.price}`}
        </HistorySheetPriceText>
      )}

    </CardHistoryContainer>

  );
};



export default CardOrderSheetHistory;
