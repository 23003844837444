import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const FavoriteOpacityView = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0.4;
`;


export const ServicesPlace = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 10px;
`;



export const SeeCountCompanyText = styled.p`
  width: 100%;
  margin-top: 8px;
  color: ${Colors.gray};
  font-size: 12px;
  text-align: center;
`;
