import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const TicketContentPadder = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Sizes.paddingContainer}px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
`;



export const TicketDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TicketDateView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const TicketDateText = styled.p`
  margin-left: 8px;
  color: ${Colors.white};
  font-size: 16px;
`;



// Ticket Card Items

export const CardTicketBuyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const CardTicketBuyHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CardTicketBuyIconNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${Colors.primary};
  border-radius: 50%;
`;

export const CardTicketBuyNumber = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
`;


export const CardTicketBuyinfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`;

export const CardTicketTitle = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 600;
`;

export const CardTicketBoughtUsername = styled.p`
  margin-bottom: 5px;
  color: ${Colors.textApp};
  font-size: 16px;
`;

export const CardTicketAccess = styled.p`
  margin-top: 1px;
  margin-bottom: 1px;
  color: ${Colors.textApp};
  font-size: 14px;
`;

export const CardTicketPrice = styled.p`
  color: ${Colors.accent};
  font-size: 18px;
  font-weight: 600;
  text-align: right;
`;



export const CardTicketChangePersonButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  background-color: ${Colors.buttonSolidDark};
  border-radius: ${Sizes.cardRadius}px;
`;

export const CardTicketChangePersonText = styled.p`
  margin-left: 10px;
  color: ${Colors.accent};
  font-size: 16px;
  text-align: center;
`;



export const CardTicketPaymentView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;

export const CardTicketPaymentChoose = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 5px;
`;
